import { types as modalActionTypes, modalTypes } from './modal';
import {
  TSCreateCommentPayload,
  types as opportunityTypes,
} from './opportunity/opportunities';
import {
  TSAddLabelsRequestPayload,
  TSCreateBatchPayload,
  TSCreateOpportunityRequestPayload,
  TSCurrentFiltersState,
  TSEditBatchMetadataPayload,
  TSEditBatchPayload,
  TSEditOpportunityPayload,
  TSOpportunitySeed,
  TSTransitionOpportunityPayloadPlusGainsight,
  initialMetaState,
  types as seedTypes,
} from './opportunity/opportunitySeeds';
import { TSProjectStage } from './opportunity/opportunityTypes';
import { TSMetaState } from './types';
export enum UserTrackedAction {
  NONE,
  OPPORTUNITY_KEYWORD_SEARCH,
  OPPORTUNITY_APPLY_FILTERS,
  OPPORTUNITY_UPDATE_BOOKMARKED_STATUS,
  OPPORTUNITY_VIEW_DETAIL,
  OPPORTUNITY_EDIT_TITLE,
  OPPORTUNITY_EDIT_DESCRIPTION,
  OPPORTUNITY_ADD_LABELS,
  OPPORTUNITY_REMOVE_LABEL,
  OPPORTUNITY_ADD_COMMENT,
  OPPORTUNITY_DELETE_ATTACHMENT,
  OPPORTUNITY_CREATE_BATCH,
  OPPORTUNITY_ADD_TO_BATCH,
  OPPORTUNITY_CHANGE_PROJECT_STAGE_FROM_LIST,
  OPPORTUNITY_CHANGE_PROJECT_STAGE_FROM_DETAIL,
}

export interface TSGainsightState {
  meta?: TSMetaState;
}

export const initialState: TSGainsightState = {
  meta: initialMetaState,
};

declare const window: any;

function onUpdateSearchFilters(payload: TSCurrentFiltersState) {
  window.aptrinsic('track', 'Opportunities: Update Filter', payload);
}

function onUpdateSearchTerm(payload: TSCurrentFiltersState) {
  window.aptrinsic('track', 'Opportunities: Update Search Term', {
    searchTerm: payload.searchKey,
  });
}

function onUpdateOpportunityBookmark(
  seedOrBatch: 'SEED' | 'BATCH',
  payload: {
    id: string;
    isBookmarked: boolean;
  }
) {
  window.aptrinsic('track', 'Opportunities: Update Bookmark', {
    ...payload,
    seedOrBatch,
  });
}

function onViewOpportunityDetails(payload: {
  modalProps: {
    opportunitySeedId: string;
    opportunityType: string;
  };
}) {
  window.aptrinsic('track', 'Opportunities: View Details', payload?.modalProps);
}

function onCreateOpportunityComment(payload: TSCreateCommentPayload) {
  window.aptrinsic('track', 'Opportunities: Add Comment', payload);
}

function onEditOpportunityTitle(
  seedOrBatch: 'SEED' | 'BATCH',
  id: string,
  title: string
) {
  window.aptrinsic('track', 'Opportunities: Edit Title', {
    seedOrBatch,
    id,
    title,
  });
}

function onEditOpportunityDescription(
  seedOrBatch: 'SEED' | 'BATCH',
  id: string,
  description: string
) {
  window.aptrinsic('track', 'Opportunities: Edit Description', {
    seedOrBatch,
    id,
    description,
  });
}

function onDeleteOpportunityAttachment(payload: TSCreateBatchPayload) {
  window.aptrinsic('track', 'Opportunities: Delete Attachment', payload);
}

function onCreateOpportunityBatch(payload: TSCreateBatchPayload) {
  window.aptrinsic('track', 'Opportunities: Create Batch', payload);
}

function onCreateOpportunitySuccess(payload: TSOpportunitySeed) {
  window.aptrinsic('track', 'Opportunities: Create Project', payload);
}

function onUpdateOpportunity(payload: TSCreateOpportunityRequestPayload) {
  window.aptrinsic('track', 'Opportunities: Update Project', payload);
}

function onAddRemoveOpportunityToBatch(payload: TSEditBatchPayload) {
  if (payload.addedOpportunityId) {
    window.aptrinsic('track', 'Opportunities: Add to Batch', {
      batchId: payload.batchId,
      opportunityId: payload.addedOpportunityId,
    });
  } else if (payload.removedOpportunityId) {
    window.aptrinsic('track', 'Opportunities: Remove from Batch', {
      batchId: payload.batchId,
      opportunityId: payload.addedOpportunityId,
    });
  }
}

function onAddOpportunityLabels(payload: TSAddLabelsRequestPayload) {
  window.aptrinsic('track', 'Opportunities: Add Labels', payload);
}

function onRemoveOpportunityLabel(
  seedOrBatch: 'SEED' | 'BATCH',
  id: string,
  removedLabelId: string
) {
  window.aptrinsic('track', 'Opportunities: Remove Label', {
    seedOrBatch,
    id,
    removedLabelId,
  });
}

function onTransitionOpportunityProjectStage(
  seedOrBatch: 'SEED' | 'BATCH',
  gainsightUserAction: UserTrackedAction,
  id: string,
  oldProjectStage: TSProjectStage,
  newProjectStage: TSProjectStage
) {
  if (
    gainsightUserAction ==
    UserTrackedAction.OPPORTUNITY_CHANGE_PROJECT_STAGE_FROM_DETAIL
  ) {
    window.aptrinsic(
      'track',
      'Opportunities: Change Project Stage in Details',
      {
        seedOrBatch,
        id,
        oldProjectStage,
        newProjectStage,
      }
    );
  } else {
    // from the list / kanban view
    window.aptrinsic(
      'track',
      'Opportunities: Change Project Stage in Kanban View',
      {
        seedOrBatch,
        id,
        oldProjectStage,
        newProjectStage,
      }
    );
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case seedTypes.UPDATE_CURRENT_FILTERS:
      if (
        action.payload?.gainsightUserAction ==
        UserTrackedAction.OPPORTUNITY_KEYWORD_SEARCH
      ) {
        onUpdateSearchTerm(action.payload);
      } else if (
        action.payload?.gainsightUserAction ==
        UserTrackedAction.OPPORTUNITY_APPLY_FILTERS
      ) {
        onUpdateSearchFilters(action.payload);
      }
      return initialState;
    case seedTypes.UPDATE_BOOKMARK_FOR_SEED:
      onUpdateOpportunityBookmark('SEED', action.payload);
      return initialState;
    case seedTypes.UPDATE_BOOKMARK_FOR_BATCH:
      onUpdateOpportunityBookmark('BATCH', action.payload);
      return initialState;
    case modalActionTypes.SHOW_MODAL:
      if (action?.modalType == modalTypes.MODAL_OPPORTUNITY_DETAIL) {
        onViewOpportunityDetails(action);
      }
      return initialState;
    case opportunityTypes.CREATE_BATCH_COMMENT:
    case opportunityTypes.CREATE_OPPORTUNITY_COMMENT:
      onCreateOpportunityComment(action.payload);
      return initialState;
    case seedTypes.EDIT_OPPORTUNITY: {
      const payload = action.payload as TSEditOpportunityPayload;
      onEditOpportunityDescription(
        'SEED',
        payload.opportunityId,
        payload.description
      );
      return initialState;
    }
    case seedTypes.EDIT_BATCH_METADATA: {
      const payload = action.payload as TSEditBatchMetadataPayload;
      if (
        payload?.gainsightUserAction == UserTrackedAction.OPPORTUNITY_EDIT_TITLE
      ) {
        onEditOpportunityTitle('BATCH', payload.batchId, payload.title);
      } else if (
        payload?.gainsightUserAction ==
        UserTrackedAction.OPPORTUNITY_EDIT_DESCRIPTION
      ) {
        onEditOpportunityDescription(
          'BATCH',
          payload.batchId,
          payload.description
        );
      } else if (
        payload?.gainsightUserAction ==
          UserTrackedAction.OPPORTUNITY_REMOVE_LABEL &&
        payload.removedLabelId
      ) {
        onRemoveOpportunityLabel(
          'BATCH',
          payload.batchId,
          payload.removedLabelId
        );
      }
      return initialState;
    }
    case seedTypes.ADD_LABELS:
      onAddOpportunityLabels(action.payload);
      return initialState;
    case seedTypes.CREATE_OPPORTUNITY_BATCH:
      onCreateOpportunityBatch(action.payload);
      return initialState;
    case opportunityTypes.DELETE_BATCH_ATTACHMENT:
    case opportunityTypes.DELETE_OPPORTUNITY_ATTACHMENT:
      onDeleteOpportunityAttachment(action.payload);
      return initialState;
    case seedTypes.EDIT_BATCH:
      onAddRemoveOpportunityToBatch(action.payload);
      return initialState;
    case seedTypes.CREATE_OPPORTUNITY_SUCCESS:
      onCreateOpportunitySuccess(action.payload);
      return initialState;
    case seedTypes.UPDATE_OPPORTUNITY: // the is the full edit, not just description
      onUpdateOpportunity(action.payload);
      return initialState;
    case seedTypes.TRANSITION_BATCH: {
      const payload =
        action.payload as TSTransitionOpportunityPayloadPlusGainsight;
      onTransitionOpportunityProjectStage(
        'BATCH',
        payload.gainsightUserAction,
        payload.opportunityId,
        payload.oldProjectStage,
        payload.projectStage
      );
      return initialState;
    }
    case seedTypes.TRANSITION_OPPORTUNITY: {
      const payload =
        action.payload as TSTransitionOpportunityPayloadPlusGainsight;
      onTransitionOpportunityProjectStage(
        'SEED',
        payload.gainsightUserAction,
        payload.opportunityId,
        payload.oldProjectStage,
        payload.projectStage
      );
      return initialState;
    }
    default:
      return state;
  }
};
