import axios from 'axios';
import { defaultHeaders, denaliApiBaseUrl } from '../../api';
import {
  buildURLSearchQuery,
  getDateRange,
  getRequestRange,
  handleAxiosError,
} from '../../api/utils';
import sustainabilityUsageData from '../../mockData/sustainability/sustainabilityUsageData';
import { UsageDataResponse } from '../../queries/resourceUsage';
import { isVariantActive } from '../../utils/variants';
import { ItemUsage, ResourceType } from '../types';
import { TSResourceItemUsage, TSResourceUsageDataRequest } from './electric';

export const API = {
  fetchUsageData(request: TSResourceUsageDataRequest) {
    const {
      customerId,
      fromDate,
      toDate,
      resourceType = ResourceType.ELECTRICITY,
      period,
    } = request;
    const { startDate, endDate } = getDateRange(fromDate, toDate, period);

    const { fromDate: start, toDate: end } = getRequestRange(
      startDate,
      endDate
    );

    const query = buildURLSearchQuery(
      { fromDate: start, toDate: end },
      request
    );

    if (isVariantActive('mock')) {
      const usageMockData = sustainabilityUsageData(
        resourceType,
        startDate.toString(),
        endDate.toString()
      );
      return Promise.resolve(usageMockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${denaliApiBaseUrl()}/customers/${customerId}/usage/${resourceType}?${decodeURIComponent(
      query
    )}`;

    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: UsageDataResponse }) => data)
      .catch(handleAxiosError);
  },
};

export const enhanceGroupUsageData = (
  group: ItemUsage,
  resourceType: ResourceType
): TSResourceItemUsage => {
  const { activeEnergy, volume, ...rest } = group;
  const usage =
    (resourceType == ResourceType.ELECTRICITY ? activeEnergy : volume) || [];
  return {
    ...rest,
    usage,
    totalUsage: usage.reduce((acc, cur) => acc + cur, 0),
  } as TSResourceItemUsage;
};
