import { AxiosError } from 'axios';

type ErrorResponseData = {
  status: number;
  message?: string;
  messages?: string[];
};

export type EnhancedAxiosError = AxiosError<ErrorResponseData> & {
  status: number;
};

/**
 * @throws {EnhancedAxiosError}
 */
export const enhanceAxiosError = (error: AxiosError<ErrorResponseData>) => {
  error.status = error.request.status;
  error.message =
    error.response?.data.message ||
    error.response?.data.messages?.[0] ||
    error.message;
  throw error;
};
