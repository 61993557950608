import { useEffect } from 'react';
import {
  generatePath,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

const useRedirect = (
  matchPattern: string,
  newPath: string,
  condition = true
) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchString = searchParams.toString()
    ? `?${searchParams.toString()}`
    : '';

  const match = useMatch(matchPattern);

  useEffect(() => {
    if (condition && match) {
      const path = generatePath(newPath, {
        '*': match.params['*'] ?? null,
      });
      navigate(`${path}${searchString}`, { replace: true });
    }
  }, [condition, match, navigate, newPath, searchString]);
};

export default useRedirect;
