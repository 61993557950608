import { faker } from '@faker-js/faker/locale/en';
import moment from 'moment';
import * as consts from '../../constants';
import { ItemUsage, Resolution, ResourceType } from '../../ducks/types';
import sites from '../sites';

const getResolutionPeriods = (fromDate, toDate) => {
  const start = moment(fromDate, consts.DATE_FORMAT_DATA_API_RESPONSE_NO_TZ);
  const dates: string[] = [];

  const end = toDate
    ? moment(toDate, consts.DATE_FORMAT_DATA_API_RESPONSE_NO_TZ).startOf(
        'month'
      )
    : moment(fromDate, consts.DATE_FORMAT_DATA_API_RESPONSE_NO_TZ).endOf(
        'month'
      );

  while (end >= start) {
    const currentDate = end.format(consts.DATE_FORMAT_DATA_API_RESPONSE_NO_TZ);
    dates.push(currentDate);
    end.subtract(1, 'month');
  }

  return dates.reverse();
};

const genRand = (min, max, decimalPlaces) => {
  return (Math.random() * (max - min) + min).toFixed(decimalPlaces) * 1;
};

const usageData = (
  resourceType: ResourceType,
  fromDate: string,
  toDate: string,
  site?: string
) => {
  const sitesIds = !site ? sites.map((site) => site.id) : site.split(',');
  const filterSites = sites.filter((site) => sitesIds.includes(site.id));
  const periods = getResolutionPeriods(fromDate, toDate);
  let totalUsage = 0;

  const summaryData = {
    grouping: 'site',
    from: moment
      .utc(moment(fromDate).utcOffset(0).startOf('day'))
      .format(consts.DATE_FORMAT_DATA_API_RESPONSE_NO_TZ),
    to: moment
      .utc(moment(toDate).utcOffset(0).endOf('day'))
      .format(consts.DATE_FORMAT_DATA_API_RESPONSE_NO_TZ),
    resolution: Resolution.MONTHLY,
  };

  const data = new Array(filterSites.length).fill(null).map((item, index) => {
    const usage = new Array(periods.length).fill(null).map(() => {
      return genRand(10000, 50000, 14);
    });

    const groupTotalUsage = usage.reduce((acc, cur) => acc + cur, 0);
    totalUsage += groupTotalUsage;

    const mockData = {
      groupName: sites[index].display,
      groupId: sites[index].id,
      calcSpend: faker.datatype.number(),
      ...(resourceType === ResourceType.ELECTRICITY
        ? { activeEnergy: usage }
        : { volume: usage }),
    } as ItemUsage;
    return mockData;
  });

  return {
    ts: periods,
    ...summaryData,
    totalUsage,
    data,
  };
};

export default usageData;
