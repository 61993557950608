import { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import ListSelector, { SelectStyles } from './ListSelector';

type TSProps = {
  canNextPage: boolean;
  canPreviousPage: boolean;
  gotoPage: (number) => void;
  nextPage: () => void;
  pageIndex: number;
  pageOptions: { length: number };
  pageSize: number;
  pageSizeChoices?: Array<number>;
  pageWindow?: number;
  previousPage: () => void;
  setPageSize: (number) => void;
};

export const PaginatorStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MainContentStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const PageSizeSelectorWrapperStyled = styled.div`
  align-self: flex-end;
  align-items: center;
  display: flex;

  ${SelectStyles} {
    margin-left: 8px;
    margin-top: 8px;
  }
`;

const PrevNextButtonDisabledStyles = css`
  background-color: #f5f5f5;
  color: ${({ theme }) => theme.colors.paginationNextColor};
`;

const PrevNextButtonStyled = styled.button<{
  disabled: boolean;
}>`
  border: 1px solid #e0e0e0;
  border-radius: 100px;
  height: 28px;
  margin: 0 12px;
  width: 28px;

  ${({ disabled }) => disabled && PrevNextButtonDisabledStyles};
`;

const PageNumberActiveStyles = css`
  background-color: ${({ theme }) => theme.colors.pageNumberActiveColor};
  color: #fff;
  font-weight: 600;
`;

const PageNumbersStyled = styled.div`
  display: flex;
`;

const PageNumberStyled = styled.div<{
  active: boolean;
}>`
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  justify-content: center;
  padding: 0 6px;
  margin: 0 4px;
  height: 24px;
  min-width: 24px;

  ${({ active }) => active && PageNumberActiveStyles};

  &:last-child {
    margin-right: 0;
  }
`;

const EllipsisStyled = styled.div`
  display: inline-block;
  margin: 0 8px;
`;

const Paginator = (props: TSProps) => {
  const {
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    pageIndex,
    pageOptions: { length: total },
    pageSize,
    pageSizeChoices = [10, 20, 50, 100, 500, 1000],
    pageWindow = 5,
    previousPage,
    setPageSize,
  } = props;

  const halfWindow = Math.floor(pageWindow / 2);
  let start;
  let end;

  if (total >= pageWindow && total <= pageWindow + 2) {
    start = 1;
    end = total;
  } else if (pageIndex > total - pageWindow) {
    start = Math.max(1, total - pageWindow + 1);
    end = total;
  } else {
    start = pageIndex + 1 < pageWindow ? 1 : pageIndex - halfWindow + 1;
    end =
      pageIndex + 1 < pageWindow
        ? Math.min(pageWindow, total)
        : Math.min(pageIndex + halfWindow + 1, total);
  }

  const showFirstPage = pageIndex + 1 >= pageWindow && total !== pageWindow;
  const showLastPage =
    pageIndex + 1 <= total - pageWindow + 1 && total !== pageWindow;

  const MainPageNumbers = useCallback(
    () => (
      <PageNumbersStyled>
        {new Array(end - start + 1).fill(null).map((_, index) => {
          const pageNumber = index + start - 1;
          return (
            <PageNumberStyled
              active={pageNumber === pageIndex}
              key={pageNumber}
              onClick={() => gotoPage(pageNumber)}
              data-gainsight-id='paginator-page-number-button'
            >
              {pageNumber + 1}
            </PageNumberStyled>
          );
        })}
      </PageNumbersStyled>
    ),
    [end, start, pageIndex, gotoPage]
  );

  const pageSizeItems = useMemo(
    () =>
      pageSizeChoices.map((pageSizeChoice) => ({
        id: `${pageSizeChoice}`,
        name: `${pageSizeChoice} Rows`,
      })),
    [pageSizeChoices]
  );

  const selectedPageSizeItem = useMemo(
    () =>
      pageSizeItems.find(
        (pageSizeItem) => Number(pageSizeItem.id) === pageSize
      ),
    [pageSize, pageSizeItems]
  );

  const handleUpdateSelectedPage = useCallback(
    ({ value }: { value: string }) => {
      setPageSize(Number(value));
    },
    [setPageSize]
  );

  return (
    <PaginatorStyled>
      <MainContentStyled>
        <PrevNextButtonStyled
          disabled={!canPreviousPage}
          onClick={() => previousPage()}
          data-gainsight-id='paginator-prev-next-button'
        >
          {'<'}
        </PrevNextButtonStyled>
        {showFirstPage && (
          <>
            <PageNumberStyled
              onClick={() => gotoPage(0)}
              active={pageIndex === 0}
              data-gainsight-id='paginator-page-number-button'
            >
              1
            </PageNumberStyled>
            <EllipsisStyled>...</EllipsisStyled>
          </>
        )}
        <MainPageNumbers />
        {showLastPage && (
          <>
            <EllipsisStyled>...</EllipsisStyled>
            <PageNumberStyled
              onClick={() => gotoPage(total - 1)}
              active={pageIndex === total - 1}
              data-gainsight-id='paginator-page-number-button'
            >
              {total}
            </PageNumberStyled>
          </>
        )}
        <PrevNextButtonStyled
          disabled={!canNextPage}
          onClick={() => nextPage()}
          data-gainsight-id='paginator-prev-next-button'
        >
          {'>'}
        </PrevNextButtonStyled>
      </MainContentStyled>
      <PageSizeSelectorWrapperStyled>
        Show:
        <ListSelector
          directionUp
          items={pageSizeItems}
          updateValue={handleUpdateSelectedPage}
          selectedItem={selectedPageSizeItem}
          unsettable={false}
          gainsightTagId='paginator-page-size-selector'
        />
      </PageSizeSelectorWrapperStyled>
    </PaginatorStyled>
  );
};

export default Paginator;
