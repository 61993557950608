/* eslint-disable max-len */

interface TSProps {
  color?: string;
  size?: string;
}

const ChevronLeftIcon = ({ color = '#162447', size = '16' }: TSProps) => (
  <svg fill={color} height={size} viewBox='0 0 16 16' width={size}>
    <path
      d='M3.2908 5.99999L6.8308 2.45999C7.01705 2.27263 7.12159 2.01918 7.12159 1.75499C7.12159 1.49081 7.01705 1.23736 6.8308 1.04999C6.73783 0.956266 6.62723 0.881872 6.50537 0.831103C6.38352 0.780334 6.25281 0.754196 6.1208 0.754196C5.98879 0.754196 5.85808 0.780334 5.73622 0.831103C5.61436 0.881872 5.50376 0.956266 5.4108 1.04999L1.1708 5.28999C1.07707 5.38296 1.00268 5.49356 0.951907 5.61542C0.901138 5.73728 0.875 5.86798 0.875 5.99999C0.875 6.13201 0.901138 6.26271 0.951907 6.38457C1.00268 6.50643 1.07707 6.61703 1.1708 6.70999L5.4108 11C5.50424 11.0927 5.61505 11.166 5.73689 11.2158C5.85873 11.2655 5.98919 11.2908 6.1208 11.29C6.2524 11.2908 6.38287 11.2655 6.5047 11.2158C6.62654 11.166 6.73736 11.0927 6.8308 11C7.01705 10.8126 7.12159 10.5592 7.12159 10.295C7.12159 10.0308 7.01705 9.77736 6.8308 9.58999L3.2908 5.99999Z'
      stroke={color}
    />
  </svg>
);

export default ChevronLeftIcon;
