const circuitsMeta = () => {
  return {
    results: [
      {
        fieldType: 'buildingSystem',
        id: null,
        name: 'Building System',
        values: [
          {
            id: 'f9385d6a-f6ed-5e68-b057-61a1e3f7f941',
            name: 'Lighting',
          },
          {
            id: '760f8ea2-5fa7-4ada-a187-dad36301aeca',
            name: 'Refrigeration',
          },

          { id: 'cbf5cfe2-3c6a-45ad-8bfd-7a346027efd2', name: 'HVAC' },
          { id: '7a49799e-42a9-4ef4-bfe7-7aa047c72953', name: 'Panel Feed' },
          {
            id: '00000000-0000-0000-0000-000000000000',
            name: 'Uncategorized',
          },
        ],
      },
      {
        fieldType: 'equipment',
        id: null,
        name: 'Equipment',
        values: [
          {
            id: '61701014-b4ab-43ea-9cb4-07bd9559fff9',
            name: 'Air Cooled Condenser - 2',
          },
          {
            id: '00000000-0000-0000-0000-000000000000',
            name: 'Uncategorized',
          },
        ],
      },
      {
        fieldType: 'circuit',
        id: null,
        name: 'Circuit',
        values: [
          {
            id: '5a451c29-2dc9-43a7-9dfd-a6b966ba1826',
            name: 'AUTOCLAVE HOTWATER TANK C',
          },
        ],
      },
      {
        fieldType: 'categoryValue',
        id: '5a451c29-2dc9-43a7-8dfd-sdfsbd74359nsjd',
        name: 'Color',
        values: [
          {
            id: 'c5d7f624-c177-4bad-8ff8-8ef6da6cf154:67b7d1fb-0aba-4847-a831-5b7e1c5a38e0',
            name: 'Red',
          },
          {
            id: 'c5d7f624-c177-4bad-8ff8-8ef6da6cf154:2b1c8650-93f2-4f43-8a42-ed08a77e6bc7',
            name: 'Blue',
          },
        ],
      },
      {
        fieldType: 'categoryValue',
        id: '3hngk89saaxd-2dc9-43a7-8dfd-a6b966ba1826',
        name: 'Size',
        values: [
          {
            id: '22867ad1-05b0-4a54-b3cf-a8ca36c255d5:27699f76-5d23-4013-a30f-8ef812d3e93e',
            name: 'Small',
          },
          {
            id: '22867ad1-05b0-4a54-b3cf-a8ca36c255d5:2b778fb9-b756-4a77-8a60-ceb8c02abc8d',
            name: 'Large',
          },
        ],
      },
    ],
  };
};

export default circuitsMeta;
