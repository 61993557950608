/* eslint-disable jsx-a11y/no-autofocus */
import { useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { EMPTY_METRIC_STRING } from '../../constants/strings';
import {
  actions as opportunitySeedsActions,
  getCombinedOpportunityAddress,
  TSOpportunitySeed,
  TSOpportunityBatch,
} from '../../ducks/opportunity/opportunitySeeds';
import AddToBatchIcon from '../Icons/AddToBatchIcon';
import BookmarkIcon from '../Icons/BookmarkIcon';
import { useNavigate } from 'react-router-dom';
import { queryStringify } from '../../api/utils';
import { Title, Flex, Stack, Text, Select } from '@mantine/core';
import { actions as modalActions } from '../../ducks/modal';
import { UilEdit, UilTrash } from '@iconscout/react-unicons';
import { getProjectStageValuesForSelect } from '../../ducks/opportunity/opportunityTypes';

const ParentSeedLink = styled.a`
  line-height: 16px;
  cursor: pointer;
  &:hover {
    color: #485da0;
    text-decoration: underline !important;
  }
`;

const SubtitleStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontMain};
  font-size: 14px;
  font-weight: 600;
`;

const OpportunityDetailsHeader = ({
  headerData: {
    opportunitySeed,
    isBatch,
    parentBatch,
    setShowAddToBatchModal,
    setShowRemoveOpportunityForm,
    transitionProjectStage,
  },
}: {
  headerData: {
    opportunitySeed: TSOpportunitySeed;
    isBatch: boolean;
    opportunitySeedId: string;
    parentBatch: TSOpportunityBatch | null;
    setShowAddToBatchModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowRemoveOpportunityForm: React.Dispatch<React.SetStateAction<boolean>>;
    transitionProjectStage: (newStage: string | null) => void;
  };
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const getOpportunityAddress = () => {
    const address = getCombinedOpportunityAddress(
      opportunitySeed as TSOpportunitySeed,
      'full'
    );
    return `${address != '' ? address : EMPTY_METRIC_STRING}`;
  };

  const handleBookmarkClick = (isBookmarked, id) => {
    if (!isBatch) {
      dispatch(
        opportunitySeedsActions.setBookmarkForSeed({ id, isBookmarked })
      );
    } else if (isBatch) {
      dispatch(
        opportunitySeedsActions.setBookmarkForBatch({ id, isBookmarked })
      );
    }
  };

  const handleShowRemoveOpportunityForm = (bShow: boolean) => {
    setShowRemoveOpportunityForm(bShow);
  };

  const projectStageValues = getProjectStageValuesForSelect();

  return (
    <Flex justify={'space-between'} gap={'sm'} align={'flex-start'}>
      <Stack gap={'sm'}>
        <Text c={'#6c6d6e'} fw={'500'}>
          {parentBatch?.id ? (
            <>
              <ParentSeedLink
                onClick={() => {
                  navigate(
                    `${location?.pathname}?${queryStringify({
                      opportunityId: parentBatch.id,
                      opportunityType: 'batch',
                      customerId: parentBatch.customerId,
                    })}`
                  );
                }}
              >
                {`${parentBatch.displayId}`}
              </ParentSeedLink>
              <span> / </span>
            </>
          ) : null}
          {opportunitySeed.displayId}
        </Text>
        <Title mb={'xs'} c={'black'}>
          {opportunitySeed.title != ''
            ? opportunitySeed.title
            : `${opportunitySeed.city}, ${opportunitySeed.state} - ${opportunitySeed.ecmType}`}
        </Title>
        {!isBatch && <SubtitleStyled>{getOpportunityAddress()}</SubtitleStyled>}
      </Stack>

      <Stack>
        <Flex align={'right'} gap={'16px'} mt={'4px'} ml='auto'>
          <Flex
            align={'center'}
            justify={'center'}
            h={'32px'}
            w={'32px'}
            style={{
              borderRadius: '100%',
              background: 'rgba(22, 36, 71, 0.1)',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleBookmarkClick(
                !opportunitySeed.bookmark,
                opportunitySeed.id
              );
            }}
          >
            <BookmarkIcon
              size='20'
              color={opportunitySeed.bookmark ? theme.colors.primary : '#fff'}
            />
          </Flex>
          {!isBatch && !parentBatch && (
            <Flex
              align={'center'}
              justify={'center'}
              h={'32px'}
              w={'32px'}
              style={{
                borderRadius: '100%',
                background: 'rgba(22, 36, 71, 0.1)',
                cursor: 'pointer',
              }}
              onClick={() => {
                setShowAddToBatchModal(true);
              }}
            >
              <AddToBatchIcon size='20' color='#6C6D6E' />
            </Flex>
          )}
          {
            <Flex
              align={'center'}
              justify={'center'}
              h={'32px'}
              w={'32px'}
              style={{
                borderRadius: '100%',
                background: 'rgba(22, 36, 71, 0.1)',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(
                  modalActions.showOpportunityCreateOrEditOpportunityModal({
                    opportunitySeed,
                    isEditMode: true,
                  })
                );
              }}
            >
              <UilEdit size='20' />
            </Flex>
          }
          <Flex
            align={'center'}
            justify={'center'}
            h={'32px'}
            w={'32px'}
            style={{
              background: 'rgba(212, 46, 46, 0.2)',
              borderRadius: '100%',
              cursor: 'pointer',
            }}
            onClick={() => handleShowRemoveOpportunityForm(true)}
          >
            <UilTrash size='20' color={'#D42E2E'} />
          </Flex>
        </Flex>
        <Select
          comboboxProps={{ zIndex: 100000 }}
          w={'272px'}
          size='md'
          radius={'md'}
          allowDeselect={false}
          data={projectStageValues}
          value={opportunitySeed?.projectStage ?? ''}
          onChange={(selectedOption) => {
            transitionProjectStage(selectedOption);
          }}
        />
      </Stack>
    </Flex>
  );
};

export default OpportunityDetailsHeader;
