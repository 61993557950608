import _, {
  difference,
  find,
  fromPairs,
  intersection,
  isEmpty,
  isEqual,
  omit,
  omitBy,
  reject,
} from 'lodash';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DATE_FORMAT_DATA_API_REQUEST } from '../../../constants';
import {
  Grouping,
  MeasurementTypes,
  Resolution,
  ResourceType,
} from '../../../ducks/types';
import {
  MetadataParams,
  useCachedResourceMetadata,
  useResourceMetadata,
} from '../../../queries/resourceMetadata';
import { useSites } from '../../../queries/sites';
import { titleCase } from '../../../utils';
import { omitNilEmpty, rejectNilEmpty } from '../../../utils/functional';
import { DataExplorerFilterParams } from '../types';
import changedFiltersNotification from '../utils/changedFiltersNotification';
import { useSelectedSiteIds } from '../utils/filterParams';

const compatibleResolutionsByResource: Record<ResourceType, Resolution[]> = {
  [ResourceType.ELECTRICITY]: [
    Resolution.DAILY,
    Resolution.HOURLY,
    Resolution.FIFTEEN_MINUTES,
  ],
  [ResourceType.WATER]: [Resolution.DAILY, Resolution.HOURLY],
  [ResourceType.NATURAL_GAS]: [
    Resolution.DAILY,
    Resolution.HOURLY,
    Resolution.FIFTEEN_MINUTES,
  ],
  [ResourceType.ALL]: [],
};

const moreThan30Days = (fromStr: string, toStr: string) => {
  const fromDate = moment(fromStr, DATE_FORMAT_DATA_API_REQUEST);
  const toDate = moment(toStr, DATE_FORMAT_DATA_API_REQUEST);

  return toDate.diff(fromDate, 'days') > 30;
};

export const measurementTypes = {
  [ResourceType.ELECTRICITY]: [
    MeasurementTypes.ACTIVE_ENERGY,
    MeasurementTypes.RMS_VOLTAGE,
  ],
  [ResourceType.WATER]: [MeasurementTypes.VOLUME],
  [ResourceType.NATURAL_GAS]: [MeasurementTypes.VOLUME],
};

const useValidFilterOptions = (filters: DataExplorerFilterParams) => {
  const { pageId = 'consumption-analysis' } = useParams();
  const { data: resourceMetadata = [] } = useResourceMetadata(filters);
  const metadataGroupings = resourceMetadata.map((group) => group.id);

  const defaultValidOptions = {
    grouping: metadataGroupings.length
      ? metadataGroupings
      : [
          Grouping.SITE,
          Grouping.PANEL,
          Grouping.BUILDING_SYSTEM,
          Grouping.EQUIPMENT,
          Grouping.CIRCUIT,
          Grouping.METER,
        ],
    resolution: [
      Resolution.DAILY,
      Resolution.HOURLY,
      Resolution.FIFTEEN_MINUTES,
    ],
    resourceType: [ResourceType.ELECTRICITY],
  };

  const validOptionsByPage = {
    'consumption-analysis': {
      resolution:
        filters.resourceType === ResourceType.ELECTRICITY &&
        moreThan30Days(filters.fromDate ?? '', filters.toDate ?? '')
          ? [Resolution.DAILY]
          : compatibleResolutionsByResource[filters.resourceType],
      resourceType: [
        ResourceType.ELECTRICITY,
        ResourceType.WATER,
        ResourceType.NATURAL_GAS,
      ],
    },
    'operating-hours': {
      grouping:
        filters.siteIds.length === 1 ? metadataGroupings : [Grouping.SITE],
      resolution: [Resolution.HOURLY],
    },
    'peak-demand': { resolution: [Resolution.FIFTEEN_MINUTES] },
    'voltage-analysis': { resolution: [Resolution.FIFTEEN_MINUTES] },
  };

  return { ...defaultValidOptions, ...validOptionsByPage[pageId] };
};

export const useUpdateInvalidBasicFilters = (
  filters: DataExplorerFilterParams,
  setFilters: (DataExplorerParams) => void
) => {
  const { data: sites = [] } = useSites();
  const siteIds = sites.map((site) => site.id);

  // Remove invalid measurementTypes
  useEffect(() => {
    if (
      !measurementTypes[filters.resourceType].includes(filters.measurementTypes)
    ) {
      setFilters((prevFilters) => omit(prevFilters, ['measurementTypes']));
    }
    // only run when resource type changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.resourceType]);

  // Remove invalid siteIds
  useEffect(() => {
    if (siteIds.length && !isEmpty(difference(filters.siteIds, siteIds))) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        siteIds: intersection(filters.siteIds, siteIds),
      }));
    }
    // only run when valid siteIds change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteIds]);
};

export const useMetadataFieldsWithoutSites = (
  filters: Omit<MetadataParams, 'customerId'>
) => {
  const metadataQuery = useCachedResourceMetadata(filters);

  const fieldsWithoutSites = useMemo(() => {
    return reject(metadataQuery.data, ['fieldType', 'site']).map((v) => ({
      ...v,
      options: v.values.map((option) => ({
        value: option.id,
        label: option.name,
      })),
    }));
  }, [metadataQuery.data]);

  return { ...metadataQuery, data: fieldsWithoutSites };
};

export const isAdvancedFilter = (fieldName: string) =>
  fieldName.match(/^categoryValue/) ||
  ['panel', 'equipment', 'circuit', 'buildingSystem', 'meter'].includes(
    fieldName
  );

const useFilterIdToName = () => {
  const { data: allfields } = useMetadataFieldsWithoutSites({
    resourceType: ResourceType.ELECTRICITY,
  });
  return (filterId: string) =>
    filterId.startsWith('categoryValue-')
      ? find(allfields, { id: filterId.replace('categoryValue-', '') })?.name
      : titleCase(filterId);
};

export const useRemoveInvalidAdvancedFilters = (
  filters: DataExplorerFilterParams,
  setFilters
) => {
  const { data: fieldsWithoutSites } = useMetadataFieldsWithoutSites(filters);
  const filterIdToName = useFilterIdToName();
  const { data: sites = [] } = useSites();
  const validFilterOptions = useValidFilterOptions(filters);
  const selectedSiteIds = useSelectedSiteIds();

  useEffect(() => {
    if (isEmpty(fieldsWithoutSites)) return;

    const fieldsValidOptions = fromPairs(
      fieldsWithoutSites.map(({ fieldName, options }) => [
        fieldName,
        options.map(({ value }) => value),
      ])
    );

    const updatedFilters = _(fieldsValidOptions)
      .pickBy(
        (validOptions, key) => !isEmpty(difference(filters[key], validOptions))
      )
      .mapValues((validOptions, key) =>
        intersection(validOptions, filters[key])
      )
      .value();

    const validFilters = omitBy(
      filters,
      (v, k) =>
        isAdvancedFilter(k) && !Object.keys(fieldsValidOptions).includes(k)
    );

    const filterChanges = _(validFilterOptions)
      .pickBy((values, key) => values.length && !values.includes(filters[key]))
      .mapValues((value) => value[0])
      .value();

    if (filters.options?.singleSiteSelect && selectedSiteIds.length > 1) {
      filterChanges['siteIds'] = [selectedSiteIds[0] ?? sites[0]?.id];
    }

    if (
      !isEmpty(updatedFilters) ||
      !isEqual(filters, validFilters) ||
      !isEmpty(filterChanges)
    ) {
      const invalidFilters = Object.keys(filterChanges);
      const removedKeys = Object.keys(omit(filters, Object.keys(validFilters)));
      const removedFilters = Object.keys(updatedFilters);
      const changedFilters = [
        ...invalidFilters,
        ...removedKeys,
        ...removedFilters,
      ];
      const filterNames = rejectNilEmpty<string>(
        changedFilters.map(filterIdToName)
      );

      changedFiltersNotification.addMessage(filterNames);
      setFilters(() => {
        return {
          ...validFilters,
          ...omitNilEmpty(updatedFilters),
          ...filterChanges,
        };
      });
    }

    // only run when valid filter options change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsWithoutSites, validFilterOptions]);
};
