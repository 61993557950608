import { getUsername } from '../api/utils';
import { TSCustomerResponse } from '../ducks/customers';
import { TSInvoice, TSInvoiceDetail } from '../ducks/projects/invoice';
import { TSSiteResponse } from '../ducks/sites';
import { ItemUsage } from '../ducks/types';
import {
  TSResourceMetaResponse,
  TSResourceMetaValue,
} from '../ducks/usageByCustomerMetaData/electricityMetaData';
import { UsageDataResponse } from '../queries/resourceUsage';
import { siteIdMask } from './demoSiteIdMask';

// Masked customer names for demo users
const customerIdMask = {
  'd39fe31f-22ef-41f0-ab74-0b00ebb24f3b': 'Manufacturing (Life Sciences)', // Avantor
  '938bd255-a9f3-4d11-888d-af68d8e37e4b': 'Real Estate Investment Trust (REIT)', // WPT Capital Advisors
};
interface SiteMask {
  name: string;
  city: string;
  postalCode: string;
  state: string;
  lat: number;
  lng: number;
}

const demoSiteIds = [...Object.keys(siteIdMask)];

// RDP-3519: The specific Redaptive demo user has a fixed list of sites that are allowed to be displayed.
export const isRedaptiveDemoUser = (username?: string): any =>
  username &&
  (username.toLowerCase() === 'demo@redaptiveinc.com' ||
    username.toLowerCase() === 'demo.denali@redaptiveinc.com'); //TODO remove?

export const isCurrentUserRedaptiveDemoUser = (): any =>
  isRedaptiveDemoUser(getUsername());

export const getSiteMask = (
  siteId: string,
  site: TSSiteResponse
): SiteMask => ({
  // include defaults, override w/ any values set in the site mask
  name: `${site.address.city} - ${site.address.state}`,
  lat: site.lat,
  lng: site.lng,
  city: site.address.city,
  state: site.address.state,
  postalCode: ((site.address.postalCode || '') + '111').substring(0, 2) + '305',
  ...siteIdMask[siteId],
});

export const getMaskedCustomerName = (
  customerId: string,
  fallback: string
): any => customerIdMask[customerId] || fallback;

export const demoCustomerFilter = ({ id }: TSCustomerResponse): any =>
  customerIdMask[id];

export const demoSiteFilter = ({ id }: TSSiteResponse): any =>
  demoSiteIds.includes(id);

const sanitizeSiteName = (site: TSSiteResponse) => {
  const siteMask = getSiteMask(site.id, site);
  /**
   * If demoSiteIdMask mapping doe not have explicitly set a name, lat, lng... for a site use what is explain
   * in the following ticket, therefore update siteIdMask for current site
   * @see YLW-628
   */
  siteIdMask[site.id] = siteMask;

  return {
    ...site,
    name: siteMask.name,
    validName: siteMask.name,
    display: siteMask.name,
    lat: siteMask.lat,
    lng: siteMask.lng,
    address: {
      ...site.address,
      address1: '',
      city: siteMask.city,
      state: siteMask.state,
      postalCode: siteMask.postalCode,
    },
  };
};

const sanitizeCircuitGroupName = (group: ItemUsage) => {
  const siteMask = siteIdMask[group.groupId];
  group.groupName = siteMask?.name;
  return siteMask?.name;
};

const sanitizeUsageMetaDataSiteGroupName = (group: TSResourceMetaValue) => {
  const siteMask = siteIdMask[group.id];
  group.name = siteMask?.name;
  return siteMask?.name;
};

/**
 * Filters and changes customer names for demo users.
 *
 * RDP-5251 To support only allowing particular customers to be associated with demo users.
 */
export const filterDemoCustomers = (
  customers: Array<TSCustomerResponse>
): Array<TSCustomerResponse> =>
  isCurrentUserRedaptiveDemoUser()
    ? customers.filter(demoCustomerFilter).map((customer) => ({
        ...customer,
        name: getMaskedCustomerName(customer.id, 'Customer Demo'),
        validName: getMaskedCustomerName(customer.id, 'Customer Demo'),
      }))
    : customers;

/**
 * Shows only specific sites and masks them for demo users.
 *
 * Currently only the Redaptive demo user has a filtered list of sites.
 * @see RDP-3519, RDP-3138, and RDP-3312.
 */
export const filterDemoSites = (response: Array<TSSiteResponse>): any =>
  response
    .filter(isCurrentUserRedaptiveDemoUser() ? demoSiteFilter : (site) => site)
    .map<TSSiteResponse>(
      isCurrentUserRedaptiveDemoUser() ? sanitizeSiteName : (site) => site
    );

/**
 * When multi-site is grouped by site, the site name
 * is returned in the response as the group name. Need to check for that here.
 * @see YLW-389
 */
export const filterDemoCircuitData = (response: UsageDataResponse): any => {
  if (isCurrentUserRedaptiveDemoUser() && response.grouping == 'site') {
    response.data = response.data.filter(sanitizeCircuitGroupName);
  }
};

/**
 * When using site meta data response for meted sites, the site name
 * is returned in the response as a fieldType. Need to check for that here.
 * @see YLW-631
 */
export const filterDemoUsageMetaData = (
  response: TSResourceMetaResponse
): any => {
  if (isCurrentUserRedaptiveDemoUser()) {
    response.results = response.results.map((group) => {
      if (group.fieldType === 'site') {
        return {
          ...group,
          values: group.values.filter(sanitizeUsageMetaDataSiteGroupName),
        };
      }
      return group;
    });
  }
};

/**
 * Shows only Invoices from the demoSites,
 * when demo user Logged in.
 * masks the sitename field in allSites, single site views
 * */
export const filterDemoInvoices = (invoiceData: TSInvoice[]) => {
  if (isCurrentUserRedaptiveDemoUser()) {
    return invoiceData
      .filter((invoice) => demoSiteIds.includes(invoice.summary.siteId))
      .map((invoice, index) => {
        return {
          ...invoice,
          summary: {
            ...invoice.summary,
            siteName: `${siteIdMask[invoice.summary.siteId].name} -${index}`,
          },
        };
      });
  }
  return invoiceData;
};

/**
 * gets the single invoice details when a demo user is logged in.
 * masks the sensitive data fields from the invoice details.
 * */
export const filterDemoInvoiceDetails = (invoiceDetails: TSInvoiceDetail) => {
  if (isCurrentUserRedaptiveDemoUser()) {
    return {
      ...invoiceDetails,
      //The string is a placeholder for masked customer name,
      // customerId is not available in InvoiceDetails Object as of now.
      customerName: 'Redaptive Customer',
      opportunityName: '',
      siteCode: '',
      opportunityId: '',
    };
  }
  return invoiceDetails;
};
