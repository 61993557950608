import { UilChartLine } from '@iconscout/react-unicons';
import DoubleDownIcon from '@iconscout/react-unicons/icons/uil-angle-double-down';
import DoubleUpIcon from '@iconscout/react-unicons/icons/uil-angle-double-up';
import {
  Card,
  Container,
  Grid,
  MantineStyleProps,
  Pill,
  useMantineTheme,
} from '@mantine/core';
import { every, omit } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';

import VoltageAnalysisChart from '../../components/CircuitsUsageDataView/VoltageAnalysisChart';
import Spinner from '../../components/Spinner';
import Stack from '../../components/Stack';
import Button from '../../components/mantine/Button';
import Pagination from '../../components/mantine/Pagination';

import Quantity from '../../components/mantine/Quantity';
import { DATE_FORMAT_DATA_API_RESPONSE_NO_TZ } from '../../constants';
import { REDAPTIVE_ONE_PAGE_TITLE } from '../../constants/strings';
import { useDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useSiteVoltageData } from '../../queries/siteVoltage';
import { useSitesById } from '../../queries/sites';
import { useIsDateRangeValid } from './Filters/useProgramStartDate';
import InvalidDateRangeAlert from './InvalidDateRangeAlert';
import { QueryError } from './QueryError';
import styles from './styles.module.css';
import { FiltersContext } from './utils/filterParams';

const headerColProps = {
  lh: '1',
  ta: 'right',
} as Partial<MantineStyleProps>;

const dataColProps = {
  lh: '1',
  ta: 'right',
} as Partial<MantineStyleProps>;

const cardProps = {
  shadow: '0',
  padding: '0',
  radius: 'md',
  withBorder: true,
};

type PaginationSettings = {
  currentPage: number; // starts at 0
  rowsPerPage: number; // default = 10
};

const getFirstVisibleRow = (
  paginationSettings: PaginationSettings,
  totalRows: number
) => {
  const firstRow =
    paginationSettings.rowsPerPage * paginationSettings.currentPage;
  return firstRow > totalRows ? totalRows : firstRow;
};

const getLastVisibleRow = (
  paginationSettings: PaginationSettings,
  totalRows: number
) => {
  const lastRow =
    getFirstVisibleRow(paginationSettings, totalRows) +
    (paginationSettings.rowsPerPage - 1);
  return lastRow > totalRows ? totalRows : lastRow;
};

const BuildingVoltageAnalysisPage = () => {
  const theme = useMantineTheme();
  useDocumentTitle(`Voltage Analysis | ${REDAPTIVE_ONE_PAGE_TITLE}`);
  const activeFilters = useContext(FiltersContext);
  const sitesQuery = useSitesById();

  const siteVoltageQuery = useSiteVoltageData(omit(activeFilters, 'options'));

  const isDateRangeValid = useIsDateRangeValid(activeFilters);

  const singleSiteSelected = sitesQuery.data?.[activeFilters.siteIds[0]];
  const timezone = singleSiteSelected?.address?.timezone || 'UTC';

  const [pagination, setPagination] = useState<PaginationSettings>({
    currentPage: 0,
    rowsPerPage: 10,
  });
  const totalRows = siteVoltageQuery.data?.groups?.length ?? 0;
  const [expandedState, setExpandedState] = useState(
    new Array(siteVoltageQuery.data?.groups?.length ?? 0).fill(false)
  );
  useEffect(() => {
    setExpandedState(
      new Array(siteVoltageQuery.data?.groups?.length ?? 0).fill(false)
    );
  }, [siteVoltageQuery.data?.groups?.length]);
  const isAllExpanded = every(expandedState);

  const handleExpandCollapseAll = (expanded: boolean) => {
    setExpandedState((prev) => prev.map(() => expanded));
  };

  const handleExpandCollapseOne = (i: number) => {
    setExpandedState((prev) => {
      const newState = [...prev];
      newState[i] = !newState[i];
      return newState;
    });
  };

  const isRowVisible = (ix: number) => {
    const startIndex = getFirstVisibleRow(pagination, totalRows);
    const endIndex = getLastVisibleRow(pagination, totalRows);
    return ix >= startIndex && ix <= endIndex;
  };

  return (
    <Stack gap='sm'>
      {siteVoltageQuery.isLoading && <Spinner centered />}
      {!isDateRangeValid && siteVoltageQuery.isSuccess && (
        <InvalidDateRangeAlert />
      )}
      {siteVoltageQuery.isError && (
        <QueryError
          status={siteVoltageQuery.error.status}
          message={siteVoltageQuery.error.message}
        />
      )}
      {siteVoltageQuery.isSuccess && isDateRangeValid && (
        <>
          <Card {...cardProps} bg='gray.1' mb='0.5rem'>
            <Card.Section>
              <Grid columns={12} p='sm' align='center' gutter={0}>
                <Grid.Col span={3} {...headerColProps} ta='left'>
                  Groups
                </Grid.Col>
                <Grid.Col span={2} {...headerColProps}>
                  Average
                </Grid.Col>
                <Grid.Col span={2} {...headerColProps}>
                  Minimum
                </Grid.Col>
                <Grid.Col span={2} {...headerColProps}>
                  Maximum
                </Grid.Col>
                <Grid.Col span={3} {...headerColProps} lh=''>
                  <Container ta='right' p='0'>
                    {isAllExpanded ? (
                      <Button
                        variant='outline'
                        color='gray.6'
                        size='sm'
                        h={32}
                        data-gainsight-id='voltage-collapse-all-button'
                        onClick={() => handleExpandCollapseAll(false)}
                      >
                        Collapse All
                        <DoubleUpIcon height={20} width={20} />
                      </Button>
                    ) : (
                      <Button
                        variant='outline'
                        color='gray.6'
                        size='sm'
                        h={32}
                        data-gainsight-id='voltage-expand-all-button'
                        onClick={() => handleExpandCollapseAll(true)}
                      >
                        Expand All
                        <DoubleDownIcon height={20} width={20} />
                      </Button>
                    )}
                  </Container>
                </Grid.Col>
              </Grid>
            </Card.Section>
          </Card>

          {/* Repeat for each voltage group: */}
          {siteVoltageQuery.data?.groups.map((row, ix) => {
            if (!isRowVisible(ix)) {
              return null;
            }
            const expanded = expandedState[ix];
            const { groupName, average, min, max, rmsVoltage = [] } = row;
            return (
              <Card
                key={`row_voltage_${ix}`}
                {...cardProps}
                className={styles.card}
                data-expanded={expanded}
                data-gainsight-id='voltage-button-expand-collapse-all'
              >
                <Card.Section onClick={() => handleExpandCollapseOne(ix)}>
                  <Grid columns={12} p='sm' align='center' gutter={0}>
                    <Grid.Col {...dataColProps} ta={'left'} span={3} fw='600'>
                      {groupName}
                    </Grid.Col>
                    <Grid.Col {...dataColProps} span={2}>
                      {average == 0 ? (
                        '-'
                      ) : (
                        <Quantity quantityType='V' value={average} />
                      )}
                    </Grid.Col>
                    <Grid.Col {...dataColProps} span={2}>
                      {min == 0 ? (
                        '-'
                      ) : (
                        <Quantity quantityType='V' value={min} />
                      )}
                    </Grid.Col>
                    <Grid.Col {...dataColProps} span={2}>
                      {max == 0 ? (
                        '-'
                      ) : (
                        <Quantity quantityType='V' value={max} />
                      )}
                    </Grid.Col>
                    <Grid.Col {...dataColProps} pt={2} span={3} ta='right'>
                      <Pill
                        radius='xl'
                        bg={expanded ? 'blue.5' : 'blue.0'}
                        h='32'
                        pt='7'
                        data-gainsight-id='voltage-button-expand-collapse-all'
                      >
                        <UilChartLine
                          height={14}
                          width={14}
                          color={expanded ? 'white' : theme.colors.blue[5]}
                        />
                      </Pill>
                    </Grid.Col>
                  </Grid>
                </Card.Section>
                {expanded && (
                  <Card.Section
                    bg='#fff'
                    p='sm'
                    data-gainsight-id='voltage-analysis-chart'
                  >
                    <VoltageAnalysisChart
                      startDate={moment(
                        activeFilters.fromDate,
                        DATE_FORMAT_DATA_API_RESPONSE_NO_TZ
                      )}
                      endDate={moment(
                        activeFilters.toDate,
                        DATE_FORMAT_DATA_API_RESPONSE_NO_TZ
                      )}
                      resolution={activeFilters.resolution}
                      ts={siteVoltageQuery.data?.ts}
                      voltageData={rmsVoltage}
                      avgVoltage={average}
                      chartRef={null}
                      timezone={timezone}
                    />
                  </Card.Section>
                )}
              </Card>
            );
          })}
          {/* End Repeat for each voltage group */}
          <Pagination
            total={totalRows / pagination.rowsPerPage}
            totalItems={totalRows}
            itemsPerPage={pagination.rowsPerPage}
            currentPage={pagination.currentPage}
            onChangePage={(page) => {
              setPagination({
                ...pagination,
                currentPage: page - 1,
              });
            }}
            onPageSizeChange={(pageSize) => {
              setPagination({
                ...pagination,
                rowsPerPage: pageSize,
              });
            }}
          />
        </>
      )}{' '}
    </Stack>
  );
};

export default BuildingVoltageAnalysisPage;
