/* eslint-disable max-len */

interface TSProps {
  color?: string;
  size?: string;
}

const BatchIcon = ({
  color = '#6C6D6E',
  size = '20',
}: TSProps) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d="M21.0909 14.7273H19.2727V12C19.2727 11.7589 19.177 11.5277 19.0065 11.3572C18.836 11.1867 18.6047 11.0909 18.3636 11.0909H12.9091V9.27273H14.7273C14.9684 9.27273 15.1996 9.17695 15.3701 9.00646C15.5406 8.83597 15.6364 8.60474 15.6364 8.36364V2.90909C15.6364 2.66799 15.5406 2.43675 15.3701 2.26627C15.1996 2.09578 14.9684 2 14.7273 2H9.27273C9.03162 2 8.80039 2.09578 8.6299 2.26627C8.45942 2.43675 8.36364 2.66799 8.36364 2.90909V8.36364C8.36364 8.60474 8.45942 8.83597 8.6299 9.00646C8.80039 9.17695 9.03162 9.27273 9.27273 9.27273H11.0909V11.0909H5.63636C5.39526 11.0909 5.16403 11.1867 4.99354 11.3572C4.82305 11.5277 4.72727 11.7589 4.72727 12V14.7273H2.90909C2.66799 14.7273 2.43675 14.8231 2.26627 14.9935C2.09578 15.164 2 15.3953 2 15.6364V21.0909C2 21.332 2.09578 21.5632 2.26627 21.7337C2.43675 21.9042 2.66799 22 2.90909 22H8.36364C8.60474 22 8.83597 21.9042 9.00646 21.7337C9.17695 21.5632 9.27273 21.332 9.27273 21.0909V15.6364C9.27273 15.3953 9.17695 15.164 9.00646 14.9935C8.83597 14.8231 8.60474 14.7273 8.36364 14.7273H6.54545V12.9091H17.4545V14.7273H15.6364C15.3953 14.7273 15.164 14.8231 14.9935 14.9935C14.8231 15.164 14.7273 15.3953 14.7273 15.6364V21.0909C14.7273 21.332 14.8231 21.5632 14.9935 21.7337C15.164 21.9042 15.3953 22 15.6364 22H21.0909C21.332 22 21.5632 21.9042 21.7337 21.7337C21.9042 21.5632 22 21.332 22 21.0909V15.6364C22 15.3953 21.9042 15.164 21.7337 14.9935C21.5632 14.8231 21.332 14.7273 21.0909 14.7273ZM7.45455 16.5455V20.1818H3.81818V16.5455H7.45455ZM10.1818 7.45455V3.81818H13.8182V7.45455H10.1818ZM20.1818 20.1818H16.5455V16.5455H20.1818V20.1818Z"
      fill={color}
    />

  </svg>
);

export default BatchIcon;
