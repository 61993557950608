import styled from 'styled-components';
import InfoIcon from './Icons/InfoIcon';
import WarningIcon from './Icons/WarningIcon';

type TSType = 'info' | 'warning' | 'error';

interface TSProps {
  children: React.ReactNode;
  type?: TSType;
}

const MessageStyled = styled.div<{ $type: TSType }>`
  align-items: center;
  background-color: ${({ theme, $type }) => {
    switch ($type) {
      case 'info':
        return theme.colors.messageInfo;
      case 'warning':
        return theme.colors.messageWarning;
      case 'error':
        return theme.colors.messageError;
    }
  }};
  border-radius: 8px;
  color: #fff;
  display: flex;
  font-size: 14px;
  padding: 10px;
`;

const IconWrapperStyled = styled.div`
  margin-right: 10px;
  position: relative;
  top: 1px;
`;

const ChildrenWrapperStyled = styled.div``;

const Message = ({ children, type = 'info' }: TSProps) => {
  return (
    <MessageStyled $type={type}>
      <IconWrapperStyled>
        {type === 'info' ? (
          <InfoIcon color='#fff' size='24px' />
        ) : (
          <WarningIcon color='#fff' size='24px' />
        )}
      </IconWrapperStyled>
      <ChildrenWrapperStyled>{children}</ChildrenWrapperStyled>
    </MessageStyled>
  );
};

export default Message;
