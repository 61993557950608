/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { defaultHeaders, denaliApiBaseUrl } from '../api/index';
import { handleAxiosError, handleSagaError } from '../api/utils';
import { DAYS_OF_WEEK } from '../constants';
import siteShiftsMockData from '../mockData/siteShifts';
import OperatingHoursHelper from '../utils/operatingHoursHelper';
import { isVariantActive } from '../utils/variants';
import { TSMetaState } from './types';
export interface TSSiteShiftResponse {
  id: string;
  siteId: string;
  name: string;
  startDay: string; // TODO: make enum?
  startTime: string; // format hh:mm (24 hour)
  durationInMinutes: number;
}

export type TSSiteShiftsResponse = Array<TSSiteShiftResponse>;

export interface TSSiteShift extends TSSiteShiftResponse {
  day: number;
  startHour: number;
  startMinute: number;
  endDay: string;
  endTime: string;
}

interface TSSiteShiftsMetaState extends TSMetaState {
  noSiteShifts: boolean;
}

export interface TSSiteShiftsEntityState {
  byId: {
    [id: string]: TSSiteShift;
  };
  items: Array<TSSiteShift>;
  meta: TSSiteShiftsMetaState;
  createShiftMeta: TSMetaState;
  deleteShiftMeta: TSMetaState;
  updateShiftMeta: TSMetaState;
}

interface TSState {
  entities: {
    siteShifts: TSSiteShiftsEntityState;
  };
}

interface TSCreateSiteShiftPayload {
  siteId: string;
  name: string;
  startDay: string;
  startTime: string;
  durationInMinutes: number;
}

interface TSUpdateSiteShiftPayload extends TSCreateSiteShiftPayload {
  shiftId: string;
}

interface TSDeleteSiteShiftPayload {
  siteId: string;
  shiftId: string;
}

export const types = {
  FETCH_SITE_SHIFTS: 'FETCH_SITE_SHIFTS',
  FETCH_SITE_SHIFTS_SUCCESS: 'FETCH_SITE_SHIFTS_SUCCESS',
  FETCH_SITE_SHIFTS_ERROR: 'FETCH_SITE_SHIFTS_ERROR',
  CREATE_SITE_SHIFT: 'CREATE_SITE_SHIFT',
  CREATE_SITE_SHIFT_SUCCESS: 'CREATE_SITE_SHIFT_SUCCESS',
  CREATE_SITE_SHIFT_ERROR: 'CREATE_SITE_SHIFT_ERROR',
  DELETE_SITE_SHIFT: 'DELETE_SITE_SHIFT',
  DELETE_SITE_SHIFT_SUCCESS: 'DELETE_SITE_SHIFT_SUCCESS',
  DELETE_SITE_SHIFT_ERROR: 'DELETE_SITE_SHIFT_ERROR',
  UPDATE_SITE_SHIFT: 'UPDATE_SITE_SHIFT',
  UPDATE_SITE_SHIFT_SUCCESS: 'UPDATE_SITE_SHIFT_SUCCESS',
  UPDATE_SITE_SHIFT_ERROR: 'UPDATE_SITE_SHIFT_ERROR',
  RESET_META_ACTION: 'RESET_META_ACTION',
};

export const actions = {
  fetchSiteShifts: (siteId: string) => ({
    type: types.FETCH_SITE_SHIFTS,
    siteId,
  }),
  createSiteShift: (payload: TSCreateSiteShiftPayload) => ({
    type: types.CREATE_SITE_SHIFT,
    payload,
  }),
  deleteSiteShift: (payload: TSDeleteSiteShiftPayload) => ({
    type: types.DELETE_SITE_SHIFT,
    payload,
  }),
  updateSiteShift: (payload: TSUpdateSiteShiftPayload) => ({
    type: types.UPDATE_SITE_SHIFT,
    payload,
  }),
  resetMetaAction: () => ({
    type: types.RESET_META_ACTION,
  }),
};

export const initialState: TSSiteShiftsEntityState = {
  byId: {},
  items: [],
  meta: {
    error: '',
    loading: false,
    noSiteShifts: false,
  },
  createShiftMeta: {
    error: '',
    loading: false,
  },
  deleteShiftMeta: {
    error: '',
    loading: false,
  },
  updateShiftMeta: {
    error: '',
    loading: false,
  },
};

function entityById(action, state) {
  return {
    ...state,
    ...action.payload.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {}
    ),
  };
}

function updateEntityById(action, state) {
  return {
    ...state,
    [action.payload.id]: action.payload,
  };
}

function deleteEntityItemById(action, state) {
  const { [action.payload]: omit, ...res } = state;
  return res;
}

function entityItems(action, state) {
  const newItems: Array<TSSiteShift> = Object.values(action.payload);
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems);
}

function updateEntityItems(action, state) {
  const newItem: TSSiteShift = action.payload;
  return state.concat(newItem);
}

function deleteEntityItem(action, state) {
  return state.filter((item: TSSiteShift) => item.id !== action.payload);
}

function updateEntityItem(action, state) {
  return state.map((item: TSSiteShift) => {
    if (item.id === action.payload.id) {
      return action.payload;
    }
    return item;
  });
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_SITE_SHIFTS:
      return initialState.byId;
    case types.FETCH_SITE_SHIFTS_SUCCESS:
      return entityById(action, state);
    case types.CREATE_SITE_SHIFT_SUCCESS:
    case types.UPDATE_SITE_SHIFT_SUCCESS:
      return updateEntityById(action, state);
    case types.DELETE_SITE_SHIFT_SUCCESS:
      return deleteEntityItemById(action, state);
    default:
      return state;
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_SITE_SHIFTS:
      return initialState.items;
    case types.FETCH_SITE_SHIFTS_SUCCESS:
      return entityItems(action, state);
    case types.CREATE_SITE_SHIFT_SUCCESS:
      return updateEntityItems(action, state);
    case types.DELETE_SITE_SHIFT_SUCCESS:
      return deleteEntityItem(action, state);
    case types.UPDATE_SITE_SHIFT_SUCCESS:
      return updateEntityItem(action, state);
    default:
      return state;
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_SITE_SHIFTS:
      return {
        ...state,
        error: '',
        loading: true,
        noSiteShifts: false,
      };
    case types.FETCH_SITE_SHIFTS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_SITE_SHIFTS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noSiteShifts: !action.payload[0],
      };
    case types.CREATE_SITE_SHIFT_SUCCESS:
      return {
        ...state,
        noSiteShifts: false,
      };
    default:
      return state;
  }
}

function createShiftMeta(state = initialState.createShiftMeta, action) {
  switch (action.type) {
    case types.CREATE_SITE_SHIFT:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.CREATE_SITE_SHIFT_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.CREATE_SITE_SHIFT_SUCCESS:
    case types.RESET_META_ACTION:
      return {
        ...state,
        error: '',
        loading: false,
      };
    default:
      return state;
  }
}

function deleteShiftMeta(state = initialState.deleteShiftMeta, action) {
  switch (action.type) {
    case types.DELETE_SITE_SHIFT:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.DELETE_SITE_SHIFT_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.DELETE_SITE_SHIFT_SUCCESS:
    case types.RESET_META_ACTION:
      return {
        ...state,
        error: '',
        loading: false,
      };
    default:
      return state;
  }
}

function updateShiftMeta(state = initialState.updateShiftMeta, action) {
  switch (action.type) {
    case types.UPDATE_SITE_SHIFT:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.UPDATE_SITE_SHIFT_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.UPDATE_SITE_SHIFT_SUCCESS:
    case types.RESET_META_ACTION:
      return {
        ...state,
        error: '',
        loading: false,
      };
    default:
      return state;
  }
}

export default combineReducers({
  byId,
  items,
  meta,
  createShiftMeta,
  deleteShiftMeta,
  updateShiftMeta,
});

export const selectSiteShiftsEntity = (
  state: TSState
): TSSiteShiftsEntityState => state.entities.siteShifts;

export const enhanceSiteShift = (
  siteShift: TSSiteShiftResponse
): TSSiteShift => {
  const day = DAYS_OF_WEEK.indexOf(siteShift.startDay);
  const startHour = parseInt(siteShift.startTime.split(':')[0]);
  const startMinute = parseInt(siteShift.startTime.split(':')[1]);
  const durationInMinutes = siteShift.durationInMinutes;

  const endDayTime = OperatingHoursHelper.getShiftEndDayTime({
    day,
    startHour,
    startMinute,
    durationInMinutes,
  });

  return {
    ...siteShift,
    ...endDayTime,
    day: day,
    startHour: startHour,
    startMinute: startMinute,
  };
};

export const API = {
  fetchSiteShifts: (siteId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(siteShiftsMockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const url = `${denaliApiBaseUrl()}/sites/${siteId}/shifts`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSiteShiftsResponse }) => data)
      .catch(handleAxiosError);
  },
  createSiteShift: (payload: TSCreateSiteShiftPayload) => {
    const url = `${denaliApiBaseUrl()}/sites/${payload.siteId}/shifts`;
    const { siteId, ...postData } = payload;

    return axios
      .post(url, postData, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSiteShiftResponse }) => data)
      .catch(handleAxiosError);
  },
  deleteSiteShift: (payload: TSDeleteSiteShiftPayload) => {
    const url = `${denaliApiBaseUrl()}/sites/${payload.siteId}/shifts/${
      payload.shiftId
    }`;

    return axios
      .delete(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSiteShiftsResponse }) => data)
      .catch(handleAxiosError);
  },
  updateSiteShift: (payload: TSUpdateSiteShiftPayload) => {
    const url = `${denaliApiBaseUrl()}/sites/${payload.siteId}/shifts/${
      payload.shiftId
    }`;

    const { siteId, shiftId, ...postData } = payload;

    return axios
      .patch(url, postData, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSiteShiftResponse }) => data)
      .catch(handleAxiosError);
  },
};

function* fetchSiteShiftsSaga({
  type,
  siteId,
}: {
  type: string;
  siteId: string;
}): Generator<any, void, any> {
  try {
    const siteShifts: TSSiteShiftsResponse = yield call(
      API.fetchSiteShifts,
      siteId
    );
    yield put({
      type: types.FETCH_SITE_SHIFTS_SUCCESS,
      payload: siteShifts.map((siteShift) => enhanceSiteShift(siteShift)),
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_SITE_SHIFTS_ERROR, e as Error);
  }
}

function* createSiteShiftSaga({
  type,
  payload,
}: {
  type: string;
  payload: TSCreateSiteShiftPayload;
}): Generator<any, void, any> {
  try {
    const siteShift: TSSiteShiftResponse = yield call(
      API.createSiteShift,
      payload
    );
    yield put({
      type: types.CREATE_SITE_SHIFT_SUCCESS,
      payload: enhanceSiteShift(siteShift),
    });
  } catch (e) {
    yield handleSagaError(types.CREATE_SITE_SHIFT_ERROR, e as Error);
  }
}

function* deleteSiteShiftSaga({
  type,
  payload,
}: {
  type: string;
  payload: TSDeleteSiteShiftPayload;
}): Generator<any, void, any> {
  try {
    yield call(API.deleteSiteShift, payload);
    yield put({
      type: types.DELETE_SITE_SHIFT_SUCCESS,
      payload: payload.shiftId,
    });
  } catch (e) {
    yield handleSagaError(types.DELETE_SITE_SHIFT_ERROR, e as Error);
  }
}

function* updateSiteShiftSaga({
  type,
  payload,
}: {
  type: string;
  payload: TSUpdateSiteShiftPayload;
}): Generator<any, void, any> {
  try {
    const updatedShift: TSSiteShiftResponse = yield call(
      API.updateSiteShift,
      payload
    );
    yield put({
      type: types.UPDATE_SITE_SHIFT_SUCCESS,
      payload: enhanceSiteShift(updatedShift),
    });
  } catch (e) {
    yield handleSagaError(types.UPDATE_SITE_SHIFT_ERROR, e as Error);
  }
}

export const sagas = [
  takeLatest(types.FETCH_SITE_SHIFTS, fetchSiteShiftsSaga),
  takeLatest(types.CREATE_SITE_SHIFT, createSiteShiftSaga),
  takeLatest(types.DELETE_SITE_SHIFT, deleteSiteShiftSaga),
  takeLatest(types.UPDATE_SITE_SHIFT, updateSiteShiftSaga),
];
