import _, { some } from 'lodash';
import moment from 'moment';
import { ResourceType } from '../../../ducks/types';
import { useSitesById } from '../../../queries/sites';
import { resourceStartDate } from '../ConsumptionAnalysisPage';
import { DataExplorerFilterParams } from '../types';
import { useSelectedSiteIds } from '../utils/filterParams';

const useProgramStartDate = (resourceType: ResourceType) => {
  const sitesQuery = useSitesById();
  const selectedSiteIds = useSelectedSiteIds();
  const resourceStartDateKey = resourceStartDate[resourceType];
  const selectedSites = _(sitesQuery.data ?? {})
    .pick(selectedSiteIds)
    .values()
    .value();

  const selectedSitesIngestionDates = selectedSites
    .map((site) => moment(site[resourceStartDateKey]))
    .filter((ingestionDate) => ingestionDate.isValid());
  const anySiteMissingStartDate = some(
    selectedSites.map(
      (site) =>
        !site?.[resourceStartDateKey] && site?.meterCounts[resourceType] > 0
    ),
    Boolean
  );

  return anySiteMissingStartDate || !selectedSitesIngestionDates.length
    ? moment('2018', 'YYYY')
    : moment.min(selectedSitesIngestionDates);
};

export const useIsDateRangeValid = (
  activeFilters: DataExplorerFilterParams
) => {
  const programStartDate = useProgramStartDate(activeFilters.resourceType);

  // Range is valid as long as there is some overlap between
  // the program active dates and the selected date range
  return programStartDate <= moment(activeFilters.toDate);
};

export default useProgramStartDate;
