import moment, { Moment } from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { END_DATE, START_DATE } from 'react-dates/constants';
import styled, { css } from 'styled-components';

import {
  DATE_FORMAT_DATA_API_REQUEST,
  DATE_SHORT_MONTH_FORMAT,
} from '../constants';
import ChevronLeftIcon from './Icons/ChevronLeftIcon';
import ChevronRightIcon from './Icons/ChevronRightIcon';
import ListSelector, { SelectStyles, type TSItem } from './ListSelector';

type TSProps = {
  endDate: Moment | null;
  minDate: () => Moment;
  startDate: Moment | null;
  onDatesChange: ({
    endDate,
    startDate,
    source,
  }: {
    endDate: Moment | null;
    startDate: Moment;
    source?: string;
  }) => void;
  focusedInput: string;
  onFocusChange: (string) => void;
  monthsLimit?: number;
};

const MonthCalendarWrapperStyles = styled.div`
  margin: 5px 10px;
`;

const StyledListSelector = styled(ListSelector)`
  width: 268px;

  ${SelectStyles} {
    margin-right: 0;

    .Select__menu {
      z-index: 100;
    }
  }
`;

const MonthRangePickerStyles = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CalendarCaptionStyles = styled.div`
  padding-bottom: 20px;
  padding-top: 22px;
  position: relative;
  text-align: center;
  cursor: pointer;
`;

const CalendarTitleStyles = styled.div`
  color: #162447;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

const ChevronIconLeftStyles = styled.div`
  position: absolute;
`;

const ChevronIconRightStyles = styled.div`
  position: absolute;
  right: 24px;
`;

const ChevronIconStyled = styled.div<{
  disabled: boolean;
}>`
  position: absolute;
  color: #000000;

  button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 25px;
    cursor: pointer;
  }
`;

const CalendarMonthGridItemActiveStyles = css`
  background-color: #485da0;
  color: #ffffff;
`;

const CalendarMonthGridItemRangeStyles = css`
  background-color: #485da0;
  color: #ffffff;
  opacity: 0.7;
`;

const CalendarMonthGridItemDisabledStyles = css`
  background-color: #f9f9f9;
  color: #000000;
  cursor: unset;

  &:hover {
    background-color: #f9f9f9;
    color: #000000;
    opacity: 1;
  }
`;

const CalendarMonthGridItemStyled = styled.div<{
  disabled: boolean;
  range: boolean | null;
  active: boolean;
}>`
  background-color: #e0e0e0;
  border-radius: 4px;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;

  &:hover {
    background-color: #485da0;
    color: #ffffff;
    opacity: 0.7;
  }

  ${({ active }) => active && CalendarMonthGridItemActiveStyles};
  ${({ range }) => range && CalendarMonthGridItemRangeStyles};
  ${({ disabled }) => disabled && CalendarMonthGridItemDisabledStyles};
`;

const CalendarMonthGridStyles = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, minmax(50px, 0));
  margin-left: 20px;
`;

const DividerWrapperStyles = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopDividerStyles = styled.div`
  border-left: 1px solid #e0e0e0;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 5px;
  padding: 1px;
`;

const BottomDividerStyles = styled.div`
  border-left: 1px solid #e0e0e0;
  height: 210px;
  padding: 1px;
`;

const monthsList = moment.monthsShort();

const MonthRangePicker = (props: TSProps): any => {
  const {
    endDate,
    minDate: getMinDate,
    onDatesChange,
    startDate,
    focusedInput,
    onFocusChange,
    monthsLimit,
  } = props;

  const [startDateYear, setStartDateYear] = useState(
    endDate?.clone().subtract(1, 'years').format('YYYY') ??
      moment().subtract(1, 'years').format('YYYY')
  );
  const [endDateYear, setEndDateYear] = useState(
    endDate?.clone().format('YYYY') ?? moment().format('YYYY')
  );

  const minDate = getMinDate();

  const totalAvailableMonths = useMemo(() => {
    return (
      moment()
        .startOf('month')
        .diff(minDate.clone().startOf('month'), 'months') + 1
    );
  }, [minDate]);

  const startListSelectorItems: Array<TSItem> = useMemo(
    () =>
      new Array(totalAvailableMonths)
        .fill(null)
        .map((_, index) => {
          const monthYear = minDate
            .clone()
            .startOf('month')
            .add(index, 'months')
            .format('MMMM YYYY');
          return {
            id: monthYear,
            name: monthYear,
          };
        })
        .reverse(),
    [totalAvailableMonths, minDate]
  );

  const getStartItemFromValue = useCallback(
    (value: string | undefined) => {
      return startListSelectorItems.find(
        (startListSelectorItem) => startListSelectorItem.id === value
      );
    },
    [startListSelectorItems]
  );

  const getEndItemFromValue = useCallback(
    (value: string | undefined) => {
      return startListSelectorItems.find(
        (endListSelectorItem) => endListSelectorItem.id === value
      );
    },
    [startListSelectorItems]
  );

  const selectedStartItem = useMemo(() => {
    return getStartItemFromValue(startDate?.clone().format('MMMM YYYY'));
  }, [getStartItemFromValue, startDate]);

  const selectedEndItem = useMemo(
    () => getEndItemFromValue(endDate?.clone().format('MMMM YYYY')),
    [getEndItemFromValue, endDate]
  );

  const updateValueStart = useCallback(
    ({ value }) => {
      const formattedStartDate = moment(value, 'MMMM YYYY').startOf('month');

      const isValidToSet = !moment(formattedStartDate).isAfter(
        endDate?.clone()
      );

      if (isValidToSet) {
        onDatesChange({
          endDate: null,
          startDate: formattedStartDate,
          source: 'start',
        });
        onFocusChange(END_DATE);
      }
    },
    [endDate, onDatesChange, onFocusChange]
  );

  const updateValueEnd = useCallback(
    ({ value }) => {
      const formattedDate = moment(value, 'MMMM YYYY').endOf('month');

      const isValidToSet = !moment(formattedDate).isBefore(startDate?.clone());

      if (isValidToSet) {
        onDatesChange({
          endDate: formattedDate,
          startDate: moment(startDate),
          source: 'end',
        });
        onFocusChange(START_DATE);
      }
    },
    [onDatesChange, onFocusChange, startDate]
  );

  const getItemProperties = (month: string, year: string | undefined) => {
    const formattedDate = moment(`${month} ${year}`, DATE_SHORT_MONTH_FORMAT);
    const monthsLimitDate = startDate?.clone().add(monthsLimit ?? 0, 'months');
    const endDateLimit =
      monthsLimit && monthsLimitDate
        ? moment.min(monthsLimitDate, moment())
        : moment();

    const disabled = !formattedDate
      .clone()
      .isBetween(minDate.clone(), endDateLimit, 'month', '[]');
    const range = formattedDate
      .clone()
      .isBetween(startDate?.clone(), endDate?.clone(), 'months', '()');
    const active =
      startDate?.clone().format('MMM YYYY') === `${month} ${year}` ||
      endDate?.clone().format('MMM YYYY') === `${month} ${year}`;

    return {
      active: active,
      disabled: disabled,
      range: range,
    };
  };

  const handleGridItemClick = useCallback(
    ({
      target: {
        dataset: { disabled, month, year },
      },
    }: any) => {
      const formattedDate = moment(`${month} ${year}`, DATE_SHORT_MONTH_FORMAT);

      if (disabled === 'true') {
        return;
      }

      const isInvalidEndDate = moment(formattedDate).isBefore(startDate);
      const isInvalidStartDate =
        moment(formattedDate).isAfter(endDate) ||
        moment(formattedDate).isSame(endDate);

      if (focusedInput === END_DATE && isInvalidEndDate) {
        onDatesChange({
          endDate: null,
          startDate: moment(formattedDate, DATE_FORMAT_DATA_API_REQUEST),
        });
      } else if (focusedInput === START_DATE && isInvalidStartDate) {
        onDatesChange({
          endDate: null,
          startDate: moment(formattedDate, DATE_FORMAT_DATA_API_REQUEST),
        });
      } else {
        onDatesChange({
          endDate:
            focusedInput === END_DATE
              ? moment(formattedDate, DATE_FORMAT_DATA_API_REQUEST).endOf(
                  'month'
                )
              : moment(endDate).endOf('month'),
          startDate:
            focusedInput === START_DATE
              ? moment(formattedDate, DATE_FORMAT_DATA_API_REQUEST).startOf(
                  'month'
                )
              : moment(startDate).startOf('month'),
        });

        onFocusChange(focusedInput === START_DATE ? END_DATE : START_DATE);
      }
    },
    [startDate, endDate, focusedInput, onDatesChange, onFocusChange]
  );

  const handlePreviousIconClick = useCallback(
    (event: any) => {
      const isDisabled = event.currentTarget.getAttribute('data-disabled');
      if (isDisabled === 'true') {
        return;
      }
      setEndDateYear(startDateYear);
      setStartDateYear(
        moment(startDateYear).subtract(1, 'years').format('YYYY')
      );
    },
    [startDateYear]
  );

  const handleNextIconClick = useCallback(
    (event: any) => {
      const isDisabled = event.currentTarget.getAttribute('data-disabled');
      if (isDisabled === 'true') {
        return;
      }
      setEndDateYear(moment(endDateYear).add(1, 'years').format('YYYY'));
      setStartDateYear(endDateYear);
    },
    [endDateYear]
  );

  const renderChevronIcon = useCallback(
    ({ year, direction }: { year: string | undefined; direction: string }) => {
      const disabled =
        year === minDate?.format('YYYY') || year === moment().format('YYYY');
      const handleClick =
        direction === 'left' ? handlePreviousIconClick : handleNextIconClick;

      return (
        <ChevronIconStyled disabled={disabled}>
          <button type='button' data-disabled={disabled} onClick={handleClick}>
            {direction === 'left' && (
              <ChevronLeftIcon color={disabled ? '#E0E0E0' : '000'} />
            )}
            {direction === 'right' && (
              <ChevronRightIcon color={disabled ? '#E0E0E0' : '000'} />
            )}
          </button>
        </ChevronIconStyled>
      );
    },
    [handleNextIconClick, handlePreviousIconClick, minDate]
  );

  return (
    <MonthRangePickerStyles>
      <MonthCalendarWrapperStyles>
        <StyledListSelector
          items={startListSelectorItems}
          selectedItem={selectedStartItem}
          unsettable={false}
          updateValue={updateValueStart}
        />
        <CalendarCaptionStyles>
          <ChevronIconLeftStyles>
            {renderChevronIcon({ year: startDateYear, direction: 'left' })}
          </ChevronIconLeftStyles>
          <CalendarTitleStyles>
            <strong>{startDateYear}</strong>
          </CalendarTitleStyles>
        </CalendarCaptionStyles>
        <CalendarMonthGridStyles>
          {monthsList.map((month) => {
            const { active, disabled, range } = getItemProperties(
              month,
              startDateYear
            );

            return (
              <CalendarMonthGridItemStyled
                active={active}
                disabled={disabled}
                range={range}
                key={month}
                data-disabled={disabled}
                data-month={month}
                data-year={startDateYear}
                onClick={handleGridItemClick}
              >
                {month}
              </CalendarMonthGridItemStyled>
            );
          })}
        </CalendarMonthGridStyles>
      </MonthCalendarWrapperStyles>
      <DividerWrapperStyles>
        <TopDividerStyles></TopDividerStyles>
        <BottomDividerStyles></BottomDividerStyles>
      </DividerWrapperStyles>
      <MonthCalendarWrapperStyles>
        <StyledListSelector
          items={startListSelectorItems}
          selectedItem={selectedEndItem}
          unsettable={false}
          updateValue={updateValueEnd}
        />
        <CalendarCaptionStyles>
          <ChevronIconRightStyles>
            {renderChevronIcon({ year: endDateYear, direction: 'right' })}
          </ChevronIconRightStyles>
          <CalendarTitleStyles>
            <strong>{endDateYear}</strong>
          </CalendarTitleStyles>
        </CalendarCaptionStyles>
        <CalendarMonthGridStyles>
          {monthsList.map((month) => {
            const { active, disabled, range } = getItemProperties(
              month,
              endDateYear
            );

            return (
              <CalendarMonthGridItemStyled
                active={active}
                disabled={disabled}
                range={range}
                key={month}
                data-disabled={disabled}
                data-month={month}
                data-year={endDateYear}
                onClick={handleGridItemClick}
              >
                {month}
              </CalendarMonthGridItemStyled>
            );
          })}
        </CalendarMonthGridStyles>
      </MonthCalendarWrapperStyles>
    </MonthRangePickerStyles>
  );
};

export default MonthRangePicker;
