import { useEffect } from 'react';

export default (callback: () => void) => {
  useEffect(() => {
    const onKeyPress = (event) => event.keyCode === 27 && callback();
    window.document.addEventListener('keydown', onKeyPress);
    return () => {
      window.document.removeEventListener('keydown', onKeyPress);
    };
  }, [callback]);
};
