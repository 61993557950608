import axios from 'axios';
import moment from 'moment';
import { combineReducers } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  defaultHeaders,
  gcProposalApiBaseUrl,
  opportunityApiBaseUrl,
  redaptiveGeneralApiBaseUrl,
} from '../../api/index';
import { handleAxiosError, handleSagaError } from '../../api/utils';
import {
  DATE_FORMAT_DATA_API_RESPONSE,
  DATE_RANGE_PICKER_FORMAT,
} from '../../constants';
import {
  customerTasks,
  opportunityAttachments,
  batchAttachments as opportunityBatchAttachments,
  batchComments as opportunityBatchComments,
  opportunityComments,
  opportunityCustomers,
  preSignedUrls,
} from '../../mockData/opportunities';
import { TSState } from '../../reducers/rootReducer';
import { isVariantActive } from '../../utils/variants';
import { TSCustomer, TSCustomersResponse } from '../customers';
import { TSMetaState } from '../types';

// Interfaces

export interface TSOpportunityComment {
  id: string;
  comment: string;
  commentAddedBy: string;
  createdBy: string;
  created: string;
  modified: string;
}
export interface TSCreateCustomerTaskPayload {
  title: string;
  description: string;
  assignedToEmail: string;
  dueDate: Date | null | string;
  projectOpportunityId?: string;
  projectBatchId?: string;
  customerId: string;
  sequenceNumber: number;
  status: string;
}
export type TSUpdateCustomerTaskPayload = Omit<
  TSCreateCustomerTaskPayload,
  'opportunityId' | 'batchId' | 'customerId'
> & { id: string };

export interface TSUpdateCustomerTaskStatusPayload {
  taskId: string;
  status: 'IN_PROGRESS' | 'COMPLETED';
}
export interface TSReorderCustomerTaskPayload {
  taskId: string;
  sequenceNumber: number;
}

export interface TSDeleteCustomerTaskPayload {
  taskId: string;
}
export interface TSOpportunityTaskResponse {
  id: string;
  title: string;
  description: string;
  assignedToEmail: string | null;
  dueDate: Date | null;
  projectOpportunityId?: string;
  projectBatchId?: string;
  customerId?: string;
  sequenceNumber: number;
  created?: string;
  createdBy?: string;
  modified?: string;
  modifiedBy?: string;
  status?: 'IN_PROGRESS' | 'COMPLETED';
  isNew?: boolean;
}

export interface TSFile {
  name: string;
  path: string;
  size: number;
  type: string;
}
export interface TSOpportunityAttachment {
  recordId: string;
  id: string;
  attachmentType: string;
  attachmentUrl: string;
  fileName: string;
  commentId: string;
  created: string;
  createdBy: string;
}

// this is the shape of what is stored in redux under state.opportunity
export interface TSOpportunityEntityState {
  customersById: {
    [id: string]: TSCustomer;
  };
  customers: Array<TSCustomer>;
  customersMeta: TSMetaState;
  commentsById: {
    [id: string]: TSOpportunityComment;
  };
  comments: Array<TSOpportunityComment>;
  tasks: Array<TSOpportunityTaskResponse>;
  tasksMeta: TSMetaState;
  createCustomerTaskMeta: TSMetaState;
  batchComments: Array<TSOpportunityComment>;
  commentsMeta: TSMetaState;
  batchCommentsMeta: TSMetaState;
  createCommentMeta: TSMetaState;
  createBatchCommentMeta: TSMetaState;
  rejectOpportunityMeta: TSMetaState;
  attachments: Array<TSOpportunityAttachment>;
  attachmentsMeta: TSMetaState;
  batchAttachments: Array<TSOpportunityAttachment>;
  batchAttachmentsMeta: TSMetaState;
  attachmentsUploadMeta: TSMetaState;
  batchAttachmentsUploadMeta: TSMetaState;
  deleteOpportunityAttachmentMeta: TSMetaState;
  deleteBatchAttachmentMeta: TSMetaState;
  updateCustomerTaskMeta: TSMetaState;
  updateCustomerTaskStatusMeta: TSMetaState;
  deleteCustomerTaskMeta: TSMetaState;
  reorderCustomerTaskMeta: TSMetaState;
}

export interface TSCreateCommentPayload {
  opportunityId: string;
  comment: string;
  acceptedFiles?: any;
}

interface TSRejectOpportunityPayload {
  opportunityId: string;
  reason: string;
}

// Redux types
export const types = {
  FETCH_OPPORTUNITY_CUSTOMERS: 'FETCH_OPPORTUNITY_CUSTOMERS',
  FETCH_OPPORTUNITY_CUSTOMERS_SUCCESS: 'FETCH_OPPORTUNITY_CUSTOMERS_SUCCESS',
  FETCH_OPPORTUNITY_CUSTOMERS_ERROR: 'FETCH_OPPORTUNITY_CUSTOMERS_ERROR',
  FETCH_OPPORTUNITY_COMMENTS: 'FETCH_OPPORTUNITY_COMMENTS',
  FETCH_OPPORTUNITY_COMMENTS_SUCCESS: 'FETCH_OPPORTUNITY_COMMENTS_SUCCESS',
  FETCH_OPPORTUNITY_COMMENTS_ERROR: 'FETCH_OPPORTUNITY_COMMENTS_ERROR',
  FETCH_BATCH_COMMENTS: 'FETCH_BATCH_COMMENTS',
  FETCH_BATCH_COMMENTS_SUCCESS: 'FETCH_BATCH_COMMENTS_SUCCESS',
  FETCH_BATCH_COMMENTS_ERROR: 'FETCH_BATCH_COMMENTS_ERROR',
  UPLOAD_FILES: 'UPLOAD_FILES',
  UPLOAD_FILES_SUCCESS: 'UPLOAD_FILES_SUCCESS',
  UPLOAD_FILES_ERROR: 'UPLOAD_FILES_ERROR',
  CREATE_OPPORTUNITY_COMMENT: 'CREATE_OPPORTUNITY_COMMENT',
  CREATE_OPPORTUNITY_COMMENT_SUCCESS: 'CREATE_OPPORTUNITY_COMMENT_SUCCESS',
  CREATE_OPPORTUNITY_COMMENT_ERROR: 'CREATE_OPPORTUNITY_COMMENT_ERROR',
  CREATE_BATCH_COMMENT: 'CREATE_BATCH_COMMENT',
  CREATE_BATCH_COMMENT_SUCCESS: 'CREATE_BATCH_COMMENT_SUCCESS',
  CREATE_BATCH_COMMENT_ERROR: 'CREATE_BATCH_COMMENT_ERROR',
  REJECT_OPPORTUNITY: 'REJECT_OPPORTUNITY',
  REJECT_OPPORTUNITY_SUCCESS: 'REJECT_OPPORTUNITY_SUCCESS',
  REJECT_OPPORTUNITY_ERROR: 'REJECT_OPPORTUNITY_ERROR',
  FETCH_OPPORTUNITY_ATTACHMENTS: 'FETCH_OPPORTUNITY_ATTACHMENTS',
  FETCH_OPPORTUNITY_ATTACHMENTS_SUCCESS:
    'FETCH_OPPORTUNITY_ATTACHMENTS_SUCCESS',
  FETCH_OPPORTUNITY_ATTACHMENTS_ERROR: 'FETCH_BATCH_ATTACHMENTS_ERROR',
  FETCH_BATCH_ATTACHMENTS: 'FETCH_BATCH_ATTACHMENTS',
  FETCH_BATCH_ATTACHMENTS_SUCCESS: 'FETCH_BATCH_ATTACHMENTS_SUCCESS',
  FETCH_BATCH_ATTACHMENTS_ERROR: 'FETCH_BATCH_ATTACHMENTS_ERROR',
  DELETE_OPPORTUNITY_ATTACHMENT: 'DELETE_OPPORTUNITY_ATTACHMENT',
  DELETE_OPPORTUNITY_ATTACHMENT_SUCCESS:
    'DELETE_OPPORTUNITY_ATTACHMENT_SUCCESS',
  DELETE_OPPORTUNITY_ATTACHMENT_ERROR: 'DELETE_OPPORTUNITY_ATTACHMENT_ERROR',
  DELETE_BATCH_ATTACHMENT: 'DELETE_BATCH_ATTACHMENT',
  DELETE_BATCH_ATTACHMENT_SUCCESS: 'DELETE_BATCH_ATTACHMENT_SUCCESS',
  DELETE_BATCH_ATTACHMENT_ERROR: 'DELETE_BATCH_ATTACHMENT_ERROR',
  FETCH_CUSTOMER_TASKS: 'smartProjects/FETCH_CUSTOMER_TASKS',
  FETCH_CUSTOMER_TASKS_SUCCESS: 'smartProjects/FETCH_CUSTOMER_TASKS_SUCCESS',
  FETCH_CUSTOMER_TASKS_ERROR: 'smartProjects/FETCH_CUSTOMER_TASKS_ERROR',
  CREATE_CUSTOMER_TASK: 'smartProjects/CREATE_CUSTOMER_TASK',
  CREATE_CUSTOMER_TASK_SUCCESS: 'smartProjects/CREATE_CUSTOMER_TASK_SUCCESS',
  CREATE_CUSTOMER_TASK_ERROR: 'smartProjects/CREATE_CUSTOMER_TASK_ERROR',
  UPDATE_CUSTOMER_TASK: 'smartProjects/UPDATE_CUSTOMER_TASK',
  UPDATE_CUSTOMER_TASK_SUCCESS: 'smartProjects/UPDATE_CUSTOMER_TASK_SUCCESS',
  UPDATE_CUSTOMER_TASK_ERROR: 'smartProjects/UPDATE_CUSTOMER_TASK_ERROR',
  UPDATE_CUSTOMER_TASK_STATUS: 'smartProjects/UPDATE_CUSTOMER_TASK_STATUS',
  UPDATE_CUSTOMER_TASK_STATUS_SUCCESS:
    'smartProjects/UPDATE_CUSTOMER_TASK_STATUS_SUCCESS',
  UPDATE_CUSTOMER_TASK_STATUS_ERROR:
    'smartProjects/UPDATE_CUSTOMER_TASK_STATUS_ERROR',
  DELETE_CUSTOMER_TASK: 'smartProjects/DELETE_CUSTOMER_TASK',
  DELETE_CUSTOMER_TASK_SUCCESS: 'smartProjects/DELETE_CUSTOMER_TASK_SUCCESS',
  DELETE_CUSTOMER_TASK_ERROR: 'smartProjects/DELETE_CUSTOMER_TASK_ERROR',
  REORDER_CUSTOMER_TASK: 'smartProjects/REORDER_CUSTOMER_TASK',
  REORDER_CUSTOMER_TASK_SUCCESS: 'smartProjects/REORDER_CUSTOMER_TASK_SUCCESS',
  REORDER_CUSTOMER_TASK_ERROR: 'smartProjects/REORDER_CUSTOMER_TASK_ERROR',
};

// Redux actions
export const actions = {
  fetchOpportunityCustomers: () => ({
    type: types.FETCH_OPPORTUNITY_CUSTOMERS,
  }),
  fetchOpportunityComments: (opportunityId: string, customerId: string) => ({
    type: types.FETCH_OPPORTUNITY_COMMENTS,
    opportunityId,
    customerId,
  }),
  // TODO: Can we use the same actions and reducers to create batch
  // comments and opportunity comments along with attachments
  // if we pass in the entityType to the action, we can fork in the duck instead
  fetchBatchComments: (opportunityId: string, customerId: string) => ({
    type: types.FETCH_BATCH_COMMENTS,
    opportunityId,
    customerId,
  }),
  createOpportunityComment: (payload: TSCreateCommentPayload) => ({
    type: types.CREATE_OPPORTUNITY_COMMENT,
    payload,
  }),
  createBatchComment: (payload: TSCreateCommentPayload) => ({
    type: types.CREATE_BATCH_COMMENT,
    payload,
  }),
  rejectOpportunity: (payload: TSRejectOpportunityPayload) => ({
    type: types.REJECT_OPPORTUNITY,
    payload,
  }),
  fetchOpportunityAttachments: (opportunityId: string, customerId: string) => ({
    type: types.FETCH_OPPORTUNITY_ATTACHMENTS,
    opportunityId,
    customerId,
  }),
  fetchBatchAttachments: (opportunityId: string, customerId: string) => ({
    type: types.FETCH_BATCH_ATTACHMENTS,
    opportunityId,
    customerId,
  }),
  deleteOpportunityAttachment: (
    opportunityId: string,
    attachmentId: string
  ) => ({
    type: types.DELETE_OPPORTUNITY_ATTACHMENT,
    seedOrBatch: 'SEED',
    opportunityId,
    attachmentId,
  }),
  deleteBatchAttachment: (opportunityId: string, attachmentId: string) => ({
    type: types.DELETE_BATCH_ATTACHMENT,
    seedOrBatch: 'BATCH',
    opportunityId,
    attachmentId,
  }),
  fetchCustomerTasks: (customerId: string) => ({
    type: types.FETCH_CUSTOMER_TASKS,
    customerId,
  }),
  createCustomerTask: (payload: TSCreateCustomerTaskPayload) => ({
    type: types.CREATE_CUSTOMER_TASK,
    payload,
  }),
  updateCustomerTask: (payload: TSUpdateCustomerTaskPayload) => ({
    type: types.UPDATE_CUSTOMER_TASK,
    payload,
  }),
  updateCustomerTaskStatus: (payload: TSUpdateCustomerTaskStatusPayload) => ({
    type: types.UPDATE_CUSTOMER_TASK_STATUS,
    payload,
  }),
  deleteCustomerTask: (payload: TSDeleteCustomerTaskPayload) => ({
    type: types.DELETE_CUSTOMER_TASK,
    payload,
  }),
  reorderCustomerTask: (payload: TSReorderCustomerTaskPayload) => ({
    type: types.REORDER_CUSTOMER_TASK,
    payload,
  }),
};

export const initialOpportunityState: TSOpportunityEntityState = {
  customersById: {},
  customers: [],
  customersMeta: {
    loading: false,
    error: '',
  },
  commentsById: {},
  comments: [],
  commentsMeta: {
    loading: false,
    error: '',
  },
  batchComments: [],
  batchCommentsMeta: {
    loading: false,
    error: '',
  },
  createCommentMeta: {
    loading: false,
    error: '',
  },
  createBatchCommentMeta: {
    loading: false,
    error: '',
  },
  rejectOpportunityMeta: {
    loading: false,
    error: '',
  },
  attachments: [],
  attachmentsMeta: {
    loading: false,
    error: '',
  },
  batchAttachments: [],
  batchAttachmentsMeta: {
    loading: false,
    error: '',
  },
  batchAttachmentsUploadMeta: {
    loading: false,
    error: '',
  },
  attachmentsUploadMeta: {
    loading: false,
    error: '',
  },
  deleteOpportunityAttachmentMeta: {
    loading: false,
    error: '',
  },
  deleteBatchAttachmentMeta: {
    loading: false,
    error: '',
  },
  tasks: [],
  tasksMeta: {
    loading: false,
    error: '',
  },
  createCustomerTaskMeta: {
    loading: false,
    error: '',
  },
  updateCustomerTaskMeta: {
    loading: false,
    error: '',
  },
  updateCustomerTaskStatusMeta: {
    loading: false,
    error: '',
  },
  deleteCustomerTaskMeta: {
    loading: false,
    error: '',
  },
  reorderCustomerTaskMeta: {
    loading: false,
    error: '',
  },
};

function entityById(action, state) {
  return {
    ...state,
    ...action.payload.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {}
    ),
  };
}

function entityItems(action, state) {
  const newItems: Array<any> = Object.values(action.payload);
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems);
}

// prepare the pieces needed for initializing the state:

function customersById(state = initialOpportunityState.customersById, action) {
  switch (action.type) {
    case types.FETCH_OPPORTUNITY_CUSTOMERS:
      return initialOpportunityState.customersById;
    case types.FETCH_OPPORTUNITY_CUSTOMERS_SUCCESS:
      return entityById(action, state);
    default:
      return state;
  }
}

function customers(state = initialOpportunityState.customers, action) {
  switch (action.type) {
    case types.FETCH_OPPORTUNITY_CUSTOMERS:
      return initialOpportunityState.customers;
    case types.FETCH_OPPORTUNITY_CUSTOMERS_SUCCESS:
      return entityItems(action, state);
    default:
      return state;
  }
}

function defaultMeta(state: TSMetaState, actionTypePrefix: string, action) {
  switch (action.type) {
    case actionTypePrefix:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case `${actionTypePrefix}_ERROR`:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case `${actionTypePrefix}_SUCCESS`:
      return {
        ...state,
        error: '',
        loading: false,
      };
    default:
      return state;
  }
}

function customersMeta(state = initialOpportunityState.customersMeta, action) {
  return defaultMeta(state, types.FETCH_OPPORTUNITY_CUSTOMERS, action);
}

function commentsMeta(state = initialOpportunityState.commentsMeta, action) {
  return defaultMeta(state, types.FETCH_OPPORTUNITY_COMMENTS, action);
}
function tasksMeta(state = initialOpportunityState.tasksMeta, action) {
  return defaultMeta(state, types.FETCH_CUSTOMER_TASKS, action);
}

function batchCommentsMeta(
  state = initialOpportunityState.batchCommentsMeta,
  action
) {
  return defaultMeta(state, types.FETCH_OPPORTUNITY_COMMENTS, action);
}

function createCommentMeta(
  state = initialOpportunityState.createCommentMeta,
  action
) {
  return defaultMeta(state, types.CREATE_OPPORTUNITY_COMMENT, action);
}

function createCustomerTaskMeta(
  state = initialOpportunityState.createCustomerTaskMeta,
  action
) {
  return defaultMeta(state, types.CREATE_CUSTOMER_TASK, action);
}

function updateCustomerTaskMeta(
  state = initialOpportunityState.updateCustomerTaskMeta,
  action
) {
  return defaultMeta(state, types.UPDATE_CUSTOMER_TASK, action);
}

function deleteCustomerTaskMeta(
  state = initialOpportunityState.deleteCustomerTaskMeta,
  action
) {
  return defaultMeta(state, types.DELETE_CUSTOMER_TASK, action);
}

function createBatchCommentMeta(
  state = initialOpportunityState.createBatchCommentMeta,
  action
) {
  return defaultMeta(state, types.CREATE_BATCH_COMMENT, action);
}

function deleteOpportunityAttachmentMeta(
  state = initialOpportunityState.deleteOpportunityAttachmentMeta,
  action
) {
  return defaultMeta(state, types.DELETE_OPPORTUNITY_ATTACHMENT, action);
}

function deleteBatchAttachmentMeta(
  state = initialOpportunityState.deleteBatchAttachmentMeta,
  action
) {
  return defaultMeta(state, types.DELETE_BATCH_ATTACHMENT, action);
}

function rejectOpportunityMeta(
  state = initialOpportunityState.rejectOpportunityMeta,
  action
) {
  return defaultMeta(state, types.REJECT_OPPORTUNITY, action);
}

function commentsById(state = initialOpportunityState.customersById, action) {
  switch (action.type) {
    case types.FETCH_OPPORTUNITY_COMMENTS:
      return initialOpportunityState.customersById;
    case types.FETCH_OPPORTUNITY_COMMENTS_SUCCESS:
      return entityById(action, state);
    default:
      return state;
  }
}

function onCreateComment(action, state) {
  if (action.payload) {
    const newItem: TSOpportunityComment = action.payload;
    return [newItem].concat(state);
  }
  return state;
}

function onCreateTask(action, state) {
  if (action.payload) {
    const newItem = action.payload;
    return [newItem].concat(state);
  }
  return state;
}

function updateEntityItem(action, state) {
  return state.map((item) =>
    item.id === action.payload.id ? action.payload : item
  );
}

function deleteEntityItem(action, state) {
  return state.filter((item) => item.id !== action.payload);
}

function onUploadFiles(action, state) {
  const newItems = action.payload.map((item) => item.data);
  return [...state, ...newItems];
}

function comments(state = initialOpportunityState.comments, action) {
  switch (action.type) {
    case types.FETCH_OPPORTUNITY_COMMENTS:
      return initialOpportunityState.comments;
    case types.FETCH_OPPORTUNITY_COMMENTS_SUCCESS:
      return entityItems(action, state);
    case types.CREATE_OPPORTUNITY_COMMENT_SUCCESS:
      return onCreateComment(action, state);
    default:
      return state;
  }
}
function updateCompletedStatusForTasks(
  payload: { taskId: string; status: 'COMPLETED' | 'IN_PROGRESS' },
  tasks: Array<TSOpportunityTaskResponse>
) {
  return tasks.map((task) => ({
    ...task,
    status: task.id == payload.taskId ? payload.status : task.status,
  }));
}

function tasks(state = initialOpportunityState.tasks, action) {
  switch (action.type) {
    case types.FETCH_CUSTOMER_TASKS:
      return initialOpportunityState.tasks;
    case types.FETCH_CUSTOMER_TASKS_SUCCESS:
      return entityItems(action, state);
    case types.CREATE_CUSTOMER_TASK_SUCCESS:
      return onCreateTask(action, state);
    case types.UPDATE_CUSTOMER_TASK_SUCCESS:
      return updateEntityItem(action, state);
    case types.UPDATE_CUSTOMER_TASK_STATUS_SUCCESS:
      return updateEntityItem(action, state);
    case types.DELETE_CUSTOMER_TASK_SUCCESS:
      return deleteEntityItem(action, state);
    case types.REORDER_CUSTOMER_TASK_SUCCESS:
      return updateEntityItem(action, state);
    case types.UPDATE_CUSTOMER_TASK_STATUS:
      return updateCompletedStatusForTasks(action.payload, state);
    default:
      return state;
  }
}

function batchComments(state = initialOpportunityState.batchComments, action) {
  switch (action.type) {
    case types.FETCH_BATCH_COMMENTS:
      return initialOpportunityState.batchComments;
    case types.FETCH_BATCH_COMMENTS_SUCCESS:
      return entityItems(action, state);
    case types.CREATE_BATCH_COMMENT_SUCCESS:
      return onCreateComment(action, state);
    default:
      return state;
  }
}

function attachmentsMeta(
  state = initialOpportunityState.attachmentsMeta,
  action
) {
  return defaultMeta(state, types.FETCH_OPPORTUNITY_ATTACHMENTS, action);
}

function batchAttachmentsMeta(
  state = initialOpportunityState.batchAttachmentsMeta,
  action
) {
  return defaultMeta(state, types.FETCH_BATCH_ATTACHMENTS, action);
}

function attachmentsUploadMeta(
  state = initialOpportunityState.attachmentsUploadMeta,
  action
) {
  return defaultMeta(state, types.UPLOAD_FILES, action);
}

function batchAttachmentsUploadMeta(
  state = initialOpportunityState.batchAttachmentsUploadMeta,
  action
) {
  return defaultMeta(state, types.UPLOAD_FILES, action);
}

function attachments(state = initialOpportunityState.attachments, action) {
  switch (action.type) {
    case types.FETCH_OPPORTUNITY_ATTACHMENTS:
      return initialOpportunityState.attachments;
    case types.FETCH_OPPORTUNITY_ATTACHMENTS_SUCCESS:
      return entityItems(action, state);
    case types.DELETE_OPPORTUNITY_ATTACHMENT_SUCCESS:
      return state.filter((item) => item.id != action.payload.id);
    case types.UPLOAD_FILES_SUCCESS:
      return onUploadFiles(action, state);
    default:
      return state;
  }
}

function batchAttachments(
  state = initialOpportunityState.batchAttachments,
  action
) {
  switch (action.type) {
    case types.FETCH_BATCH_ATTACHMENTS:
      return initialOpportunityState.attachments;
    case types.FETCH_BATCH_ATTACHMENTS_SUCCESS:
      return entityItems(action, state);
    case types.UPLOAD_FILES_SUCCESS:
      return onUploadFiles(action, state);
    case types.DELETE_BATCH_ATTACHMENT_SUCCESS:
      return state.filter((item) => item.id != action.payload.id);
    default:
      return state;
  }
}

export default combineReducers({
  customersById,
  customers,
  customersMeta,
  commentsById,
  comments,
  batchComments,
  commentsMeta,
  batchCommentsMeta,
  createCommentMeta,
  rejectOpportunityMeta,
  attachments,
  attachmentsMeta,
  attachmentsUploadMeta,
  deleteOpportunityAttachmentMeta,
  batchAttachments,
  batchAttachmentsMeta,
  createBatchCommentMeta,
  batchAttachmentsUploadMeta,
  deleteBatchAttachmentMeta,
  tasks,
  tasksMeta,
  createCustomerTaskMeta,
  updateCustomerTaskMeta,
  deleteCustomerTaskMeta,
});

export const selectOpportunityEntity = (
  state: TSState
): TSOpportunityEntityState => state.entities.opportunity;

const enhanceComment = (
  comment: TSOpportunityComment
): TSOpportunityComment => {
  // TODO: make separate const formats for these?
  return {
    ...comment,
    created: moment(comment.created, DATE_FORMAT_DATA_API_RESPONSE).format(
      DATE_RANGE_PICKER_FORMAT
    ),
  };
};

// Request subset of customers who are authorized to see the opportunity feed
export const API = {
  fetchOpportunityCustomers: () => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityCustomers).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${opportunityApiBaseUrl()}/customers`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results;
      })
      .catch(handleAxiosError);
  },
  fetchOpportunitiesComments: (opportunityId: string, customerId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityComments).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}/comments?customerId=${customerId}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results;
      })
      .catch(handleAxiosError);
  },
  fetchBatchComments: (opportunityId: string, customerId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityBatchComments).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${opportunityApiBaseUrl()}/batches/${opportunityId}/comments?customerId=${customerId}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results;
      })
      .catch(handleAxiosError);
  },
  createOpportunityComment: (payload: TSCreateCommentPayload) => {
    const { opportunityId, ...postData } = payload;
    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}/comments`;

    return axios
      .post(url, postData, { headers: defaultHeaders() })
      .then(({ data }: { data: TSOpportunityComment }) => data)
      .catch(handleAxiosError);
  },
  createBatchComment: (payload: TSCreateCommentPayload) => {
    const { opportunityId, ...postData } = payload;
    const url = `${opportunityApiBaseUrl()}/batches/${opportunityId}/comments`;

    return axios
      .post(url, postData, { headers: defaultHeaders() })
      .then(({ data }: { data: TSOpportunityComment }) => data)
      .catch(handleAxiosError);
  },
  rejectOpportunity: (payload: TSRejectOpportunityPayload) => {
    const { opportunityId, reason } = payload;
    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}/reject`;
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { reason },
    }).catch(handleAxiosError);
  },
  fetchOpportunitiesAttachments: (
    opportunityId: string,
    customerId: string
  ) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityAttachments).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}/attachments?customerId=${customerId}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results;
      })
      .catch(handleAxiosError);
  },
  fetchBatchAttachments: (opportunityId: string, customerId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityBatchAttachments).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${opportunityApiBaseUrl()}/batches/${opportunityId}/attachments?customerId=${customerId}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results;
      })
      .catch(handleAxiosError);
  },
  deleteOpportunityAttachment: (
    opportunityId: string,
    attachmentId: string
  ) => {
    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}/attachments/${attachmentId}`;
    return axios({
      method: 'delete',
      url: url,
      headers: defaultHeaders(),
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  deleteBatchAttachment: (opportunityId: string, attachmentId: string) => {
    const url = `${opportunityApiBaseUrl()}/batches/${opportunityId}/attachments/${attachmentId}`;
    return axios({
      method: 'delete',
      url: url,
      headers: defaultHeaders(),
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  generatePreSignedUrls: (fileName, opportunityId) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(preSignedUrls).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}/attachments/pre-signed-url`;
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: { fileName: fileName },
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  uploadFileUsingPreSignedUrls: (preSignedUrlToUpload, file) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(preSignedUrls).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const partUrl = preSignedUrlToUpload.split('amazonaws.com')[1];
    const url = `${redaptiveGeneralApiBaseUrl() + partUrl}`;
    const headers: {
      'Content-Type': string;
      Authorization?: string;
    } = {
      'Content-Type': file.type,
    };
    return axios({
      method: 'put',
      url: url,
      headers: headers,
      data: file,
    })
      .then((response) => {
        return response;
      })
      .catch(handleAxiosError);
  },
  addFileMetadata: (
    opportunitySeedId,
    preSignedUrlToDownload,
    commentId,
    fileName
  ) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(preSignedUrls).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const url = `${opportunityApiBaseUrl()}/projects/${opportunitySeedId}/attachments`;

    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: {
        attachmentUrl: preSignedUrlToDownload,
        commentId: commentId,
        fileName,
      },
    })
      .then((response) => {
        return response;
      })
      .catch(handleAxiosError);
  },
  addBatchFileMetadata: (
    opportunitySeedId,
    preSignedUrlToDownload,
    commentId,
    fileName
  ) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(preSignedUrls).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const url = `${opportunityApiBaseUrl()}/batches/${opportunitySeedId}/attachments`;

    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: {
        attachmentUrl: preSignedUrlToDownload,
        commentId: commentId,
        fileName,
      },
    })
      .then((response) => {
        return response;
      })
      .catch(handleAxiosError);
  },
  fetchCustomerTasks: (customerId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerTasks).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${gcProposalApiBaseUrl()}/smart-projects/tasks?customerId=${customerId}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  createCustomerTask: ({
    title,
    assignedToEmail,
    customerId,
    description,
    dueDate,
    sequenceNumber,
    projectBatchId,
    projectOpportunityId,
    status,
  }: TSCreateCustomerTaskPayload) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerTasks[0]).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${gcProposalApiBaseUrl()}/smart-projects/tasks`;
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: {
        title,
        assignedToEmail,
        customerId,
        description,
        dueDate,
        sequenceNumber,
        projectBatchId,
        projectOpportunityId,
        status,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  updateCustomerTask: ({
    id,
    title,
    assignedToEmail,
    description,
    dueDate,
    sequenceNumber,
    status,
  }: TSUpdateCustomerTaskPayload) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerTasks[0]).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${gcProposalApiBaseUrl()}/smart-projects/tasks/${id}`;
    return axios({
      method: 'put',
      url: url,
      headers: defaultHeaders(),
      data: {
        title,
        assignedToEmail,
        description,
        dueDate,
        sequenceNumber,
        status,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  updateCustomerTaskStatus: ({
    taskId,
    status,
  }: TSUpdateCustomerTaskStatusPayload) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerTasks[1]).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${gcProposalApiBaseUrl()}/smart-projects/tasks/${taskId}/status`;
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: {
        status,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
  deleteCustomerTask: ({ taskId }: TSDeleteCustomerTaskPayload) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerTasks[1]).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${gcProposalApiBaseUrl()}/smart-projects/tasks/${taskId}`;
    return axios({
      method: 'delete',
      url: url,
      headers: defaultHeaders(),
    })
      .then((response) => {
        return response;
      })
      .catch(handleAxiosError);
  },
  reorderCustomerTask: ({
    taskId,
    sequenceNumber,
  }: TSReorderCustomerTaskPayload) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerTasks[1]).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const url = `${gcProposalApiBaseUrl()}/smart-projects/tasks/${taskId}/re-order`;
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: {
        sequenceNumber,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(handleAxiosError);
  },
};

// Redux sagas
function* fetchOpportunityCustomersSaga(): Generator<any, void, any> {
  try {
    const payload: TSCustomersResponse = yield call(
      API.fetchOpportunityCustomers
    );
    yield put({
      type: types.FETCH_OPPORTUNITY_CUSTOMERS_SUCCESS,
      payload,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_OPPORTUNITY_CUSTOMERS_ERROR, e as Error);
  }
}

function* fetchOpportunityCommentsSaga({
  opportunityId,
  customerId,
}: {
  type: string;
  opportunityId: string;
  customerId: string;
}): Generator<any, void, any> {
  try {
    const comments: TSOpportunityComment[] = yield call(
      API.fetchOpportunitiesComments,
      opportunityId,
      customerId
    );
    yield put({
      type: types.FETCH_OPPORTUNITY_COMMENTS_SUCCESS,
      payload: comments.map((comment) => enhanceComment(comment)),
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_OPPORTUNITY_COMMENTS_ERROR, e as Error);
  }
}

function* fetchBatchCommentsSaga({
  opportunityId,
  customerId,
}: {
  type: string;
  opportunityId: string;
  customerId: string;
}): Generator<any, void, any> {
  try {
    const comments: TSOpportunityComment[] = yield call(
      API.fetchBatchComments,
      opportunityId,
      customerId
    );
    yield put({
      type: types.FETCH_BATCH_COMMENTS_SUCCESS,
      payload: comments.map((comment) => enhanceComment(comment)),
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_BATCH_COMMENTS_ERROR, e as Error);
  }
}

function* createOpportunityCommentSaga({
  payload,
}: {
  type: string;
  payload: TSCreateCommentPayload;
}): Generator<any, void, any> {
  let commentID: string | null = null;
  // If there are files to upload, set loading state to true
  if (payload.acceptedFiles) {
    yield put({
      type: types.UPLOAD_FILES,
    });
  }
  if (payload.comment != '') {
    try {
      // Upload a comment
      const comment: TSOpportunityComment = yield call(
        API.createOpportunityComment,
        payload
      );
      commentID = comment.id;
      yield put({
        type: types.CREATE_OPPORTUNITY_COMMENT_SUCCESS,
        payload: enhanceComment(comment),
      });
    } catch (e) {
      yield handleSagaError(types.CREATE_OPPORTUNITY_COMMENT_ERROR, e as Error);
    }
  }

  if (payload.acceptedFiles) {
    // set create comments loading to false
    yield put({
      type: types.CREATE_OPPORTUNITY_COMMENT_SUCCESS,
      payload: null,
    });

    try {
      // Upload attached documents
      const preSignedUrls = yield all(
        payload.acceptedFiles.map(function* (file) {
          const preSignedUrl = yield call(
            API.generatePreSignedUrls,
            file.name,
            payload.opportunityId
          );
          return { ...preSignedUrl, fileName: file.name };
        })
      );
      // TODO: Can we avoid sequential dependence here?
      yield all(
        payload.acceptedFiles.map((file, index) =>
          call(
            API.uploadFileUsingPreSignedUrls,
            preSignedUrls[index].preSignedUrlToUpload,
            file
          )
        )
      );
      const uploadedFiles = yield all(
        preSignedUrls.map((urls) =>
          call(
            API.addFileMetadata,
            payload.opportunityId,
            urls.preSignedUrlToDownload,
            commentID,
            urls.fileName
          )
        )
      );
      yield put({
        type: types.UPLOAD_FILES_SUCCESS,
        payload: uploadedFiles,
      });
    } catch (e) {
      yield handleSagaError(types.UPLOAD_FILES_ERROR, e as Error);
    }
  }
}

function* createBatchCommentSaga({
  payload,
}: {
  type: string;
  payload: TSCreateCommentPayload;
}): Generator<any, void, any> {
  let commentID: string | null = null;
  // If there are files to upload, set loading state to true
  if (payload.acceptedFiles) {
    yield put({
      type: types.UPLOAD_FILES,
    });
  }
  if (payload.comment != '') {
    try {
      // Upload a comment
      const comment: TSOpportunityComment = yield call(
        API.createBatchComment,
        payload
      );
      commentID = comment.id;
      yield put({
        type: types.CREATE_BATCH_COMMENT_SUCCESS,
        payload: enhanceComment(comment),
      });
    } catch (e) {
      yield handleSagaError(types.CREATE_BATCH_COMMENT_ERROR, e as Error);
    }
  }

  if (payload.acceptedFiles) {
    // set create comments loading to false
    yield put({
      type: types.CREATE_BATCH_COMMENT_SUCCESS,
      payload: null,
    });

    try {
      // Upload attached documents
      const preSignedUrls = yield all(
        payload.acceptedFiles.map(function* (file) {
          const preSignedUrl = yield call(
            API.generatePreSignedUrls,
            file.name,
            payload.opportunityId
          );
          return { ...preSignedUrl, fileName: file.name };
        })
      );
      // TODO: Can we avoid sequential dependence here?
      yield all(
        payload.acceptedFiles.map((file, index) =>
          call(
            API.uploadFileUsingPreSignedUrls,
            preSignedUrls[index].preSignedUrlToUpload,
            file
          )
        )
      );
      const uploadedFiles = yield all(
        preSignedUrls.map((urls) =>
          call(
            API.addBatchFileMetadata,
            payload.opportunityId,
            urls.preSignedUrlToDownload,
            commentID,
            urls.fileName
          )
        )
      );
      yield put({
        type: types.UPLOAD_FILES_SUCCESS,
        payload: uploadedFiles,
      });
    } catch (e) {
      yield handleSagaError(types.UPLOAD_FILES_ERROR, e as Error);
    }
  }
}

function* rejectOpportunitySaga({
  payload,
}: {
  type: string;
  payload: TSRejectOpportunityPayload;
}): Generator<any, void, any> {
  try {
    yield call(API.rejectOpportunity, payload);
    yield put({
      type: types.REJECT_OPPORTUNITY_SUCCESS,
      payload: payload.opportunityId,
    });
  } catch (e) {
    yield handleSagaError(types.REJECT_OPPORTUNITY_ERROR, e as Error);
  }
}

function* fetchOpportunityAttachmentsSaga({
  opportunityId,
  customerId,
}: {
  type: string;
  opportunityId: string;
  customerId: string;
}): Generator<any, void, any> {
  try {
    const attachments: TSOpportunityAttachment[] = yield call(
      API.fetchOpportunitiesAttachments,
      opportunityId,
      customerId
    );
    yield put({
      type: types.FETCH_OPPORTUNITY_ATTACHMENTS_SUCCESS,
      payload: attachments,
    });
  } catch (e) {
    yield handleSagaError(
      types.FETCH_OPPORTUNITY_ATTACHMENTS_ERROR,
      e as Error
    );
  }
}

function* fetchBatchAttachmentsSaga({
  opportunityId,
  customerId,
}: {
  type: string;
  opportunityId: string;
  customerId: string;
}): Generator<any, void, any> {
  try {
    const attachments: TSOpportunityAttachment[] = yield call(
      API.fetchBatchAttachments,
      opportunityId,
      customerId
    );
    yield put({
      type: types.FETCH_BATCH_ATTACHMENTS_SUCCESS,
      payload: attachments,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_BATCH_ATTACHMENTS_ERROR, e as Error);
  }
}

function* deleteOpportunityAttachmentSaga({
  opportunityId,
  attachmentId,
}: {
  type: string;
  opportunityId: string;
  attachmentId: string;
}): Generator<any, void, any> {
  try {
    const attachmentRemoved: TSOpportunityAttachment = yield call(
      API.deleteOpportunityAttachment,
      opportunityId,
      attachmentId
    );
    yield put({
      type: types.DELETE_OPPORTUNITY_ATTACHMENT_SUCCESS,
      payload: attachmentRemoved,
    });
  } catch (e) {
    yield handleSagaError(
      types.DELETE_OPPORTUNITY_ATTACHMENT_ERROR,
      e as Error
    );
  }
}

function* deleteBatchAttachmentSaga({
  opportunityId,
  attachmentId,
}: {
  type: string;
  opportunityId: string;
  attachmentId: string;
}): Generator<any, void, any> {
  try {
    const attachmentRemoved: TSOpportunityAttachment = yield call(
      API.deleteBatchAttachment,
      opportunityId,
      attachmentId
    );
    yield put({
      type: types.DELETE_BATCH_ATTACHMENT_SUCCESS,
      payload: attachmentRemoved,
    });
  } catch (e) {
    yield handleSagaError(types.DELETE_BATCH_ATTACHMENT_ERROR, e as Error);
  }
}

function* fetchCustomerTasksSaga({
  customerId,
}: {
  type: string;
  customerId: string;
}): Generator<any, void, any> {
  try {
    const tasks: Array<TSOpportunityTaskResponse> = yield call(
      API.fetchCustomerTasks,
      customerId
    );
    yield put({
      type: types.FETCH_CUSTOMER_TASKS_SUCCESS,
      payload: tasks,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_CUSTOMER_TASKS_ERROR, e as Error);
  }
}

function* createCustomerTaskSaga({
  payload,
}: {
  type: string;
  payload: TSCreateCustomerTaskPayload;
}): Generator<any, void, any> {
  try {
    const createdTask = yield call(API.createCustomerTask, payload);
    yield put({
      type: types.CREATE_CUSTOMER_TASK_SUCCESS,
      payload: createdTask,
    });
  } catch (e) {
    yield handleSagaError(types.CREATE_CUSTOMER_TASK_ERROR, e as Error);
  }
}

function* updateCustomerTaskSaga({
  payload,
}: {
  type: string;
  payload: TSUpdateCustomerTaskPayload;
}): Generator<any, void, any> {
  try {
    const updatedTask = yield call(API.updateCustomerTask, payload);
    yield put({
      type: types.UPDATE_CUSTOMER_TASK_SUCCESS,
      payload: updatedTask,
    });
  } catch (e) {
    yield handleSagaError(types.UPDATE_CUSTOMER_TASK_ERROR, e as Error);
  }
}

function* deleteCustomerTaskSaga({
  payload,
}: {
  type: string;
  payload: TSDeleteCustomerTaskPayload;
}): Generator<any, void, any> {
  try {
    yield call(API.deleteCustomerTask, payload);
    yield put({
      type: types.DELETE_CUSTOMER_TASK_SUCCESS,
      payload: payload.taskId,
    });
  } catch (e) {
    yield handleSagaError(types.DELETE_CUSTOMER_TASK_ERROR, e as Error);
  }
}

function* updateCustomerTaskStatusSaga({
  payload,
}: {
  type: string;
  payload: TSUpdateCustomerTaskStatusPayload;
}): Generator<any, void, any> {
  try {
    const updatedTask = yield call(API.updateCustomerTaskStatus, payload);
    yield put({
      type: types.UPDATE_CUSTOMER_TASK_STATUS_SUCCESS,
      payload: updatedTask,
    });
  } catch (e) {
    yield handleSagaError(types.UPDATE_CUSTOMER_TASK_STATUS_ERROR, e as Error);
  }
}
function* reorderCustomerTaskSaga({
  payload,
}: {
  type: string;
  payload: TSReorderCustomerTaskPayload;
}): Generator<any, void, any> {
  try {
    const reorderedTask = yield call(API.reorderCustomerTask, payload);
    yield put({
      type: types.REORDER_CUSTOMER_TASK_SUCCESS,
      payload: reorderedTask,
    });
  } catch (e) {
    yield handleSagaError(types.REORDER_CUSTOMER_TASK_ERROR, e as Error);
  }
}

export const sagas = [
  takeLatest(types.FETCH_OPPORTUNITY_CUSTOMERS, fetchOpportunityCustomersSaga),
  takeLatest(types.FETCH_OPPORTUNITY_COMMENTS, fetchOpportunityCommentsSaga),
  takeLatest(types.FETCH_BATCH_COMMENTS, fetchBatchCommentsSaga),
  takeLatest(types.CREATE_OPPORTUNITY_COMMENT, createOpportunityCommentSaga),
  takeLatest(types.CREATE_BATCH_COMMENT, createBatchCommentSaga),
  takeLatest(types.REJECT_OPPORTUNITY, rejectOpportunitySaga),
  takeLatest(
    types.FETCH_OPPORTUNITY_ATTACHMENTS,
    fetchOpportunityAttachmentsSaga
  ),
  takeLatest(types.FETCH_BATCH_ATTACHMENTS, fetchBatchAttachmentsSaga),
  takeLatest(
    types.DELETE_OPPORTUNITY_ATTACHMENT,
    deleteOpportunityAttachmentSaga
  ),
  takeLatest(types.DELETE_BATCH_ATTACHMENT, deleteBatchAttachmentSaga),
  takeLatest(types.FETCH_CUSTOMER_TASKS, fetchCustomerTasksSaga),
  takeLatest(types.CREATE_CUSTOMER_TASK, createCustomerTaskSaga),
  takeLatest(types.UPDATE_CUSTOMER_TASK, updateCustomerTaskSaga),
  takeLatest(types.UPDATE_CUSTOMER_TASK_STATUS, updateCustomerTaskStatusSaga),
  takeLatest(types.DELETE_CUSTOMER_TASK, deleteCustomerTaskSaga),
  takeLatest(types.REORDER_CUSTOMER_TASK, reorderCustomerTaskSaga),
];
