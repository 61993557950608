// MantinePagination component
import {
  Grid,
  Group,
  Pagination,
  PaginationProps,
  Select,
} from '@mantine/core';
import { usePagination } from '@mantine/hooks';
import { PaginationParams } from '@mantine/hooks/lib/use-pagination/use-pagination';

export type R1PaginationProps = PaginationProps & {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  onPageSizeChange: (size: number) => void;
};

export type R1PaginationParams = PaginationParams & {
  limit: number;
};

const MantinePagination = ({
  total,
  totalItems,
  itemsPerPage,
  currentPage,
  onChangePage,
  onPageSizeChange,
  ...rest
}: R1PaginationProps) => {
  const { setPage } = usePagination({
    total: totalItems,
    limit: itemsPerPage,
    initialPage: currentPage,
  } as R1PaginationParams);

  const handlePageChange = (page: number) => {
    setPage(page);
    onChangePage(page);
  };

  const recordCountOptions = [10, 25, 50, 100].filter((n) => n < totalItems);
  if (recordCountOptions[recordCountOptions.length - 1] < 100) {
    recordCountOptions.push(totalItems);
  }
  const recordsPerPageOptions = recordCountOptions.map((v) => ({
    label: `${v} Rows`,
    value: v.toString(),
  }));

  return (
    <Grid w='100%' align='center' justify='center'>
      <Grid.Col span={{ base: 'auto', sm: 8 }} offset={{ base: 0, sm: 2 }}>
        <Group justify='center' wrap='nowrap'>
          <Pagination
            total={Math.ceil(total)}
            onChange={handlePageChange}
            radius='xl'
            {...rest}
          />
        </Group>
      </Grid.Col>

      <Grid.Col span={{ base: 'content', sm: 2 }}>
        {totalItems > 10 && (
          <Group wrap='nowrap' justify='flex-end'>
            Show:
            <Select
              value={`${itemsPerPage}`}
              data={recordsPerPageOptions}
              w='100'
              clearable={false}
              onChange={(v) => {
                onPageSizeChange(Number(v));
              }}
            />
          </Group>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default MantinePagination;
