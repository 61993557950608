import { Navigate, Outlet } from 'react-router-dom';
import { isAuthenticated } from '../api/utils';
import NewLayout from '../components/NewLayout';
import useAppData from '../hooks/useAppData';

const PrivateRoutes = () => {
  useAppData();

  let pathAndSearch = '';
  if (location.pathname) {
    pathAndSearch = `?${location.pathname.slice(1)}`;
    if (location.search) pathAndSearch += location.search;
  }

  return isAuthenticated() ? (
    <NewLayout>
      <Outlet />
    </NewLayout>
  ) : (
    <Navigate to={`/login${pathAndSearch}`} />
  );
};

export default PrivateRoutes;
