import { kebabCase } from 'lodash';
import {
  ConnectPropertyMapped,
  ResourceNameEnum,
  TSBenchmarkingCardData,
  TSBenchmarkingDataMapped,
  TSBenchmarkingDataOriginal,
  TSEnergyStarCustomerState,
  TSEnergyStarCustomerStateMapped,
  TSEnergyStarDownload,
  TSEnergyStarSubmissionDataPayload,
  TSEnergyStarSummaryDataMapped,
  TSEnergyStarSummaryDataOriginal,
  TSPendingPropertiesMapped,
  TSPendingPropertiesOriginal,
  TSPendingPropertySite,
} from '../types';

import moment from 'moment';
import {
  SUBMISSIONS_CREATE_DATE,
  meterTypeToolip,
  valueNotSentTooltip,
} from '../../../pages/Sustainability/ESGReportingPage';
import { formatNumberWithCustomFormatting } from '../../../utils';
import { isCurrentUserRedaptiveDemoUser } from '../../../utils/demo';
import {
  demoUserBenchmarkingMapper,
  demoUserCustomerMappedPayload,
  demoUserSubmissionsMapper,
} from './demoUserMapper';

export const WPT_CUSTOMER_ID = '938bd255-a9f3-4d11-888d-af68d8e37e4b';

const generateMonthsArray = (startYearMonth, endYearMonth) => {
  const start = moment(startYearMonth, 'YYYY-MM');
  const end = moment(endYearMonth, 'YYYY-MM');
  const months: string[] = [];

  while (start.isSameOrBefore(end, 'month')) {
    months.push(start.format('MMM YYYY'));
    start.add(1, 'month');
  }

  return months;
};

export const ES_DATE_FORMAT = 'MM/DD/YYYY';

export const getAllSitesDownloadJSON = (data, listOfMonths) => {
  const downloadJSON: TSEnergyStarDownload[] = [];

  data.forEach((item) => {
    const commonData = {
      siteName: item.siteName,
      resourceName: item.resourceName,
      espmMeterId: 'espmMeterId',
      meterStartDate: item.espmStartdate
        ? moment(item.espmStartdate.split('T')[0], 'YYYY-MM-DD').format(
            'MM-DD-YYYY'
          )
        : 'N/A',
    };

    const uniqueMeterList: any[] = [];
    listOfMonths.forEach((month) => {
      if (item?.[month]?.meterList?.length) {
        item?.[month]?.meterList?.forEach((meterData) => {
          if (uniqueMeterList.indexOf(meterData.espmMeterId) === -1) {
            uniqueMeterList.push(meterData.espmMeterId);
          }
        });
      }
    });

    if (!uniqueMeterList.length) {
      commonData['espmMeterId'] = item.espmMeterId;
      listOfMonths.forEach((month) => {
        if (item?.[month].value === null || item?.[month].value === undefined) {
          commonData[`${month} Submission Date`] = 'N/A';
          commonData[month] = '-';
        } else {
          commonData[`${month} Submission Date`] = moment(
            item?.[month].date,
            ES_DATE_FORMAT
          ).format('MM-DD-YYYY');
          commonData[month] = item?.[month].originalValue;
        }
      });
      downloadJSON.push({ ...commonData });
    } else {
      uniqueMeterList.forEach((espmMeterId) => {
        const tempCommonData = { ...commonData };
        tempCommonData['espmMeterId'] = espmMeterId;
        tempCommonData['meterStartDate'] = 'N/A';
        listOfMonths.forEach((month) => {
          const meterListData = item?.[month]?.meterList?.find(
            (meter) => meter.espmMeterId === espmMeterId
          );
          if (meterListData) {
            tempCommonData[`${month} Submission Date`] = moment(
              meterListData.date,
              ES_DATE_FORMAT
            ).format('MM-DD-YYYY');
            tempCommonData[month] = meterListData.originalValue;
            tempCommonData['meterStartDate'] = meterListData.espmStartdate
              ? moment(
                  meterListData.espmStartdate.split('T')[0],
                  'YYYY-MM-DD'
                ).format('MM-DD-YYYY')
              : 'N/A';
          } else {
            tempCommonData[`${month} Submission Date`] = 'N/A';
            tempCommonData[month] = '-';
          }
        });
        downloadJSON.push({ ...tempCommonData });
      });
    }
  });
  return downloadJSON.reverse();
};

export const submissionsMapper = (
  originalData: TSEnergyStarSummaryDataOriginal[],
  reqData: TSEnergyStarSubmissionDataPayload,
  customerId: string,
  unitFormatPreference: string
): {
  mappedData: TSEnergyStarSummaryDataMapped[];
  listOfMonths: string[];
  allSitesDownloadJSON: TSEnergyStarDownload[];
} => {
  const EMPTY_MONTH_TEMPLATE = { value: null, date: 'N/A', meterList: [] };
  const CURRENT_DATE = new Date();

  const listOfMonths = generateMonthsArray(
    reqData.startYearMonth,
    reqData.endYearMonth
  );

  const monthsObj = listOfMonths.reduce((acc, cur) => {
    acc[cur] = structuredClone(EMPTY_MONTH_TEMPLATE);
    return acc;
  }, {});

  const siteIdAndResourceCombinedList: string[] = [];

  const formatDate = (date: string | null) => {
    if (!date) return 'N/A';
    const formattedDate = date?.split('T')[0];
    return moment(formattedDate, 'YYYY-MM-DD').format(ES_DATE_FORMAT);
  };

  const originalDataWithProperDate = originalData.map((item) => ({
    ...item,
    submissionDate: formatDate(item.submissionDate),
  }));

  const meterVerifiedDateMap = {};

  originalData
    .sort((a, b) => b.siteName.localeCompare(a.siteName))
    .forEach(({ siteId, resourceType, espmStartdate }) => {
      const dataString = `${siteId}/${resourceType}`;
      if (!siteIdAndResourceCombinedList.includes(dataString)) {
        siteIdAndResourceCombinedList.push(dataString);
      }

      if (!meterVerifiedDateMap[siteId]) {
        meterVerifiedDateMap[siteId] = {};
      }
      const formattedDate = espmStartdate?.split('T')[0];
      const resourceName = ResourceNameEnum[resourceType];
      const existingDate = meterVerifiedDateMap[siteId][resourceName];
      if (
        !existingDate ||
        moment(formattedDate, 'YYYY-MM-DD').isBefore(
          moment(existingDate, ES_DATE_FORMAT)
        )
      ) {
        meterVerifiedDateMap[siteId][resourceName] = moment(
          formattedDate,
          'YYYY-MM-DD'
        ).format(ES_DATE_FORMAT);
      }
    });

  const mappedData: any = siteIdAndResourceCombinedList.map((dataString) => {
    const [siteId, resourceType] = dataString.split('/');
    const combinedData = originalDataWithProperDate
      .filter(
        (data) => data.siteId === siteId && data.resourceType === resourceType
      )
      .reduce((acc: any, cur) => {
        let tempObj = { ...acc };
        const formattedDate = moment(cur.reportMonthlyCycle, 'YYYY-MM').format(
          'MMM YYYY'
        );

        if (!tempObj.siteId) {
          tempObj = {
            ...tempObj,
            siteId: cur.siteId,
            siteName: cur.siteName,
            espmStartdate: cur.espmStartdate,
            espmMeterId: cur.espmMeterId,
            resourceName: ResourceNameEnum[cur.resourceType],
            reportMonthlyCycle: formattedDate,
            currentMonthEnergyConsumption: cur.currentMonthEnergyConsumption,
          };
        }
        if (!tempObj[formattedDate]) {
          const obj: any = structuredClone(EMPTY_MONTH_TEMPLATE);
          if (cur.status === 'SUCCESS') {
            obj.value = cur.currentMonthEnergyConsumption;
            obj.date = cur.submissionDate;
            if (cur.dataAggregation === 'meter') {
              obj.meterList.push({
                name: cur.meterName,
                value: formatNumberWithCustomFormatting(
                  Number(cur.currentMonthEnergyConsumption),
                  unitFormatPreference
                ),
                originalValue: cur.currentMonthEnergyConsumption,
                date: cur.submissionDate,
                espmMeterId: cur.espmMeterId,
                espmStartdate: cur.espmStartdate,
              });
            }
          }
          tempObj[formattedDate] = obj;
        } else {
          if (cur.status === 'SUCCESS') {
            const obj = structuredClone(tempObj[formattedDate]);
            obj.value = obj.value
              ? obj.value + cur.currentMonthEnergyConsumption
              : cur.currentMonthEnergyConsumption;
            obj.date =
              obj.date === 'N/A'
                ? cur.submissionDate
                : moment
                    .max(
                      moment(obj.date, ES_DATE_FORMAT),
                      moment(cur.submissionDate, ES_DATE_FORMAT)
                    )
                    .format(ES_DATE_FORMAT);
            obj.meterList.push({
              name: cur.meterName,
              value: formatNumberWithCustomFormatting(
                Number(cur.currentMonthEnergyConsumption),
                unitFormatPreference
              ),
              date: cur.submissionDate,
              originalValue: cur.currentMonthEnergyConsumption,
              espmMeterId: cur.espmMeterId,
              espmStartdate: cur.espmStartdate,
            });
            tempObj[formattedDate] = obj;
          }
        }
        return tempObj;
      }, {});

    return combinedData;
  });

  let completeMappedData = mappedData.map((item, i) => {
    const allData = {
      ...monthsObj,
      ...item,
    };

    Object.keys(allData).forEach((key) => {
      if (allData[key]?.date) {
        let tempObj = structuredClone(allData[key]);
        tempObj = {
          ...tempObj,
          date:
            tempObj.date === 'N/A'
              ? moment().format(ES_DATE_FORMAT)
              : tempObj.date,
          id: kebabCase(`${key} ${i}`),
        };

        const meterVerifiedDate =
          meterVerifiedDateMap[item.siteId][item.resourceName];

        if (
          meterVerifiedDate &&
          moment(key, 'MMM YYYY').isBefore(
            moment(meterVerifiedDate, ES_DATE_FORMAT)
          )
        ) {
          tempObj.submissionIsInvalid = true;
          tempObj.tooltipContent = 'Meters not configured for this month.';
        } else if (
          CURRENT_DATE.getUTCDate() < SUBMISSIONS_CREATE_DATE &&
          moment().subtract(1, 'month').format('MMM YYYY') === key
        ) {
          tempObj.submissionNotGenerated = true;
          tempObj.tooltipContent =
            'Submission Data is currently being processed and will be submitted' +
            ' after the 10th day of this month. Thank you for your patience.';
        } else {
          if (tempObj.value !== null) {
            tempObj.originalValue = tempObj.value;
            tempObj.value = formatNumberWithCustomFormatting(
              Number(tempObj.value),
              unitFormatPreference
            );
            tempObj.tooltipContent = `This value was submitted to ENERGY STAR on ${tempObj.date}`;
            if (tempObj?.meterList?.length) {
              tempObj.tooltipContent = meterTypeToolip({
                meterList: tempObj?.meterList,
              });
            }
          } else {
            tempObj.tooltipContent = valueNotSentTooltip();
          }
        }

        allData[key] = tempObj;
      }
    });

    return allData;
  });

  if (isCurrentUserRedaptiveDemoUser() && customerId === WPT_CUSTOMER_ID) {
    completeMappedData = demoUserSubmissionsMapper(
      completeMappedData,
      listOfMonths,
      unitFormatPreference
    );
  }

  const allSitesDownloadJSON = getAllSitesDownloadJSON(
    completeMappedData,
    listOfMonths
  );

  return { mappedData: completeMappedData, listOfMonths, allSitesDownloadJSON };
};

export const customerStatusMapper = (
  data: TSEnergyStarCustomerState,
  customerId: string
): TSEnergyStarCustomerStateMapped => {
  if (isCurrentUserRedaptiveDemoUser() && customerId === WPT_CUSTOMER_ID) {
    return demoUserCustomerMappedPayload();
  }
  return {
    isEnabled: data.integrationEnabled,
    energyStarCustomerId: data.energyStarCustomerId,
    sitesCount: {
      electric: data.enabledElectricSites,
      water: data.enabledWaterSites,
      gas: data.enabledNaturalGasSites,
    },
  };
};

export const benchmarkingMapper = (
  data: TSBenchmarkingDataOriginal[],
  customerId: string,
  unitFormatPreference: string
): {
  mappedData: TSBenchmarkingDataMapped[];
  cardData: TSBenchmarkingCardData;
} => {
  const checkValueAndFormat = (value: number) => {
    if (value) {
      return formatNumberWithCustomFormatting(value, unitFormatPreference);
    }
    return value;
  };

  let mappedData: TSBenchmarkingDataMapped[] = data
    .sort((a, b) => b.siteName.localeCompare(a.siteName))
    .map((item) => {
      return {
        id: item.id,
        siteEui: checkValueAndFormat(item.siteEui),
        sourceEui: checkValueAndFormat(item.sourceEui),
        siteName: item.siteName,
        nationalMedianSiteEui: checkValueAndFormat(item.nationalMedianSiteEui),
        nationalMedianSourceEui: checkValueAndFormat(
          item.nationalMedianSourceEui
        ),
        nationalMedianSiteEuiDiff: checkValueAndFormat(
          item.nationalMedianSiteEuiDiff
        ),
        nationalMedianSourceEuiDiff: checkValueAndFormat(
          item.nationalMedianSourceEuiDiff
        ),
        energyStarScore: checkValueAndFormat(item.energyStarScore),
        eligibleForCertification:
          item.eligibleForCertification.toLocaleLowerCase() === 'yes'
            ? 'Yes'
            : 'No',
        totalGhgEmissions: checkValueAndFormat(item.totalGhgEmissions),
        modified: item.modified,
      };
    });

  const cardData: TSBenchmarkingCardData = {
    siteWithLowestEUI: null,
    siteWithLowestGHGSubmissions: null,
    sitesEligibleForCertification: 0,
  };

  if (isCurrentUserRedaptiveDemoUser() && customerId === WPT_CUSTOMER_ID) {
    const demoData = demoUserBenchmarkingMapper(
      mappedData,
      unitFormatPreference
    );
    mappedData = structuredClone(demoData.mappedData);
  }

  let siteWithLowestEUI: TSBenchmarkingDataMapped | null = null;
  let siteWithLowestGHGSubmissions: TSBenchmarkingDataMapped | null = null;

  mappedData.forEach((site) => {
    const { siteEui, totalGhgEmissions, eligibleForCertification } = site;
    if (siteEui) {
      if (!siteWithLowestEUI) {
        siteWithLowestEUI = site;
      } else if (site.siteEui < siteWithLowestEUI.siteEui) {
        siteWithLowestEUI = site;
      }
      cardData.siteWithLowestEUI = siteWithLowestEUI.siteName;
    }

    if (totalGhgEmissions) {
      if (!siteWithLowestGHGSubmissions) {
        siteWithLowestGHGSubmissions = site;
      } else if (
        site.totalGhgEmissions < siteWithLowestGHGSubmissions.totalGhgEmissions
      ) {
        siteWithLowestGHGSubmissions = site;
      }
      cardData.siteWithLowestGHGSubmissions =
        siteWithLowestGHGSubmissions.siteName;
    }

    if (eligibleForCertification?.toLocaleLowerCase() === 'yes') {
      if (!cardData.sitesEligibleForCertification) {
        cardData.sitesEligibleForCertification = 1;
      } else {
        cardData.sitesEligibleForCertification += 1;
      }
    }
  });

  return {
    mappedData,
    cardData,
  };
};

export const espmMeterMapper = (
  data: TSPendingPropertySite[]
): ConnectPropertyMapped[] => {
  return data
    .sort((a, b) => a.siteName.localeCompare(b.siteName))
    .map(({ siteName, rdpSiteId, esPropertyId, esPropertyName, esMeters }) => {
      const electricMeter = esMeters.find(
        (meter) => meter.esMeterType === 'electricity'
      );
      const gasMeter = esMeters.find(
        (meter) => meter.esMeterType === 'naturalGas'
      );
      const waterMeter = esMeters.find(
        (meter) => meter.esMeterType === 'water'
      );
      let esPropertyLabel = esPropertyName;
      if (esPropertyId?.toString() && !esPropertyLabel) {
        esPropertyLabel = siteName;
      }
      return {
        siteName,
        rdpSiteId,
        esPropertyData: {
          value: esPropertyId?.toString(),
          label: esPropertyLabel,
          isDisabled: !!esPropertyId,
        },
        electricity: {
          isChecked: electricMeter?.esMeterActive ?? false,
          isDisabled:
            (!electricMeter?.esMeterEnabled || electricMeter?.esMeterActive) ??
            true,
          esMeterId: electricMeter?.esMeterId,
        },
        water: {
          isChecked: waterMeter?.esMeterActive ?? false,
          isDisabled:
            (!waterMeter?.esMeterEnabled || waterMeter?.esMeterActive) ?? true,
          esMeterId: waterMeter?.esMeterId,
        },
        naturalGas: {
          isChecked: gasMeter?.esMeterActive ?? false,
          isDisabled:
            (!gasMeter?.esMeterEnabled || gasMeter?.esMeterActive) ?? true,
          esMeterId: gasMeter?.esMeterId,
        },
      };
    })
    .filter(
      (item) =>
        !(
          item.electricity.isDisabled &&
          item.water.isDisabled &&
          item.naturalGas.isDisabled
        )
    );
};

export const espmMeterResponseMapper = (
  data: ConnectPropertyMapped[],
  customerId: string
) => {
  return data.map((item) => {
    const getResponseMeter = (item, key) => {
      return {
        esMeterType: key,
        esMeterEnabled: !item[key].isDisabled,
        esMeterActive: item[key].isChecked,
        esMeterId: item[key].esMeterId,
      };
    };

    return {
      siteName: item.siteName,
      rdpCustomerId: customerId,
      rdpSiteId: item.rdpSiteId,
      esPropertyName: item?.esPropertyData?.label,
      esPropertyId: item?.esPropertyData?.value,
      esMeters: [
        getResponseMeter(item, 'electricity'),
        getResponseMeter(item, 'water'),
        getResponseMeter(item, 'naturalGas'),
      ],
    };
  });
};

export const pendingPropertiesMapper = (
  data: TSPendingPropertiesOriginal[]
): TSPendingPropertiesMapped[] => {
  return data.map((item) => ({
    label: item.propertyName,
    value: item.propertyId?.toString(),
  }));
};
