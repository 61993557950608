import { UilComparison, UilListUl } from '@iconscout/react-unicons';
import { Card, Flex, SimpleGrid, Stack, Text } from '@mantine/core';
import { max, min, sum } from 'lodash';
import { useContext, useState } from 'react';

import Currency, { useCurrencyConverter } from '../../components/Currency';
import { Alert } from '../../components/mantine/Alert/Alert';
import Quantity from '../../components/mantine/Quantity';
import SegmentedControl from '../../components/mantine/SegmentedControl';
import MetricCard from '../../components/MetricCard';
import Spinner from '../../components/Spinner';
import { toResource } from '../../ducks/types';
import { useResourceUsageData } from '../../queries/resourceUsage';
import { useSitesById } from '../../queries/sites';
import { memberWithMax } from '../../utils/functional';
import ConsumptionBarChart from './ConsumptionBarChart';
import ConsumptionLineChart from './ConsumptionLineChart';
import ConsumptionList from './ConsumptionList';
import useProgramStartDate, {
  useIsDateRangeValid,
} from './Filters/useProgramStartDate';
import InvalidDateRangeAlert from './InvalidDateRangeAlert';
import { QueryError } from './QueryError';
import { FiltersContext } from './utils/filterParams';

const availableViews = [
  {
    label: <UilComparison size={20} />,
    value: 'chart',
    gainsightTagId: 'consumption-analysis-toggle-chart',
  },
  {
    label: <UilListUl size={20} />,
    value: 'list',
    gainsightTagId: 'consumption-analysis-toggle-list',
  },
];

export const resourceStartDate = {
  electricity: 'ingestionDataStartElectricity',
  water: 'ingestionDataStartWater',
  'natural-gas': 'ingestionDataStartNaturalGas',
};

const ConsumptionAnalysisPage = () => {
  // Shared
  const activeFilters = useContext(FiltersContext);
  const sitesQuery = useSitesById();

  const isSingleSiteSelected = activeFilters.siteIds.length === 1;
  const singleSite = isSingleSiteSelected
    ? sitesQuery.data?.[activeFilters.siteIds[0]]
    : undefined;
  const programStartDate = useProgramStartDate(activeFilters.resourceType);
  const isDateRangeValid = useIsDateRangeValid(activeFilters);

  const usageQuery = useResourceUsageData(activeFilters, programStartDate);
  const usageGroups = usageQuery.data?.groups ?? [];
  const hasUsageData =
    usageQuery.isSuccess && usageQuery.data.groups.length > 0;

  // Metric Cards
  const convertCurrency = useCurrencyConverter();
  const totalUsageByTime: [string, number][] | undefined =
    usageQuery.data?.ts?.map((ts, i) => {
      const usage = sum(usageGroups.map((group) => group.usage?.[i]));
      return [ts, usage];
    });
  const minTotalUsageFor15MinPeriod = min(
    totalUsageByTime?.map(([, usage]) => usage)
  );
  const maxTotalUsageFor15MinPeriod = max(
    totalUsageByTime?.map(([, usage]) => usage)
  );
  const totalUsage = sum(usageGroups.map((group) => group.totalUsage));
  const totalElectricitySpend = sum(
    usageGroups.map((group) => group.calcSpend)
  );
  const largestConsumer = memberWithMax(usageGroups, 'totalUsage');
  const isElectricity = activeFilters.resourceType === 'electricity';

  // Cumulative Consumption Over Time
  const [activeView, setActiveView] = useState('chart');

  return (
    <Stack pt={0} p='2xl' gap={24}>
      {(usageQuery.isLoading || sitesQuery.isLoading) && <Spinner centered />}
      {sitesQuery.isSuccess && !isDateRangeValid && <InvalidDateRangeAlert />}
      {usageQuery.isError && (
        <QueryError
          status={usageQuery.error.status}
          message={usageQuery.error.message}
        />
      )}
      {!hasUsageData && usageQuery.isSuccess && (
        <Alert title='No Usage Data' type='orange' />
      )}
      {usageQuery.isSuccess &&
        sitesQuery.isSuccess &&
        isDateRangeValid &&
        hasUsageData && (
          <>
            <SimpleGrid
              cols={{ base: 1, sm: isElectricity ? 4 : 3 }}
              spacing='lg'
            >
              <MetricCard
                title='Metered Consumption'
                value={
                  <Quantity
                    value={totalUsage || 0}
                    quantityType={toResource[activeFilters.resourceType]}
                    precision={0}
                  />
                }
              />
              {isElectricity && (
                <MetricCard
                  title='Metered Spend'
                  subtitle={
                    singleSite
                      ? `Based on: ${convertCurrency(
                          singleSite.electricUtilityRate
                        )} utility rate.`
                      : 'Based on individual site utility rates'
                  }
                  value={<Currency amount={totalElectricitySpend} />}
                />
              )}
              <MetricCard
                title='Largest Consumer'
                value={largestConsumer?.groupName}
              />
              {activeFilters.resourceType === 'electricity' ? (
                <MetricCard
                  title='Minimum Consumption'
                  subtitle='Based on selected resolution'
                  value={
                    <Quantity
                      value={minTotalUsageFor15MinPeriod}
                      quantityType='electric'
                      precision={0}
                    />
                  }
                />
              ) : (
                <MetricCard
                  title='Peak Usage'
                  value={
                    <Quantity
                      value={maxTotalUsageFor15MinPeriod}
                      quantityType={toResource[activeFilters.resourceType]}
                      precision={0}
                    />
                  }
                />
              )}
            </SimpleGrid>

            <Card
              data-gainsight-id='consumption-line-chart'
              p='xl'
              radius='md'
              withBorder
            >
              <Stack gap='sm'>
                <Flex justify='space-between' align='center'>
                  <Text size='14px' fw={'600'}>
                    Cumulative Consumption Over Time
                  </Text>
                  <SegmentedControl
                    activeOption={activeView}
                    options={availableViews}
                    updateOption={(view) => setActiveView(view)}
                    activeColor='#fff'
                    inactiveColor='#6C6D6E'
                  />
                </Flex>
                {activeView === 'chart' && (
                  <div>
                    <ConsumptionLineChart
                      groups={usageGroups}
                      fromDate={activeFilters.fromDate}
                      isSingleSiteSelected={isSingleSiteSelected}
                      resolution={activeFilters.resolution}
                      resource={toResource[activeFilters.resourceType]}
                      timezone={singleSite?.address?.timezone}
                      toDate={activeFilters.toDate}
                      ts={usageQuery.data?.ts}
                    />
                    <Text fz={12} ta='right'>
                      Click-and-drag to zoom. Shift-drag to pan.
                    </Text>
                  </div>
                )}
                {activeView === 'list' && (
                  <ConsumptionList
                    fromDate={activeFilters.fromDate}
                    groups={usageGroups}
                    resolution={activeFilters.resolution}
                    singleSite={singleSite}
                    toDate={activeFilters.toDate}
                    ts={usageQuery.data?.ts}
                    units={usageQuery.data?.units}
                  />
                )}
              </Stack>
            </Card>

            <Card
              data-gainsight-id='consumption-bar-chart'
              withBorder
              radius='md'
              p='xl'
            >
              <ConsumptionBarChart
                groups={usageGroups}
                resource={toResource[activeFilters.resourceType]}
                title='Consumption by Group'
                totalUsage={totalUsage}
              />
              <Text fz={12} ta='right'>
                Click-and-drag to zoom. Shift-drag to pan.
              </Text>
            </Card>
          </>
        )}
    </Stack>
  );
};

export default ConsumptionAnalysisPage;
