import { useCallback } from 'react';
import {
  AbstractApiCurrencies,
  useExchangeRate,
} from '../queries/exchangeRates';
import {
  useCurrencyPreference,
  useThousandsSeparatorPreference,
} from '../queries/settings';
import { getCurrencyFormat, swapCommasAndPeriods } from '../utils';

interface CurrencyTypes {
  amount: number;
  precision?: number;
  /* The currency associated with `amount` */
  sourceCurrency?: AbstractApiCurrencies;
  isThousandsSeparatorPeriod?: boolean;
}

const Currency = ({
  amount,
  sourceCurrency = 'USD',
  precision = 2,
}: CurrencyTypes) => {
  const { data: thousandsSeparator } = useThousandsSeparatorPreference();
  const { data: targetCurrency } = useCurrencyPreference();
  const isThousandsSeparatorPeriod = thousandsSeparator === 'period';

  if (targetCurrency === sourceCurrency) {
    return (
      <>
        {formatCurrency({
          currency: sourceCurrency,
          amount,
          precision,
          isThousandsSeparatorPeriod,
        })}
      </>
    );
  } else {
    return (
      <ConvertedCurrency
        sourceCurrency={sourceCurrency}
        targetCurrency={targetCurrency}
        amount={amount}
        precision={precision}
        isThousandsSeparatorPeriod={isThousandsSeparatorPeriod}
      />
    );
  }
};

interface ConvertedCurrencyTypes {
  amount: number;
  precision?: number;
  sourceCurrency?: AbstractApiCurrencies;
  targetCurrency?: AbstractApiCurrencies;
  isThousandsSeparatorPeriod?: boolean;
}

const ConvertedCurrency = ({
  sourceCurrency = 'USD',
  targetCurrency,
  amount,
  precision,
  isThousandsSeparatorPeriod,
}: ConvertedCurrencyTypes) => {
  const { data: exchangeRate = 1 } = useExchangeRate(sourceCurrency);
  const displayCurrency = exchangeRate ? targetCurrency : sourceCurrency;
  const displayAmount = exchangeRate ? amount * exchangeRate : amount;

  return (
    <>
      {formatCurrency({
        currency: displayCurrency,
        amount: displayAmount,
        precision,
        isThousandsSeparatorPeriod,
      })}
    </>
  );
};

export const useCurrencyConverter = (
  sourceCurrency: AbstractApiCurrencies = 'USD'
) => {
  const { data: targetCurrency } = useCurrencyPreference();
  const { data: thousandsSeparator } = useThousandsSeparatorPreference();
  const { data: exchangeRate = 1 } = useExchangeRate(sourceCurrency);

  const converter = useCallback(
    (amount: number) => {
      const useConversion = exchangeRate !== 1;
      const displayCurrency = useConversion ? targetCurrency : sourceCurrency;
      const displayAmount = useConversion ? amount * exchangeRate : amount;

      return formatCurrency({
        amount: displayAmount,
        currency: displayCurrency,
        precision: 2,
        isThousandsSeparatorPeriod: thousandsSeparator === 'period',
      });
    },
    [exchangeRate, targetCurrency, sourceCurrency, thousandsSeparator]
  );

  return converter;
};

export const formatCurrency = ({
  currency,
  amount,
  precision,
  isThousandsSeparatorPeriod,
}) => {
  const currencyFormat = getCurrencyFormat('en-US', currency, precision);
  const formattedAmount = currencyFormat.format(amount);
  return isThousandsSeparatorPeriod
    ? swapCommasAndPeriods(formattedAmount)
    : formattedAmount;
};

export default Currency;
