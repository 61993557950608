import styled, { css } from 'styled-components';
import { breakpoints } from '../../themes';

const OpHoursChartLegendWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 90px;
`;

const OpHoursLegendContainerStyled = styled.div`
  margin-right: 24px;
`;

const OpHoursLinearGradientStyles = css`
  border-radius: 2px;
  height: 20px;
  margin-bottom: 10px;
  width: 150px;

  @media (min-width: ${breakpoints.sm}) {
    width: 200px;
  }
`;

const OutsideOpHoursLinearGradientStyled = styled.div`
  background-image: ${({ theme }) => `linear-gradient(to right,
    ${theme.colors.primaryLighter}, ${theme.colors.primary})`};
  ${OpHoursLinearGradientStyles};
`;

const InsideOpHoursLinearGradientStyled = styled.div`
  background-image: linear-gradient(to right, #f0f0f0, #6c6d6e);
  ${OpHoursLinearGradientStyles};
`;

const OpHoursLegendTitleStyled = styled.div`
  color: #000;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 12px;
  font-weight: 400;
`;

const OperatingHoursLegend = () => {
  return (
    <OpHoursChartLegendWrapperStyled>
      <OpHoursLegendContainerStyled>
        <OutsideOpHoursLinearGradientStyled />
        <OpHoursLegendTitleStyled>
          Outside Operating Hours
        </OpHoursLegendTitleStyled>
      </OpHoursLegendContainerStyled>
      <OpHoursLegendContainerStyled>
        <InsideOpHoursLinearGradientStyled />
        <OpHoursLegendTitleStyled>
          Inside Operating Hours
        </OpHoursLegendTitleStyled>
      </OpHoursLegendContainerStyled>
    </OpHoursChartLegendWrapperStyled>
  );
};

export default OperatingHoursLegend;
