import { ComboboxItem } from '@mantine/core';
import { naturallySortItems } from '.';
import { TSItem } from '../components/ListSelector';
import { TSSite } from '../ducks/sites';

// mantine uses ComboboxItem, older components use TSItem
export const getSitesOptionsItems = (
  sites: TSSite[]
): Array<TSItem & ComboboxItem> => {
  return sites
    .map((site) => ({
      id: site.id,
      name: site.validName,
      value: site.id,
      label: site.validName,
    }))
    .sort(naturallySortItems);
};
