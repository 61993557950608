import { RefObject, useEffect, useState } from 'react';
import useEscapeKey from './useEscapeKey';

interface TSProps {
  componentRef: RefObject<HTMLElement>;
  initialVisible?: boolean;
  toggleRef: RefObject<HTMLElement>;
}

export default ({
  componentRef,
  initialVisible = false,
  toggleRef,
}: TSProps): {
  isComponentVisible: boolean;
  setIsComponentVisible: (visible: boolean) => void;
} => {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialVisible);

  const handleClick = (event: MouseEvent) => {
    if (toggleRef.current && toggleRef.current.contains(event.target as Node)) {
      setIsComponentVisible(!isComponentVisible);
    } else if (
      componentRef.current &&
      !componentRef.current.contains(event.target as Node)
    ) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  });

  useEscapeKey(
    () => {
      setIsComponentVisible(false);
    },
  );

  return {
    isComponentVisible,
    setIsComponentVisible,
  };
};
