import { Popover, Stack } from '@mantine/core';
import { every, omit } from 'lodash';
import { useState } from 'react';
import { ParentNavLink } from '.';
import NavButton from './NavButton';
import NavLink from './NavLink';

const NavButtonWithPopoverMenu = (item: ParentNavLink) => {
  const [opened, setOpened] = useState(false);
  if (item.isHidden) return null;
  if (every(item.children, (c) => c.isHidden)) return null;

  return (
    <Popover
      key={item.label}
      position='right-start'
      withArrow
      shadow='md'
      opened={opened}
      onChange={setOpened}
      arrowOffset={24}
      offset={{ mainAxis: 5, crossAxis: 10 }}
      zIndex={1002}
    >
      <Popover.Target>
        <NavButton
          {...omit(item, 'children', 'component')}
          onClick={() => setOpened((o) => !o)}
        />
      </Popover.Target>
      <Popover.Dropdown miw={220}>
        <Stack gap='xs'>
          <NavLink
            {...omit(item, 'component', 'icon')}
            label={item.label}
            opened
            topLevel
            styles={{
              children: {
                paddingLeft: 0,
              },
            }}
          >
            {item.children
              ?.filter((c) => !c.isHidden)
              ?.map((child) => (
                <NavLink
                  onClick={() => setOpened(false)}
                  key={child.label}
                  {...omit(child, 'component', 'isHidden')}
                />
              ))}
          </NavLink>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default NavButtonWithPopoverMenu;
