/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { defaultHeaders, denaliApiBaseUrl } from '../../api';
import { handleAxiosError, handleSagaError } from '../../api/utils';
import customerCircuitCategoriesMockData from '../../mockData/customCircuitCategories/customerCategories';
import { TSMetaState } from '../types';
import { naturallySortEmptyLastCaseInsensitive } from '../../utils';
import { isVariantActive } from '../../utils/variants';

export interface TSCircuitCategoryValue {
  id: string;
  name: string;
  categoryId: string;
}

export interface TSCustomerCircuitCategoryResponse {
  id: string;
  name: string;
  description: string | null;
  values?: Array<TSCircuitCategoryValue>;
}

interface CustomerCircuitCategoryCreatePayload {
  name: string;
  values: Array<{ name: string }>;
}

export type TSCustomerCircuitCategoriesResponse =
  Array<TSCustomerCircuitCategoryResponse>;

interface TSCustomerCircuitCategoriesMetaState extends TSMetaState {
  noCustomerCategories: boolean;
}

export interface TSCustomerCircuitCategoriesEntityState {
  byId: {
    [id: string]: TSCustomerCircuitCategoryResponse;
  };
  items: TSCustomerCircuitCategoriesResponse;
  categoryValuesById: {
    [id: string]: TSCircuitCategoryValue;
  };
  meta: TSCustomerCircuitCategoriesMetaState;
  deleteCategoryOrValueMeta: TSMetaState;
}

interface TSState {
  entities: {
    customerCategories: TSCustomerCircuitCategoriesEntityState;
  };
}

export const naturallySortByCategoryOrValueName = (
  a: TSCustomerCircuitCategoryResponse | TSCircuitCategoryValue,
  b: TSCustomerCircuitCategoryResponse | TSCircuitCategoryValue
) => naturallySortEmptyLastCaseInsensitive(a.name, b.name);

export const types = {
  FETCH_CUSTOMER_CIRCUIT_CATEGORIES: 'FETCH_CUSTOMER_CIRCUIT_CATEGORIES',
  FETCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    'FETCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS',
  FETCH_CUSTOMER_CIRCUIT_CATEGORIES_ERROR:
    'FETCH_CUSTOMER_CIRCUIT_CATEGORIES_ERROR',
  POST_CUSTOMER_CIRCUIT_CATEGORIES: 'POST_CUSTOMER_CIRCUIT_CATEGORIES',
  POST_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    'POST_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS',
  POST_CUSTOMER_CIRCUIT_CATEGORIES_ERROR:
    'POST_CUSTOMER_CIRCUIT_CATEGORIES_ERROR',
  PATCH_CUSTOMER_CIRCUIT_CATEGORIES: 'PATCH_CUSTOMER_CIRCUIT_CATEGORIES',
  PATCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    'PATCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS',
  PATCH_CUSTOMER_CIRCUIT_CATEGORIES_ERROR:
    'PATCH_CUSTOMER_CIRCUIT_CATEGORIES_ERROR',
  DELETE_CUSTOMER_CIRCUIT_CATEGORY: 'DELETE_CUSTOMER_CIRCUIT_CATEGORY',
  DELETE_CUSTOMER_CIRCUIT_CATEGORY_SUCCESS:
    'DELETE_CUSTOMER_CIRCUIT_CATEGORY_SUCCESS',
  DELETE_CUSTOMER_CIRCUIT_CATEGORY_ERROR:
    'DELETE_CUSTOMER_CIRCUIT_CATEGORY_ERROR',
  DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE:
    'DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE',
  DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_SUCCESS:
    'DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_SUCCESS',
  DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_ERROR:
    'DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_ERROR',
  CLEAR_DELETE_ERROR: 'CLEAR_DELETE_ERROR',
};

export const actions = {
  fetchCustomerCircuitCategories: (customerId: string, values?: boolean) => ({
    type: types.FETCH_CUSTOMER_CIRCUIT_CATEGORIES,
    customerId,
    values,
  }),
  patchCustomerCircuitCategories: (
    customerId: string,
    categoryId: string,
    payload: Array<TSCustomerCircuitCategoryResponse>
  ) => ({
    type: types.PATCH_CUSTOMER_CIRCUIT_CATEGORIES,
    customerId,
    categoryId,
    payload,
  }),
  postCustomerCircuitCategories: (
    customerId: string,
    payload: Array<CustomerCircuitCategoryCreatePayload>
  ) => ({
    type: types.POST_CUSTOMER_CIRCUIT_CATEGORIES,
    customerId,
    payload,
  }),
  deleteCustomerCircuitCategory: (customerId: string, categoryId: string) => ({
    type: types.DELETE_CUSTOMER_CIRCUIT_CATEGORY,
    customerId,
    categoryId,
  }),
  deleteCustomerCircuitCategoryValue: (
    customerId: string,
    categoryId: string,
    valueId: string
  ) => ({
    type: types.DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE,
    customerId,
    categoryId,
    valueId,
  }),
  clearDeleteError: () => ({ type: types.CLEAR_DELETE_ERROR }),
};

export const initialState: TSCustomerCircuitCategoriesEntityState = {
  byId: {},
  items: [],
  categoryValuesById: {},
  meta: {
    error: '',
    loading: false,
    noCustomerCategories: false,
  },
  deleteCategoryOrValueMeta: {
    error: '',
    loading: false,
  },
};

function customerCategoryById(action, state) {
  if (
    action.type === types.DELETE_CUSTOMER_CIRCUIT_CATEGORY_SUCCESS ||
    action.type === types.DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_SUCCESS
  ) {
    return {
      ...action.payload.reduce(
        (acc: any, cur: TSCustomerCircuitCategoryResponse) => ({
          ...acc,
          [cur.id]: cur,
        }),
        {}
      ),
    };
  }
  return {
    ...state,
    ...action.payload.reduce(
      (acc: any, cur: TSCustomerCircuitCategoryResponse) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {}
    ),
  };
}

function customerCategoryValuesById(action, state) {
  const customerCategoriesValues = action.payload
    .map(({ values }) => values)
    .flat();

  if (
    action.type === types.DELETE_CUSTOMER_CIRCUIT_CATEGORY_SUCCESS ||
    action.type === types.DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_SUCCESS
  ) {
    return customerCategoriesValues;
  }
  return {
    ...state,
    ...customerCategoriesValues.reduce(
      (acc, cur) => ({
        ...acc,
        [cur?.id ?? '']: cur,
      }),
      {}
    ),
  };
}

function customerCategoriesItems(action, state) {
  const newItems: Array<TSCustomerCircuitCategoryResponse> = Object.values(
    action.payload
  );
  if (
    action.type === types.DELETE_CUSTOMER_CIRCUIT_CATEGORY_SUCCESS ||
    action.type === types.DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_SUCCESS
  ) {
    return newItems;
  }
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems);
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_CUSTOMER_CIRCUIT_CATEGORIES:
      return initialState.byId;
    case types.FETCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    case types.PATCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    case types.POST_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    case types.DELETE_CUSTOMER_CIRCUIT_CATEGORY_SUCCESS:
    case types.DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_SUCCESS:
      return customerCategoryById(action, state);
    default:
      return state;
  }
}

function categoryValuesById(state = initialState.categoryValuesById, action) {
  switch (action.type) {
    case types.FETCH_CUSTOMER_CIRCUIT_CATEGORIES:
      return initialState.categoryValuesById;
    case types.FETCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    case types.PATCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    case types.POST_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    case types.DELETE_CUSTOMER_CIRCUIT_CATEGORY_SUCCESS:
    case types.DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_SUCCESS:
      return customerCategoryValuesById(action, state);
    default:
      return state;
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_CUSTOMER_CIRCUIT_CATEGORIES:
      return initialState.items;
    case types.FETCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    case types.PATCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    case types.POST_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    case types.DELETE_CUSTOMER_CIRCUIT_CATEGORY_SUCCESS:
    case types.DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_SUCCESS:
      return customerCategoriesItems(action, state);
    default:
      return state;
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_CUSTOMER_CIRCUIT_CATEGORIES:
    case types.PATCH_CUSTOMER_CIRCUIT_CATEGORIES:
    case types.POST_CUSTOMER_CIRCUIT_CATEGORIES:
      return {
        ...state,
        error: '',
        loading: true,
        noCustomerCategories: false,
      };
    case types.FETCH_CUSTOMER_CIRCUIT_CATEGORIES_ERROR:
    case types.PATCH_CUSTOMER_CIRCUIT_CATEGORIES_ERROR:
    case types.POST_CUSTOMER_CIRCUIT_CATEGORIES_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    case types.PATCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
    case types.POST_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noCustomerCategories: !action.payload[0],
      };
    default:
      return state;
  }
}

function deleteCategoryOrValueMeta(
  state = initialState.deleteCategoryOrValueMeta,
  action
) {
  switch (action.type) {
    case types.DELETE_CUSTOMER_CIRCUIT_CATEGORY:
    case types.DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE:
    case types.CLEAR_DELETE_ERROR:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case types.DELETE_CUSTOMER_CIRCUIT_CATEGORY_ERROR:
    case types.DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.DELETE_CUSTOMER_CIRCUIT_CATEGORY_SUCCESS:
    case types.DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      };
    default:
      return state;
  }
}

export default combineReducers({
  byId,
  categoryValuesById,
  items,
  meta,
  deleteCategoryOrValueMeta,
});

export const selectCustomerCategoriesEntity = (
  state: TSState
): TSCustomerCircuitCategoriesEntityState => state.entities.customerCategories;

export const API = {
  fetchCustomerCircuitCategories: (customerId: string, values = true) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerCircuitCategoriesMockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }
    const url = `${denaliApiBaseUrl()}/customers/${customerId}/categories?values=${values}`;

    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSCustomerCircuitCategoriesResponse }) => data)
      .catch(handleAxiosError);
  },
  patchCustomerCircuitCategory: (
    customerId: string,
    categoryId: string,
    payload: TSCustomerCircuitCategoriesResponse
  ) => {
    const { values, ...category } = payload[0];
    const categoryUrl = `${denaliApiBaseUrl()}/customers/${customerId}/categories/${categoryId}`;
    const categoryUpdate = axios
      .patch(
        categoryUrl,
        { payload: [category] },
        { headers: defaultHeaders() }
      )
      .then(
        ({
          data,
        }: {
          data: { results: TSCustomerCircuitCategoriesResponse };
        }) => data
      )
      .catch(handleAxiosError);
    const valuesUrl = `${categoryUrl}/values`;
    const valuesUpdate = axios
      .post(valuesUrl, { payload: values }, { headers: defaultHeaders() })
      .then(
        ({
          data,
        }: {
          data: { results: TSCustomerCircuitCategoriesResponse };
        }) => data
      )
      .catch(handleAxiosError);
    return Promise.all([categoryUpdate, valuesUpdate]).then(
      ([{ results: category }, { results: values }]) => {
        return {
          results: [
            {
              ...category[0],
              values,
            },
          ],
        };
      }
    );
  },
  postCustomerCircuitCategory: (
    customerId: string,
    payload: TSCustomerCircuitCategoriesResponse
  ) => {
    const url = `${denaliApiBaseUrl()}/customers/${customerId}/categories`;
    return axios
      .post(url, { payload }, { headers: defaultHeaders() })
      .then(({ data }: { data: TSCustomerCircuitCategoriesResponse }) => data)
      .catch(handleAxiosError);
  },
  deleteCustomerCircuitCategory: (customerId: string, categoryId: string) => {
    const url = `${denaliApiBaseUrl()}/customers/${customerId}/categories/${categoryId}`;

    return axios
      .delete(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSCustomerCircuitCategoriesResponse }) => data)
      .catch(handleAxiosError);
  },
  deleteCustomerCircuitCategoryValue: (
    customerId: string,
    categoryId: string,
    valueId: string
  ) => {
    const url = `${denaliApiBaseUrl()}/customers/${customerId}/categories/${categoryId}/values/${valueId}`;

    return axios
      .delete(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSCustomerCircuitCategoriesResponse }) => data)
      .catch(handleAxiosError);
  },
};

function* fetchCustomerCircuitCategoriesSaga({
  type,
  customerId,
}: {
  type: string;
  customerId: string;
}): Generator<any, void, any> {
  try {
    const customerCircuitCategories = yield call(
      API.fetchCustomerCircuitCategories,
      customerId
    );

    yield put({
      type: types.FETCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS,
      payload: [...customerCircuitCategories.results].sort(
        naturallySortByCategoryOrValueName
      ),
    });
  } catch (e) {
    yield handleSagaError(
      types.FETCH_CUSTOMER_CIRCUIT_CATEGORIES_ERROR,
      e as Error
    );
  }
}

function* patchCustomerCircuitCategoriesSaga({
  type,
  customerId,
  categoryId,
  payload,
}: {
  type: string;
  customerId: string;
  categoryId: string;
  payload: TSCustomerCircuitCategoriesResponse;
}): Generator<any, void, any> {
  const { items: existingCategories } = yield select(
    selectCustomerCategoriesEntity
  );
  try {
    const customerCircuitCategories = yield call(
      API.patchCustomerCircuitCategory,
      customerId,
      categoryId,
      payload
    );
    yield put({
      type: types.PATCH_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS,
      payload: [...customerCircuitCategories.results].sort(
        naturallySortByCategoryOrValueName
      ),
    });
  } catch (e) {
    yield handleSagaError(
      types.PATCH_CUSTOMER_CIRCUIT_CATEGORIES_ERROR,
      e as Error
    );
  }
}

function* postCustomerCircuitCategoriesSaga({
  type,
  customerId,
  payload,
}: {
  type: string;
  customerId: string;
  payload: TSCustomerCircuitCategoriesResponse;
}): Generator<any, void, any> {
  const { items: existingCategories } = yield select(
    selectCustomerCategoriesEntity
  );
  try {
    const customerCircuitCategories = yield call(
      API.postCustomerCircuitCategory,
      customerId,
      payload
    );
    yield put({
      type: types.POST_CUSTOMER_CIRCUIT_CATEGORIES_SUCCESS,
      payload: [
        ...existingCategories,
        ...customerCircuitCategories.results,
      ].sort(naturallySortByCategoryOrValueName),
    });
  } catch (e) {
    yield handleSagaError(
      types.POST_CUSTOMER_CIRCUIT_CATEGORIES_ERROR,
      e as Error
    );
  }
}

function* deleteCustomerCircuitCategorySaga({
  type,
  customerId,
  categoryId,
}: {
  type: string;
  customerId: string;
  categoryId: string;
}): Generator<any, void, any> {
  const { items: existingCategories } = yield select(
    selectCustomerCategoriesEntity
  );
  try {
    yield call(API.deleteCustomerCircuitCategory, customerId, categoryId);

    const updatedCategories = [
      ...existingCategories.filter(({ id }) => categoryId !== id),
    ].sort(naturallySortByCategoryOrValueName);
    yield put({
      type: types.DELETE_CUSTOMER_CIRCUIT_CATEGORY_SUCCESS,
      payload: updatedCategories,
    });
  } catch (e) {
    yield handleSagaError(
      types.DELETE_CUSTOMER_CIRCUIT_CATEGORY_ERROR,
      e as Error
    );
  }
}

function* deleteCustomerCircuitCategoryValueSaga({
  type,
  customerId,
  categoryId,
  valueId,
}: {
  type: string;
  customerId: string;
  categoryId: string;
  valueId: string;
}): Generator<any, void, any> {
  const { items: existingCategories } = yield select(
    selectCustomerCategoriesEntity
  );
  try {
    yield call(
      API.deleteCustomerCircuitCategoryValue,
      customerId,
      categoryId,
      valueId
    );
    const updatedCategories = [
      ...existingCategories.map((category) => {
        const { id, values } = category;
        if (categoryId === id) {
          return {
            ...category,
            values: values.filter((value) => value.id !== valueId),
          };
        }
        return category;
      }),
    ];
    yield put({
      type: types.DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_SUCCESS,
      payload: updatedCategories.sort(naturallySortByCategoryOrValueName),
    });
  } catch (e) {
    yield handleSagaError(
      types.DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE_ERROR,
      e as Error
    );
  }
}

export const sagas = [
  takeLatest(
    types.FETCH_CUSTOMER_CIRCUIT_CATEGORIES,
    fetchCustomerCircuitCategoriesSaga
  ),
  takeLatest(
    types.PATCH_CUSTOMER_CIRCUIT_CATEGORIES,
    patchCustomerCircuitCategoriesSaga
  ),
  takeLatest(
    types.POST_CUSTOMER_CIRCUIT_CATEGORIES,
    postCustomerCircuitCategoriesSaga
  ),
  takeLatest(
    types.DELETE_CUSTOMER_CIRCUIT_CATEGORY,
    deleteCustomerCircuitCategorySaga
  ),
  takeLatest(
    types.DELETE_CUSTOMER_CIRCUIT_CATEGORIES_VALUE,
    deleteCustomerCircuitCategoryValueSaga
  ),
];
