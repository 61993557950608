import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { useSavedFilter } from '../../../../queries/savedFilters';
import DeleteSavedFilterConfirmation from './DeleteConfirmation';
import SavedFiltersMenu from './Menu';
import UpsertSavedFilterModal from './UpsertModal';

const SavedFilters = () => {
  const [editSavedFilterId, setEditSavedFilterId] = useState<string | null>(
    null
  );
  const [openActionMenuId, setOpenActionMenuId] = useState<string | null>(null);

  const { data: editSavedFilter } = useSavedFilter(editSavedFilterId);

  const [isSavedFiltersMenuOpen, savedFiltersMenuHandler] = useDisclosure(
    false,
    {
      onClose: () => setOpenActionMenuId(null),
    }
  );
  const [isSaveModalOpen, saveModalHandlers] = useDisclosure(false, {
    onOpen: () => savedFiltersMenuHandler.close(),
  });
  const [isDeleteConfirmationOpen, deleteMenuHandler] = useDisclosure(false, {
    onOpen: savedFiltersMenuHandler.close,
  });

  const openSaveQueryModal = (id: string | null) => {
    setEditSavedFilterId(id);
    saveModalHandlers.open();
  };
  const openDeleteConfirmation = (id: string | null) => {
    setEditSavedFilterId(id);
    deleteMenuHandler.open();
  };

  return (
    <>
      <SavedFiltersMenu
        isOpen={isSavedFiltersMenuOpen}
        openActionMenuId={openActionMenuId}
        openDeleteConfirmation={openDeleteConfirmation}
        openSaveQueryModal={openSaveQueryModal}
        setOpenActionMenuId={setOpenActionMenuId}
        toggleMenu={savedFiltersMenuHandler.toggle}
      />

      {isSaveModalOpen && (
        <UpsertSavedFilterModal
          id={editSavedFilterId}
          name={editSavedFilter?.name}
          onClose={saveModalHandlers.close}
        />
      )}

      {isDeleteConfirmationOpen && (
        <DeleteSavedFilterConfirmation
          id={editSavedFilterId}
          name={editSavedFilter?.name}
          onClose={deleteMenuHandler.close}
        />
      )}
    </>
  );
};

export default SavedFilters;
