import _, { camelCase, isEqual, kebabCase, omit } from 'lodash';
import { useMemo } from 'react';
import { ResourceType } from '../../../ducks/types';
import {
  customFiltersToCategoryValue,
  customGroupingToPayload,
} from '../../../queries/resourceUsage';
import { useSavedFilters } from '../../../queries/savedFilters';
import { Resource, SavedFilter } from '../../../queries/savedFilters/types';
import { omitNilEmpty } from '../../../utils/functional';
import {
  DataExplorerDefaultFilters,
  DateStr,
  DateTimeStr,
  ParsedDataExplorerSearchParams,
} from '../types';
import useActiveFilters from './filterParams';
import { useSearchParamsToFilters } from './searchParams';

const removeTimeFromDatetime = (date?: DateTimeStr) =>
  date?.split('T')[0] as DateStr | undefined;
const addTimeToDate = (date?: DateStr): DateTimeStr | undefined =>
  date ? `${date}T00:00:00` : date;
const toResourceType = (resource: Resource | undefined) =>
  resource ? (kebabCase(resource) as ResourceType) : undefined;
const toResource = (resourceType: ResourceType) =>
  camelCase(resourceType) as Resource;

export const savedFilterToSearchParams = (
  query: SavedFilter['parameters']['query']
): ParsedDataExplorerSearchParams => {
  const customCategories = _(query.categoryValue)
    .groupBy((v) => v.split(':')[0])
    .mapKeys((_, k) => `categoryValue-${k}`)
    .value();
  const searchParams = {
    ...omit(query, 'resource', 'site', 'categoryValue', 'groupingId'),
    siteIds: query.site,
    resourceType: toResourceType(query.resource),
    fromDate: removeTimeFromDatetime(query.fromDate),
    toDate: removeTimeFromDatetime(query.toDate),
    grouping: query.groupingId ?? query.grouping,
    ...customCategories,
  };
  return omitNilEmpty(searchParams) as ParsedDataExplorerSearchParams;
};

export const searchParamsToSavedFilter = (
  searchParams: ParsedDataExplorerSearchParams,
  pageDefaults: DataExplorerDefaultFilters
): SavedFilter['parameters']['query'] => {
  const filteredParams = _(searchParams)
    .omit('siteIds', 'resourceType', 'measurementTypes')
    .omitBy((_, k) => k.startsWith('categoryValue-'))
    .value();
  const query = {
    ...filteredParams,
    site: searchParams.siteIds,
    resource: toResource(
      searchParams.resourceType ?? pageDefaults.resourceType
    ),
    resolution: searchParams.resolution ?? pageDefaults.resolution,
    fromDate: addTimeToDate(searchParams.fromDate),
    toDate: addTimeToDate(searchParams.toDate),
    categoryValue: customFiltersToCategoryValue(searchParams),
    ...customGroupingToPayload(searchParams.grouping),
  };
  return omitNilEmpty(query) as SavedFilter['parameters']['query'];
};

export const useActiveSavedFilter = () => {
  const { data: savedFilters = [] } = useSavedFilters();
  const [activeFilters] = useActiveFilters();
  const searchParamsToFilters = useSearchParamsToFilters();
  const activeSavedFilter = useMemo(
    () =>
      savedFilters.find((query) => {
        const filterQuery = searchParamsToFilters(
          savedFilterToSearchParams(query.parameters.query)
        );
        return isEqual(filterQuery, activeFilters);
      }),
    [activeFilters, savedFilters, searchParamsToFilters]
  );

  return activeSavedFilter;
};
