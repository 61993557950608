/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiBaseUrl, defaultHeaders } from '../../api';
import {
  getRequestMonthRange,
  handleAxiosError,
  handleSagaError,
  queryStringify,
} from '../../api/utils';
import { TSState } from '../../reducers/rootReducer';
import { TSMetaState } from '../types';
export enum OpportunityStageName {
  SHIPPING = 'Shipping',
  BILLING_AND_MONITORING = 'Billing & Monitoring',
  INSTALLING = 'Installing',
  TERMINATION_AFTER_LOA = 'Termination After LOA',
  MASTER_BOOKED = 'Master Booked',
  SCOPING = 'Scoping',
  DISCONTINUED_AFTER_BOOKING = 'Discontinued After Booking',
  LOST_DEAD = 'Lost/Dead',
  EVALUATING = 'Evaluating',
  CONTRACT_FULFILLED = 'Contract Fulfilled',
  EMPTY = '',
}
export type TSAggregationSavings = {
  actualCost: number;
  actualUsageKwh: number;
  carbonImpact: number;
  costSavings: number;
  energyPayment: number;
  energyPaymentDue: number;
  expectedSavingsCost: number;
  expectedSavingsKwh: number;
  meteredUsageKwh: number;
  contractId: string;
  month: number;
  netCostSavings: number;
  projectedCost: number;
  projectedUsageKwh: number;
  remainingEnergyCommitment?: number;
  savedKwh: number;
  year: number;
};

export type TSSavingsAggregations = {
  carbonAvoided: number;
  energySavings: number;
  netCostSavings: number;
  firstSiteROID: number;
  firstSiteLastBlessedBillDate: string;
  firstSiteOpportunityStageName: OpportunityStageName;
  savings: Array<TSAggregationSavings>;
};

type TSSavingsSiteEntity = {
  aggregations: TSSavingsAggregations;
  allSavings?: Array<TSAggregationSavings>;
};

type TSSavingsMeta = {
  error: string;
  loading: boolean;
  noDataAvailable: boolean;
};

type TSSavingsEntity = {
  aggregations: TSSavingsAggregations;
};

export type TSFetchSavingsCustomerAction = {
  customerId: string;
  fromDate: string;
  toDate: string;
};

export type TSFetchSavingsSiteAction = {
  siteId: string;
  fromDate: string;
  toDate: string;
};

interface TSSavingsMetaState extends TSMetaState {
  noDataAvailable: boolean;
}

export interface TSSavingsEntityState {
  data: TSSavingsSiteEntity;
  meta: TSSavingsMeta;
}

const types = {
  FETCH_SAVINGS_CUSTOMER: 'FETCH_SAVINGS_CUSTOMER',
  FETCH_SAVINGS_CUSTOMER_SUCCESS: 'FETCH_SAVINGS_CUSTOMER_SUCCESS',
  FETCH_SAVINGS_CUSTOMER_ERROR: 'FETCH_SAVINGS_CUSTOMER_ERROR',
  FETCH_SAVINGS_SITE: 'FETCH_SAVINGS_SITE',
  FETCH_SAVINGS_SITE_SUCCESS: 'FETCH_SAVINGS_SITE_SUCCESS',
  FETCH_SAVINGS_SITE_ERROR: 'FETCH_SAVINGS_SITE_ERROR',
};

export const actions = {
  fetchSavingsCustomer: (request: TSFetchSavingsCustomerAction) => ({
    type: types.FETCH_SAVINGS_CUSTOMER,
    request,
  }),

  fetchSavingsSite: (request: TSFetchSavingsSiteAction) => ({
    type: types.FETCH_SAVINGS_SITE,
    request,
  }),
};

export const initialStateMeta: TSSavingsMeta = {
  error: '',
  loading: false,
  noDataAvailable: false,
};

export const defaultAggregations: TSSavingsAggregations = {
  carbonAvoided: 0,
  energySavings: 0,
  netCostSavings: 0,
  firstSiteROID: 0,
  firstSiteLastBlessedBillDate: '',
  savings: [
    {
      actualCost: 0,
      actualUsageKwh: 0,
      carbonImpact: 0,
      costSavings: 0,
      energyPayment: 0,
      energyPaymentDue: 0,
      expectedSavingsCost: 0,
      expectedSavingsKwh: 0,
      meteredUsageKwh: 0,
      month: 0,
      contractId: '',
      netCostSavings: 0,
      projectedCost: 0,
      projectedUsageKwh: 0,
      remainingEnergyCommitment: 0,
      savedKwh: 0,
      year: 0,
    },
  ],
  firstSiteOpportunityStageName: OpportunityStageName.EMPTY,
};
export const initialStateCustomerEntity: TSSavingsEntity = {
  aggregations: {
    carbonAvoided: 0,
    energySavings: 0,
    netCostSavings: 0,
    firstSiteROID: 0,
    firstSiteOpportunityStageName: OpportunityStageName.EMPTY,
    firstSiteLastBlessedBillDate: '',
    savings: [
      {
        actualCost: 0,
        actualUsageKwh: 0,
        carbonImpact: 0,
        costSavings: 0,
        energyPayment: 0,
        energyPaymentDue: 0,
        expectedSavingsCost: 0,
        expectedSavingsKwh: 0,
        meteredUsageKwh: 0,
        month: 0,
        contractId: '',
        netCostSavings: 0,
        projectedCost: 0,
        projectedUsageKwh: 0,
        remainingEnergyCommitment: 0,
        savedKwh: 0,
        year: 0,
      },
    ],
  },
};

export const initialState: TSSavingsEntityState = {
  data: initialStateCustomerEntity,
  meta: initialStateMeta,
};

function data(state = initialState.data, action): TSSavingsEntity {
  switch (action.type) {
    case types.FETCH_SAVINGS_CUSTOMER:
    case types.FETCH_SAVINGS_SITE:
      return initialState.data;
    case types.FETCH_SAVINGS_CUSTOMER_SUCCESS:
    case types.FETCH_SAVINGS_SITE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

function meta(state = initialState.meta, action): TSSavingsMetaState {
  switch (action.type) {
    case types.FETCH_SAVINGS_CUSTOMER:
    case types.FETCH_SAVINGS_SITE:
      return {
        ...state,
        error: '',
        loading: true,
        noDataAvailable: false,
      };
    case types.FETCH_SAVINGS_CUSTOMER_ERROR:
    case types.FETCH_SAVINGS_SITE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        noDataAvailable: false,
      };
    case types.FETCH_SAVINGS_CUSTOMER_SUCCESS:
    case types.FETCH_SAVINGS_SITE_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noDataAvailable: !action.payload[0],
      };
    default:
      return state;
  }
}

export default combineReducers({
  data,
  meta,
});

export const selectSavings = (state: TSState): TSSavingsEntityState =>
  state.entities.savings;

const API = {
  fetchSavingsCustomer: ({
    customerId,
    fromDate,
    toDate,
  }: TSFetchSavingsCustomerAction) => {
    const monthRange = getRequestMonthRange(fromDate, toDate);

    const query = queryStringify({
      ...monthRange,
    });

    const url = `${apiBaseUrl()}/savings/customer/${customerId}?${decodeURIComponent(
      query
    )}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSavingsSiteEntity }) => data)
      .catch(handleAxiosError);
  },

  fetchSavingsSite: ({
    siteId,
    fromDate,
    toDate,
  }: TSFetchSavingsSiteAction) => {
    const monthRange = getRequestMonthRange(fromDate, toDate);

    const query = queryStringify({
      ...monthRange,
    });
    const url = `${apiBaseUrl()}/savings/site/${siteId}?${decodeURIComponent(
      query
    )}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSavingsSiteEntity }) => data)
      .catch(handleAxiosError);
  },
};

// sagas
function* fetchSavingsCustomerSaga({
  request,
}: {
  request: TSFetchSavingsCustomerAction;
  type: string;
}): Generator<any, void, any> {
  try {
    const payload: TSSavingsSiteEntity = yield call(
      API.fetchSavingsCustomer,
      request
    );
    yield put({
      type: types.FETCH_SAVINGS_CUSTOMER_SUCCESS,
      payload: payload,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_SAVINGS_CUSTOMER_ERROR, e as Error);
  }
}

function* fetchSavingsSiteSaga({
  request,
}: {
  request: TSFetchSavingsSiteAction;
  type: string;
}): Generator<any, void, any> {
  try {
    const payload: TSSavingsSiteEntity = yield call(
      API.fetchSavingsSite,
      request
    );
    yield put({
      type: types.FETCH_SAVINGS_SITE_SUCCESS,
      payload: payload,
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_SAVINGS_SITE_ERROR, e as Error);
  }
}

export const sagas = [
  takeLatest(types.FETCH_SAVINGS_CUSTOMER, fetchSavingsCustomerSaga),
  takeLatest(types.FETCH_SAVINGS_SITE, fetchSavingsSiteSaga),
];
