/* eslint-disable max-len */

interface TSProps {
  color?: string;
  size?: string;
  onClick?: (event: any) => void;
}

const AddToBatchIcon = ({
  color = '#6C6D6E',
  size = '18',
  onClick = () => ({}),
}: TSProps) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.3535 10.8919V10.8889C17.3535 10.6746 17.2684 10.469 17.1169 10.3175C16.9653 10.1659 16.7598 10.0808 16.5455 10.0808H11.697V8.46465H13.3131C13.5274 8.46465 13.733 8.37951 13.8845 8.22797C14.0361 8.07642 14.1212 7.87088 14.1212 7.65657V2.80808C14.1212 2.59376 14.0361 2.38823 13.8845 2.23668C13.733 2.08514 13.5274 2 13.3131 2H8.46465C8.25033 2 8.04479 2.08514 7.89325 2.23668C7.7417 2.38823 7.65657 2.59376 7.65657 2.80808V7.65657C7.65657 7.87088 7.7417 8.07642 7.89325 8.22797C8.04479 8.37951 8.25033 8.46465 8.46465 8.46465H10.0808V10.0808H5.23232C5.01801 10.0808 4.81247 10.1659 4.66092 10.3175C4.50938 10.469 4.42424 10.6746 4.42424 10.8889V13.3131H2.80808C2.59376 13.3131 2.38823 13.3983 2.23668 13.5498C2.08514 13.7014 2 13.9069 2 14.1212V18.9697C2 19.184 2.08514 19.3896 2.23668 19.5411C2.38823 19.6926 2.59376 19.7778 2.80808 19.7778H7.65657C7.87088 19.7778 8.07642 19.6926 8.22797 19.5411C8.37951 19.3896 8.46465 19.184 8.46465 18.9697V14.1212C8.46465 13.9069 8.37951 13.7014 8.22797 13.5498C8.07642 13.3983 7.87088 13.3131 7.65657 13.3131H6.0404V11.697H14.3715C15.2622 11.2119 16.2757 10.924 17.3535 10.8919ZM6.84848 18.1616V14.9293H3.61616V18.1616H6.84848ZM9.27273 3.61616V6.84848H12.5051V3.61616H9.27273Z'
      fill={color}
    />
    <path
      d='M21.4444 17H18.1111V13.6666C18.1111 13.5193 18.0526 13.378 17.9484 13.2738C17.8442 13.1696 17.7029 13.1111 17.5555 13.1111C17.4082 13.1111 17.2669 13.1696 17.1627 13.2738C17.0585 13.378 17 13.5193 17 13.6666V17H13.6666C13.5193 17 13.378 17.0585 13.2738 17.1627C13.1696 17.2669 13.1111 17.4082 13.1111 17.5555C13.1111 17.7029 13.1696 17.8442 13.2738 17.9484C13.378 18.0526 13.5193 18.1111 13.6666 18.1111H17V21.4444C17 21.5918 17.0585 21.7331 17.1627 21.8373C17.2669 21.9414 17.4082 22 17.5555 22C17.7029 22 17.8442 21.9414 17.9484 21.8373C18.0526 21.7331 18.1111 21.5918 18.1111 21.4444V18.1111H21.4444C21.5918 18.1111 21.7331 18.0526 21.8373 17.9484C21.9414 17.8442 22 17.7029 22 17.5555C22 17.4082 21.9414 17.2669 21.8373 17.1627C21.7331 17.0585 21.5918 17 21.4444 17Z'
      fill={color}
      stroke={color}
      strokeWidth='0.4'
    />
  </svg>
);

export default AddToBatchIcon;
