import { Group, Input, InputProps, Popover, Text } from '@mantine/core';
import { DatePicker, MonthPicker } from '@mantine/dates';
import '@mantine/dates/styles.css';
import moment, { Moment } from 'moment';
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as consts from '../../../constants';
import { dateRangeOptions, Period } from '../../../constants';
import useWindowSize from '../../../hooks/useWindowSize';
import CalendarIcon from '../../Icons/CalendarIcon';
import Button from '../Button';
import styles from './DateRangePicker.module.css';

interface DateRangePickerProps extends InputProps {
  endDate?: Date | null;
  height?: number;
  gainsightTagId?: string;
  getProgramStartDate?: () => Moment;
  isMonthlyDateRangePicker?: boolean;
  isVerticalOrientation?: boolean;
  label?: string;
  maxDays?: number;
  maxMonths?: number;
  options?: Array<any>;
  period?: string;
  programStartDate?: Moment;
  startDate?: Date | null;
  updateDateRange: (props: any) => void;
  withinPortal?: boolean;
}

const defaultFormat = 'MMM D, YYYY';

const inputText = (
  start: moment.MomentInput,
  end: moment.MomentInput,
  isMonthlyDateRangePicker: boolean | undefined
) => {
  const format = isMonthlyDateRangePicker
    ? consts.DATE_RANGE_PICKER_MONTHLY_FORMAT
    : defaultFormat;
  const startDate = moment(start, consts.DATE_FORMAT_DATA_API_REQUEST);

  const startText = startDate.format(format);
  let text = startText;
  const endText = end
    ? moment(end, consts.DATE_FORMAT_DATA_API_REQUEST).format(format)
    : '';
  if (end && endText !== startText) {
    text = `${text} - ${endText}`;
  }
  return text;
};

const DateRangePicker = ({
  endDate = null,
  height,
  gainsightTagId,
  getProgramStartDate,
  isMonthlyDateRangePicker,
  isVerticalOrientation,
  label,
  maxDays,
  maxMonths,
  options = [],
  period = '',
  programStartDate: programStartDateProp,
  startDate = null,
  updateDateRange,
  withinPortal,
  w = 225,
  ...rest
}: DateRangePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const windowSize = useWindowSize();
  const programStartDate =
    programStartDateProp ??
    (getProgramStartDate && getProgramStartDate()) ??
    moment('2010', 'YYYY');

  const [value, setValue] = useState<[Date | null, Date | null]>([
    startDate,
    endDate,
  ]);
  const [calendarOrientation, setCalendarOrientation] = useState('horizontal');
  const [selectedPeriod, updateSelectedPeriod] = useState(period);

  const calendarText = useMemo(() => {
    if (selectedPeriod !== Period.CUSTOM) {
      return dateRangeOptions.find((option) => option.value === selectedPeriod)
        ?.label;
    }
    return inputText(value[0], value[1], isMonthlyDateRangePicker);
  }, [value, selectedPeriod, isMonthlyDateRangePicker]);

  const handleOptionSelect: any = useCallback(
    (value: string) => () => {
      updateSelectedPeriod(value);
      setValue([null, null]);
    },
    []
  );

  const handleDateChange = (
    dates: SetStateAction<[Date | null, Date | null]>
  ) => {
    setValue(dates);
    updateSelectedPeriod(Period.CUSTOM);
  };

  const handleCancelClick: any = () => {
    setValue([startDate, endDate]);
    updateSelectedPeriod(period);
    setIsOpen(false);
  };

  const handleApplyClick = useCallback(() => {
    const [updatedStartDate, updatedEndDate] =
      selectedPeriod === Period.CUSTOM ? value : [null, null];

    if (selectedPeriod !== Period.CUSTOM) {
      setValue([updatedStartDate, updatedEndDate]);
    }
    setIsOpen(false);

    return (
      updateDateRange &&
      updateDateRange({
        startDate: updatedStartDate,
        endDate: updatedEndDate ?? updatedStartDate,
        selectedOptionDate: selectedPeriod,
      })
    );
  }, [value, selectedPeriod, updateDateRange, setIsOpen]);

  const maxDate = () => {
    if (value[0] && !value[1] && maxDays) {
      const plusDays = moment(value[0]).add(maxDays, 'days');
      if (plusDays > moment()) {
        return moment().toDate();
      } else {
        return plusDays.toDate();
      }
    }
    return moment().toDate();
  };

  const minDate = () => {
    if (value[0] && !value[1] && maxDays) {
      if (moment(value[0]).subtract(maxDays, 'days') < programStartDate) {
        return programStartDate.toDate();
      }
      return moment(value[0]).subtract(maxDays, 'days').toDate();
    }
    return programStartDate.toDate();
  };

  const maxDateForMonthly = () => {
    if (value[0] && !value[1] && maxMonths) {
      const plusMonths = moment(value[0]).add(maxMonths, 'months');
      if (plusMonths > moment()) {
        return moment().toDate();
      } else {
        return plusMonths.toDate();
      }
    }
    return moment().toDate();
  };

  const minDateForMonthly = () => {
    if (value[0] && !value[1] && maxMonths) {
      return moment(value[0]).subtract(maxMonths, 'months').toDate();
    }
    return programStartDate.toDate();
  };

  useEffect(() => {
    if (windowSize[0] <= 960 || isVerticalOrientation) {
      setCalendarOrientation('vertical');
    } else {
      setCalendarOrientation('horizontal');
    }
  }, [windowSize, isVerticalOrientation]);

  useEffect(() => {
    if (!isOpen) {
      updateSelectedPeriod(period);
      setValue([startDate, endDate]);
    }
  }, [isOpen, startDate, endDate, period]);

  return (
    <>
      <Popover
        trapFocus
        opened={isOpen}
        onChange={setIsOpen}
        shadow='sm'
        withinPortal={withinPortal}
        offset={4}
      >
        <Popover.Target>
          <Input.Wrapper size='md'>
            {label && <Input.Label>{label}</Input.Label>}
            <Input
              component='button'
              data-gainsight-id={gainsightTagId}
              onClick={() => setIsOpen((prev) => !prev)}
              rightSection={<CalendarIcon size='16' />}
              contentEditable={false}
              radius={8}
              size='md'
              styles={{ input: { height: height, minHeight: height } }}
              w={w}
              {...rest}
            >
              <Text span lineClamp={1}>
                {calendarText}
              </Text>
            </Input>
          </Input.Wrapper>
        </Popover.Target>
        <Popover.Dropdown p={0}>
          <Group wrap='nowrap' gap={0} align='start'>
            {!isMonthlyDateRangePicker ? (
              <DatePicker
                p='sm'
                className={styles.date}
                type='range'
                value={value}
                defaultDate={value[0] ?? undefined}
                onChange={handleDateChange}
                maxLevel='year'
                numberOfColumns={calendarOrientation === 'vertical' ? 1 : 2}
                size='sm'
                minDate={minDate()}
                maxDate={maxDate()}
                firstDayOfWeek={0}
                allowSingleDateInRange
                styles={{
                  calendarHeaderLevel: {
                    fontSize: 'large',
                    fontColor: 'black',
                  },
                }}
              />
            ) : (
              <MonthPicker
                type='range'
                value={value}
                defaultDate={value[0] ?? undefined}
                onChange={handleDateChange}
                maxLevel='year'
                numberOfColumns={calendarOrientation === 'vertical' ? 1 : 2}
                size='md'
                minDate={minDateForMonthly()}
                maxDate={maxDateForMonthly()}
              />
            )}
            {calendarOrientation === 'horizontal' && options.length > 0 && (
              <Group className={styles.options}>
                {options?.map((option) => {
                  const activeClass =
                    selectedPeriod === option.value
                      ? styles.optionItemActive
                      : '';
                  return (
                    <div
                      className={`${styles.optionItem} ${activeClass}`}
                      onClick={handleOptionSelect(option.value)}
                      key={option.value}
                    >
                      {option.label}
                    </div>
                  );
                })}
              </Group>
            )}
          </Group>
          <Group justify='flex-end' className={styles.actionButtons} p='sm'>
            <Button
              size='sm'
              type='button'
              variant='filled'
              color='#E0E0E0'
              styles={{
                label: {
                  color: '#6d6e6f',
                },
              }}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              size='sm'
              type='button'
              color='#9dbd79'
              variant='filled'
              onClick={handleApplyClick}
            >
              Confirm
            </Button>
          </Group>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

export default DateRangePicker;
