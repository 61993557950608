import CheckmarkIcon from '@iconscout/react-unicons/icons/uil-check';
import EllipsisHorizontalIcon from '@iconscout/react-unicons/icons/uil-ellipsis-h';
import { ActionIcon, Menu, Tooltip } from '@mantine/core';

import { useSavedFilter } from '../../../../queries/savedFilters';
import useActiveFilters from '../../utils/filterParams';
import { savedFilterToSearchParams } from '../../utils/savedFilters';
import styles from './styles.module.css';
import { SavedFilterItemProps } from './types';

const SavedFilterMenuItem = ({
  id,
  isActionMenuOpen,
  name,
  selected,
  toggleActionMenu,
  openSaveQueryModal,
  openDeleteConfirmation,
  onClose,
}: SavedFilterItemProps) => {
  const { data: query } = useSavedFilter(id);

  const [, setActiveFilters] = useActiveFilters();

  const applySavedFilter = () => {
    if (!query) return;
    setActiveFilters(() => savedFilterToSearchParams(query.parameters.query));
    onClose?.();
  };

  return (
    <Menu
      key={id}
      position='bottom-start'
      opened={isActionMenuOpen}
      offset={2}
      width={100}
    >
      <Tooltip label={name} openDelay={500}>
        <Menu.Item
          className={styles.menuItem}
          data-gainsight-id='data-explorer-saved-filter-menu-apply-button'
          data-selected={selected}
          leftSection={<CheckmarkIcon size={18} />}
          onClick={applySavedFilter}
          component='div'
          rightSection={
            <Menu.Target>
              <ActionIcon
                variant='transparent'
                onClick={(e) => {
                  e.stopPropagation();
                  toggleActionMenu();
                }}
              >
                <EllipsisHorizontalIcon size={18} />
              </ActionIcon>
            </Menu.Target>
          }
        >
          {name}
        </Menu.Item>
      </Tooltip>
      <Menu.Dropdown>
        <Menu.Item
          fz='md'
          onClick={openSaveQueryModal}
          data-autofocus
          data-gainsight-id='data-explorer-saved-filter-menu-edit-button'
        >
          Edit Name
        </Menu.Item>
        <Menu.Item
          fz='md'
          onClick={openDeleteConfirmation}
          c='red'
          data-gainsight-id='data-explorer-saved-filter-menu-delete-button'
        >
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default SavedFilterMenuItem;
