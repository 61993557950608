import { TSSiteShiftsResponse } from '../../ducks/siteShifts';

const siteShifts: TSSiteShiftsResponse = [
  {
    id: '01dfbb18-7125-45c6-9bc4-b9645e16c91f',
    siteId: 'aedfbb18-7125-45c6-9bc4-b9645e16c91f',
    name: 'Monday',
    startDay: 'MONDAY',
    startTime: '09:00',
    durationInMinutes: 480,
  },
  {
    id: '02dfbb18-7125-45c6-9bc4-b9645e16c91f',
    siteId: 'aedfbb18-7125-45c6-9bc4-b9645e16c91f',
    name: 'Tuesday',
    startDay: 'TUESDAY',
    startTime: '09:00',
    durationInMinutes: 480,
  },
  {
    id: '03dfbb18-7125-45c6-9bc4-b9645e16c91f',
    siteId: 'aedfbb18-7125-45c6-9bc4-b9645e16c91f',
    name: 'Wednesday',
    startDay: 'WEDNESDAY',
    startTime: '09:00',
    durationInMinutes: 480,
  },
  {
    id: '04dfbb18-7125-45c6-9bc4-b9645e16c91f',
    siteId: 'aedfbb18-7125-45c6-9bc4-b9645e16c91f',
    name: 'Thursday',
    startDay: 'THURSDAY',
    startTime: '09:00',
    durationInMinutes: 480,
  },
  {
    id: '05dfbb18-7125-45c6-9bc4-b9645e16c91f',
    siteId: 'aedfbb18-7125-45c6-9bc4-b9645e16c91f',
    name: 'Friday',
    startDay: 'FRIDAY',
    startTime: '09:00',
    durationInMinutes: 360,
  },
];

export default siteShifts;
