/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiBaseUrl, defaultHeaders } from '../../api';
import { handleAxiosError, handleSagaError } from '../../api/utils';
import { TSState } from '../../reducers/rootReducer';
import { TSMetaState } from '../types';

type TSSavingsAggregationsEntity = {
  carbonAvoided: number;
  energySavings: number;
  netCostSavings: number;
  energySavingPerformance: number;
};

type TSSavingsMeta = {
  error: string;
  loading: boolean;
  noDataAvailable: boolean;
};

type TSSavingsEntity = {
  aggregations: TSSavingsAggregationsEntity;
};

export type TSFetchSavingsCustomerAggregationAction = {
  customerId: string;
  fromDate?: string;
  toDate?: string;
};

export type TSFetchSavingsSiteAggregationAction = {
  siteId: string;
  fromDate?: string;
  toDate?: string;
};

interface TSSavingsMetaState extends TSMetaState {
  noDataAvailable: boolean;
}

export interface TSSavingsAggregationsEntityState {
  data: TSSavingsAggregationsEntity;
  meta: TSSavingsMeta;
}

const types = {
  FETCH_SAVINGS_CUSTOMER_AGGREGATIONS: 'FETCH_SAVINGS_CUSTOMER_AGGREGATIONS',
  FETCH_SAVINGS_CUSTOMER_AGGREGATIONS_SUCCESS:
    'FETCH_SAVINGS_CUSTOMER_AGGREGATIONS_SUCCESS',
  FETCH_SAVINGS_CUSTOMER_AGGREGATIONS_ERROR:
    'FETCH_SAVINGS_CUSTOMER_AGGREGATIONS_ERROR',

  FETCH_SAVINGS_SITE_AGGREGATIONS: 'FETCH_SAVINGS_SITE_AGGREGATIONS',
  FETCH_SAVINGS_SITE_AGGREGATIONS_SUCCESS:
    'FETCH_SAVINGS_SITE_AGGREGATIONS_SUCCESS',
  FETCH_SAVINGS_SITE_AGGREGATIONS_ERROR:
    'FETCH_SAVINGS_SITE_AGGREGATIONS_ERROR',
};

export const actions = {
  fetchSavingsCustomerAggregations: (
    request: TSFetchSavingsCustomerAggregationAction
  ) => ({
    type: types.FETCH_SAVINGS_CUSTOMER_AGGREGATIONS,
    request,
  }),

  fetchSavingsSiteAggregations: (
    request: TSFetchSavingsSiteAggregationAction
  ) => ({
    type: types.FETCH_SAVINGS_SITE_AGGREGATIONS,
    request,
  }),
};

export const initialStateMeta: TSSavingsMeta = {
  error: '',
  loading: false,
  noDataAvailable: false,
};

export const initialStateAggregationsEntity: TSSavingsAggregationsEntity = {
  carbonAvoided: 0,
  energySavings: 0,
  netCostSavings: 0,
  energySavingPerformance: 0,
};

export const initialState: TSSavingsAggregationsEntityState = {
  data: initialStateAggregationsEntity,
  meta: initialStateMeta,
};

function data(state = initialState.data, action): TSSavingsAggregationsEntity {
  switch (action.type) {
    case types.FETCH_SAVINGS_CUSTOMER_AGGREGATIONS:
    case types.FETCH_SAVINGS_SITE_AGGREGATIONS:
      return initialState.data;
    case types.FETCH_SAVINGS_CUSTOMER_AGGREGATIONS_SUCCESS:
    case types.FETCH_SAVINGS_SITE_AGGREGATIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

function meta(state = initialState.meta, action): TSSavingsMetaState {
  switch (action.type) {
    case types.FETCH_SAVINGS_CUSTOMER_AGGREGATIONS:
    case types.FETCH_SAVINGS_SITE_AGGREGATIONS:
      return {
        ...state,
        error: '',
        loading: true,
        noDataAvailable: false,
      };
    case types.FETCH_SAVINGS_CUSTOMER_AGGREGATIONS_ERROR:
    case types.FETCH_SAVINGS_SITE_AGGREGATIONS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        noDataAvailable: false,
      };
    case types.FETCH_SAVINGS_CUSTOMER_AGGREGATIONS_SUCCESS:
    case types.FETCH_SAVINGS_SITE_AGGREGATIONS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noDataAvailable: !action.payload[0],
      };
    default:
      return state;
  }
}

export default combineReducers({
  data,
  meta,
});

export const selectSavingsAggregations = (
  state: TSState
): TSSavingsAggregationsEntityState => state.entities.savingsAggregations;

const API = {
  fetchSavingsCustomerAggregations: ({
    customerId,
  }: TSFetchSavingsCustomerAggregationAction) => {
    const url = `${apiBaseUrl()}/savings/customer/${customerId}/aggregations`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSavingsAggregationsEntity }) => data)
      .catch(handleAxiosError);
  },

  fetchSavingsSiteAggregations: ({
    siteId,
  }: TSFetchSavingsSiteAggregationAction) => {
    const url = `${apiBaseUrl()}/savings/site/${siteId}/aggregations`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSavingsAggregationsEntity }) => data)
      .catch(handleAxiosError);
  },
};

// sagas
function* fetchSavingsCustomerAggregationsSaga({
  request,
}: {
  request: TSFetchSavingsCustomerAggregationAction;
  type: string;
}): Generator<any, void, any> {
  try {
    const payload: TSSavingsEntity = yield call(
      API.fetchSavingsCustomerAggregations,
      request
    );
    yield put({
      type: types.FETCH_SAVINGS_CUSTOMER_AGGREGATIONS_SUCCESS,
      payload: payload,
    });
  } catch (e) {
    yield handleSagaError(
      types.FETCH_SAVINGS_CUSTOMER_AGGREGATIONS_ERROR,
      e as Error
    );
  }
}

function* fetchSavingsSiteAggregationsSaga({
  request,
}: {
  request: TSFetchSavingsSiteAggregationAction;
  type: string;
}): Generator<any, void, any> {
  try {
    const payload: TSSavingsEntity = yield call(
      API.fetchSavingsSiteAggregations,
      request
    );
    yield put({
      type: types.FETCH_SAVINGS_SITE_AGGREGATIONS_SUCCESS,
      payload: payload,
    });
  } catch (e) {
    yield handleSagaError(
      types.FETCH_SAVINGS_SITE_AGGREGATIONS_ERROR,
      e as Error
    );
  }
}

export const sagas = [
  takeLatest(
    types.FETCH_SAVINGS_CUSTOMER_AGGREGATIONS,
    fetchSavingsCustomerAggregationsSaga
  ),
  takeLatest(
    types.FETCH_SAVINGS_SITE_AGGREGATIONS,
    fetchSavingsSiteAggregationsSaga
  ),
];
