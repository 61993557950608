import { Modal, ModalProps } from '@mantine/core';

const ModalCustom = ({ size = 'md', children, ...rest }: ModalProps) => {
  return (
    <Modal zIndex={1002} size={size} centered {...rest}>
      {children}
    </Modal>
  );
};

export default ModalCustom;
