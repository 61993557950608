import moment from 'moment';
import 'moment-timezone';
import { API_DATE_FORMAT, DATE_FORMAT_TIMESTAMP } from '../constants';
import { TSGroupUsageEnhanced } from '../ducks/circuit/circuitData';
import { ResourceType } from '../ducks/types';

export interface TSPeakAndDaysPeakPower {
  daysPeak: Map<string, { ts: string; value: number }>;
  peak: { ts: string; value: number; average: number };
}

export interface TSGroupAvg {
  groupName: string;
  groupId: string;
  average: number;
  max: number;
  maxTs: string;
  min: number;
  minTs: string;
  total: number;
}

export enum TSAverageUnit {
  KW = 'kW',
  kWh = 'kWh',
}

class PeakPowerChartHelper {
  /**
   * returns a dayPeaks map and peak: { value, date, avg } object
   * @param ts
   * @param data
   * @param siteTimezone
   */
  static getPeakAndDaysPeak(
    ts: string[],
    data: Array<TSGroupUsageEnhanced>,
    siteTimezone: string,
    resourceType: ResourceType
  ): TSPeakAndDaysPeakPower {
    const groupDataToUse =
      resourceType === ResourceType.ELECTRICITY ? 'kWUsage' : 'usage';
    const daysPeak: Map<string, { ts: string; value: number }> = new Map();
    const peak = ts.reduce(
      (acc, val, idx) => {
        const localValue = data.reduce((accu, group) => {
          return accu + (group[groupDataToUse][idx] || 0);
        }, 0);

        const dt = moment(ts[idx]).format(API_DATE_FORMAT);

        if (daysPeak.has(dt)) {
          const { value } = daysPeak.get(dt) || { ts: '', value: 0 };
          if (localValue > value) {
            daysPeak.set(dt, {
              ts: moment
                .tz(ts[idx], siteTimezone)
                .format(DATE_FORMAT_TIMESTAMP),
              value: localValue,
            });
          }
        } else {
          daysPeak.set(dt, {
            ts: moment.tz(ts[idx], siteTimezone).format(DATE_FORMAT_TIMESTAMP),
            value: localValue,
          });
        }

        if (localValue > acc.value) {
          acc.value = localValue;
          acc.ts = moment
            .tz(ts[idx], siteTimezone)
            .format(DATE_FORMAT_TIMESTAMP);
        }
        acc.average += localValue / ts.length;
        return acc;
      },
      { ts: '', value: -1, average: 0 }
    );
    return { daysPeak, peak };
  }

  /**
   * returns each group enhanced with avg and max consumption
   * @param ts
   * @param data
   */
  static groupsAverage(
    ts: string[],
    data: Array<TSGroupUsageEnhanced>,
    unit: TSAverageUnit
  ): TSGroupAvg[] {
    return data.map(({ groupName, groupId, kWUsage, usage }) => {
      const usageData = unit === TSAverageUnit.KW ? kWUsage : usage;
      const watermarkMax = { idx: -1, value: 0 };
      const watermarkMin = { idx: -1, value: 1000000 };

      let totalUsage = 0;
      for (let i = 0; i < ts.length; i++) {
        const v = usageData[i];
        if (v > watermarkMax.value) {
          watermarkMax.value = v;
          watermarkMax.idx = i;
        }
        if (v < watermarkMin.value) {
          watermarkMin.value = v;
          watermarkMin.idx = i;
        }
        totalUsage += v;
      }

      return {
        groupName,
        groupId,
        average: totalUsage / ts.length,
        max: watermarkMax.value,
        maxTs: ts[watermarkMax.idx],
        min: watermarkMin.value,
        minTs: ts[watermarkMin.idx],
        total: totalUsage,
      };
    });
  }
}

export default PeakPowerChartHelper;
