import {
  ESOnboardingContext,
  SubModalHeading,
  SubModalSubHeading,
} from '../OnboardingModal';
import { useOnboardingAccountDataQuery } from '../../../queries/energyStar';
import { useContext } from 'react';
import Spinner from '../../Spinner';
import { Flex } from '@mantine/core';

const Confirmation = () => {
  const { formik } = useContext(ESOnboardingContext);
  const onboardingDataQuery = useOnboardingAccountDataQuery({
    currentAccountId: formik?.values?.accountId,
  });

  if (onboardingDataQuery?.isPending) {
    return (
      <Flex justify='center' align='center'>
        <Spinner size='sm' />
      </Flex>
    );
  }

  return (
    <>
      <SubModalHeading>Success!</SubModalHeading>
      <SubModalSubHeading>
        Our system detected{' '}
        <b>{onboardingDataQuery.data?.numberOfInactiveProperties} properties</b>{' '}
        that have been shared with Redaptive from your Energy Star Portfolio
        Manager Account.
      </SubModalSubHeading>
    </>
  );
};

export default Confirmation;
