import { SVGProps } from 'react';

const SignOutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <path
      d='M17.9421 9.7C17.865 9.625 17.865 9.55 17.7879 9.475L15.4738 7.225C15.1653 6.925 14.7025 6.925 14.3939 7.225C14.0854 7.525 14.0854 7.975 14.3939 8.275L15.3967 9.25H9.77135C9.30854 9.25 9 9.55 9 10C9 10.45 9.30854 10.75 9.77135 10.75H15.3967L14.3939 11.725C14.0854 12.025 14.0854 12.475 14.3939 12.775C14.5482 12.925 14.7796 13 14.9339 13C15.0882 13 15.3196 12.925 15.4738 12.775L17.7879 10.525C17.865 10.45 17.9421 10.375 17.9421 10.3C18.0193 10.075 18.0193 9.925 17.9421 9.7Z'
      fill='#626365'
    />
    <path
      d='M12.7928 14.7444C11.931 15.2111 10.9909 15.4444 10.0508 15.4444C6.99546 15.4444 4.56685 13.0333 4.56685 10C4.56685 6.96667 6.99546 4.55556 10.0508 4.55556C10.9909 4.55556 11.931 4.78889 12.7928 5.25556C13.1845 5.48889 13.6545 5.33333 13.8896 4.94444C14.1246 4.55556 13.9679 4.08889 13.5762 3.85556C12.4794 3.31111 11.3043 3 10.0508 3C6.13369 3 3 6.11111 3 10C3 13.8889 6.13369 17 10.0508 17C11.3043 17 12.4794 16.6889 13.5762 16.0667C13.9679 15.8333 14.0463 15.3667 13.8896 14.9778C13.6545 14.6667 13.1845 14.5111 12.7928 14.7444Z'
      fill='#626365'
      stroke='#626365'
      strokeWidth='0.1'
    />
  </svg>
);

export default SignOutIcon;
