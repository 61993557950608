import { useTheme } from 'styled-components';
import BatchIcon from './Icons/BatchIcon';
import ControlsIcon from './Icons/ControlsIcon';
import HvacIcon from './Icons/HvacIcon';
import LightingIcon from './Icons/LightingIcon';
import SolarIcon from './Icons/SolarIcon';

type TSProps = {
  ecmType: string;
  iconSize?: string;
};

const OpportunityIcon = ({ ecmType, iconSize = '36' }: TSProps) => {
  const theme = useTheme();
  const iconColor = theme.colors.primary;

  let Icon = SolarIcon;

  switch (ecmType) {
    case 'Solar Install':
      Icon = SolarIcon;
      break;
    case 'batch':
      Icon = BatchIcon;
      break;
    case 'VFD Install':
    case 'HVAC':
    case 'EC Motor':
      Icon = HvacIcon;
      break;
    case 'Lighting Scheduling':
    case 'HVAC Scheduling':
    case 'HVAC Controls':
    case 'Lighting Controls':
      Icon = ControlsIcon;
      break;
    case 'Lighting':
      Icon = LightingIcon;
      break;
    default:
      Icon = SolarIcon;
      break;
  }

  return <Icon size={iconSize} color={iconColor} />;
};

export default OpportunityIcon;
