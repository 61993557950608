import { VisuallyHidden } from '@mantine/core';
import styled, { css } from 'styled-components';

type TSSize = 'sm' | 'md' | 'lg';

interface TSProps {
  centered?: boolean;
  size?: TSSize;
}

const largeStyles = css`
  &,
  &:after {
    height: 10em;
    width: 10em;
  }
`;

const mediumStyles = css`
  border-width: 0.6em;

  &,
  &:after {
    height: 5em;
    width: 5em;
  }
`;

const smallStyles = css`
  border-width: 0.4em;

  &,
  &:after {
    height: 20px;
    width: 20px;
  }
`;

const sizeStyles = ({ $size }: { $size: TSSize }) => {
  switch ($size) {
    case 'sm':
      return smallStyles;
    case 'md':
      return mediumStyles;
    default:
      return largeStyles;
  }
};

export const SpinnerStyled = styled.div<{ $size: TSSize }>`
  animation: spinner 1.1s infinite linear;
  border-bottom-color: ${({ theme }) => theme.colors.spinnerBackgroundColor};
  border-left-color: ${({ theme }) => theme.colors.spinnerColor};
  border-radius: 50%;
  border-right-color: ${({ theme }) => theme.colors.spinnerBackgroundColor};
  border-style: solid;
  border-top-color: ${({ theme }) => theme.colors.spinnerBackgroundColor};
  border-width: 1.1em;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);

  ${sizeStyles};

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SpinnerWrapperCenteredStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Spinner = ({ centered = false, size = 'lg' }: TSProps) => {
  if (centered) {
    return (
      <SpinnerWrapperCenteredStyled>
        <SpinnerStyled $size={size} />
        <VisuallyHidden>Loading</VisuallyHidden>
      </SpinnerWrapperCenteredStyled>
    );
  }

  return <SpinnerStyled $size={size} />;
};

export default Spinner;
