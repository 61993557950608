/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { defaultHeaders, denaliApiBaseUrl } from '../../api/index';
import {
  handleAxiosError,
  handleSagaError,
  queryStringify,
} from '../../api/utils';
import circuitsMockMetaData from '../../mockData/circuits/circuitsMeta';
import { filterDemoUsageMetaData } from '../../utils/demo';
import { isVariantActive } from '../../utils/variants';
import { ResourceType, TSMetaState } from '../types';

export interface TSGetResourceMetaData {
  customerId: string;
  site?: string;
  buildingSystem?: string;
  equipment?: string;
  categoryValue?: string;
  resourceType: string;
}

export interface TSResourceMetaValue {
  name: string;
  id: string;
}

export interface TSResourceMetaItemResponse {
  fieldType: string;
  name: string;
  id: string | null;
  values: Array<TSResourceMetaValue>;
}

export type TSResourceMetaResponse = {
  results: Array<TSResourceMetaItemResponse>;
};

export interface TSElectricMetaData extends TSResourceMetaItemResponse {
  id: string;
}

interface TSElectricMetaDataState extends TSMetaState {
  noElectricMetaData: boolean;
}

export interface TSElectricMetaEntityState {
  items: Array<TSElectricMetaData>;
  meta: TSElectricMetaDataState;
}

interface TSState {
  entities: {
    electricityMetaData: TSElectricMetaEntityState;
  };
}

export const types = {
  RESET_ELECTRIC_META_DATA: 'RESET_ELECTRIC_META_DATA',
  FETCH_ELECTRIC_META_DATA: 'FETCH_ELECTRIC_META_DATA',
  FETCH_ELECTRIC_META_DATA_SUCCESS: 'FETCH_ELECTRIC_META_DATA_SUCCESS',
  FETCH_ELECTRIC_META_DATA_ERROR: 'FETCH_ELECTRIC_META_DATA_ERROR',
};

export const actions = {
  fetchElectricMetaData: (payload: TSGetResourceMetaData): any => ({
    type: types.FETCH_ELECTRIC_META_DATA,
    ...payload,
  }),
  resetElectricMetaData: () => ({
    type: types.RESET_ELECTRIC_META_DATA,
  }),
};

export const initialState: TSElectricMetaEntityState = {
  items: [],
  meta: {
    loading: true,
    error: '',
    noElectricMetaData: true,
  },
};

function entityItems(action, state) {
  const newItems: Array<TSElectricMetaData> = Object.values(action.payload);
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems);
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.RESET_ELECTRIC_META_DATA:
    case types.FETCH_ELECTRIC_META_DATA:
      return initialState.items;
    case types.FETCH_ELECTRIC_META_DATA_SUCCESS:
      return entityItems(action, state);
    default:
      return state;
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_ELECTRIC_META_DATA:
      return {
        ...state,
        error: '',
        loading: true,
        noElectricMetaData: false,
      };
    case types.FETCH_ELECTRIC_META_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_ELECTRIC_META_DATA_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noElectricMetaData: !action.payload[0],
      };
    default:
      return state;
  }
}

export default combineReducers({
  items,
  meta,
});

export const selectElectricMetaDataEntity = (
  state: TSState
): TSElectricMetaEntityState => state.entities.electricityMetaData;

export const enhanceGroupItem = (
  groupItem: TSResourceMetaItemResponse
): TSElectricMetaData => ({
  ...groupItem,
  id: groupItem.id ? groupItem.id : groupItem.name,
});

export const API = {
  fetchResourceMetaData: ({
    customerId = '',
    site = '',
    buildingSystem = '',
    equipment = '',
    categoryValue = '',
    resourceType,
  }: TSGetResourceMetaData) => {
    if (isVariantActive('mock')) {
      const mockData = circuitsMockMetaData();
      return Promise.resolve(mockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const query = queryStringify({
      site,
      buildingSystem,
      equipment,
      categoryValue,
    });

    const url = `${denaliApiBaseUrl()}/customers/${customerId}/metadata/${resourceType}?${decodeURIComponent(
      query
    )}`;

    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSResourceMetaResponse }) => data)
      .catch(handleAxiosError);
  },
};

function* fetchElectricMetaDataSaga({
  type,
  ...payload
}: TSGetResourceMetaData & { type: string }): Generator<any, void, any> {
  try {
    const electricMetaData: TSResourceMetaResponse = yield call(
      API.fetchResourceMetaData,
      { ...payload, resourceType: ResourceType.ELECTRICITY }
    );
    filterDemoUsageMetaData(electricMetaData);

    yield put({
      type: types.FETCH_ELECTRIC_META_DATA_SUCCESS,
      payload: electricMetaData?.results.map((groupItem) =>
        enhanceGroupItem(groupItem)
      ),
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_ELECTRIC_META_DATA_ERROR, e as Error);
  }
}

export const sagas = [
  takeLatest(types.FETCH_ELECTRIC_META_DATA, fetchElectricMetaDataSaga),
];
