import moment from 'moment';
import {
  DAILY_DATE_FORMAT,
  DATE_FORMAT_DATA_API_RESPONSE_NO_TZ,
  DATE_FORMAT_TOOLTIP,
  DATE_FORMAT_TOOLTIP_NO_TZ,
} from '../constants';
import { TSRange } from '../ducks/circuit/circuitData';
import { Resolution } from '../ducks/types';

export interface TSBaseChartData {
  ts: Array<string>;
  startDateTime: number;
  xAxisMajorTickInterval: number;
  xAxisMinorTickInterval: number;
}

export const getTooltipDateFormat = (
  resolution: Resolution,
  showTZ: boolean
): string => {
  let strFormat;
  if (resolution == Resolution.DAILY) {
    strFormat = DAILY_DATE_FORMAT;
  } else if (showTZ) {
    strFormat = DATE_FORMAT_TOOLTIP;
  } else {
    strFormat = DATE_FORMAT_TOOLTIP_NO_TZ;
  }
  return strFormat;
};

export const getXAxisDateFormat = (): string => {
  return DAILY_DATE_FORMAT;
};

export const formatTimestamp = (
  strDateTime: string,
  dateFormat: string
): string => {
  return moment(strDateTime).format(dateFormat);
};

export const formatTimestampForTooltip = (
  strDateTime: string,
  dateFormat: string,
  siteTimezone?: string
): string => {
  let momentDate;
  if (siteTimezone) {
    momentDate = moment.tz(strDateTime, siteTimezone);
  } else {
    momentDate = moment(strDateTime, DATE_FORMAT_DATA_API_RESPONSE_NO_TZ);
  }
  return momentDate.format(dateFormat);
};

export const getXAxisTickIntervals = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  resolution: Resolution
): {
  xAxisMajorTickInterval: number;
  xAxisMinorTickInterval: number;
} => {
  // we want up to 10 total visible major ticks, and 15 of the minor ticks;
  // but, we want them to coincide w/ the day start.  so, we'll round up on the ticks to the nearest whole x:
  let totalTicks;
  if (resolution == Resolution.DAILY) {
    totalTicks = endDate.diff(startDate, 'days');
  } else if (resolution == Resolution.HOURLY) {
    totalTicks = endDate.diff(startDate, 'hours');
  } else if (resolution == Resolution.FIFTEEN_MINUTES) {
    totalTicks = endDate.diff(startDate, 'minutes') / 15;
  } else if (resolution == Resolution.MONTHLY) {
    totalTicks = endDate.diff(startDate, 'months');
  }
  let majorTicks = Math.ceil(totalTicks / 10);
  // adjust for whole days:
  if (
    resolution == Resolution.HOURLY ||
    resolution == Resolution.FIFTEEN_MINUTES
  ) {
    const divisor = resolution == Resolution.HOURLY ? 24 : 96;
    if (majorTicks > divisor) {
      majorTicks -= majorTicks % divisor; // down to the closest full day
    } else {
      majorTicks = divisor; // 1day
    }
  }
  return {
    xAxisMajorTickInterval: majorTicks,
    xAxisMinorTickInterval: majorTicks / 2,
  };
};

export const getDatePlusIntervals = (
  theDate: moment.Moment,
  resolution: Resolution,
  numIntervals: number
): moment.Moment => {
  switch (resolution) {
    case Resolution.MONTHLY:
      return theDate.clone().add(numIntervals, 'months');
    case Resolution.DAILY:
      return theDate.clone().add(numIntervals, 'days');
    case Resolution.HOURLY:
      return theDate.clone().add(numIntervals, 'hours');
    case Resolution.FIFTEEN_MINUTES:
    default:
      return theDate.clone().add(15 * numIntervals, 'minutes');
  }
};

/**
 * identifies any weekends in the given range, so they can be mapped on the xAxis
 * returns an array of ts ranges (from, to) that cover the weekends inside the range
 */
export const getWeekendRanges = (ts: Array<string>): Array<TSRange> => {
  const weekends: TSRange[] = [];
  let startIx = -1;

  ts.forEach((timestamp, index) => {
    const day = moment(timestamp).day();

    if (day === 0 || day === 6) {
      // It's a weekend
      if (startIx < 0) {
        startIx = index;
      }
      // If it's the last timestamp and we're in a weekend, push the range
      if (index === ts.length - 1) {
        weekends.push({ from: startIx, to: index });
      }
    } else if (startIx >= 0) {
      // It's not a weekend, wrap up the previously discovered weekend if it exists
      weekends.push({ from: startIx, to: index - 1 });
      startIx = -1;
    }
  });

  return weekends;
};

export const calculateAvg = (numbers: Array<number>): number => {
  if (!numbers || !numbers.length) {
    return 0;
  }
  const sum = numbers.reduce((partialSum, a) => partialSum + a, 0);
  return Math.round((100 * sum) / numbers.length) / 100.0;
};
