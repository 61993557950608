import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Select from '../../../components/mantine/Select';
import { Grouping } from '../../../ducks/types';
import { useResourceMetadata } from '../../../queries/resourceMetadata';
import { DataExplorerFilterParams } from '../types';

type Props = {
  pendingFilters: DataExplorerFilterParams;
  setPendingFilters: Dispatch<SetStateAction<DataExplorerFilterParams>>;
};

const GroupSelect = ({ pendingFilters, setPendingFilters }: Props) => {
  const { pageId = 'consumption-analysis' } = useParams();
  const { data: resourceMetadata } = useResourceMetadata(pendingFilters);

  const isGroupingLockedToSite =
    pageId === 'operating-hours' && pendingFilters.siteIds.length !== 1;

  const groupingOptions = useMemo(() => {
    const defaultGroupingOptions = [
      { label: 'Site', value: 'site' },
      { label: 'Panel', value: 'panel' },
      { label: 'Building System', value: 'buildingSystem' },
      { label: 'Equipment', value: 'equipment' },
      { label: 'Circuit', value: 'circuit' },
      { label: 'Meter', value: 'meter' },
    ];

    return isGroupingLockedToSite
      ? [{ label: 'Site', value: 'site' }]
      : resourceMetadata?.map((group) => ({
          label: group.name,
          value: group.id,
        })) ?? defaultGroupingOptions;
  }, [isGroupingLockedToSite, resourceMetadata]);

  const handleGroupingUpdate = useCallback(
    (value: string | null) => {
      setPendingFilters((prev) => ({
        ...prev,
        grouping: (value as Grouping) ?? '',
      }));
    },
    [setPendingFilters]
  );

  return (
    <Select
      data-gainsight-id='sites-query-filter-selector-group-selector'
      key='grouping-selection'
      label='Group By'
      data={groupingOptions}
      onChange={handleGroupingUpdate}
      value={pendingFilters.grouping}
      disabled={isGroupingLockedToSite}
      searchable={false}
      size='md'
      styles={{ input: { paddingLeft: 8 } }}
      rightSectionWidth={30}
      w={145}
    />
  );
};

export default GroupSelect;
