import { Anchor, Center, Group, Image, Stack, Text } from '@mantine/core';
import { useSelector } from 'react-redux';
import {
  COMPANY_URL,
  FOOTER_HOME,
  FOOTER_PRIVACY,
  FOOTER_TERMS,
  PRIVACY_URL,
  TERMS_URL,
} from '../../../constants/strings';
import { selectCustomersEntity } from '../../../ducks/customers';
import redaptiveLogoWhite from '../../../logos/redaptive-logo-white.png';
import classes from './SimpleFooter.module.css';

const links = [
  { link: COMPANY_URL, label: FOOTER_HOME },
  { link: TERMS_URL, label: FOOTER_TERMS },
  { link: PRIVACY_URL, label: FOOTER_PRIVACY },
];

const SimpleFooter = () => {
  const { items: customers, currentCustomerId } = useSelector(
    selectCustomersEntity
  );
  const currentCustomer = customers.find((c) => c.id === currentCustomerId);

  const items = links.map((link) => (
    <Anchor
      c='#fff'
      key={link.label}
      href={link.link}
      lh={1}
      size='lg'
      target='_blank'
      rel='noreferrer noopener'
    >
      {link.label}
    </Anchor>
  ));

  return (
    <Center className={classes.footer} p={24}>
      <Stack justify='center' align='center' gap={32}>
        {currentCustomer?.customerLogoUrl && (
          <Stack>
            <Text c='#fff' lh={1} ta='center'>
              Powered By
            </Text>
            <Image src={redaptiveLogoWhite} w={167} />
          </Stack>
        )}
        <Group gap='2rem' className={classes.links}>
          {items}
        </Group>
      </Stack>
    </Center>
  );
};

export default SimpleFooter;
