import { TSCustomerCircuitCategoriesResponse } from '../../ducks/customerCircuitCategories/customerCategories';

const customerCircuitCategories: TSCustomerCircuitCategoriesResponse = [
  {
    id: '178a708c-f5fd-468e-af22-fb0c49783396',
    name: 'Better Height',
    description: null,
    values: [
      {
        id: 'b05422ee-2db4-4877-a57a-f64c919adb51',
        name: 'Tall',
        categoryId: '178a708c-f5fd-468e-af22-fb0c49783396',
      },
      {
        id: '7d460490-cafb-48ec-b648-6e7cd28de6a5',
        name: 'Short',
        categoryId: '178a708c-f5fd-468e-af22-fb0c49783396',
      },
      {
        id: '59569621-02ff-47c5-8e5d-df1a3ef38234',
        name: 'Medium',
        categoryId: '178a708c-f5fd-468e-af22-fb0c49783396',
      },
    ],
  },
  {
    id: '22867ad1-05b0-4a54-b3cf-a8ca36c255d5',
    name: 'Building',
    description: null,
    values: [
      {
        id: '27699f76-5d23-4013-a30f-8ef812d3e93e',
        name: 'Small',
        categoryId: '22867ad1-05b0-4a54-b3cf-a8ca36c255d5',
      },
      {
        id: '2b778fb9-b756-4a77-8a60-ceb8c02abc8d',
        name: 'Large',
        categoryId: '22867ad1-05b0-4a54-b3cf-a8ca36c255d5',
      },
      {
        id: '8bb4381b-8d11-4717-b1f8-d88db489e2f8',
        name: 'Medium',
        categoryId: '22867ad1-05b0-4a54-b3cf-a8ca36c255d5',
      },
    ],
  },
  {
    id: '9474c20d-e9c9-4480-a7b8-4f038b6b0ca9',
    name: 'Machine type',
    description: 'The type of machine',
    values: [
      {
        id: 'df3028b7-b499-4bbd-8e01-a1cf2b5e4148',
        name: 'RTU',
        categoryId: '9474c20d-e9c9-4480-a7b8-4f038b6b0ca9',
      },
    ],
  },
  {
    id: 'c5d7f624-c177-4bad-8ff8-8ef6da6cf154',
    name: 'Color',
    description: null,
    values: [
      {
        id: '2b1c8650-93f2-4f43-8a42-ed08a77e6bc7',
        name: 'blue',
        categoryId: 'c5d7f624-c177-4bad-8ff8-8ef6da6cf154',
      },
      {
        id: 'e9281edd-d6f6-4d06-9d23-9931b58c7256',
        name: 'orange',
        categoryId: 'c5d7f624-c177-4bad-8ff8-8ef6da6cf154',
      },
      {
        id: '67b7d1fb-0aba-4847-a831-5b7e1c5a38e0',
        name: 'red',
        categoryId: 'c5d7f624-c177-4bad-8ff8-8ef6da6cf154',
      },
      {
        id: '3a56fe13-59f6-4f40-aa7b-e526a0a5f327',
        name: 'yellow',
        categoryId: 'c5d7f624-c177-4bad-8ff8-8ef6da6cf154',
      },
    ],
  },
];

export default customerCircuitCategories;
