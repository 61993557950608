type BatchHandler<T> = (messages: T[]) => void;

class MessageBatcher<T> {
  private batchHandler: BatchHandler<T>;
  private delay: number;
  private messages: T[];
  private timeout: NodeJS.Timeout | null;

  constructor(batchHandler: BatchHandler<T>, delay: number = 500) {
    this.batchHandler = batchHandler;
    this.delay = delay;
    this.messages = [];
    this.timeout = null;
  }

  addMessage(message: T[]): void {
    this.messages.push(...message);

    if (!this.timeout) {
      this.timeout = setTimeout(() => {
        this.processBatch();
      }, this.delay);
    }
  }

  private processBatch(): void {
    if (this.messages.length > 0) {
      this.batchHandler(this.messages);
      this.messages = [];
    }
    this.timeout = null;
  }
}

export default MessageBatcher;
