import { TSSitesResponse } from '../../ducks/sites';

const sites: TSSitesResponse = [
  {
    id: 'aedfbb18-7125-45c6-9bc4-b9645e16c91f',
    display: 'MA6-127: 271 Great Road - Acton - MA',
    address: {
      address1: '271 Great Road',
      city: 'Concord',
      state: 'MA',
      postalCode: '01742',
      country: 'US',
      timezone: 'America/Chicago',
    },
    electricUtilityRate: 0.2023,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 42.4956878,
    lng: -71.4160677,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 1,
    theme: 'REDAPTIVE',
    timezone: 'America/Chicago',
    active: true,
    ingestionDataStart: '2021-04-05T00:00:00',
    validName: 'MA6-127: 271 Great Road - Acton - MA',
    siteShifts: [],
    meterCounts: {
      electricity: 6,
      naturalGas: 2,
      water: 0,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '14953a76-df93-486f-a968-d76c2107ddd8',
    display: 'DC1-826: 1835 Columbia Road, N.W. - Washington - DC',
    address: {
      address1: '1835 Columbia Road Northwest',
      city: 'Washington',
      state: 'DC',
      postalCode: '20009',
      country: 'US',
      timezone: 'America/Chicago',
    },
    electricUtilityRate: 0.1477,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 38.9216043,
    lng: -77.0438329,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 3535,
    theme: 'REDAPTIVE',
    timezone: 'America/Chicago',
    active: true,
    ingestionDataStart: '2020-03-16T00:00:00',
    validName: 'DC1-826: 1835 Columbia Road, N.W. - Washington - DC',
    siteShifts: [],
    meterCounts: {
      electricity: 5,
      naturalGas: 0,
      water: 3,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'eaaaea2d-fe00-4d50-ac5b-92efdd56c8ca',
    display: 'NY5-213: 1150 Grand Avenue - South Hempstead - NY',
    address: {
      address1: '1150 Grand Avenue',
      city: 'South Hempstead',
      state: 'NY',
      postalCode: '11550',
      country: 'US',
      timezone: 'America/Los_Angeles',
    },
    electricUtilityRate: 0.2194,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 40.6823983,
    lng: -73.6154231,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 1,
    theme: 'REDAPTIVE',
    timezone: 'America/Los_Angeles',
    active: true,
    ingestionDataStart: '2021-03-31T00:00:00',
    validName: 'NY5-213: 1150 Grand Avenue - South Hempstead - NY',
    siteShifts: [],
    meterCounts: {
      electricity: 8,
      naturalGas: 0,
      water: 0,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '8160bb59-1d4e-45a3-856e-61fd578a455a',
    display: 'MA6-259: 306 Turnpike Road - Westborough - MA',
    address: {
      address1: '306 Turnpike Road',
      city: 'Westborough',
      state: 'MA',
      postalCode: '01581',
      country: 'US',
      timezone: 'America/Los_Angeles',
    },
    electricUtilityRate: 0.1884,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 42.2824386,
    lng: -71.6482922,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 3574,
    theme: 'REDAPTIVE',
    timezone: 'America/Los_Angeles',
    active: true,
    ingestionDataStart: '2021-04-05T00:00:00',
    validName: 'MA6-259: 306 Turnpike Road - Westborough - MA',
    siteShifts: [],
    meterCounts: {
      electricity: 0,
      naturalGas: 2,
      water: 4,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '9cc371a1-9270-48a3-892c-5d3ba99f70e5',
    display: 'NY5-176: 534 5th Avenue - Brooklyn - NY',
    address: {
      address1: '534 5th Avenue',
      city: 'New York',
      state: 'NY',
      postalCode: '10036',
      country: 'US',
      timezone: 'America/Los_Angeles',
    },
    electricUtilityRate: 0.1831,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 40.7552751,
    lng: -73.97999,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 4500,
    theme: 'REDAPTIVE',
    timezone: 'America/Los_Angeles',
    active: true,
    ingestionDataStart: '2021-06-17T00:00:00',
    validName: 'NY5-176: 534 5th Avenue - Brooklyn - NY',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'ba4df855-7308-43bd-b99d-f099d031a11b',
    display: 'NY5-169: 266 Broadway - Brooklyn - NY',
    address: {
      address1: '266 Broadway',
      city: '',
      state: 'NY',
      postalCode: '11211',
      country: 'US',
      timezone: 'America/Los_Angeles',
    },
    electricUtilityRate: 0.1948,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 40.7085176,
    lng: -73.9592722,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 1,
    theme: 'REDAPTIVE',
    timezone: 'America/Los_Angeles',
    active: true,
    ingestionDataStart: '2021-04-01T00:00:00',
    validName: 'NY5-169: 266 Broadway - Brooklyn - NY',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'e77c9b18-1825-4d6b-995f-d24a48a8ae9c',
    display: 'NY7-167: 2334 James Street - Syracuse - NY',
    address: {
      address1: '2334 James Street',
      city: 'Syracuse',
      state: 'NY',
      postalCode: '13206',
      country: 'US',
      timezone: 'America/Los_Angeles',
    },
    electricUtilityRate: 0.0974,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 43.06827819999999,
    lng: -76.1137385,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 7570,
    theme: 'REDAPTIVE',
    timezone: 'America/Los_Angeles',
    active: true,
    ingestionDataStart: '2021-08-03T00:00:00',
    validName: 'NY7-167: 2334 James Street - Syracuse - NY',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '0cadcdfe-6ccd-4e9d-aa01-3ada0a761293',
    display: 'DC1-710: 915 Rhode Island Avenue NE - Washington - DC',
    address: {
      address1: '915 Rhode Island Avenue Northeast',
      city: 'Washington',
      state: 'DC',
      postalCode: '20018',
      country: 'US',
      timezone: 'America/Los_Angeles',
    },
    electricUtilityRate: 0.1401,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 38.9219479,
    lng: -76.99315240000001,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 1,
    theme: 'REDAPTIVE',
    timezone: 'America/Los_Angeles',
    active: true,
    ingestionDataStart: '2021-02-22T00:00:00',
    validName: 'DC1-710: 915 Rhode Island Avenue NE - Washington - DC',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'c14bb9fb-4c5e-4400-82f6-e134cc55b5af',
    display: 'NJ7-297: 46 Parsonage Road - Edison - NJ',
    address: {
      address1: '46 Parsonage Road',
      city: 'Edison',
      state: 'NJ',
      postalCode: '08837',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1279,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 40.5480005,
    lng: -74.3329716,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 8669,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-07-21T00:00:00',
    validName: 'NJ7-297: 46 Parsonage Road - Edison - NJ',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'ed1e9b02-8f09-4e8d-ad05-c7ad4a577766',
    display: 'NJ7-238: 656 Plainsboro Road - Plainsboro - NJ',
    address: {
      address1: '656 Plainsboro Road',
      city: 'Plainsboro Center',
      state: 'NJ',
      postalCode: '08536',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1238,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 40.3287868,
    lng: -74.577862,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 3332,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-03-02T00:00:00',
    validName: 'NJ7-238: 656 Plainsboro Road - Plainsboro - NJ',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'e0715810-dc51-4b78-8197-d358d8217ed1',
    display: 'NY7-164: 5345 West Genesee St - Camillus - NY',
    address: {
      address1: '5345 West Genesee Street',
      city: 'Camillus',
      state: 'NY',
      postalCode: '13031',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.0885,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 43.0410317,
    lng: -76.2714254,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 1,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2021-03-02T00:00:00',
    validName: 'NY7-164: 5345 West Genesee St - Camillus - NY',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'fb1d181c-b14d-42b4-8af7-cce540a8d497',
    display: 'MA5-145: 125 High Street - Boston - MA',
    address: {
      address1: '125 High Street',
      city: 'Boston',
      state: 'MA',
      postalCode: '02110',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.2,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 42.3558495,
    lng: -71.0531567,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 1,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2021-04-06T00:00:00',
    validName: 'MA5-145: 125 High Street - Boston - MA',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '1e0ebf9d-7959-41fe-8264-aeaa8f4cf171',
    display: 'NY5-108: 589 Broadway - New York - NY',
    address: {
      address1: '589 Broadway',
      city: 'New York',
      state: 'NY',
      postalCode: '10012',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1951,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 40.7250406,
    lng: -73.99767159999999,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 5000,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-09-23T00:00:00',
    validName: 'NY5-108: 589 Broadway - New York - NY',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '54d08991-c9a8-4297-bc83-80e4d41a6395',
    display: 'VA6-935: 1370 Fordham Drive - Virginia Beach - VA',
    address: {
      address1: '1370 Fordham Drive',
      city: 'Virginia Beach',
      state: 'VA',
      postalCode: '23464',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1009,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 36.7988827,
    lng: -76.1767033,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 1,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2021-02-12T00:00:00',
    validName: 'VA6-935: 1370 Fordham Drive - Virginia Beach - VA',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '636038f4-80d9-4bf4-982e-0999e3cc0f2d',
    display: 'NY5-238: 250 Main St - Huntington - NY',
    address: {
      address1: '250 Main Street',
      city: 'Huntington',
      state: 'NY',
      postalCode: '11743',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1903,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 40.8709843,
    lng: -73.42593149999999,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 10201,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-02-25T00:00:00',
    validName: 'NY5-238: 250 Main St - Huntington - NY',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'cddbf97f-04c2-4387-a3f2-c60e734e0894',
    display: 'VA9-003: 555 Fort Evans Road - Leesburg - VA',
    address: {
      address1: '555 Fort Evans Road',
      city: 'Leesburg',
      state: 'VA',
      postalCode: '20176',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.0972,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 39.1049345,
    lng: -77.5278683,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 4240,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2021-06-14T00:00:00',
    validName: 'VA9-003: 555 Fort Evans Road - Leesburg - VA',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '87065853-4481-4a31-9c32-4616fdc90fe3',
    display: 'MA6-176 : 684 Fellsway - Medford - MA',
    address: {
      address1: '684 Fellsway',
      city: 'Medford',
      state: 'MA',
      postalCode: '02155',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1909,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 42.40778359999999,
    lng: -71.08484469999999,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 3750,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2021-03-11T00:00:00',
    validName: 'MA6-176 : 684 Fellsway - Medford - MA',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '88aa3e76-1e3d-484a-8528-6bef3936f8fc',
    display: 'NJ6-104: 11 Wyckoff Ave - Waldwick - NJ',
    address: {
      address1: '11 Wyckoff Avenue',
      city: 'Waldwick',
      state: 'NJ',
      postalCode: '07463',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1274,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 41.0091434,
    lng: -74.1204633,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 3000,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-02-11T00:00:00',
    validName: 'NJ6-104: 11 Wyckoff Ave - Waldwick - NJ',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'aeb2eb43-94c1-465a-80a4-35ecd507d3a7',
    display: 'VA8-850: 2200 Langhorne Road - Lynchburg - VA',
    address: {
      address1: '2200 Langhorne Road',
      city: 'Lynchburg',
      state: 'VA',
      postalCode: '24501',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1068,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 37.413304,
    lng: -79.1742493,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 7148,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2021-02-17T00:00:00',
    validName: 'VA8-850: 2200 Langhorne Road - Lynchburg - VA',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'a7c563bb-4546-4bfd-8503-5709408fd33b',
    display: 'NJ7-299: 900 Oak Tree Rd - South Plainfield - NJ',
    address: {
      address1: '900 Oak Tree Road',
      city: 'South Plainfield',
      state: 'NJ',
      postalCode: '07080',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1265,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 40.5768391,
    lng: -74.3897653,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 4649,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2019-12-03T00:00:00',
    validName: 'NJ7-299: 900 Oak Tree Rd - South Plainfield - NJ',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '6d2917dc-8d9f-444a-a482-27e0594b6fe6',
    display: 'MA6-200: 505 Pleasant Street - Attleboro - MA',
    address: {
      address1: '505 Pleasant Street',
      city: 'Attleboro',
      state: 'MA',
      postalCode: '02703',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1715,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 41.9518345,
    lng: -71.2705956,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 1,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-03-11T00:00:00',
    validName: 'MA6-200: 505 Pleasant Street - Attleboro - MA',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '9070ad71-3213-4a08-9ea1-c8fadb64f04d',
    display: 'PA7-234: 520 West Lancaster Avenue - Strafford - PA',
    address: {
      address1: '520 West Lancaster Avenue',
      city: 'Wayne',
      state: 'PA',
      postalCode: '19087',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.0885,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 40.044793,
    lng: -75.40333679999999,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 5722,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-03-05T00:00:00',
    validName: 'PA7-234: 520 West Lancaster Avenue - Strafford - PA',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'ce638159-af53-4759-8887-f053c83f501e',
    display: 'MD4-740: 5135 River Road - Bethesda - MD',
    address: {
      address1: '5135 River Road',
      city: 'Bethesda',
      state: 'MD',
      postalCode: '20816',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1345,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 38.9642256,
    lng: -77.1023553,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 1,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-03-12T00:00:00',
    validName: 'MD4-740: 5135 River Road - Bethesda - MD',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'bd30f691-cc1a-434d-9a0f-e3d7720c936f',
    display: 'DC1-803: 722 H Street N.E. - Washington - DC',
    address: {
      address1: '722 H Street Northeast',
      city: 'Washington',
      state: 'DC',
      postalCode: '20002',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.151,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 38.9004447,
    lng: -76.9951513,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 1,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-03-13T00:00:00',
    validName: 'DC1-803: 722 H Street N.E. - Washington - DC',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '9a6569de-a342-40e7-b09d-7ca333dce77b',
    display: 'MA6-261: Forty-Four Plaza, Rte 44 - Raynham - MA',
    address: {
      address1: 'Forty-Four Plaza, Rte 44 - Raynham - MA',
      city: 'Raynham',
      state: 'MA',
      postalCode: '',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.169,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 41.9058156,
    lng: -71.0250741,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 3600,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-03-05T00:00:00',
    validName: 'MA6-261: Forty-Four Plaza, Rte 44 - Raynham - MA',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'b8d76109-a3ac-4cbb-af8c-fcca0bf276e6',
    display: 'MA6-205: 1857 Centre Street - Boston - MA',
    address: {
      address1: '1857 Centre Street',
      city: 'Boston',
      state: 'MA',
      postalCode: '02132',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.2131,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 42.2864759,
    lng: -71.1547385,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 3708,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-03-05T00:00:00',
    validName: 'MA6-205: 1857 Centre Street - Boston - MA',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'b6dd3962-c69d-4572-8839-d13713baf57e',
    display: 'MA6-204: 677 Centre Street - Boston - MA',
    address: {
      address1: '677 Centre Street',
      city: 'Boston',
      state: 'MA',
      postalCode: '02130',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1776,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 42.3122055,
    lng: -71.11446529999999,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 5400,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-01-22T00:00:00',
    validName: 'MA6-204: 677 Centre Street - Boston - MA',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: 'ad056fd1-e8b7-4e76-978f-2ef65ac7f26e',
    display: 'NJ7-191: Gills Lane & Rte 1- Woodbridge - Iselin - NJ',
    address: {
      address1: 'Gills Lane & Rte 1- Woodbridge - Iselin - NJ',
      city: 'Woodbridge Township',
      state: 'NJ',
      postalCode: '',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1288,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 40.5699202,
    lng: -74.32434789999999,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 2922,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-01-07T00:00:00',
    validName: 'NJ7-191: Gills Lane & Rte 1- Woodbridge - Iselin - NJ',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
  {
    id: '86446f7d-750e-4a30-92d3-30810e656050',
    display: 'NJ7-273: 222 Whitehorse Pike - Hammonton - NJ',
    address: {
      address1: '222 White Horse Pike',
      city: 'Chesilhurst',
      state: 'NJ',
      postalCode: '08089',
      country: 'US',
      timezone: 'America/New_York',
    },
    electricUtilityRate: 0.1473,
    currencyCode: 'USD',
    enableEquipmentView: true,
    ingestionSourceId: '3d8aafdc-a27e-400a-891b-1acee5bbe8e9',
    lat: 39.7388489,
    lng: -74.8720996,
    locale: 'en_US',
    name: null,
    source: 'REDAPTIVE',
    squareFootage: 2067,
    theme: 'REDAPTIVE',
    timezone: 'America/New_York',
    active: true,
    ingestionDataStart: '2020-04-09T00:00:00',
    validName: 'NJ7-273: 222 Whitehorse Pike - Hammonton - NJ',
    siteShifts: [],
    meterCounts: {
      electricity: 3,
      naturalGas: 4,
      water: 1,
    },
    ingestionDataStartElectricity: '',
    ingestionDataStartNaturalGas: '',
    ingestionDataStartWater: '',
  },
];

export default sites;
