export const cities = ['Anytown', 'Hometown'];

export const siteIdMask: Record<string, any> = {
  // Avantor ("Manufacturing (Life Sciences)")
  // Solon
  'e5eb568b-11cd-4737-8dec-851631687271': {
    name: 'OH - Manufacturing Facility',
    city: cities[0],
    state: 'OH',
    lat: 39.226,
    lng: -84.649,
  },
  // Aurora
  '285112ea-6b0a-4bfa-939f-01f58b2338fb': {
    name: 'NJ - Manufacturing Facility',
    city: cities[1],
    state: 'NJ',
    postalCode: '10035',
    lat: 40.278,
    lng: -74.703,
  },
  // 800 East Fabyan
  'ee0a0355-87e0-4af0-83bc-8f333a2a0d52': {
    name: 'IL - Manufacturing Facility',
    city: cities[0],
    state: 'IL',
    postalCode: '60510',
    lat: 41.8670209,
    lng: -88.2950714,
  },
  //
  '272b25b1-6717-47d8-9f3f-3e59b3c1f0e5': {
    name: 'CA - Manufacturing Facility',
    city: cities[1],
    state: 'CA',
    postalCode: '91710',
    lat: 34.0008985,
    lng: -117.6725435,
  },
  /**
   * Following customer's sites will use real site's city - state as the name, and lat/long
   *
   * @see YLW-628.
   */
  // WPT Capital Advisors ("Real Estate Investment Trust (REIT)")
  '02ac8ce8-22bc-44e6-a6eb-ced286ad791b': {
    name: 'NJ - Office Building',
    city: 'Bayonne',
    state: 'NJ',
    postalCode: '10001',
    lat: 40.748817,
    lng: -73.985428,
    isWpt: true,
  },
  '0339d1e0-e647-496d-bdc0-f63776880c09': {
    name: 'GA - Distribution Center',
    city: 'Social Circle',
    state: 'GA',
    postalCode: '90001',
    lat: 34.052235,
    lng: -118.243683,
    isWpt: true,
  },
  '178a785c-0ae5-4af8-8d88-2af12744d8c2': {
    name: 'MN - Warehouse',
    city: 'Eagan',
    state: 'MN',
    postalCode: '77001',
    lat: 29.760427,
    lng: -95.369804,
    isWpt: true,
  },
  '3fe73706-5137-4a6f-a1cf-9150b16ba2d1': {
    name: 'MN - Manufacturing Plant',
    city: 'Saint Paul',
    state: 'MN',
    postalCode: '60601',
    lat: 41.878113,
    lng: -87.629799,
    isWpt: true,
  },
  '4c13efa2-b919-48ef-a378-e75934c659b1': {
    name: 'GA - Production Facility',
    city: 'Duluth',
    state: 'GA',
    postalCode: '33101',
    lat: 25.761681,
    lng: -80.191788,
    isWpt: true,
  },
  '5ef4b64f-2105-4d9e-ae93-3c575dd059cc': {
    name: 'IL - Assembly Plant',
    city: 'Carol Stream',
    state: 'IL',
    postalCode: '98101',
    lat: 47.606209,
    lng: -122.332069,
    isWpt: true,
  },
  '86110579-50fc-42bf-9374-1063b7554b86': {
    name: 'GA - Manufacturing Site',
    city: 'Auburn',
    state: 'GA',
    postalCode: '02101',
    lat: 42.360081,
    lng: -71.058884,
    isWpt: true,
  },
  'bd54d8ac-d1f9-405d-85db-d097db8c0ff3': {
    name: 'IL - Production Center',
    city: 'West Chicago',
    state: 'IL',
    postalCode: '48201',
    lat: 42.331429,
    lng: -83.045753,
    isWpt: true,
  },
  'bf40540b-fdcb-4eb5-a75b-86c6798a3b15': {
    name: 'KS - Manufacturing Complex',
    city: 'Lenexa',
    state: 'KS',
    postalCode: '19101',
    lat: 39.952583,
    lng: -75.165222,
    isWpt: true,
  },
  'cc9c0bcf-a96c-4921-9150-37abb7f5466f': {
    name: 'PA - Manufacturing Plant',
    city: 'Eddystone',
    state: 'PA',
    postalCode: '85001',
    lat: 33.448376,
    lng: -112.074036,
    isWpt: true,
  },
  'd0115baa-eeaf-4b9e-bdc7-5c086b24efe9': {
    name: 'GA - Assembly Plant',
    city: 'Mableton',
    state: 'GA',
    postalCode: '55401',
    lat: 44.977753,
    lng: -93.265011,
    isWpt: true,
  },
  'ddc8dbbf-d4c1-4305-845c-7fe833a6d347': {
    name: 'MN - Manufacturing Facility',
    city: 'Shakopee',
    state: 'MN',
    postalCode: '37201',
    lat: 36.162663,
    lng: -86.781601,
    isWpt: true,
  },
  'eecc1d3a-eaee-4fbb-b641-99c66f6b1704': {
    name: 'GA - Manufacturing Center',
    city: 'Conyers',
    state: 'GA',
    postalCode: '28201',
    lat: 35.227085,
    lng: -80.843124,
    isWpt: true,
  },
};
