export const customerStatusMockData = {
  integrationEnabled: true,
  enabledElectricSites: 5,
  enabledWaterSites: 2,
  enabledNaturalGasSites: 4,
  energyStarCustomerId: 12345,
};

export const benchmarkingMockData = [
  {
    id: '9a9217d9-244a-4b50-9f58-f61ab0e84e43',
    created: '2023-12-18T17:23:47.721+00:00',
    createdBy: null,
    modified: '2023-12-18T17:23:47.721+00:00',
    modifiedBy: null,
    siteId: '9a9217d9-244a-4b50-9f58-f61ab0e84e44',
    siteName: 'Site 1',
    meterDetails: {
      created: null,
    },
    yearMonth: '2023-07',
    siteEui: '1.00',
    sourceEui: 2.0,
    nationalMedianSiteEui: 3.0,
    nationalMedianSourceEui: 4.0,
    nationalMedianSiteEuiDiff: 5.0,
    nationalMedianSourceEuiDiff: 6.0,
    energyStarScore: null,
    nationalMedianEnergyStarSource: 40,
    eligibleForCertification: 'Yes',
    totalGhgEmissions: 0.0,
  },
  {
    id: '7b5c8f2a-1a89-42d3-8f79-5c4eab69d1a1',
    created: '2023-12-19T09:45:22.512+00:00',
    createdBy: null,
    modified: '2023-12-19T09:45:22.512+00:00',
    modifiedBy: null,
    siteId: '7b5c8f2a-1a89-42d3-8f79-5c4eab69d1a2',
    siteName: 'Site 2',
    meterDetails: {
      created: null,
    },
    yearMonth: '2023-08',
    siteEui: '1.20',
    sourceEui: 2.5,
    nationalMedianSiteEui: 3.5,
    nationalMedianSourceEui: 4.5,
    nationalMedianSiteEuiDiff: 5.5,
    nationalMedianSourceEuiDiff: 6.5,
    energyStarScore: 60,
    nationalMedianEnergyStarSource: 45,
    eligibleForCertification: 'Yes',
    totalGhgEmissions: 0.1,
  },
  {
    id: 'e3c91d4b-4f58-43a2-9d3b-6e789fc70dab',
    created: '2023-12-19T10:15:33.721+00:00',
    createdBy: null,
    modified: '2023-12-19T10:15:33.721+00:00',
    modifiedBy: null,
    siteId: 'e3c91d4b-4f58-43a2-9d3b-6e789fc70dac',
    siteName: 'Site 3',
    meterDetails: {
      created: null,
    },
    yearMonth: '2023-09',
    siteEui: '1.40',
    sourceEui: 3.0,
    nationalMedianSiteEui: 4.0,
    nationalMedianSourceEui: 5.0,
    nationalMedianSiteEuiDiff: 6.0,
    nationalMedianSourceEuiDiff: 7.0,
    energyStarScore: 70,
    nationalMedianEnergyStarSource: 55,
    eligibleForCertification: 'Yes',
    totalGhgEmissions: 0.2,
  },
];

export const pendingPropertiesMockData = [
  {
    siteName: 'Site B',
    rdpSiteId: 'e027596a-bab5-4b34-b6f6-29363116efb1',
    esPropertyName: 'Property B',
    esPropertyId: 111111,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
  {
    siteName: 'Site B',
    rdpSiteId: '72b11fc9-60b9-4f40-b1fe-96f48218a75b',
    esPropertyName: null,
    esPropertyId: null,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
  {
    siteName: 'Site B',
    rdpSiteId: 'cc283087-3608-45d2-afb3-b4f49a51a2ec',
    esPropertyName: null,
    esPropertyId: null,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
  {
    siteName: 'Site B',
    rdpSiteId: '2bcf95e9-bd48-443e-84a1-5fb8cf37c2b3',
    esPropertyName: null,
    esPropertyId: null,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
  {
    siteName: 'Site B',
    rdpSiteId: '3efcd452-4554-42a2-8d47-d738e952ff6e',
    esPropertyName: null,
    esPropertyId: null,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
];
