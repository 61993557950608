import { create } from 'zustand';
import zukeeper from 'zukeeper';

// First we define the structure of our store
interface TSEnableEnergyStarPopupState {
  hasUserDismissedEnableEnergyStarPopup: boolean;
  dismissEnableEnergyStarPopup: () => void;
  resetEnableEnergyStarPopupState: () => void;
}
// Then we create the store
export const useEnableEnergyStarPopupStore =
  create<TSEnableEnergyStarPopupState>(
    zukeeper((set) => ({
      // Initial state of the store
      hasUserDismissedEnableEnergyStarPopup: false,
      // Additional state variables can be added here...

      // Actions to update the state
      dismissEnableEnergyStarPopup: () =>
        set({ hasUserDismissedEnableEnergyStarPopup: true }),
      resetEnableEnergyStarPopupState: () =>
        set({ hasUserDismissedEnableEnergyStarPopup: false }),
    }))
  );

// export a combined hook for convenience, if you prefer that
export const useEnergyStarStores = () => ({
  enableEnergyStarPopup: useEnableEnergyStarPopupStore(),
  // Additional stores can be added here...
});
