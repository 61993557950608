import styled from '@emotion/styled';
import { AppShell } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PropsWithChildren } from 'react';
import styles from './NewLayout.module.css';
import NewMainNav from './NewMainNav';
import SimpleFooter from './mantine/SimpleFooter/SimpleFooter';

const Content = styled.div`
  min-height: calc(100vh - 62px); // Height of footer
`;

const NewLayout = ({ children }: PropsWithChildren) => {
  const [navExpanded, { toggle: toggleNav }] = useDisclosure(true);

  return (
    <AppShell
      navbar={{
        width: navExpanded ? 272 : 72,
        breakpoint: 'none',
      }}
    >
      <AppShell.Navbar className={styles.navbar}>
        <NewMainNav toggle={toggleNav} expanded={navExpanded} />
      </AppShell.Navbar>
      <AppShell.Main bg='gray.0'>
        <Content>{children}</Content>
        <SimpleFooter />
      </AppShell.Main>
    </AppShell>
  );
};

export default NewLayout;
