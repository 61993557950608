import { AnchorHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const LinkStyles = css`
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    text-decoration: underline;
  }
`;

export const LinkStyled = styled.a`
  ${LinkStyles}
`;

export const RouterLinkStyled = styled(Link)`
  ${LinkStyles}
`;

interface TSProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  children: React.ReactNode;
  external?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  gainsightTagId?: string;
}

const StyledLink = ({
  href,
  children,
  external = false,
  gainsightTagId = '',
  ...props
}: TSProps) => {
  if (external) {
    return (
      <LinkStyled
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        data-gainsight-id={gainsightTagId}
      >
        {children}
      </LinkStyled>
    );
  }

  return (
    <RouterLinkStyled data-gainsight-id={gainsightTagId} {...props} to={href}>
      {children}
    </RouterLinkStyled>
  );
};

export default StyledLink;
