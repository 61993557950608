import { PropsWithChildren } from 'react'
import { ThemeProvider } from "styled-components"
import theme from './redaptiveOne'

const RedaptiveThemeProvider = ({ children }: PropsWithChildren) => {
  return (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  )
}
export default RedaptiveThemeProvider
