import _, { isEmpty, isNil, mapValues, omit, omitBy, pick } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { TSSiteResponse } from '../../../ducks/sites';
import { useSites } from '../../../queries/sites';
import { omitNilEmpty } from '../../../utils/functional';
import {
  CSVSearchParams,
  DataExplorerDefaultFilters,
  DataExplorerFilterParams,
  DataExplorerSearchParams,
  ParsedDataExplorerSearchParams,
  SearchObjectUpdateFn,
  SingleValueSearchParams,
  SingleValueSearchParamsKeys,
} from '../types';
import { usePageDefaults } from './usePageDefaults';

export const useFiltersToSearchParams = () => {
  const pageDefaults = usePageDefaults();
  const { data: sites = [] } = useSites();

  return (filters) => filtersToSearchParams(pageDefaults, filters, sites);
};

export const filtersToSearchParams = (
  pageDefaults: DataExplorerDefaultFilters,
  query: DataExplorerFilterParams,
  sites: TSSiteResponse[]
): ParsedDataExplorerSearchParams => {
  const filteredQuery = _(query)
    .omitBy(isNil)
    .omitBy(isEmpty)
    .omit('options')
    .value();
  if (sites?.length !== 1 && filteredQuery.siteIds?.length === sites?.length) {
    delete filteredQuery.siteIds;
  }
  const queryWithoutDefaults = omitBy(
    filteredQuery,
    (v, k) => pageDefaults[k] === v
  );
  const queryCommaSeparated = mapValues(queryWithoutDefaults, (v) =>
    Array.isArray(v) ? v.join(',') : v
  ) as ParsedDataExplorerSearchParams;

  return queryCommaSeparated;
};

export const useDataExplorerSearchParams = (): [
  ParsedDataExplorerSearchParams,
  (updateFn: SearchObjectUpdateFn) => void,
] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const toSearchObject = (searchParams) =>
    parseSearchParams(Object.fromEntries(searchParams));
  const setSearchObject = (updateFn) =>
    setSearchParams((prev) => updateFn(toSearchObject(prev)));
  return [toSearchObject(searchParams), setSearchObject];
};

const searchParamsToFilters = (
  pageDefaults: DataExplorerDefaultFilters,
  searchParams: ParsedDataExplorerSearchParams
) => {
  const siteIds = searchParams.siteIds || searchParams.site || [];
  const filters = omit(
    omitNilEmpty({
      ...pageDefaults,
      ...searchParams,
    }),
    'site'
  );
  return {
    ...filters,
    siteIds,
  } as DataExplorerFilterParams;
};

export const useSearchParamsToFilters = () => {
  const pageDefaults = usePageDefaults();

  return (
    searchParams: ParsedDataExplorerSearchParams
  ): DataExplorerFilterParams =>
    searchParamsToFilters(pageDefaults, searchParams);
};

const csvToArray = (value: string | undefined): string[] | undefined =>
  typeof value === 'string' ? value.split(',').filter((v) => v) : value;

const singleValueParams: SingleValueSearchParamsKeys = [
  'fromDate',
  'grouping',
  'measurementTypes',
  'period',
  'resolution',
  'resourceType',
  'toDate',
];

export const parseSearchParams = (
  searchParams: DataExplorerSearchParams
): ParsedDataExplorerSearchParams => {
  const searchParamsSingleValues = pick(
    searchParams as SingleValueSearchParams,
    singleValueParams
  ) as Partial<SingleValueSearchParams>;
  const searchParamsArrayValues = mapValues(
    omit(searchParams, singleValueParams) as Partial<CSVSearchParams>,
    csvToArray
  );
  const parsedSearchParams = {
    ...searchParamsSingleValues,
    ...searchParamsArrayValues,
  };
  const filteredSearchParams = _(parsedSearchParams)
    .omitBy(isNil)
    .omitBy(isEmpty)
    .value();

  return filteredSearchParams;
};
