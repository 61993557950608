import styled from '@emotion/styled';
import AngleDownIcon from '@iconscout/react-unicons/icons/uil-angle-down';

import {
  CheckIcon,
  Group,
  Select as MantineSelect,
  SelectProps as MantineSelectProps,
  Mark,
} from '@mantine/core';
import classes from './Select.module.css';

type SelectProps = {
  gainsightTagId?: string;
  /* Indicates the field has been modified by rendering a colored dot next to it */
  edited?: boolean;
  /* Position of edit dot from top of field */
  editDotTop?: string;
} & MantineSelectProps;

const SelectContent = styled.div`
  position: relative;
`;

/* a red dot to indicate edited status */
const Marker = styled(Mark)`
  display: none;
  &[data-edited='true'] {
    display: block;
  }
  position: absolute;
  left: 4px;
  top: ${({ top = '15px' }) => top};
  width: 6px;
  height: 6px;
  border-radius: 10px;
`;

const renderSelectOption: MantineSelectProps['renderOption'] = ({
  option,
  checked,
}) => (
  <Group gap='sm'>
    {checked ? (
      <CheckIcon stroke='1.5' color='currentColor' opacity='0.4' size={12} />
    ) : (
      <div style={{ width: 12 }} />
    )}
    {option.label}
  </Group>
);

const Select = ({
  clearable = true,
  editDotTop,
  edited,
  fz = 14,
  gainsightTagId,
  nothingFoundMessage = 'No options',
  size = 'md',
  ...rest
}: SelectProps) => {
  return (
    <SelectContent>
      <MantineSelect
        className={classes.select}
        classNames={{ option: classes.optionSelector }}
        clearable={clearable}
        comboboxProps={{
          width: 'fit-content',
          position: 'bottom-start',
          offset: 4,
        }}
        data-gainsight-id={gainsightTagId}
        fz={fz}
        nothingFoundMessage={nothingFoundMessage}
        size={size}
        defaultValue='React'
        rightSection={<AngleDownIcon />}
        rightSectionPointerEvents='none'
        rightSectionWidth={30}
        radius='md'
        renderOption={renderSelectOption}
        maxDropdownHeight='13.25rem'
        {...rest}
      />
      <Marker color='red' data-edited={edited} top={editDotTop} />
    </SelectContent>
  );
};

export default Select;
