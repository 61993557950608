import { max, sum } from 'lodash';
import moment from 'moment';
import { DEFAULT_ELECTRIC_UTILITY_RATE } from '../constants';
import { TSSiteShift } from '../ducks/siteShifts';
import { Grouping } from '../ducks/types';
import { SitesById } from '../queries/sites';

export interface DataByInterval {
  consumptionByDayOfWeek: Map<string, number[]>;
  largestConsumerOutHoursName: string;
  operatingHoursTotals: any;
}

export const OPERATING_HOURS_CHART_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
type DaysOfTheWeek =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';
type WeeklyDaysHoursBool = Record<DaysOfTheWeek, Record<number, boolean>>;
type GroupOutsideOpHoursUsageProps = {
  groupUsageByHourDayOfWeek: any;
  grouping: Grouping;
  sitesById: SitesById;
  siteShifts: any;
};

class OperatingHoursHelper {
  /**
   * Calculate the end day and end time given shift start time and duration
   * @param day
   * @param startHour
   * @param startMinute
   * @param duration
   */
  static getShiftEndDayTime({
    day,
    startHour,
    startMinute,
    durationInMinutes,
  }): { endDay: string; endTime: string } {
    // eslint-disable-next-line no-mixed-operators
    const endTimeMinute = startHour * 60 + startMinute + durationInMinutes;
    // 1440 (0ne day in minutes)
    const endHour = endTimeMinute % 1440;

    const shiftDays = Math.floor(endTimeMinute / 1440);
    let endDay = 0;

    if (shiftDays + day > 7) {
      endDay = shiftDays + day - 7;
    } else {
      endDay = shiftDays + day;
    }
    const endHourInMinutes = endHour / 60;
    const hour = Math.floor(endHourInMinutes);
    const minutes =
      parseFloat(
        (endHourInMinutes - Math.floor(endHourInMinutes)).toPrecision(2)
      ) * 60;

    return {
      endDay: moment().isoWeekday(endDay).format('dddd'),
      endTime: moment(`${hour.toString()}:${Math.round(minutes).toString()}`, [
        'HH:mm',
      ]).format('hh:mm a'),
    };
  }

  /**
   * Calculate shift duration in minutes given start day/time and end day/time
   * @param startDay
   * @param startTime
   * @param endDay
   * @param endTime
   */
  static getDurationInMinutes({
    startDay,
    startTime,
    endDay,
    endTime,
  }): number {
    const startHour = parseInt(startTime.split(':')[0]);
    const startMinute = parseInt(startTime.split(':')[1]);
    const endHour = parseInt(endTime.split(':')[0]);
    const endMinute = parseInt(endTime.split(':')[1]);
    // eslint-disable-next-line no-mixed-operators
    const startTimeInMinutes = startHour * 60 + startMinute;
    // eslint-disable-next-line no-mixed-operators
    const endTimeInMinutes = endHour * 60 + endMinute;

    let daysDiff;
    let durationInMinutes;

    // get number of days within a range
    if (startDay > endDay) {
      daysDiff = 7 - (startDay - endDay);
    } else {
      daysDiff = endDay - startDay;
    }

    // calculate duration
    if (startTimeInMinutes === endTimeInMinutes) {
      durationInMinutes = daysDiff * 24 * 60;
    } else if (startTimeInMinutes < endTimeInMinutes) {
      durationInMinutes =
        // eslint-disable-next-line no-mixed-operators
        daysDiff * 24 * 60 + (endTimeInMinutes - startTimeInMinutes);
    } else if (startTimeInMinutes > endTimeInMinutes) {
      durationInMinutes =
        // eslint-disable-next-line no-mixed-operators
        daysDiff * 24 * 60 - (startTimeInMinutes - endTimeInMinutes);
    }

    return durationInMinutes;
  }

  static DEFAULT_SHIFTS = [
    { startDay: 'Monday', startHour: 6, durationInMinutes: 720 },
    { startDay: 'Tuesday', startHour: 6, durationInMinutes: 720 },
    { startDay: 'Wednesday', startHour: 6, durationInMinutes: 720 },
    { startDay: 'Thursday', startHour: 6, durationInMinutes: 720 },
    { startDay: 'Friday', startHour: 6, durationInMinutes: 720 },
  ];

  /**
   * Takes shift objects
   * returns true for every hour and day of the week that are in operating hours
   */
  static calcOpHoursByHourDayOfWeek(
    siteShifts: TSSiteShift[]
  ): WeeklyDaysHoursBool {
    const shifts = siteShifts?.length === 0 ? this.DEFAULT_SHIFTS : siteShifts;
    const operatingDaysHours: WeeklyDaysHoursBool = {} as WeeklyDaysHoursBool;
    shifts.forEach((shift) => {
      const startTime = moment('2024-01-01');
      startTime.day(shift.startDay);
      startTime.hour(shift.startHour);
      const durationInHours = shift.durationInMinutes / 60;

      for (let i = 0; i < durationInHours; i++) {
        const currentTime = startTime.clone().add(i, 'hours');
        const day = currentTime.format('dddd');
        const hour = currentTime.hour();
        operatingDaysHours[day] ||= {};
        operatingDaysHours[day][hour] = true;
      }
    });
    return operatingDaysHours;
  }

  /**
   * Takes group circuit usage data indexed by time and
   * returns group usage indexed by day of the week and hour of the day
   */
  static groupUsageByHourDayOfWeek(groupUsageByTime) {
    return groupUsageByTime.map((group) => {
      const { usageByTime } = group;
      const dayHourUsage = {};
      Object.keys(usageByTime).forEach((time) => {
        const usage = usageByTime[time];
        const mom = moment(time);
        const day = mom.format('dddd');

        dayHourUsage[day] ||= {};
        dayHourUsage[day][mom.hour()] ||= 0;
        dayHourUsage[day][mom.hour()] += usage;
      });

      return {
        ...group,
        dayHourUsage,
      };
    });
  }

  /**
   * Takes group usage by hour and day of week and
   * returns how much of that usage was inside and outside operating hours, by group
   */

  static groupOutsideOpHoursUsage({
    groupUsageByHourDayOfWeek,
    grouping,
    sitesById,
    siteShifts,
  }: GroupOutsideOpHoursUsageProps) {
    return groupUsageByHourDayOfWeek.map((group) => {
      const { dayHourUsage, groupId } = group;
      const groupSiteShifts =
        grouping === 'site' ? sitesById[groupId]?.siteShifts : siteShifts;
      const defaultOpHoursUsed = groupSiteShifts?.length === 0;
      const operatingDaysHours = this.calcOpHoursByHourDayOfWeek(
        groupSiteShifts ?? []
      );

      let outsideOpHoursUsage = 0;
      let insideOpHoursUsage = 0;
      Object.keys(dayHourUsage).forEach((day) => {
        Object.keys(dayHourUsage[day]).forEach((hour) => {
          const usage = dayHourUsage[day]?.[hour];
          const insideHours = operatingDaysHours[day]?.[hour];
          if (insideHours) {
            insideOpHoursUsage += usage;
          } else {
            outsideOpHoursUsage += usage;
          }
        });
      });

      return {
        defaultOpHoursUsed,
        insideOpHoursUsage,
        outsideOpHoursUsage,
        ...group,
      };
    });
  }

  /**
   * Calculates total cost of outside operating hours usage
   * using the electric utility rate of each site, if there are multiple
   */
  static totalOutsideOpHoursSpend({
    groupOutsideOpHoursUsage,
    sitesById,
    grouping,
    siteId,
  }) {
    const groupCostOutsideOpHours = groupOutsideOpHoursUsage.map((group) => {
      // If grouping === 'site', groupId is the siteId
      // If grouping !== 'site', we are filtered to only one site. use that siteId to look up electric rate
      const rateSiteId = grouping === 'site' ? group.groupId : siteId;
      const rate =
        sitesById[rateSiteId]?.electricUtilityRate ||
        DEFAULT_ELECTRIC_UTILITY_RATE;
      return group.outsideOpHoursUsage * rate;
    });
    return sum(groupCostOutsideOpHours);
  }

  /**
   * Takes group usage by hour and day of week and
   * returns total usage by hour and day of week
   */
  static totalUsageByHourDayOfWeek(groupUsageByHourDayOfWeek) {
    const totalUsage = {};
    groupUsageByHourDayOfWeek.forEach((group) => {
      Object.keys(group.dayHourUsage).forEach((day) => {
        Object.keys(group.dayHourUsage[day]).forEach((hour) => {
          const usage = group.dayHourUsage[day][hour];
          totalUsage[day] ||= {};
          totalUsage[day][hour] ||= 0;
          totalUsage[day][hour] += usage;
        });
      });
    });

    return totalUsage;
  }

  /**
   * Takes usage by hour and day of week and
   * returns the max usage value across all hours
   */
  static overallMaxHourlyUsage(usageByHourDayOfWeek) {
    const allDayHourUsage = Object.keys(usageByHourDayOfWeek)
      .map((day) =>
        Object.keys(usageByHourDayOfWeek[day]).map(
          (hour) => usageByHourDayOfWeek[day][hour]
        )
      )
      .flat();

    return max(allDayHourUsage);
  }

  /**
   * Takes group outside op hour usage data and
   * returns:
   *   - the group with most usage outside op hours
   *   - the total outside op hour usage in kWh
   *   - a bool if any group used default op hours in their calculations
   */
  static outsideOpHoursUsageTotals(groupOutsideOpHoursUsage) {
    const totalOutsideOpHourUsage = sum(
      groupOutsideOpHoursUsage.map((group) => group.outsideOpHoursUsage)
    );
    const maxOutsideOpHoursUsage = max(
      groupOutsideOpHoursUsage.map((group) => group.outsideOpHoursUsage)
    );
    const groupWithMaxOutsideOpHoursUsage = groupOutsideOpHoursUsage.find(
      (group) => group.outsideOpHoursUsage === maxOutsideOpHoursUsage
    );
    const isDefaultOpHoursUsed = groupOutsideOpHoursUsage.some(
      (group) => group.defaultOpHoursUsed
    );

    return {
      groupWithMaxOutsideOpHoursUsage,
      totalOutsideOpHourUsage,
      isDefaultOpHoursUsed,
    };
  }

  /**
   * Takes a shift object and
   * returns the days that shift spans
   */
  static calculateShiftSpan = (shiftInfo) => {
    // Convert start day to Moment.js representation
    const startDay = shiftInfo.startDay.toUpperCase();
    const startHour = shiftInfo.startHour;
    const startMinute = shiftInfo.startMinute;

    // Create a moment representing the start time
    const shiftStart = moment()
      .day(startDay)
      .hour(startHour)
      .minute(startMinute)
      .second(0);

    // Calculate the end time based on the duration
    const shiftEnd = shiftStart
      .clone()
      .add(shiftInfo.durationInMinutes, 'minutes');

    const result = {};

    // Iterate through each day between the start and end of the shift
    const currentDay = shiftStart.clone().startOf('day');

    while (currentDay.isSameOrBefore(shiftEnd)) {
      const formattedDay = currentDay.format('dddd'); // Get day name (e.g., "Monday")

      // Define start and end times for this day
      let dayStart = currentDay.clone();
      if (currentDay.isSame(shiftStart, 'day')) {
        // Start time for the first day
        dayStart = shiftStart;
      } else {
        // For intermediate days, start at midnight
        dayStart.hour(0).minute(0);
      }

      let dayEnd = currentDay.clone().endOf('day');
      if (currentDay.isSame(shiftEnd, 'day')) {
        // End time for the last day
        dayEnd = shiftEnd;
      }

      // Store the start and end times for this day
      result[formattedDay] = {
        start: dayStart.format('hh:mm A'), // Convert to 12-hour with AM/PM
        end: dayEnd.format('hh:mm A'), // Convert to 12-hour with AM/PM
      };

      // Move to the next day
      currentDay.add(1, 'day');
    }

    return result;
  };
}
export default OperatingHoursHelper;
