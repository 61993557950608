import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  DATA_EXPLORER_PATH,
  PORTFOLIO_PATH,
  PROJECTS_PATH,
} from '../constants/paths';
import { actions as customerActions } from '../ducks/customers';
import { actions as siteActions } from '../ducks/sites';
import { TSActionTypes, gainsightPXGlobalContext } from '../utils/gainsight';

const useCustomerSwitch = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    gainsightPXGlobalContext(TSActionTypes.setOnce, {
      userAgent: navigator.userAgent,
    });
    const noSiteContextPage =
      location?.pathname.includes(`${DATA_EXPLORER_PATH}/`) ||
      location?.pathname.includes(`${PORTFOLIO_PATH}/`) ||
      location?.pathname.includes(`${PROJECTS_PATH}/`); //TODO: put these values in an array

    if (!noSiteContextPage) {
      // Reset url params when navigating away from this page
      gainsightPXGlobalContext(TSActionTypes.remove, ['siteId', 'siteName']);
    }
    if (location?.pathname === '/' && !isEmpty(location?.state)) {
      dispatch(
        customerActions.switchCustomer({
          customerId: location?.state.customerId,
        })
      );
      dispatch(siteActions.clearSites());
    }
  }, [dispatch, location]);
};

export default useCustomerSwitch;
