import { TSSiteContractResponse, TSSiteResponse } from '../../ducks/sites';

export const site: TSSiteResponse = {
  id: '30bfef7e-6a80-3d53-9a7e-eadff14cf49e',
  ingestionSourceId: '1e9f11ab-9d7f-4f6c-8656-317179dd16a9',
  customerId: '13e72426-509e-44cf-9fe1-fc15df4fd9b8',
  display: 'MC632 - WI - La Crosse - 1920 Oak Street',
  name: null,
  source: 'LEVITON',
  address: {
    address1: '11823 Plano Road',
    city: 'Dallas',
    state: 'TX',
    postalCode: '75243',
    country: 'US',
    timezone: 'America/Chicago',
  },
  lat: 43.85134110000001,
  lng: -91.2282063,
  locale: 'en_US',
  currencyCode: 'USD',
  theme: 'REDAPTIVE',
  squareFootage: 60000,
  enableEquipmentView: false,
  active: true,
  ingestionDataStart: '2018-07-01T00:00:00.000Z',
  firstBillDate: '2018-10-01T00:00:00.000Z',
  electricUtilityRate: 0.12,
  timezone: 'America/Chicago',
  validName: 'MC632 - WI - La Crosse - 1920 Oak Street',
  siteShifts: [],
  meterCounts: {
    electricity: 3,
    naturalGas: 4,
    water: 1,
  },
  ingestionDataStartElectricity: '2018-07-01T00:00:00.000Z',
  ingestionDataStartNaturalGas: '',
  ingestionDataStartWater: '',
};

export const siteContractMockData: TSSiteContractResponse = {
  siteId: 'cb9ff883-e0b4-4201-8cf5-20ff44477b35',
  contractDTOList: [
    {
      id: 'dba3b1ba-63f9-40c3-90ee-f3e2043f2827',
      opportunityId: 'R00006595',
      energyCommitment: 1587579.0,
      energyRate: 0.1596,
      utilityRate: 0.168,
      monthlyBlock: 22049.71,
      resourceUnit: 'kWh',
      type: 'LIGHTING',
    },
  ],
};

export default site;
