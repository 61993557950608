import styled from 'styled-components';
import { breakpoints } from '../themes';
import { gapMap } from './Stack';

const Card = styled.div<{ gap?: keyof typeof gapMap }>`
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: ${({ gap = 'md' }) => gapMap[gap]}px;
  padding: 16px;

  @media (min-width: ${breakpoints.xl}) {
    padding: 24px;
  }
`;

export default Card;
