import { Stack } from '@mantine/core';
import { useRollbarContext } from '@rollbar/react';
import { Suspense } from 'react';

import { Alert } from '../../components/mantine/Alert/Alert';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { useSites } from '../../queries/sites';

import DataExplorerFilters from './Filters';
import {
  useRemoveInvalidAdvancedFilters,
  useUpdateInvalidBasicFilters,
} from './Filters/filterUtils';
import HeaderActions from './Filters/HeaderActions';
import DataExplorerTabs from './Tabs';
import useActiveFilters, { FiltersContext } from './utils/filterParams';

export const noDataMessage = <>No Usage Data</>;

const DataExplorer = () => {
  useRollbarContext('Data Explorer');

  const sitesQuery = useSites();
  const { data: sites = [] } = sitesQuery;

  const [activeFilters, setActiveFilters] = useActiveFilters();

  useUpdateInvalidBasicFilters(activeFilters, setActiveFilters);
  useRemoveInvalidAdvancedFilters(activeFilters, setActiveFilters);

  const noSites = sitesQuery.isSuccess && sites.length === 0;
  return (
    <>
      {noSites && <Alert title='No sites' type='orange' />}
      {!noSites && (
        <>
          <Stack gap='0' bg='gray.0'>
            <FiltersContext.Provider value={activeFilters}>
              <Stack>
                <PageHeader Title='Data Explorer' Actions={<HeaderActions />} />
                <DataExplorerFilters setActiveFilters={setActiveFilters} />
              </Stack>

              <Suspense fallback={<Spinner centered />}>
                <DataExplorerTabs />
              </Suspense>
            </FiltersContext.Provider>
          </Stack>
        </>
      )}
    </>
  );
};

export default DataExplorer;
