import styled from 'styled-components';
import { breakpoints } from '../themes';

const Grid = styled.div<{ colsLg?: number }>`
  gap: 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
  list-style: none;
  padding: 0;
  margin: 0;
  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(
      ${({ colsLg }) => (colsLg ? colsLg : 'auto-fit')},
      minmax(0, 1fr)
    );
    gap: 24px;
  }
`;
export default Grid;
