import ImportIcon from '@iconscout/react-unicons/icons/uil-import';
import { ActionIcon, Group, Tooltip } from '@mantine/core';
import SavedFilters from './SavedFilters';
import useDataExplorerCSVDownload from './useDataExplorerCSVDownload';

const HeaderActions = () => {
  const saveCSV = useDataExplorerCSVDownload();

  return (
    <Group gap={8} p='none'>
      <SavedFilters />

      <Tooltip label='Download CSV' withArrow arrowSize={8} p='4px 12px'>
        <ActionIcon
          aria-label='Download CSV'
          c='black'
          data-gainsight-id='data-explorer-download-csv-button'
          onClick={saveCSV}
          size='md'
          variant='transparent'
        >
          <ImportIcon size={16} />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
};

export default HeaderActions;
