import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _, {
  camelCase,
  isEmpty,
  isNil,
  omit,
  orderBy,
  partition,
  pick,
  trim,
} from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { defaultHeaders, denaliApiBaseUrl } from '../api';
import { handleAxiosError, queryStringify } from '../api/utils';
import { TSCircuitsMetaResponse } from '../ducks/circuit/circuitMeta';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../ducks/customers';
import { ResourceType } from '../ducks/types';
import { filterDemoUsageMetaData } from '../utils/demo';

export type MetadataParams = {
  resourceType: ResourceType;
  siteIds?: string[];
  customerId: string;
  buildingSystem?: string[];
  equipment?: string[];
  panel?: string[];
};

const fetchResourceMetadata = async (queryParams: MetadataParams) => {
  const { siteIds, customerId, resourceType, ...rest } = queryParams;
  const filteredQuery = _({ ...rest, site: siteIds })
    .omit('siteIds')
    .omitBy(isNil)
    .omitBy(isEmpty)
    .valueOf();
  const query = queryStringify(filteredQuery);
  const url = `${denaliApiBaseUrl()}/customers/${customerId}/metadata/${resourceType}?${decodeURIComponent(
    query
  )}`;

  return axios
    .get<TSCircuitsMetaResponse>(url, { headers: defaultHeaders() })
    .then(({ data }) => data)
    .catch(handleAxiosError);
};

export const useResourceMetadata = (
  options: Omit<MetadataParams, 'customerId'>
) => {
  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );
  const filteredOptions = pick(
    options,
    'siteIds',
    'resourceType',
    'panel',
    'buildingSystem',
    'equipment'
  );
  const queryParams = { ...filteredOptions, customerId: currentCustomerId };

  return useQuery({
    queryKey: [
      'resourceMetadata',
      queryParams.resourceType,
      omit(queryParams, 'resourceType'),
    ],
    queryFn: () => fetchResourceMetadata(queryParams),
    staleTime: 1000 * 60 * 60, // Cache for 1 hr
    enabled: !!currentCustomerId,
    select: (data) => {
      filterDemoUsageMetaData(data);
      const enhancedFields = data.results.map((field) => ({
        ...field,
        id: field.id ?? camelCase(field.name),
        fieldName:
          field.fieldType === 'categoryValue'
            ? `categoryValue-${field.id}`
            : field.fieldType,
        values: orderBy(field.values, (value) =>
          trim(value.name.toLowerCase())
        ),
      }));
      const [customFields, builtInFields] = partition(enhancedFields, {
        fieldType: 'categoryValue',
      });
      return [
        ...builtInFields,
        ...orderBy(customFields, (field) => trim(field.name.toLowerCase())),
      ];
    },
  });
};

export const useCachedResourceMetadata = (
  options: Omit<MetadataParams, 'customerId'>
) => {
  const metadataQuery = useResourceMetadata(options);
  const [cachedMetadata, updateCachedMetadata] = useState(metadataQuery.data);
  // Only update metadata when the new query returns data
  useEffect(() => {
    if (metadataQuery.data?.length) {
      updateCachedMetadata(metadataQuery.data);
    }
  }, [metadataQuery.data]);

  return { ...metadataQuery, data: cachedMetadata };
};
