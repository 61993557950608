import { naturallySort } from '../../utils';
import { TSLabels } from './opportunitySeeds';

export enum TSOpportunityEntityType {
  SEED = 'seed',
  BATCH = 'batch',
}

export enum TSProjectColumn {
  CONSIDERING = 'CONSIDERING',
  EVALUATING = 'EVALUATING',
  SCOPING = 'SCOPING',
  INSTALLING = 'INSTALLING',
  PERFORMING = 'PERFORMING',
  COMPLETED = 'COMPLETED',
  ON_HOLD = 'ONHOLD', // don't add the "_" unless you want to fix the parsing in the d&d
}

export enum TSProjectStage {
  NEW = 'NEW',
  DISCOVERY = 'DISCOVERY',
  DEVELOPING_INDICATIVE_PROPOSAL = 'DEVELOPING_INDICATIVE_PROPOSAL',
  INDICATIVE_PROPOSAL_SHARED = 'INDICATIVE_PROPOSAL_SHARED',
  AWAITING_AUDIT = 'AWAITING_AUDIT',
  DEVELOPING_EXECUTABLE_PROPOSAL = 'DEVELOPING_EXECUTABLE_PROPOSAL',
  EXECUTABLE_PROPOSAL_SHARED = 'EXECUTABLE_PROPOSAL_SHARED',
  CONTRACTED = 'CONTRACTED',
  INSTALLING = 'INSTALLING',
  PERFORMANCE_MONITORING_BILLING = 'PERFORMANCE_MONITORING_BILLING',
  BOUGHT_OUT = 'BOUGHT_OUT',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  ON_HOLD = 'ON_HOLD',
}

export const projectStageMap: Record<
  TSProjectStage,
  { label: string; column: TSProjectColumn }
> = {
  [TSProjectStage.NEW]: { label: 'New', column: TSProjectColumn.CONSIDERING },
  [TSProjectStage.DISCOVERY]: {
    label: 'Discovery',
    column: TSProjectColumn.CONSIDERING,
  },
  [TSProjectStage.DEVELOPING_INDICATIVE_PROPOSAL]: {
    label: 'Developing Indicative Proposal',
    column: TSProjectColumn.EVALUATING,
  },
  [TSProjectStage.INDICATIVE_PROPOSAL_SHARED]: {
    label: 'Indicative Proposal Shared',
    column: TSProjectColumn.EVALUATING,
  },
  [TSProjectStage.AWAITING_AUDIT]: {
    label: 'Awaiting Audit',
    column: TSProjectColumn.SCOPING,
  },
  [TSProjectStage.DEVELOPING_EXECUTABLE_PROPOSAL]: {
    label: 'Developing Executable Proposal',
    column: TSProjectColumn.SCOPING,
  },
  [TSProjectStage.EXECUTABLE_PROPOSAL_SHARED]: {
    label: 'Executable Proposal Shared',
    column: TSProjectColumn.SCOPING,
  },
  [TSProjectStage.CONTRACTED]: {
    label: 'Contracted',
    column: TSProjectColumn.INSTALLING,
  },
  [TSProjectStage.INSTALLING]: {
    label: 'Installing',
    column: TSProjectColumn.INSTALLING,
  },
  [TSProjectStage.PERFORMANCE_MONITORING_BILLING]: {
    label: 'Performance Monitoring & Billing',
    column: TSProjectColumn.PERFORMING,
  },
  [TSProjectStage.BOUGHT_OUT]: {
    label: 'Bought Out',
    column: TSProjectColumn.COMPLETED,
  },
  [TSProjectStage.COMPLETED]: {
    label: 'Completed',
    column: TSProjectColumn.COMPLETED,
  },
  [TSProjectStage.CANCELED]: {
    label: 'Canceled',
    column: TSProjectColumn.COMPLETED,
  },
  [TSProjectStage.ON_HOLD]: {
    label: 'On Hold',
    column: TSProjectColumn.ON_HOLD,
  },
};

// There's probably a better way to store this info that doesn't repeat like this..
export const projectColumnMap: Record<
  TSProjectColumn,
  { label: string; stages: TSProjectStage[] }
> = {
  [TSProjectColumn.CONSIDERING]: {
    label: 'Considering',
    stages: [TSProjectStage.NEW, TSProjectStage.DISCOVERY],
  },
  [TSProjectColumn.EVALUATING]: {
    label: 'Evaluating',
    stages: [
      TSProjectStage.DEVELOPING_INDICATIVE_PROPOSAL,
      TSProjectStage.INDICATIVE_PROPOSAL_SHARED,
    ],
  },
  [TSProjectColumn.SCOPING]: {
    label: 'Scoping',
    stages: [
      TSProjectStage.AWAITING_AUDIT,
      TSProjectStage.DEVELOPING_EXECUTABLE_PROPOSAL,
      TSProjectStage.EXECUTABLE_PROPOSAL_SHARED,
    ],
  },
  [TSProjectColumn.INSTALLING]: {
    label: 'Installing',
    stages: [TSProjectStage.CONTRACTED, TSProjectStage.INSTALLING],
  },
  [TSProjectColumn.PERFORMING]: {
    label: 'Performing',
    stages: [TSProjectStage.PERFORMANCE_MONITORING_BILLING],
  },
  [TSProjectColumn.COMPLETED]: {
    label: 'Completed',
    stages: [
      TSProjectStage.BOUGHT_OUT,
      TSProjectStage.COMPLETED,
      TSProjectStage.CANCELED,
    ],
  },
  [TSProjectColumn.ON_HOLD]: {
    label: 'On Hold',
    stages: [TSProjectStage.ON_HOLD],
  },
};

export const getFirstStageForColumn = (column: TSProjectColumn) => {
  return projectColumnMap[column].stages[0];
};

// TODO: currently always grouping, let's confirm we don't need these ungrouped and remove the option
export const getProjectStageValuesForSelect = (dontGroup = false) => {
  const projectStageValues = [] as any;
  if (!dontGroup) {
    for (const key in projectColumnMap) {
      const items = [] as any;
      projectColumnMap[key].stages.forEach((stage) => {
        items.push({
          label: projectStageMap[stage].label,
          value: stage,
        });
      });

      projectStageValues.push({
        group: projectColumnMap[key].label,
        items,
      });
    }
  } else {
    for (const key in projectStageMap) {
      projectStageValues.push({
        label: projectStageMap[key].label,
        name: projectStageMap[key].label, // needed for filter, still uses ListSelector
        value: key,
        id: key,
      });
    }
  }
  return projectStageValues;
};

const getLabelOptionsForTitle = (title: string, labels: TSLabels) => {
  const options = labels
    .filter((label) => label.title == title)
    .map((label) => ({
      // this works for mantine + react-select format
      value: label.id,
      label: label.text,
    }));
  // sort alphabetically
  options.sort((a, b) => naturallySort(a.label, b.label));
  if (title === 'Estimate Precision') {
    options.reverse(); // the right order happens to be reverse alphabetical
  }
  return options;
};

export const getLabelOptionsByGroupForReactSelect = (labels: TSLabels) => {
  return [
    {
      // react-select format:
      label: 'Estimate Precision',
      options: getLabelOptionsForTitle('Estimate Precision', labels),
    },
    { label: 'Other', options: getLabelOptionsForTitle('Other', labels) },
  ];
};

export const getLabelOptionsByGroupForMantine = (labels: TSLabels) => {
  return [
    {
      // mantine format:
      group: 'Estimate Precision',
      items: getLabelOptionsForTitle('Estimate Precision', labels),
    },
    { group: 'Other', items: getLabelOptionsForTitle('Other', labels) },
  ];
};

export const ecmTypeToMlovMapping = {
  // use smallcase without spaces and hyphens to match format
  solar: 'SOLAR_ECM_SPECIFIC_FIELDS',
  lighting: 'LIGHTING_ECM_SPECIFIC_FIELDS',
  hvac: 'HVAC_ECM_SPECIFIC_FIELDS',
  lightingcontrols: 'LIGHTING_CONTROL_ECM_SPECIFIC_FIELDS',
  hvaccontrols: 'HVAC_CONTROL_ECM_SPECIFIC_FIELDS',
  bmsaas: 'BMSAAS_CONTROL_ECM_SPECIFIC_FIELDS',
};

export type TSCustomOpportunityField = {
  id: string;
  title: string;
  helperText: string;
  unit: string;
};
