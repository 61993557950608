import { Image, Title } from '@mantine/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectCustomersEntity } from '../../ducks/customers';
import r1MiniLogo from '../../logos/r1-mini-logo.svg';
import styles from './NewMainNav.module.css';

const MiniLogo = () => {
  const { items: customers, currentCustomerId } = useSelector(
    selectCustomersEntity
  );
  const currentCustomer = customers.find((c) => c.id === currentCustomerId);
  const filenameWithExt = currentCustomer?.customerLogoUrl?.split('/').pop();
  const filename = filenameWithExt?.split('.').shift();
  const companyName = filename?.split('-')?.pop();
  const companyInitials =
    currentCustomer?.initials ||
    companyName
      ?.split('_')
      ?.slice(0, 2)
      ?.map((n) => n[0])
      ?.join('');
  return (
    <Link
      to='/'
      className={styles.miniLogo}
      data-gainsight-id='primary-nav-redaptive-logo'
    >
      {!companyInitials ? (
        <Image src={r1MiniLogo} h={28} w={28} alt='Redaptive ONE Logo' />
      ) : (
        <Title
          bg={currentCustomer?.logoBackgroundColor || '#3e5064'}
          className={styles.companyInitials}
        >
          {companyInitials}
        </Title>
      )}
    </Link>
  );
};

export default MiniLogo;
