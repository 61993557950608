import {
  AlignValue,
  Axis,
  Chart,
  Options,
  OptionsChartZoomingTypeValue,
} from 'highcharts';
const leftArrowUrl = '/leftArrowImg.png';
const rightArrowUrl = '/rightArrowImg.png';

let rightArrow: any;
let leftArrow: any;
let originalWidth: number;
interface CustomYAxis extends Axis {
  height: number;
  top: number;
}

const fonts = '"Public Sans", sans-serif';
export const COLOR_PLOT_BAND = '#efefef';

const createMoveRight = ({ dataMax, max, min, chart }) => {
  const distanceToDataMax = dataMax - max;
  const adjustment = Math.min(distanceToDataMax, 5);
  const newMax = max + adjustment;
  const newMin = min + adjustment;

  return () => {
    chart.xAxis[0].setExtremes(newMin, newMax);
  };
};

export const getScrollingColumnEvents = () => {
  return {
    load(): any {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const chart = this as unknown as Chart;
      const yAxis = chart.yAxis[0] as CustomYAxis;
      // eslint-disable-next-line prefer-const
      let { min, max, dataMax } = chart.xAxis[0].getExtremes();

      const yPosition = yAxis?.top + yAxis?.height / 2;

      const moveRight = createMoveRight({ min, max, dataMax, chart });

      leftArrow = chart.renderer
        .image(leftArrowUrl, 0, yPosition, 30, 30)
        .attr({
          zIndex: 10,
          id: 'leftArrow',
          cursor: 'not-allowed',
          opacity: 0.5,
        });

      rightArrow = chart.renderer
        .image(rightArrowUrl, chart?.chartWidth - 30, yPosition, 30, 30)
        .attr({ zIndex: 10, id: 'rightArrow', cursor: 'pointer' });

      leftArrow.add();
      rightArrow.on('click', moveRight).add();

      originalWidth = chart.chartWidth;
    },
    redraw(): any {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const chart = this as unknown as Chart;
      const yAxis = chart.yAxis[0] as CustomYAxis;
      const offset = chart.chartWidth - originalWidth;
      // eslint-disable-next-line prefer-const
      const { min, max, dataMin, dataMax } = chart.xAxis[0].getExtremes();

      if (offset) {
        rightArrow?.translate(offset, 0);
      }
      const yPosition = yAxis?.top + yAxis?.height / 2;

      const moveLeft = () => {
        const distanceToDataMin = min - dataMin;
        const adjustment = Math.min(distanceToDataMin, 5);
        const newMax = max - adjustment;
        const newMin = min - adjustment;
        chart.xAxis[0].setExtremes(newMin, newMax);
      };

      const moveRight = createMoveRight({ min, max, dataMax, chart });

      const canMoveRight = max !== dataMax;

      const cursorRight = canMoveRight ? 'pointer' : 'not-allowed';
      const opacityRight = canMoveRight ? 1 : 0.5;

      rightArrow?.destroy();
      rightArrow = chart.renderer
        .image(rightArrowUrl, chart?.chartWidth - 30, yPosition, 30, 30)
        .attr({
          zIndex: 10,
          id: 'rightArrow',
          cursor: cursorRight,
          opacity: opacityRight,
        });

      canMoveRight ? rightArrow.on('click', moveRight).add() : rightArrow.add();

      const canMoveLeft = min > 0;
      const cursorLeft = canMoveLeft ? 'pointer' : 'not-allowed';
      const opacityLeft = canMoveLeft ? 1 : 0.5;

      leftArrow?.destroy();
      leftArrow = chart.renderer
        .image(leftArrowUrl, 0, yPosition, 30, 30)
        .attr({
          zIndex: 10,
          id: 'leftArrow',
          cursor: cursorLeft,
          opacity: opacityLeft,
        });

      canMoveLeft ? leftArrow.on('click', moveLeft).add() : leftArrow.add();
    },
  };
};

export const config: Options = {
  accessibility: {
    enabled: false,
  },
  chart: {
    height: 480,
    style: { fontFamily: fonts },
    zooming: {
      type: 'xy' as OptionsChartZoomingTypeValue,
    },
    panning: {
      enabled: true,
      type: 'xy',
    },
    panKey: 'shift',
    events: {
      load(): any {},
    },
  },
  boost: {
    enabled: false,
  },
  credits: { enabled: false },
  title: {
    text: '',
    align: 'left' as Highcharts.AlignValue,
    x: -10,
    y: 6,
    style: {
      fontFamily: '"Public Sans", sans-serif',
      fontSize: '14px',
      fontWeight: '600',
      color: '#162447',
    },
  },
  xAxis: {
    tickLength: 5,
    crosshair: true,
    maxPadding: 0,
    minPadding: 0,
    labels: {
      style: {
        fontFamily: fonts,
        fontSize: '12px',
      },
    },
  },
  yAxis: {
    title: { text: '' },
    labels: {
      style: {
        fontFamily: fonts,
        fontSize: '12px',
      },
    },
  },
  tooltip: {
    shared: true,
    useHTML: true,
    backgroundColor: '#ffffff',
    style: {
      fontFamily: fonts,
    },
  },
  legend: {
    align: 'left' as AlignValue,
    squareSymbol: false,
    symbolHeight: 14,
    symbolWidth: 4,
    symbolRadius: 2,
    margin: 20,
    itemStyle: {
      fontFamily: fonts,
      fontSize: '12px',
      fontWeight: '400',
      color: '#444854',
    },
    itemHiddenStyle: {
      fontFamily: fonts,
      fontSize: '12px',
      fontWeight: '400',
      color: '#444854',
    },
    itemHoverStyle: {
      fontFamily: fonts,
      color: '#444854',
    },
    itemDistance: 40,
  },
  noData: {
    useHTML: false,
    style: {
      fontFamily: fonts,
      fontSize: '13px',
      fontWeight: '500',
      color: '#464a54',
      lineHeight: '1.38',
      userSelect: 'none',
    },
    position: {
      align: 'center',
      verticalAlign: 'middle',
      y: 0,
      x: 0,
    },
  },
  exporting: {
    enabled: true,
    accessibility: {
      enabled: true,
    },
    type: 'image/png',
    buttons: {
      contextButton: {
        menuItems: ['downloadPNG'],
        y: -10,
      },
    },
  },
  plotOptions: {
    column: {
      animation: {
        duration: 0,
      },
      borderWidth: 0,
      groupPadding: 0,
      negativeColor: 'rgb(240,128,0)',
      pointPadding: -0.5,
      shadow: false,
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    line: {
      animation: false,
      events: {
        legendItemClick(): any {
          return false;
        },
      },
    },
    area: {
      animation: false,
      events: {
        legendItemClick(): any {
          return false;
        },
      },
    },
  },
  lang: {
    noData: 'Data Not Yet Available',
    thousandsSep: ',',
  },
};
