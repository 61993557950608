import styled from 'styled-components';
import Title from './Title';
import CloseButtonComponent from './mantine/CloseButton';
import { zIndices } from '../utils';

interface TSProps {
  children: React.ReactNode;
  width?: string;
  handleOnClose?: () => void; // for the "x"
  title?: string; // so can inline with the "x"
}

const PopupWrapperStyled = styled.div`
  align-items: center;
  backdrop-filter: blur(2px);
  background: rgba(200, 200, 200, 0.5);
  border-radius: 0.5rem;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${zIndices.modalPopup};

  * {
    box-sizing: border-box;
  }
`;

const PopupStyled = styled.div<{
  width?: string;
}>`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '550px'};
`;

export const PopupTitleStyled = styled(Title)`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
  padding-bottom: 5px;
`;

const PopupBodyStyled = styled.div`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
  padding: 0 16px 16px 16px;
`;

export const PopupFooter = styled.div`
  margin-top: 16px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button {
    margin-left: 10px;
  }
`;

const HeaderStyled = styled.div`
  display: flex;
`;

const HeaderTitleStyled = styled.div`
  padding-left: 16px;
  padding-top: 12px;
  flex-grow: 2;
`;

const HeaderCloseButtonStyled = styled.div`
  margin-left: auto;
`;

const CloseButtonComponentStyled = styled(CloseButtonComponent)`
  margin: 5px;
`;

const Popup = ({ children, width, title, handleOnClose }: TSProps) => {
  return (
    <PopupWrapperStyled>
      <PopupStyled width={width}>
        <HeaderStyled>
          {title && (
            <HeaderTitleStyled>
              <PopupTitleStyled>{title}</PopupTitleStyled>
            </HeaderTitleStyled>
          )}
          {handleOnClose && (
            <HeaderCloseButtonStyled>
              <CloseButtonComponentStyled
                variant='filled'
                c='blue.5'
                title='Close modal'
                size='lg'
                onClick={handleOnClose}
              />
            </HeaderCloseButtonStyled>
          )}
        </HeaderStyled>
        <PopupBodyStyled>{children}</PopupBodyStyled>
      </PopupStyled>
    </PopupWrapperStyled>
  );
};

export default Popup;
