/* eslint-disable @typescript-eslint/no-unused-vars */
import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleSagaError } from '../../api/utils';
import { filterDemoUsageMetaData } from '../../utils/demo';
import { ResourceType, TSMetaState } from '../types';
import {
  API,
  TSGetResourceMetaData,
  TSResourceMetaItemResponse,
  TSResourceMetaResponse,
  enhanceGroupItem,
} from './electricityMetaData';

export interface TSWaterMetaData extends TSResourceMetaItemResponse {
  id: string;
}

interface TSWaterMetaDataState extends TSMetaState {
  noWaterMetaData: boolean;
}

export interface TSWaterMetaEntityState {
  items: Array<TSWaterMetaData>;
  meta: TSWaterMetaDataState;
}

interface TSState {
  entities: {
    waterMetaData: TSWaterMetaEntityState;
  };
}

export const types = {
  FETCH_WATER_META_DATA: 'FETCH_WATER_META_DATA',
  FETCH_WATER_META_DATA_SUCCESS: 'FETCH_WATER_META_DATA_SUCCESS',
  FETCH_WATER_META_DATA_ERROR: 'FETCH_WATER_META_DATA_ERROR',
  RESET_WATER_META_DATA: 'RESET_WATER_META_DATA',
};

export const actions = {
  fetchWaterMetaData: (payload: TSGetResourceMetaData): any => ({
    type: types.FETCH_WATER_META_DATA,
    ...payload,
  }),
  resetWaterMetaData: () => ({
    type: types.RESET_WATER_META_DATA,
  }),
};

export const initialState: TSWaterMetaEntityState = {
  items: [],
  meta: {
    loading: true,
    error: '',
    noWaterMetaData: false,
  },
};

function entityItems(action, state) {
  const newItems: Array<TSWaterMetaData> = Object.values(action.payload);
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems);
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_WATER_META_DATA:
    case types.RESET_WATER_META_DATA:
      return initialState.items;
    case types.FETCH_WATER_META_DATA_SUCCESS:
      return entityItems(action, state);
    default:
      return state;
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_WATER_META_DATA:
      return {
        ...state,
        error: '',
        loading: true,
        noWaterMetaData: false,
      };
    case types.FETCH_WATER_META_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_WATER_META_DATA_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noWaterMetaData: !action.payload[0],
      };
    default:
      return state;
  }
}

export default combineReducers({
  items,
  meta,
});

export const selectWaterMetaDataEntity = (
  state: TSState
): TSWaterMetaEntityState => state.entities.waterMetaData;

function* fetchWaterMetaDataSaga({
  type,
  ...payload
}: TSGetResourceMetaData & { type: string }): Generator<any, void, any> {
  try {
    const waterMetaData: TSResourceMetaResponse = yield call(
      API.fetchResourceMetaData,
      { ...payload, resourceType: ResourceType.WATER }
    );
    filterDemoUsageMetaData(waterMetaData);

    yield put({
      type: types.FETCH_WATER_META_DATA_SUCCESS,
      payload: waterMetaData?.results.map((groupItem) =>
        enhanceGroupItem(groupItem)
      ),
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_WATER_META_DATA_ERROR, e as Error);
  }
}

export const sagas = [
  takeLatest(types.FETCH_WATER_META_DATA, fetchWaterMetaDataSaga),
];
