import EstateIcon from '@iconscout/react-unicons/icons/uil-estate';
import { Divider, ScrollArea, Stack } from '@mantine/core';
import { omit } from 'lodash';
import { MenuItem } from '.';
import MiniLogo from './MiniLogo';
import NavButton from './NavButton';
import NavButtonWithPopoverMenu from './NavButtonWithPopoverMenu';

type NavProps = {
  navContent: MenuItem[];
};

const CollapsedNavContent = ({ navContent }: NavProps) => {
  return (
    <>
      <Stack h='calc(100vh - 68px)' gap='0'>
        <Stack p='lg' pb='sm' gap='1.5rem'>
          <MiniLogo />
          <NavButton
            icon={<EstateIcon size={18} />}
            to='/'
            label='Home'
            gainsightId='primary-nav-home'
          />
        </Stack>

        <ScrollArea type='hover' scrollbars='y'>
          <Stack p='lg' pt='sm' pb='0' gap='0'>
            <Stack gap='xs'>
              {navContent.map((item) => {
                if (item.component === 'navLink') {
                  if (item.children) {
                    return (
                      <NavButtonWithPopoverMenu key={item.label} {...item} />
                    );
                  } else {
                    return (
                      <NavButton
                        key={item.label}
                        {...omit(item, 'component', 'children')}
                      />
                    );
                  }
                }
                if (item.component === 'divider') {
                  return <Divider key={item.label} color='#E9E9E9' my='md' />;
                }
              })}
            </Stack>
          </Stack>
        </ScrollArea>
      </Stack>
    </>
  );
};

export default CollapsedNavContent;
