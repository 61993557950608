import { Tabs } from '@mantine/core';
import { useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { queryStringify } from '../../api/utils';
import ConsumptionAnalysisPage from './ConsumptionAnalysisPage';
import OperatingHoursPage from './OperatingHoursPage';
import PeakDemandPage from './PeakDemandPage';
import classes from './styles.module.css';
import VoltageAnalysisPage from './VoltageAnalysisPage';

const DataExplorerTabs = () => {
  const navigate = useNavigate();
  const { pageId } = useParams();
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries(searchParams);

  const navigateToTab = useCallback(
    (value) =>
      navigate(`/data-explorer/${value}?${queryStringify(queryParams)}`),
    [navigate, queryParams]
  );

  return (
    <Tabs
      classNames={{
        list: classes.tabList,
      }}
      keepMounted={false}
      value={pageId}
      onChange={navigateToTab}
    >
      <Tabs.List>
        <Tabs.Tab
          data-gainsight-id='tab-nav-consumption-analysis'
          value='consumption-analysis'
        >
          Consumption Analysis
        </Tabs.Tab>
        <Tabs.Tab
          data-gainsight-id='tab-nav-operating-hours'
          value='operating-hours'
        >
          Operating Hours
        </Tabs.Tab>
        <Tabs.Tab data-gainsight-id='tab-nav-peak-demand' value='peak-demand'>
          Peak Demand
        </Tabs.Tab>
        <Tabs.Tab
          data-gainsight-id='tab-nav-voltage-analysis'
          value='voltage-analysis'
        >
          Voltage Analysis
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value='consumption-analysis'>
        <ConsumptionAnalysisPage />
      </Tabs.Panel>
      <Tabs.Panel value='operating-hours'>
        <OperatingHoursPage />
      </Tabs.Panel>
      <Tabs.Panel value='peak-demand'>
        <PeakDemandPage />
      </Tabs.Panel>
      <Tabs.Panel value='voltage-analysis'>
        <VoltageAnalysisPage />
      </Tabs.Panel>
    </Tabs>
  );
};

export default DataExplorerTabs;
