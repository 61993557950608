import _, { isEmpty, isNil, sortBy, toLower } from 'lodash';

// Collection
export const sortByCaseInsensitive = <T>(arr, prop) =>
  sortBy<T>(arr, (i) => toLower(i[prop]));

export const updateById = <T>(
  previous: (T & { id: string })[],
  id: string,
  update: T
): T[] =>
  previous.map((item) => (item.id === id ? { ...item, ...update } : item));

export const rejectNilEmpty = <T>(arr: (T | undefined | null)[]) =>
  _(arr).reject(isNil).reject(isEmpty).value() as T[];

// Object
export const omitNilEmpty = (obj: Record<string, any>) =>
  _(obj).omitBy(isNil).omitBy(isEmpty).value();

// Member
export function memberWithMax(members, key) {
  if (!members || members.length === 0) {
    return null;
  }
  return members.reduce((prev, current) => {
    return prev && prev[key] > current[key] ? prev : current;
  });
}
