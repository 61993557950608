import { ResourceNameParams, Resources } from './types';
import { useUnit } from './Unit';

const ResourceName = ({ resourceType }: ResourceNameParams) => {
  const resourceName = useResourceName();

  return resourceName(resourceType);
};

export const useResourceName = () => {
  const unit = useUnit();
  return (resourceType: Resources | 'naturalGas') => {
    switch (resourceType) {
      case 'electric':
        return `Electric (${unit('electric')})`;
      case 'water':
        return `Water (${unit('water')})`;
      case 'gas':
      case 'naturalGas':
        return `Gas (${unit('gas')})`;
    }
  };
};

export const useResourceNames = (): Record<Resources, string> => {
  const resourceName = useResourceName();
  return {
    electric: resourceName('electric'),
    water: resourceName('water'),
    gas: resourceName('gas'),
  };
};

export default ResourceName;
