import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _, { isEmpty, isNil, map, max, min, sum } from 'lodash';
import moment from 'moment';

import { defaultHeaders, denaliApiBaseUrl } from '../api';
import { getDateRange, getRequestRange, queryStringify } from '../api/utils';
import { DATE_FORMAT_DATA_API_REQUEST } from '../constants';
import { Grouping, MeasurementTypes } from '../ducks/types';
import { voltageResponse } from '../mockData/singleSiteAnalysis';
import { DataExplorerFilterParams } from '../pages/DataExplorer/types';
import { isVariantActive } from '../utils/variants';
import { UsageDataResponse } from './resourceUsage';
import {
  enhanceAxiosError,
  EnhancedAxiosError,
} from './utils/enhanceAxiosError';

function fetchSiteVoltageData(params: DataExplorerFilterParams) {
  const { siteIds, fromDate, toDate, period } = params;
  const { startDate, endDate } = getDateRange(fromDate, toDate, period);

  const dateRange = getRequestRange(
    moment(startDate, DATE_FORMAT_DATA_API_REQUEST),
    moment(endDate, DATE_FORMAT_DATA_API_REQUEST)
  );

  const customCategories = map(params, (v, k) => {
    if (k.startsWith('categoryValue-')) {
      return (v as string[]).map((value) => value);
    }
  })
    .flat()
    .filter(Boolean)
    .join(',');

  const query = {
    ...params,
    ...dateRange,
    categoryValue: customCategories ? customCategories : undefined,
    measurementTypes: MeasurementTypes.RMS_VOLTAGE,
  };
  if (query.grouping && !Object.values(Grouping).includes(query.grouping)) {
    query.groupingId = query.grouping;
    query.grouping = Grouping.CATEGORY_VALUE;
  }
  const filteredQuery = _(query)
    .omitBy(isNil)
    .omitBy(isEmpty)
    .omitBy((_, k) => k.startsWith('categoryValue-'))
    .omit('selectedSiteIds', 'siteIds', 'period', 'options')
    .value();

  if (isVariantActive('mock')) {
    const usageMockData = voltageResponse; // make dynamic w/ dates?
    return Promise.resolve(usageMockData).then(
      (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
    );
  }

  const url = `${denaliApiBaseUrl()}/sites/${
    siteIds[0]
  }/data/electricity?${decodeURIComponent(queryStringify(filteredQuery))}`;

  return axios
    .get<UsageDataResponse>(url, { headers: defaultHeaders() })
    .then(({ data }: { data }) => data)
    .catch(enhanceAxiosError);
}

export const useSiteVoltageData = (
  params: DataExplorerFilterParams,
  options?: { enabled?: boolean }
) => {
  const { enabled = true } = options ?? {};
  return useQuery<ReturnType<typeof useSiteVoltageData>, EnhancedAxiosError>({
    queryKey: ['siteVoltage', { params }],
    queryFn: () => fetchSiteVoltageData(params),
    enabled: !!params.siteIds.length && enabled,
    staleTime: 1000 * 60 * 60, // 1 hour
    select: ({ data, ts }) => ({
      groups: data.map((group) => ({
        ...group,
        min: min(group.rmsVoltage) ?? 0,
        max: max(group.rmsVoltage) ?? 0,
        average: group.rmsVoltage
          ? sum(group.rmsVoltage) / group.rmsVoltage?.length
          : 0,
      })),
      ts,
    }),
  });
};
