import styled, { useTheme } from 'styled-components';
import ErrorMessage from './ErrorMessage';

export const InputWrapperStyled = styled.div`
  position: relative;
`;

const InputStyled = styled.input<{
  alwaysDraw?: boolean;
  expanded: boolean;
  error?: string;
  leftIcon?: React.ReactNode;
}>`
  background-color: ${({ alwaysDraw }) =>
    alwaysDraw ? 'white' : 'transparent'};
  border-bottom: ${({ expanded }) => expanded && '0'};
  border-radius: ${({ expanded }) => (expanded ? '3px 3px 0 0' : '3px')};
  border: ${({ alwaysDraw, error }) =>
    (alwaysDraw && '1px solid #c7c7c7') ||
    (error && `1px solid ${({ theme }) => theme.colors.error}`) ||
    '1px solid transparent'};
  box-shadow: unset;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.fontMain};
  font-size: 14px;
  height: 36px;
  line-height: 19px;
  padding-left: ${({ leftIcon }) => (leftIcon ? '35px' : '11px')};
  text-overflow: ellipsis;

  &:focus,
  &:hover {
    background-color: white;
    outline: none;
    border: 1px solid #c7c7c7;
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.focused};
  }
`;

const MarkerStyled = styled.div<{
  edited: boolean;
  hideOnHover: boolean;
  right: string;
  left: string;
}>`
  display: ${({ edited }) => (edited ? 'inline-block' : 'none')};
  ${InputStyled}:focus + &,
  ${InputStyled}:hover + & {
    display: ${({ hideOnHover }) => hideOnHover && 'none'};
  }
  background-color: ${({ color }) => color};
  border-radius: 10px;
  height: 6px;
  left: ${({ left }) => left};
  position: absolute;
  right: ${({ right }) => right};
  top: 15px;
  width: 6px;
`;

export const InputErrorMessageStyled = styled(ErrorMessage)`
  display: inline-block;
  position: absolute;
  left: 330px;
  top: -11px;

  &:before {
    content: '* ';
  }
`;

export interface TSInputProps {
  alwaysDraw?: boolean;
  className?: string;
  editDotColor?: string;
  editDotLeft?: string;
  editDotRight?: string;
  edited?: boolean;
  error?: string;
  expanded?: boolean;
  field?: any; // To support Formik field object
  form?: any; // To support Formik form object
  hideEditDotOnHover?: boolean;
  input?: any; // To support ReduxForm input props object
  inputRef?: HTMLInputElement | null;
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  id?: string;
  value?: string | number;
  leftIcon?: React.ReactNode;
  autoFocus?: boolean;
  defaultValue?: string;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
}

const Input = ({
  alwaysDraw = true,
  className = '',
  editDotColor: editDotColorProp = '',
  editDotLeft = '',
  editDotRight = '',
  edited = false,
  error = '',
  field = {},
  hideEditDotOnHover = true,
  input = {},
  inputRef = null,
  placeholder = '',
  leftIcon,
  ...rest
}: TSInputProps) => {
  const theme = useTheme();
  const editDotColor = editDotColorProp || theme.colors.error;
  return (
    <InputWrapperStyled className={className}>
      {leftIcon && leftIcon}
      <InputStyled
        {...rest}
        {...input}
        {...field}
        alwaysDraw={alwaysDraw}
        className={className}
        ref={inputRef}
        placeholder={placeholder}
        leftIcon={leftIcon}
      />
      <MarkerStyled
        edited={edited}
        color={editDotColor}
        left={editDotLeft}
        right={editDotRight}
        hideOnHover={hideEditDotOnHover}
      />
      {error && <InputErrorMessageStyled message={error} />}
    </InputWrapperStyled>
  );
};

export default Input;
