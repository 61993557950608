import axios from 'axios';
import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiBaseUrl, defaultHeaders } from '../../api';
import {
  getRequestMonthRange,
  handleAxiosError,
  handleSagaError,
  queryStringify,
} from '../../api/utils';
import { TSState } from '../../reducers/rootReducer';
import { TSMetaState } from '../types';

export const paramToDisplay = (system: string): any => {
  const name = system?.replace('+', ' ');
  return name === 'hvac' || name === 'HVAC' ? 'HVAC' : name;
};

export type TSSavingsPerformanceSummary = {
  costSavingsAvg: TSCostSavings;
  costSavingsTotal: TSCostSavings;
  energySavingsAvg: TSEnergySavings;
  energySavingsTotal: TSEnergySavings;
  utilityBillSavingsAvg: TSUtilityBillSavings;
  utilityBillSavingsTotal: TSUtilityBillSavings;
};

type TSCostSavings = {
  netCostSavingPercentage: number;
  netCostSavings: number;
  redaptiveInvoice: number;
  utilityBillSavings: number;
};

type TSEnergySavings = {
  actualKwh: number;
  baselineKwh: number;
  netSavingsKwh: number;
  percentComparedToBaselineKwh: number;
};

type TSUtilityBillSavings = {
  actualCost: number;
  baselineCost: number;
  utilityBillSavingPercentage: number;
};

export type TSSavingsCustomerPerformanceAction = {
  customerId: string;
  fromDate: string;
  toDate: string;
};

export type TSFetchSavingsSitePerformanceAction = {
  siteId: string;
  fromDate: string;
  toDate: string;
};

type TSSavingsMeta = {
  error: string;
  loading: boolean;
  noDataAvailable: boolean;
};

type TSSavingsPerformanceEntity = {
  performanceBySiteId: {
    [id: string]: TSSavingsPerformanceSummary;
  };
  summary: TSSavingsPerformanceSummary;
};

interface TSSavingsMetaState extends TSMetaState {
  noDataAvailable: boolean;
}

export interface TSSavingsPerformanceEntityState {
  data: TSSavingsPerformanceEntity;
  meta: TSSavingsMeta;
}

const types = {
  FETCH_SAVINGS_CUSTOMER_PERFORMANCE: 'FETCH_SAVINGS_CUSTOMER_PERFORMANCE',
  FETCH_SAVINGS_CUSTOMER_PERFORMANCE_SUCCESS:
    'FETCH_SAVINGS_CUSTOMER_PERFORMANCE_SUCCESS',
  FETCH_SAVINGS_CUSTOMER_PERFORMANCE_ERROR:
    'FETCH_SAVINGS_CUSTOMER_PERFORMANCE_ERROR',
  FETCH_SAVINGS_SITE_PERFORMANCE: 'FETCH_SAVINGS_SITE_PERFORMANCE',
  FETCH_SAVINGS_SITE_PERFORMANCE_SUCCESS:
    'FETCH_SAVINGS_SITE_PERFORMANCE_SUCCESS',
  FETCH_SAVINGS_SITE_PERFORMANCE_ERROR: 'FETCH_SAVINGS_SITE_PERFORMANCE_ERROR',
};

export const actions = {
  fetchSavingsCustomerPerformance: (
    request: TSSavingsCustomerPerformanceAction
  ) => ({
    type: types.FETCH_SAVINGS_CUSTOMER_PERFORMANCE,
    request,
  }),

  fetchSavingsSitePerformance: (
    request: TSFetchSavingsSitePerformanceAction
  ) => ({
    type: types.FETCH_SAVINGS_SITE_PERFORMANCE,
    request,
  }),
};

const initialStateMeta: TSSavingsMeta = {
  error: '',
  loading: false,
  noDataAvailable: false,
};

const initialStateCostSavings: TSCostSavings = {
  netCostSavingPercentage: 0,
  netCostSavings: 0,
  redaptiveInvoice: 0,
  utilityBillSavings: 0,
};
const initialStateEnergySavings: TSEnergySavings = {
  actualKwh: 0,
  baselineKwh: 0,
  netSavingsKwh: 0,
  percentComparedToBaselineKwh: 0,
};
const initialStateUtilitySavings: TSUtilityBillSavings = {
  actualCost: 0,
  baselineCost: 0,
  utilityBillSavingPercentage: 0,
};

const initialStateSavingsSummary: TSSavingsPerformanceSummary = {
  costSavingsAvg: initialStateCostSavings,
  costSavingsTotal: initialStateCostSavings,
  energySavingsAvg: initialStateEnergySavings,
  energySavingsTotal: initialStateEnergySavings,
  utilityBillSavingsAvg: initialStateUtilitySavings,
  utilityBillSavingsTotal: initialStateUtilitySavings,
};

export const initialStatePerformanceEntity: TSSavingsPerformanceEntity = {
  performanceBySiteId: {},
  summary: initialStateSavingsSummary,
};

export const initialState: TSSavingsPerformanceEntityState = {
  data: initialStatePerformanceEntity,
  meta: initialStateMeta,
};

function data(state = initialState.data, action): TSSavingsPerformanceEntity {
  switch (action.type) {
    case types.FETCH_SAVINGS_CUSTOMER_PERFORMANCE:
    case types.FETCH_SAVINGS_SITE_PERFORMANCE:
      return initialState.data;
    case types.FETCH_SAVINGS_CUSTOMER_PERFORMANCE_SUCCESS:
    case types.FETCH_SAVINGS_SITE_PERFORMANCE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

function meta(state = initialState.meta, action): TSSavingsMetaState {
  switch (action.type) {
    case types.FETCH_SAVINGS_CUSTOMER_PERFORMANCE:
    case types.FETCH_SAVINGS_SITE_PERFORMANCE:
      return {
        ...state,
        error: '',
        loading: true,
        noDataAvailable: false,
      };
    case types.FETCH_SAVINGS_CUSTOMER_PERFORMANCE_ERROR:
    case types.FETCH_SAVINGS_SITE_PERFORMANCE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        noDataAvailable: false,
      };
    case types.FETCH_SAVINGS_CUSTOMER_PERFORMANCE_SUCCESS:
    case types.FETCH_SAVINGS_SITE_PERFORMANCE_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noDataAvailable: !action.payload[0],
      };
    default:
      return state;
  }
}

export default combineReducers({
  data,
  meta,
});

export const selectSavingsPerformance = (
  state: TSState
): TSSavingsPerformanceEntityState => state.entities.savingsPerformance;

const API = {
  fetchSavingsCustomer: ({
    fromDate,
    toDate,
    customerId,
  }: TSSavingsCustomerPerformanceAction) => {
    const monthRange = getRequestMonthRange(fromDate, toDate);

    const query = queryStringify({
      ...monthRange,
    });

    const url = `${apiBaseUrl()}/savings/customer/${customerId}/performance?${decodeURIComponent(
      query
    )}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSavingsPerformanceEntity }) => data)
      .catch(handleAxiosError);
  },
  fetchSavingsSite: ({
    fromDate,
    toDate,
    siteId,
  }: TSFetchSavingsSitePerformanceAction) => {
    const monthRange = getRequestMonthRange(fromDate, toDate);

    const query = queryStringify({
      ...monthRange,
    });

    const url = `${apiBaseUrl()}/savings/site/${siteId}/performance?${decodeURIComponent(
      query
    )}`;
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSSavingsPerformanceEntity }) => data)
      .catch(handleAxiosError);
  },
};

// sagas
function* fetchSavingsCustomerPerformanceSaga({
  request,
}: {
  request: TSSavingsCustomerPerformanceAction;
  type: string;
}): Generator<any, void, any> {
  try {
    const payload: TSSavingsPerformanceEntity = yield call(
      API.fetchSavingsCustomer,
      request
    );

    yield put({
      type: types.FETCH_SAVINGS_CUSTOMER_PERFORMANCE_SUCCESS,
      payload: payload,
    });
  } catch (e) {
    yield handleSagaError(
      types.FETCH_SAVINGS_CUSTOMER_PERFORMANCE_ERROR,
      e as Error
    );
  }
}

function* fetchSavingsSitePerformanceSaga({
  request,
}: {
  request: TSFetchSavingsSitePerformanceAction;
  type: string;
}): Generator<any, void, any> {
  try {
    const payload: TSSavingsPerformanceEntity = yield call(
      API.fetchSavingsSite,
      request
    );
    yield put({
      type: types.FETCH_SAVINGS_SITE_PERFORMANCE_SUCCESS,
      payload: payload,
    });
  } catch (e) {
    yield handleSagaError(
      types.FETCH_SAVINGS_SITE_PERFORMANCE_ERROR,
      e as Error
    );
  }
}

export const sagas = [
  takeLatest(
    types.FETCH_SAVINGS_CUSTOMER_PERFORMANCE,
    fetchSavingsCustomerPerformanceSaga
  ),
  takeLatest(
    types.FETCH_SAVINGS_SITE_PERFORMANCE,
    fetchSavingsSitePerformanceSaga
  ),
];
