import { Pill } from '@mantine/core';
import { isEmpty, keyBy, omit, pick, pickBy, toPairs } from 'lodash';
import { Dispatch, SetStateAction } from 'react';

import { useCachedResourceMetadata } from '../../../queries/resourceMetadata';
import { DataExplorerFilterParams } from '../types';
import { isAdvancedFilter } from './filterUtils';
import styles from './index.module.css';

type Props = {
  pendingFilters: DataExplorerFilterParams;
  setPendingFilters: Dispatch<SetStateAction<DataExplorerFilterParams>>;
};

const AdvancedFilterPills = ({ pendingFilters, setPendingFilters }: Props) => {
  const metadataQuery = useCachedResourceMetadata(pendingFilters);
  const resourceMetadataByFieldName = keyBy(metadataQuery.data, 'fieldName');

  const advancedFilters = pickBy(pendingFilters, (v, k) => isAdvancedFilter(k));

  const advancedFiltersWithNames = !isEmpty(resourceMetadataByFieldName)
    ? toPairs(advancedFilters).map((filter) => {
        const [fieldName, values] = filter as [string, string[]];
        const field = resourceMetadataByFieldName[fieldName];

        const valueNames = values.map(
          (value) => field?.values.find((v) => v.id === value)?.name
        );

        return { ...pick(field, 'fieldName', 'name'), valueNames };
      })
    : [];

  const removeFilter = (fieldName: string) => {
    setPendingFilters(
      (prev) => omit(prev, fieldName) as DataExplorerFilterParams
    );
  };

  return advancedFiltersWithNames.map(({ fieldName, name, valueNames }) => (
    <Pill
      className={styles.advancedFiltersPill}
      data-gainsight-id={`sites-query-filter-remove-advanced-filter-${fieldName}`}
      key={fieldName}
      maw={210}
      onRemove={() => removeFilter(fieldName)}
      size='md'
      withRemoveButton
    >
      {name}: {valueNames.join(', ')}
    </Pill>
  ));
};

export default AdvancedFilterPills;
