import {
  DATA_EXPLORER_PATH,
  LEGACY_BUILDING_INSIGHTS_PATH,
  LEGACY_ESG_REPORTING_PATH,
  LEGACY_OPPORTUNITIES_PATH,
  LEGACY_PORTFOLIO_CONSUMPTION_PATH,
  LEGACY_SUSTAINABILITY_BENCHMARKING_PATH,
  PORTFOLIO_CONSUMPTION_PATH,
  PROJECTS_PATH,
  SUSTAINABILITY_BENCHMARKING_PATH,
  SUSTAINABILITY_REPORTING_PATH,
} from '../constants/paths';
import useRedirect from '../hooks/useRedirect';

const useRedirectLegacyPaths = () => {
  // Sustainability
  useRedirect(
    `${LEGACY_ESG_REPORTING_PATH}/*`,
    `${SUSTAINABILITY_REPORTING_PATH}/*`
  );
  useRedirect(
    `${LEGACY_SUSTAINABILITY_BENCHMARKING_PATH}/*`,
    `${SUSTAINABILITY_BENCHMARKING_PATH}/*`
  );
  useRedirect(
    `${LEGACY_PORTFOLIO_CONSUMPTION_PATH}/*`,
    `${PORTFOLIO_CONSUMPTION_PATH}/*`
  );

  // Opportunities
  useRedirect(`${LEGACY_OPPORTUNITIES_PATH}/*`, `${PROJECTS_PATH}/*`);

  // Building Insights
  useRedirect(`${LEGACY_BUILDING_INSIGHTS_PATH}/*`, `${DATA_EXPLORER_PATH}/*`);
};

export default useRedirectLegacyPaths;
