import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { isAxiosError } from 'axios';
import './index.css';
import './init';
import App from './pages/App';
import ErrorPage from './pages/ErrorPage';
import configureStore from './store/configureStore';
import RedaptiveThemeProvider from './themes/RedaptiveThemeProvider';
import { CustomMantineProvider } from './themes/mantine';

const store = configureStore();
export type TSRootState = ReturnType<typeof store.getState>;
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const rollbarConfig = {
  accessToken: import.meta.env.VITE_APP_ROLLBAR_DENALI_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.MODE,
    source_map_enabled: true,
    server: {
      root: '/',
    },
    code_version: import.meta.env.VITE_COMMIT_HASH,
    guess_uncaught_frames: true,
  },
};
const MAX_RETRIES = 3;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404, 413];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ globally default caching to 20 seconds
      staleTime: 1000 * 20,
      retry: (failureCount, error) => {
        if (failureCount > MAX_RETRIES) {
          return false;
        }

        if (
          isAxiosError(error) &&
          HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)
        ) {
          return false;
        }

        return true;
      },
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <RedaptiveThemeProvider>
        <CustomMantineProvider>
          <RollbarProvider config={rollbarConfig}>
            <ErrorBoundary fallbackUI={ErrorPage}>
              <Notifications zIndex={1500} />
              <Provider store={store}>
                <App />
              </Provider>
            </ErrorBoundary>
          </RollbarProvider>
        </CustomMantineProvider>
      </RedaptiveThemeProvider>
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
