import moment from 'moment';
import styled from 'styled-components';
import StyledLink from '../components/StyledLink';
import docCookies from './docCookies';

/**
 * Determines if a variant is active based on the URL and cookie.
 *
 * To activate a variant, include a comma-separated list in the 'variants' URL
 * query parameter or in the 'variants' cookie.
 *
 * @param {string} variantId
 *   The ID of the variant to check.
 *
 * @return boolean
 *   True if the variant is active, false otherwise.
 */

export const isVariantActive = (variantId: string): boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlVariantsRaw = urlParams.get('variants') || '';

  if (urlVariantsRaw) {
    const expireDate = moment().add(24, 'hours').toDate();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    docCookies.setItem('variants', urlVariantsRaw, expireDate, '/');
  }

  const urlVariants = urlVariantsRaw.split(',');

  if (urlVariants.includes(variantId)) {
    return true;
  }

  const cookieVariantsRaw = docCookies.getItem('variants') || '';
  const cookieVariants = cookieVariantsRaw.split(',');

  return cookieVariants.includes(variantId);
};

interface TSVariant {
  description: React.ReactNode;
  title: string;
}

type TSVariants = Map<string, TSVariant>;

const StyledDescription = styled.div`
  margin: 2px 26px;

  p {
    margin: 0;
  }
`;

const defaultVariants: TSVariants = new Map([
  [
    'mock',
    {
      title:
        'Use this variant to use mock data instead of responses from the backend.',
      description: (
        <StyledDescription>
          <p />
        </StyledDescription>
      ),
    },
  ],
]);

const workInProgressVariants: TSVariants = new Map([
  [
    'SILV-337',
    {
      title: 'Solar Performance',
      description: (
        <StyledDescription>
          <p>
            <StyledLink
              href='https://redaptiveinc.atlassian.net/browse/SILV-337'
              external
            >
              SILV-337
            </StyledLink>
          </p>
        </StyledDescription>
      ),
    },
  ],
]);

const variants: TSVariants = new Map([
  ...defaultVariants,
  ...workInProgressVariants,
]);

export default variants;
