// Env
export const QA_ENV = 'qa';
export const DEV_ENV = 'dev';
export const STAGE_ENV = 'stage';
export const PROD_ENV = 'prod';
export const LOCAL_ENV = 'local';

// Errors
export const ERR_UNAUTHORIZED = 'ERR_UNAUTHORIZED';
export const ERR_SERVER_ERROR = 'ERR_SERVER_ERROR';
export const ERR_OTHER = 'ERR_OTHER';
export const ERR_REQUEST_ERROR = 'ERR_REQUEST_ERROR';
export const ERR_FORBIDDEN = 'ERR_FORBIDDEN';
export const ERR_CONNECTIVITY = 'ERR_CONNECTIVITY';
export const ERR_REQUEST_TIMEOUT = 'ERR_REQUEST_TIMEOUT';
export const ERR_BAD_REQUEST = 'ERR_BAD_REQUEST';
export const ERR_CONTENT_TOO_LARGE = 'ERR_CONTENT_TOO_LARGE';
export const ERR_CONFLICT_ERROR = 'ERR_CONFLICT_ERROR';
export const NETWORK_ERROR = 'Network Error';

// storage
export const STORAGE_LOGIN_TOKEN = 'adminLoginToken';
export const STORAGE_USER_ID = 'userId';
export const STORAGE_USERNAME = 'username';
export const STORAGE_IMPERSONATE_CUSTOMER = 'impersonateCustomerId';
export const STORAGE_CUSTOMER_SELECTED_QUERY_ID = 'selectedQueryId';
