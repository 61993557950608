/* eslint-disable max-len */

interface TSProps {
  color?: string;
  size?: string;
}

const LightingIcon = ({ color = '#6C6D6E', size = '20', ...rest }: TSProps) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <path
      d='M19.87 8.59998C19.7955 8.42869 19.6745 8.2817 19.5207 8.17566C19.367 8.06961 19.1866 8.00875 19 7.99998H14.42L15.69 3.25998C15.7304 3.11165 15.7362 2.956 15.7067 2.80511C15.6772 2.65422 15.6133 2.51217 15.52 2.38998C15.4268 2.26896 15.3072 2.17089 15.1703 2.10329C15.0333 2.03568 14.8827 2.00034 14.73 1.99998H7.73C7.50424 1.99224 7.28251 2.06118 7.10092 2.19555C6.91934 2.32992 6.7886 2.52181 6.73 2.73998L4.05 12.74C4.00955 12.8883 4.00385 13.044 4.03333 13.1948C4.06282 13.3457 4.12669 13.4878 4.22 13.61C4.31418 13.7323 4.43543 13.8312 4.57422 13.8989C4.71302 13.9665 4.86559 14.0011 5.02 14H8.89L7.08 20.74C7.0207 20.9573 7.03652 21.1883 7.1249 21.3955C7.21329 21.6028 7.36905 21.7741 7.56696 21.8817C7.76487 21.9893 7.99332 22.027 8.2153 21.9886C8.43728 21.9501 8.63979 21.8379 8.79 21.67L19.69 9.66998C19.8198 9.52786 19.9058 9.35131 19.9377 9.16151C19.9697 8.97171 19.9461 8.77674 19.87 8.59998ZM10.08 17.28L11.15 13.28C11.1904 13.1317 11.1962 12.976 11.1667 12.8251C11.1372 12.6742 11.0733 12.5322 10.98 12.41C10.8868 12.289 10.7672 12.1909 10.6303 12.1233C10.4933 12.0557 10.3427 12.0203 10.19 12.02H6.35L8.49 3.99998H13.42L12.15 8.73998C12.1092 8.89103 12.1045 9.04955 12.1361 9.20278C12.1677 9.35601 12.2349 9.49968 12.3322 9.62223C12.4294 9.74479 12.5541 9.84281 12.6961 9.90841C12.8382 9.97402 12.9936 10.0054 13.15 9.99998H16.72L10.08 17.28Z'
      fill={color}
    />
  </svg>
);

export default LightingIcon;
