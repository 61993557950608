import { Alert } from '../../components/mantine/Alert/Alert';

const InvalidDateRangeAlert = () => (
  <Alert
    title='No data available for the selected date range. Please choose a different date range.'
    type='orange'
  />
);

export default InvalidDateRangeAlert;
