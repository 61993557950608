const ESNoDataIcon = () => {
  return (
    <svg
      width='233'
      height='189'
      viewBox='0 0 233 189'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M94.0608 188.122C146.009 188.122 188.122 146.009 188.122 94.0608C188.122 42.1124 146.009 0 94.0608 0C42.1124 0 0 42.1124 0 94.0608C0 146.009 42.1124 188.122 94.0608 188.122Z'
        fill='url(#paint0_linear_583_19942)'
      />
      <g filter='url(#filter0_d_583_19942)'>
        <path
          d='M51.4201 42.6406H220.73C222.393 42.6406 223.988 43.3013 225.164 44.4773C226.34 45.6533 227 47.2482 227 48.9113V80.2649C227 81.928 226.34 83.523 225.164 84.699C223.988 85.875 222.393 86.5357 220.73 86.5357H51.4201C49.757 86.5357 48.1621 85.875 46.9861 84.699C45.8101 83.523 45.1494 81.928 45.1494 80.2649V48.9113C45.1494 47.2482 45.8101 45.6533 46.9861 44.4773C48.1621 43.3013 49.757 42.6406 51.4201 42.6406V42.6406Z'
          fill='white'
        />
      </g>
      <path
        d='M131.686 52.6738H99.0779C96.9999 52.6738 95.3154 54.3583 95.3154 56.4363C95.3154 58.5142 96.9999 60.1987 99.0779 60.1987H131.686C133.764 60.1987 135.448 58.5142 135.448 56.4363C135.448 54.3583 133.764 52.6738 131.686 52.6738Z'
        fill='#B4DAFF'
      />
      <path
        d='M154.26 68.9775H99.0779C96.9999 68.9775 95.3154 70.662 95.3154 72.74C95.3154 74.8179 96.9999 76.5024 99.0779 76.5024H154.26C156.338 76.5024 158.023 74.8179 158.023 72.74C158.023 70.662 156.338 68.9775 154.26 68.9775Z'
        fill='#DEE9FC'
      />
      <path
        d='M85.2823 64.5874C85.2823 54.5441 77.1405 46.4023 67.0972 46.4023C57.0538 46.4023 48.9121 54.5441 48.9121 64.5874C48.9121 74.6308 57.0538 82.7725 67.0972 82.7725C77.1405 82.7725 85.2823 74.6308 85.2823 64.5874Z'
        fill='#485DA0'
      />
      <path
        d='M65.9689 70.4744C67.5257 70.4742 69.0377 69.9533 70.2643 68.9946L74.1208 72.8511L75.3612 71.6107L71.5034 67.753C72.4727 66.4998 72.9889 64.9554 72.968 63.3713C72.9471 61.7872 72.3903 60.2569 71.3883 59.0298C70.3864 57.8027 68.9983 56.9511 67.4504 56.6138C65.9025 56.2766 64.286 56.4735 62.8644 57.1726C61.4427 57.8717 60.2998 59.0316 59.6218 60.4634C58.9438 61.8952 58.7707 63.5144 59.1307 65.0572C59.4908 66.6 60.3628 67.9753 61.6046 68.959C62.8463 69.9428 64.3847 70.4769 65.9689 70.4744ZM65.9689 58.1914C67.0107 58.1914 68.0291 58.5003 68.8953 59.0791C69.7615 59.6579 70.4367 60.4805 70.8353 61.443C71.234 62.4055 71.3383 63.4646 71.1351 64.4864C70.9318 65.5082 70.4302 66.4467 69.6935 67.1834C68.9568 67.92 68.0183 68.4217 66.9965 68.625C65.9747 68.8282 64.9156 68.7239 63.9531 68.3252C62.9907 67.9265 62.168 67.2514 61.5892 66.3852C61.0104 65.519 60.7015 64.5006 60.7015 63.4588C60.7015 62.0618 61.2564 60.722 62.2443 59.7342C63.2321 58.7463 64.5719 58.1914 65.9689 58.1914Z'
        fill='white'
      />
      <g filter='url(#filter1_d_583_19942)'>
        <path
          d='M201.917 99.0771H32.6076C29.1444 99.0771 26.3369 101.885 26.3369 105.348V136.701C26.3369 140.165 29.1444 142.972 32.6076 142.972H201.917C205.38 142.972 208.188 140.165 208.188 136.701V105.348C208.188 101.885 205.38 99.0771 201.917 99.0771Z'
          fill='white'
        />
      </g>
      <path
        d='M112.873 109.11H80.2654C78.1874 109.11 76.5029 110.795 76.5029 112.873C76.5029 114.951 78.1874 116.635 80.2654 116.635H112.873C114.951 116.635 116.636 114.951 116.636 112.873C116.636 110.795 114.951 109.11 112.873 109.11Z'
        fill='#B4DAFF'
      />
      <path
        d='M135.448 125.414H80.2654C78.1874 125.414 76.5029 127.099 76.5029 129.176C76.5029 131.254 78.1874 132.939 80.2654 132.939H135.448C137.526 132.939 139.21 131.254 139.21 129.176C139.21 127.099 137.526 125.414 135.448 125.414Z'
        fill='#DEE9FC'
      />
      <path
        d='M66.4698 121.025C66.4698 110.982 58.328 102.84 48.2847 102.84C38.2413 102.84 30.0996 110.982 30.0996 121.025C30.0996 131.068 38.2413 139.21 48.2847 139.21C58.328 139.21 66.4698 131.068 66.4698 121.025Z'
        fill='#485DA0'
      />
      <path
        d='M47.1564 126.912C48.7132 126.912 50.2252 126.391 51.4518 125.432L55.3083 129.289L56.5487 128.048L52.6909 124.19C53.6602 122.937 54.1764 121.393 54.1555 119.809C54.1346 118.225 53.5778 116.694 52.5758 115.467C51.5739 114.24 50.1858 113.389 48.6379 113.051C47.09 112.714 45.4735 112.911 44.0519 113.61C42.6302 114.309 41.4873 115.469 40.8093 116.901C40.1313 118.333 39.9582 119.952 40.3182 121.495C40.6783 123.037 41.5503 124.413 42.7921 125.397C44.0338 126.38 45.5722 126.914 47.1564 126.912ZM47.1564 114.629C48.1982 114.629 49.2166 114.938 50.0828 115.517C50.949 116.095 51.6242 116.918 52.0228 117.881C52.4215 118.843 52.5258 119.902 52.3226 120.924C52.1193 121.946 51.6177 122.884 50.881 123.621C50.1443 124.358 49.2058 124.859 48.184 125.062C47.1622 125.266 46.1031 125.161 45.1406 124.763C44.1782 124.364 43.3555 123.689 42.7767 122.823C42.1979 121.956 41.889 120.938 41.889 119.896C41.889 118.499 42.4439 117.159 43.4318 116.172C44.4196 115.184 45.7594 114.629 47.1564 114.629Z'
        fill='white'
      />
      <defs>
        <filter
          id='filter0_d_583_19942'
          x='39.1494'
          y='39.6406'
          width='193.851'
          height='55.8955'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_583_19942'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_583_19942'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_d_583_19942'
          x='20.3369'
          y='96.0771'
          width='193.851'
          height='55.8955'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_583_19942'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_583_19942'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_583_19942'
          x1='94.0608'
          y1='0'
          x2='94.0608'
          y2='188.122'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E3ECFA' />
          <stop offset='1' stopColor='#DAE7FF' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ESNoDataIcon;
