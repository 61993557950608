import Highcharts, {
  AxisLabelsFormatterContextObject,
  Options,
} from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DraggablePoints from 'highcharts/modules/draggable-points';
import Exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { round } from 'lodash';
import { useRef } from 'react';

import { useQuantity } from '../../components/mantine/Quantity';
import { useFormatNumber } from '../../components/mantine/Quantity/FormatNumber';
import { Resources } from '../../components/mantine/Quantity/types';
import { EnhancedGroupData } from '../../queries/resourceUsage';
import {
  config as baseConfig,
  getScrollingColumnEvents,
} from '../../utils/chartsDefaultConfig';

Exporting(Highcharts);
NoDataToDisplay(Highcharts);
DraggablePoints(Highcharts);

interface TSProps extends HighchartsReact.Props {
  groups?: EnhancedGroupData[];
  resource: Resources;
  title?: string;
  totalUsage: number;
}

const ConsumptionBarChart = ({
  groups = [],
  resource,
  title,
  totalUsage,
  ...rest
}: TSProps) => {
  const chartRef = useRef(null);
  const yAxisTitleAlign = groups.length > 20 ? 'high' : 'middle';
  const formatNumber = useFormatNumber();
  const quantity = useQuantity();

  const seriesData = groups.map((group) => {
    const percentageTotal = totalUsage
      ? ((group?.totalUsage || 0) / totalUsage) * 100
      : 0;

    return {
      name: group?.groupName || '',
      y: round(percentageTotal, 2),
      groupUsage: group?.totalUsage ?? 0,
    };
  });

  const scrollingEvents = groups.length > 20 ? getScrollingColumnEvents() : {};

  const options: Options = {
    ...baseConfig,
    title: {
      ...baseConfig.title,
      text: title,
    },
    chart: {
      ...baseConfig.chart,
      events: {
        ...scrollingEvents,
      },
    },
    tooltip: {
      ...baseConfig.tooltip,
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormatter() {
        return (
          '<div>' +
          `<b>${formatNumber(this.y)}%</b><br/>` +
          `<b>${quantity(this['groupUsage'], resource)}</b>` +
          '</div>'
        );
      },
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      ...baseConfig.plotOptions,
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        borderRadius: 2,
        colorByPoint: true,
      },
    },
    xAxis: {
      ...baseConfig.xAxis,
      categories: seriesData.map((serie) => serie.name),
      max: groups.length > 20 ? 19 : groups.length - 1,
    },
    series: [
      {
        data: seriesData,
        type: 'column',
      },
    ],
    yAxis: {
      ...baseConfig.yAxis,
      min: 0,
      title: {
        align: yAxisTitleAlign,
        text: 'Percentage of Total',
        margin: 10,
        textAlign: 'right',
      },
      labels: {
        formatter(this: AxisLabelsFormatterContextObject) {
          return formatNumber(this.value);
        },
      },
    },
    legend: {
      ...baseConfig.legend,
      enabled: false,
    },
  };

  return (
    <>
      <HighchartsReact
        id={title}
        highcharts={Highcharts}
        options={options as unknown as Options}
        ref={chartRef}
        {...rest}
      />
    </>
  );
};

export default ConsumptionBarChart;
