declare const window: any;
interface TSAddGlobalContext {
  userAgent?: string;
  customerId?: string;
  customerName?: string;
  siteId?: string | string[];
  siteName?: string | string[];
}

type TSRemoveGlobalContext = Array<
  'userAgent' | 'customerId' | 'customerName' | 'siteId' | 'siteName'
>;

export enum TSActionTypes {
  set = 'set',
  remove = 'remove',
  setOnce = 'setOnce',
}

export const gainsightPXGlobalContext = (
  action: TSActionTypes,
  item: TSAddGlobalContext | TSRemoveGlobalContext
) => {
  window.aptrinsic(action, 'globalContext', item);
};
