import { UilAngleLeft, UilAngleRight } from '@iconscout/react-unicons';
import debounce from 'debounce';
import {
  ComponentType,
  ReactNode,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useAsyncDebounce,
  useColumnOrder,
  useExpanded,
  useFilters,
  useGlobalFilter,
  useMountedLayoutEffect,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import styled, { css, useTheme } from 'styled-components';
import { naturallySortEmptyLastCaseInsensitive } from '../../utils';
import ChevronDown from '../Icons/ChevronDown';
import ChevronUp from '../Icons/ChevronUp';
import SearchIcon from '../Icons/SearchIcon';
import Paginator from '../Paginator';

const columnMinWidthDefault = '140px';

export const tableBorder = '1px solid #e0e0e0';

export const RedaptiveReactTableStyles = styled.div`
  background-color: #e0e0e029;
  border: 1px solid #e0e0e0;
  border-radius: 7px 7px 0px 0px;
`;

export const TableTitleStyled = styled.div<{
  titleColor: string;
}>`
  color: ${({ titleColor }) => titleColor};
  padding: 35px 32px 20px;
  font-weight: 700;
`;

export const InputFilterStyled = styled.input`
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  min-height: 40px;
  min-width: 140px;
  width: 100%;
  padding: 5px 15px;
  &:focus {
    outline: none;
  }
`;

export const InputWrapperStyled = styled.div`
  margin: 0;
  position: relative;
`;

export const ReactTableTopSectionStyles = styled.div<{
  sideMarginWidth: number;
  increaseBottomMargin?: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  margin: 16px 32px;

  ${({ increaseBottomMargin }) =>
    increaseBottomMargin && 'margin-bottom: 37px;'}

  > * {
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const GlobalFilterWrapper = styled.div`
  position: relative;
  width: 230px;
  margin-top: 11px;

  svg {
    position: absolute;
    right: 16px;
    top: 7px;
  }
`;

const GlobalFilterInput = styled.input`
  font-family: ${({ theme }) => theme.fontFamily};
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  height: 36px;
  padding: 12px 16px;
  width: 100%;
  color: #6c6d6e;
`;

export const ReactTableWrapperStyles = styled.div`
  overflow: auto;
`;

export const ReactTableStyled = styled.table`
  border-collapse: collapse;
  overflow-wrap: break-word;
  width: 100%;
`;

export const TableHeadStyled = styled.thead`
  border-bottom: ${tableBorder};
`;

type TSThInnerAlignStyles = 'left' | 'right' | 'center';

const ThInnerAlignStyles = css<{
  align?: TSThInnerAlignStyles;
}>`
  display: flex;
  justify-content: ${({ align }) => align || 'left'};
`;

export const ThInnerStyled = styled.div<{
  align?: TSThInnerAlignStyles;
  columnMaxWidth: number;
  columnMinWidth: number;
}>`
  ${({ align }) => align && ThInnerAlignStyles};
  max-width: ${({ columnMaxWidth }) =>
    columnMaxWidth ? `${columnMaxWidth}px` : 'none'};
  min-width: ${({ columnMinWidth }) =>
    columnMinWidth ? `${columnMinWidth}px` : columnMinWidthDefault};

  &.align-right > div {
    justify-content: flex-end;
  }
`;

const ThSelectAllStyles = css`
  vertical-align: bottom;

  ${ThInnerStyled} {
    position: relative;
    bottom: 9px;
  }
`;

export const ThStyled = styled.th<{
  left: number;
  sideMarginWidth: number;
  locked?: boolean;
}>`
  font-size: 12px;
  font-weight: 600;
  left: ${({ left }) => `${left}px`};
  padding: 10px 15px;
  text-align: right;
  vertical-align: top;
  white-space: nowrap;

  ${(props) =>
    props.locked &&
    css`
      position: sticky;
      left: ${props.left}px;
      background-color: #f2f2f2;
      box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.1);
      z-index: 1;
    `}

  ${({ id }) => id === 'selectAll' && ThSelectAllStyles};

  &:first-child {
    padding-left: ${({ sideMarginWidth }) => sideMarginWidth}px;
  }

  &:last-child {
    padding-right: ${({ sideMarginWidth }) => sideMarginWidth}px;
    right: 0px;
  }
`;

const TableBodyTrStyled = styled.tr`
  position: relative;

  &:hover {
    td {
      background-color: #f0f2f8;
    }
  }

  &:last-child {
    border-bottom: 0;
  }
`;

const TrHighlightStyled = styled.div<{
  highlightColor?: string;
}>`
  background-color: ${({ highlightColor }) => highlightColor || 'transparent'};
  display: ${({ highlightColor }) => !highlightColor && 'none'};
  height: 100%;
  position: absolute;
  width: 8px;
`;

export const TdInnerStyled = styled.div<{
  columnMaxWidth: number;
  columnMinWidth: number;
  fontWeight: number;
  color: string;
}>`
  align-items: center;
  color: ${({ color = '#464a54' }) => color};
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  font-weight: ${({ fontWeight = 300 }) => fontWeight};
  max-width: ${({ columnMaxWidth }) =>
    columnMaxWidth ? `${columnMaxWidth}px` : 'none'};
  min-width: ${({ columnMinWidth }) =>
    columnMinWidth ? `${columnMinWidth}px` : columnMinWidthDefault};
`;

export const TdStyled = styled.td<{
  left: number;
  locked: boolean;
  sideMarginWidth: number;
}>`
  background-color: #fff;
  border-bottom: 1px solid rgb(187, 187, 190, 0.5);
  left: ${({ left }) => `${left}px`};
  position: ${({ locked }) => (locked ? 'sticky' : 'static')};
  padding: 10px 15px;

  ${(props) =>
    props.locked &&
    css`
      position: sticky;
      left: ${props.left}px;
      background-color: #fff;
      box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.1);
      z-index: 1;
    `}

  &.align-right > div {
    justify-content: flex-end;
  }

  &.gray-gradient {
    background: rgb(245, 245, 245);
  }

  &:first-child {
    padding-left: ${({ sideMarginWidth }) => sideMarginWidth}px;
  }

  &:last-child {
    padding-right: ${({ sideMarginWidth }) => sideMarginWidth}px;
  }
`;

export const HeaderStyled = styled.div`
  align-items: center;
  color: #6c6d6e;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
  letter-spacing: 0.02em;
  min-height: 30px;
`;

export const SortIconsWrapperStyled = styled.div`
  display: inline-block;
  margin: 0 6px;
  position: relative;
  top: -1px;
  width: 13px;
`;

export const SortIconsStyled = styled.div`
  line-height: 1;

  > * {
    display: block;
  }
`;

export const SortIconDesc = styled(ChevronDown)<{
  sorted?: boolean;
}>`
  top: ${({ sorted }) => (sorted ? '7px' : '0')};
  position: relative;
`;

export const SortIconAsc = styled(ChevronUp)<{
  sorted?: boolean;
}>`
  top: ${({ sorted }) => (sorted ? '-3px' : '5px')};
  position: relative;
`;

export const TableScrollArrowStyled = styled.div<{
  disabled?: boolean;
}>`
  background-color: ${({ disabled }) => (disabled ? '#fafafa' : '#f5f5f5')};
  border: 1px solid ${({ disabled }) => (disabled ? '#efefef' : '#e0e0e0')};
  border-radius: 4px;
  color: ${({ disabled }) => (disabled ? '#B5B6B6' : '#162447')};
  cursor: pointer;
  font-size: 16px;
  width: 30px;
  height: 30px;
  display: flex;

  > svg {
    display: block;
    margin: auto;
  }

  :first-child {
    margin-right: 8px;
  }
`;

const PaginatorWrapperStyled = styled.div`
  padding: 15px 15px;
`;

interface Obj {
  [key: string]: string | number | Obj;
}

interface TSTitle {
  titleText?: string | ReactNode;
  titleColor?: string;
}

interface TSProps {
  autoResetExpanded?: boolean;
  autoResetSortBy?: boolean;
  getRowId?: (...args) => string;
  columns: any[];
  data: any[];
  defaultSort?: { id: string; desc?: boolean }[];
  enablePagination?: boolean;
  filterable?: boolean;
  getRowProps?: (obj: Obj) => Obj;
  getCellProps?: (obj: any) => any;
  globalFilterable?: boolean;
  HeaderActions?: ComponentType;
  hideHeaders?: boolean;
  initialHiddenColumns?: string[];
  initialPageSize?: number;
  onSelectedRowsChange?: (...any) => any;
  onRowClick?: (...any) => any;
  onRowDoubleClick?: (...any) => any;
  selectedRows?: Obj;
  showTableScrollArrows?: boolean;
  sideMarginWidth?: number;
  TableHead?: ComponentType;
  tableInstanceRef?: { current: Obj };
  TdComponent?: React.ComponentType;
  title?: TSTitle;
  gainsightTagBaseId?: string;
}

type TSGlobalFilter = {
  globalFilter: string;
  setGlobalFilter: (inputValue: string) => { () };
};

const GlobalFilter = ({ globalFilter, setGlobalFilter }: TSGlobalFilter) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((inputValue) => {
    setGlobalFilter(inputValue || undefined);
  }, 200);

  return (
    <GlobalFilterWrapper>
      <SearchIcon size='18' color='#6C6D6E' />
      <GlobalFilterInput
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder='Search'
      />
    </GlobalFilterWrapper>
  );
};

export const DefaultColumnFilter = ({
  column: { filterValue, setFilter, Header, gainsightTagId },
}: {
  column: {
    filterValue: string;
    setFilter: (value: string | undefined) => { () };
    Header: string;
    gainsightTagId: '';
  };
}) => (
  <InputWrapperStyled>
    <InputFilterStyled
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${Header}`}
      data-gainsight-id={gainsightTagId}
    />
  </InputWrapperStyled>
);

export const ColumnSettingHidingItemStyled = styled.div`
  position: relative;

  > input {
    position: absolute;
    opacity: 0;
    right: -1px;
  }
`;

export const TableScrollStyled = styled.div`
  display: flex;
  align-items: center;
`;

const defaultSideMarginWidth = 32;

const RedaptiveReactTable7 = ({
  autoResetExpanded = true,
  autoResetSortBy = true,
  getRowId,
  columns,
  data,
  defaultSort = [],
  enablePagination = false,
  filterable = false,
  getRowProps = () => ({}),
  getCellProps = () => ({}),
  globalFilterable = true,
  HeaderActions = () => <></>,
  hideHeaders = false,
  initialHiddenColumns = [],
  initialPageSize = 10,
  onSelectedRowsChange = () => ({}),
  onRowClick,
  onRowDoubleClick,
  selectedRows = {},
  showTableScrollArrows = true,
  sideMarginWidth = defaultSideMarginWidth,
  TableHead,
  tableInstanceRef,
  TdComponent = TdStyled,
  title,
  gainsightTagBaseId = '',
}: TSProps): React.ReactElement => {
  const theme = useTheme();
  const TableWrapperRef = useRef(null);

  const [columnsHaveChanged, setColumnsHaveChanged] = useState(false);

  const tableData = useMemo(() => data, [data]);
  const { titleText = '', titleColor = theme.colors.fontMain } = title || {};

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const initialSortBy = useMemo(() => defaultSort || [], [defaultSort]);

  const defaultSortType = useCallback(
    (rowA: Obj, rowB: Obj, columnId: string, desc: boolean) =>
      naturallySortEmptyLastCaseInsensitive(
        rowA.values[columnId],
        rowB.values[columnId],
        desc
      ),
    []
  );

  const columnsEnhanced = useMemo(
    () =>
      columns.map((column) => ({
        sortType: defaultSortType,
        ...column,
      })),
    [columns, defaultSortType]
  );

  // Create a default prop getter
  const defaultPropGetter = () => ({});

  const tableInstance = useTable(
    {
      autoResetSelectedRows: false,
      autoResetSortBy,
      getRowId,
      autoResetExpanded,
      columns: columnsEnhanced,
      data: tableData,
      defaultColumn,
      getColumnProps: defaultPropGetter,
      getCellProps: defaultPropGetter,
      initialState: {
        hiddenColumns: initialHiddenColumns,
        pageSize: enablePagination ? initialPageSize : 100000,
        sortBy: initialSortBy,
        selectedRowIds: selectedRows,
      },
    },
    useColumnOrder,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const {
    allColumns,
    getTableBodyProps,
    getColumnProps,
    getTableProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    state,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = tableInstance;
  const { pageIndex, pageSize } = state;

  useEffect(() => {
    if (tableInstanceRef) {
      // eslint-disable-next-line no-param-reassign
      tableInstanceRef.current = tableInstance;
    }
  }, [tableInstance, tableInstanceRef]);

  const tableHeaderRefs = useMemo(
    () =>
      allColumns.reduce(
        (acc, { id }) => ({
          ...acc,
          [id]: createRef(),
        }),
        {}
      ),
    [allColumns]
  );

  const getColumnsLeftOffset = useCallback(() => {
    let previousLeftOffset = 0;
    return columns.reduce((acc, { id, accessor }) => {
      const uniqueIdentifier = id ?? accessor;
      const clientWidth =
        (tableHeaderRefs[uniqueIdentifier] &&
          tableHeaderRefs[uniqueIdentifier].current &&
          tableHeaderRefs[uniqueIdentifier].current.clientWidth) ||
        150;

      const leftOffset = previousLeftOffset;
      previousLeftOffset += clientWidth;
      return {
        ...acc,
        [uniqueIdentifier]: leftOffset,
      };
    }, {});
  }, [columns, tableHeaderRefs]);

  const [columnsLeftOffset, setColumnsLeftOffset] = useState(
    getColumnsLeftOffset()
  );

  const resizeEventAdded = useRef(false);

  if (!resizeEventAdded.current) {
    const resize = () => {
      setColumnsHaveChanged(true);
    };
    const handleResize = debounce(resize, 100);
    resizeEventAdded.current = true;

    window.addEventListener('resize', handleResize);
  }

  useEffect(() => {
    if (columnsHaveChanged) {
      setColumnsLeftOffset(getColumnsLeftOffset());
      setColumnsHaveChanged(false);
    }
  }, [columnsHaveChanged, getColumnsLeftOffset]);

  useEffect(() => {
    setColumnsHaveChanged(true);
  }, [tableHeaderRefs]);

  const getElementScrollXPosition = (
    element
  ): { left: number; right: number } => {
    if (!element) {
      return { left: 0, right: 0 };
    }

    const { clientWidth, scrollLeft, scrollWidth } = element;

    return {
      left: scrollLeft,
      right: Math.floor(scrollWidth - scrollLeft - clientWidth),
    };
  };

  const getTableScrollPosition = useCallback(
    () => getElementScrollXPosition(TableWrapperRef.current),
    [TableWrapperRef]
  );

  const [tableScrollPosition, setTableScrollPosition] = useState(
    getTableScrollPosition()
  );

  const tableScroll = useCallback(() => {
    setTableScrollPosition(getTableScrollPosition());
  }, [getTableScrollPosition]);

  const handleTableScroll = useMemo(
    () => debounce(tableScroll, 100),
    [tableScroll]
  );

  const isElementWidthOverflowing = ({
    clientWidth,
    scrollWidth,
  }: HTMLElement): boolean => scrollWidth > clientWidth;

  const getTableWidthIsOverflowing = useCallback(
    () =>
      TableWrapperRef.current &&
      isElementWidthOverflowing(TableWrapperRef.current),
    [TableWrapperRef]
  );

  const [tableWidthIsOverflowing, setTableWidthIsOverflowing] = useState(
    getTableWidthIsOverflowing()
  );

  useEffect(() => {
    if (TableWrapperRef.current) {
      setTableScrollPosition(getTableScrollPosition());
    }
  }, [TableWrapperRef, getTableScrollPosition]);

  useEffect(() => {
    setTableWidthIsOverflowing(getTableWidthIsOverflowing());
  }, [getTableWidthIsOverflowing]);

  // Keep parent/store state in sync with local state
  // No need to update on mount since we are passing initial state
  useMountedLayoutEffect(() => {
    const { selectedRowIds } = state;
    return onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
  }, [onSelectedRowsChange, state.selectedRowIds]);

  const handleTableScrollArrowClick = useCallback(
    ({ direction, disabled }: { direction: string; disabled: boolean }) =>
      () => {
        if (disabled || !TableWrapperRef.current) {
          return;
        }
        const left = direction === 'left' ? -200 : 200;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        TableWrapperRef.current.scrollBy({
          top: 0,
          left,
          behavior: 'smooth',
        });
      },
    [TableWrapperRef]
  );

  return (
    <RedaptiveReactTableStyles>
      {titleText && (
        <TableTitleStyled titleColor={titleColor}>{titleText}</TableTitleStyled>
      )}
      <ReactTableTopSectionStyles
        increaseBottomMargin={
          !(showTableScrollArrows && tableWidthIsOverflowing)
        }
        sideMarginWidth={sideMarginWidth}
      >
        {globalFilterable && (
          <GlobalFilter
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        {HeaderActions && <HeaderActions />}
        {showTableScrollArrows && tableWidthIsOverflowing && (
          <TableScrollStyled>
            <TableScrollArrowStyled
              disabled={tableScrollPosition.left === 0}
              onClick={handleTableScrollArrowClick({
                disabled: tableScrollPosition.left === 0,
                direction: 'left',
              })}
            >
              <UilAngleLeft color='#162447' size={20} />
            </TableScrollArrowStyled>
            <TableScrollArrowStyled
              disabled={tableScrollPosition.right <= 0}
              onClick={handleTableScrollArrowClick({
                disabled: tableScrollPosition.right <= 0,
                direction: 'right',
              })}
            >
              <UilAngleRight color='#162447' size={20} />
            </TableScrollArrowStyled>
          </TableScrollStyled>
        )}
      </ReactTableTopSectionStyles>
      <ReactTableWrapperStyles
        ref={TableWrapperRef}
        onScroll={handleTableScroll}
      >
        <ReactTableStyled {...getTableProps()}>
          {TableHead && <TableHead />}
          {!hideHeaders && !TableHead && (
            <TableHeadStyled>
              {headerGroups.map((headerGroup) => (
                <tr
                  key={headerGroup.headers}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <ThStyled
                      key={column.id}
                      id={column.id}
                      left={columnsLeftOffset[column.id]}
                      ref={tableHeaderRefs[column.id]}
                      sideMarginWidth={sideMarginWidth}
                      locked={column.locked}
                      {...column.getHeaderProps()}
                    >
                      <ThInnerStyled
                        columnMaxWidth={column.maxWidth}
                        columnMinWidth={column.minWidth}
                        className={column.className}
                      >
                        {column.hideHeader ? null : (
                          <>
                            <HeaderStyled {...column.getSortByToggleProps()}>
                              {column.render('Header')}
                              {column.canSort && (
                                <SortIconsWrapperStyled>
                                  <SortIconsStyled>
                                    {!column.isSorted && (
                                      <>
                                        <SortIconAsc
                                          size='10'
                                          color='#6C6D6E'
                                          gainsightTagId={
                                            gainsightTagBaseId
                                              ? `${gainsightTagBaseId}-${column.id}-sort-asc`
                                              : ''
                                          }
                                        />
                                        <SortIconDesc
                                          size='10'
                                          color='#6C6D6E'
                                          gainsightTagId={
                                            gainsightTagBaseId
                                              ? `${gainsightTagBaseId}-${column.id}-sort-asc`
                                              : ''
                                          }
                                        />
                                      </>
                                    )}
                                    {column.isSorted && (
                                      <>
                                        {column.isSortedDesc && (
                                          <SortIconDesc
                                            size='10'
                                            color='#6C6D6E'
                                            gainsightTagId={
                                              gainsightTagBaseId
                                                ? `${gainsightTagBaseId}-${column.id}-sort-off`
                                                : ''
                                            }
                                            sorted={column.isSorted}
                                          />
                                        )}
                                        {!column.isSortedDesc && (
                                          <SortIconAsc
                                            size='10'
                                            color='#6C6D6E'
                                            gainsightTagId={
                                              gainsightTagBaseId
                                                ? `${gainsightTagBaseId}-${column.id}-sort-desc`
                                                : ''
                                            }
                                            sorted={column.isSorted}
                                          />
                                        )}
                                      </>
                                    )}
                                  </SortIconsStyled>
                                </SortIconsWrapperStyled>
                              )}
                            </HeaderStyled>
                            {filterable && (
                              <div>
                                {column.canFilter
                                  ? column.render('Filter')
                                  : null}
                              </div>
                            )}
                          </>
                        )}
                      </ThInnerStyled>
                    </ThStyled>
                  ))}
                </tr>
              ))}
            </TableHeadStyled>
          )}
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableBodyTrStyled
                  onClick={
                    onRowClick ? (event) => onRowClick(row, event) : undefined
                  }
                  onDoubleClick={
                    onRowDoubleClick ? () => onRowDoubleClick(row) : undefined
                  }
                  key={row}
                  {...row.getRowProps(getRowProps(row))}
                >
                  {row.cells.map((cell, index) => (
                    <TdComponent
                      key={index}
                      row={row}
                      left={columnsLeftOffset[cell.column.id]}
                      sideMarginWidth={sideMarginWidth}
                      locked={cell.column.locked}
                      bgColor={'#E0E0E0'}
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}
                    >
                      {index === 0 && (
                        <TrHighlightStyled {...getRowProps(row)} />
                      )}
                      <TdInnerStyled
                        columnMaxWidth={cell.column.maxWidth}
                        columnMinWidth={cell.column.minWidth}
                        fontWeight={getCellProps(cell)?.style?.fontWeight}
                        color={getCellProps(cell)?.style?.color}
                      >
                        {cell.render('Cell')}
                      </TdInnerStyled>
                    </TdComponent>
                  ))}
                </TableBodyTrStyled>
              );
            })}
          </tbody>
        </ReactTableStyled>
      </ReactTableWrapperStyles>
      {enablePagination && (
        <PaginatorWrapperStyled>
          <Paginator
            pageSize={pageSize}
            gotoPage={gotoPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            setPageSize={setPageSize}
          />
        </PaginatorWrapperStyled>
      )}
    </RedaptiveReactTableStyles>
  );
};

export default RedaptiveReactTable7;
