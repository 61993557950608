import moment from 'moment';
import { camelCase, capitalizeFirstLetter } from '.';
import { queryStringify } from '../api/utils';
import { DATE_FORMAT_DATA_API_REQUEST, Period } from '../constants';
import {
  TSSite,
  TSSiteTabs,
  naturallySortSitesByValidName,
} from '../ducks/sites';
import {
  Grouping,
  MeasurementTypes,
  Resolution,
  ResourceType,
  TSQuerySearchParams,
} from '../ducks/types';

// these are the general / most common defaults, can override per page
export const defaultQuerySearchParams: TSQuerySearchParams = {
  period: Period.LAST_30_DAYS,
  resolution: Resolution.DAILY,
  grouping: Grouping.SITE,
  site: '',
  resourceType: ResourceType.ELECTRICITY,
  measurementTypes: MeasurementTypes.ACTIVE_ENERGY,
};

const MAX_NON_MONTHLY_DAYS = 30;
const MAX_MONTHLY_MONTHS = 6;

export const resolutionOptions = [
  { name: 'Daily', id: Resolution.DAILY },
  { name: 'Hourly', id: Resolution.HOURLY },
  { name: '15 Min', id: Resolution.FIFTEEN_MINUTES },
];

type TabFilterConfig = {
  singleSite: boolean;
  defaultValueOverrides: Partial<TSQuerySearchParams>;
};

// any overrides to the above defaults, per page
const searchParamConfigByTab: Record<TSSiteTabs, TabFilterConfig> = {
  [TSSiteTabs.BASIC_CONSUMPTION]: {
    singleSite: false,
    defaultValueOverrides: {},
  },
  [TSSiteTabs.OPERATING_HOURS]: {
    singleSite: false,
    defaultValueOverrides: { resolution: Resolution.HOURLY },
  },
  [TSSiteTabs.PEAK_USAGE]: {
    singleSite: false,
    defaultValueOverrides: { resolution: Resolution.FIFTEEN_MINUTES },
  },
  [TSSiteTabs.VOLTAGE_ANALYSIS]: {
    singleSite: true,
    defaultValueOverrides: {
      grouping: Grouping.EQUIPMENT,
      resolution: Resolution.FIFTEEN_MINUTES,
      measurementTypes: MeasurementTypes.RMS_VOLTAGE,
    },
  },
  [TSSiteTabs.ESG_REPORTING]: {
    singleSite: false,
    defaultValueOverrides: {
      period: Period.LAST_60_DAYS,
      resolution: Resolution.MONTHLY,
    },
  },
  [TSSiteTabs.BENCHMARKING]: {
    singleSite: false,
    defaultValueOverrides: {
      period: Period.LAST_6_MONTHS,
      resolution: Resolution.MONTHLY,
    },
  },
  [TSSiteTabs.PORTFOLIO_CONSUMPTION]: {
    singleSite: false,
    defaultValueOverrides: {
      period: Period.LAST_6_MONTHS,
      resolution: Resolution.MONTHLY,
    },
  },
  [TSSiteTabs.PROJECTS_INVOICE]: {
    singleSite: false,
    defaultValueOverrides: {
      period: Period.LAST_6_MONTHS,
      resolution: Resolution.MONTHLY,
    },
  },
  [TSSiteTabs.PROJECTS_PERFORMANCE]: {
    singleSite: false,
    defaultValueOverrides: {
      period: Period.LAST_6_MONTHS,
      resolution: Resolution.MONTHLY,
    },
  },
  [TSSiteTabs.METER_INSTALLATION_TRACKER]: {
    singleSite: false,
    defaultValueOverrides: {
      resourceType: ResourceType.ALL,
    },
  },
};

const compatibleGroupingsByResource: Record<ResourceType, Grouping[]> = {
  [ResourceType.ELECTRICITY]: [
    Grouping.SITE,
    Grouping.BUILDING_SYSTEM,
    Grouping.PANEL,
    Grouping.EQUIPMENT,
    Grouping.CIRCUIT,
    Grouping.CATEGORY_VALUE,
  ],
  [ResourceType.WATER]: [Grouping.METER, Grouping.SITE],
  [ResourceType.NATURAL_GAS]: [Grouping.METER, Grouping.SITE],
  [ResourceType.ALL]: [Grouping.SITE],
};

export const compatibleResolutionsByResource: Record<
  ResourceType,
  Resolution[]
> = {
  [ResourceType.ELECTRICITY]: [
    Resolution.DAILY,
    Resolution.HOURLY,
    Resolution.FIFTEEN_MINUTES,
  ],
  [ResourceType.WATER]: [Resolution.DAILY, Resolution.HOURLY],
  [ResourceType.NATURAL_GAS]: [
    Resolution.DAILY,
    Resolution.HOURLY,
    Resolution.FIFTEEN_MINUTES,
  ],
  [ResourceType.ALL]: [],
};

const compatibleResolutions: Record<TSSiteTabs, Resolution[]> = {
  [TSSiteTabs.OPERATING_HOURS]: [Resolution.HOURLY],
  [TSSiteTabs.PEAK_USAGE]: [Resolution.FIFTEEN_MINUTES],
  [TSSiteTabs.BASIC_CONSUMPTION]: [
    Resolution.DAILY,
    Resolution.HOURLY,
    Resolution.FIFTEEN_MINUTES,
  ],
  [TSSiteTabs.VOLTAGE_ANALYSIS]: [Resolution.FIFTEEN_MINUTES],
  [TSSiteTabs.ESG_REPORTING]: [Resolution.MONTHLY],
  [TSSiteTabs.BENCHMARKING]: [Resolution.MONTHLY],
  [TSSiteTabs.PORTFOLIO_CONSUMPTION]: [Resolution.MONTHLY],
  [TSSiteTabs.PROJECTS_INVOICE]: [Resolution.MONTHLY],
  [TSSiteTabs.PROJECTS_PERFORMANCE]: [Resolution.MONTHLY],
  [TSSiteTabs.METER_INSTALLATION_TRACKER]: [Resolution.MONTHLY],
};

const compatibleResource: Record<TSSiteTabs, ResourceType[]> = {
  [TSSiteTabs.BASIC_CONSUMPTION]: [
    ResourceType.ELECTRICITY,
    ResourceType.WATER,
    ResourceType.NATURAL_GAS,
  ],
  [TSSiteTabs.METER_INSTALLATION_TRACKER]: [
    ResourceType.ELECTRICITY,
    ResourceType.WATER,
    ResourceType.NATURAL_GAS,
    ResourceType.ALL,
  ],
  [TSSiteTabs.OPERATING_HOURS]: [ResourceType.ELECTRICITY],
  [TSSiteTabs.PEAK_USAGE]: [ResourceType.ELECTRICITY],
  [TSSiteTabs.VOLTAGE_ANALYSIS]: [ResourceType.ELECTRICITY],
  [TSSiteTabs.ESG_REPORTING]: [ResourceType.ELECTRICITY],
  [TSSiteTabs.BENCHMARKING]: [ResourceType.ELECTRICITY],
  [TSSiteTabs.PORTFOLIO_CONSUMPTION]: [ResourceType.ELECTRICITY],
  [TSSiteTabs.PROJECTS_INVOICE]: [ResourceType.ELECTRICITY],
  [TSSiteTabs.PROJECTS_PERFORMANCE]: [ResourceType.ELECTRICITY],
};

export const isSingleSite = (currentTab: TSSiteTabs) => {
  return currentTab === TSSiteTabs.VOLTAGE_ANALYSIS;
};

export const isMultiSite = (selectedSites: string[]) => {
  // Because empty selectedSites list means ALL
  return selectedSites.length === 0 || selectedSites.length > 1;
};

export const isGroupingLockedToSite = (
  currentTab: TSSiteTabs,
  selectedSites: string[]
) => {
  return (
    currentTab === TSSiteTabs.OPERATING_HOURS && isMultiSite(selectedSites)
  );
};

export const isBasicConsumption = (currentTab: TSSiteTabs) =>
  currentTab === TSSiteTabs.BASIC_CONSUMPTION;

export const isDailyOnly = (fromDate, toDate) => {
  const momentToDate = moment(toDate, DATE_FORMAT_DATA_API_REQUEST);
  const momentFromDate = moment(fromDate, DATE_FORMAT_DATA_API_REQUEST);
  // Leave only Daily resolution active when date range is equal OR grater than a month
  // + 1 to include the start
  return momentToDate.clone().diff(momentFromDate.clone(), 'days') + 1 > 31;
};

const validateDateRange = (
  resolution: Resolution,
  currentTab,
  { fromDate, toDate }
) => {
  // TODO: should we consider the site ingestion date?
  if (resolution === Resolution.MONTHLY) {
    const isDateDifferenceExceeded =
      moment(toDate).diff(fromDate, 'months') > MAX_MONTHLY_MONTHS;
    const isTabNotProjectsPerformanceOrInvoice =
      currentTab !== TSSiteTabs.PROJECTS_PERFORMANCE &&
      currentTab !== TSSiteTabs.PROJECTS_INVOICE;

    if (isDateDifferenceExceeded && isTabNotProjectsPerformanceOrInvoice) {
      fromDate = moment()
        .subtract(6, 'months')
        .startOf('month')
        .format(DATE_FORMAT_DATA_API_REQUEST);
      toDate = moment()
        .subtract(1, 'months')
        .endOf('month')
        .format(DATE_FORMAT_DATA_API_REQUEST);
    }
  }
  if (
    resolution === Resolution.HOURLY ||
    resolution === Resolution.FIFTEEN_MINUTES
  ) {
    // all other resolutions default to 30 days max
    if (moment(toDate).diff(fromDate, 'days') > MAX_NON_MONTHLY_DAYS) {
      fromDate = moment(toDate)
        .clone()
        .subtract(MAX_NON_MONTHLY_DAYS, 'day')
        .format(DATE_FORMAT_DATA_API_REQUEST);
    }
  }
  return {
    fromDate,
    toDate,
  };
};

export const getResolutionOptions = (
  currentTab,
  resourceType,
  { fromDate, toDate }
) => {
  const resolutionByTab =
    compatibleResolutions[currentTab ?? TSSiteTabs.BASIC_CONSUMPTION];
  const resolutionsByResource =
    compatibleResolutionsByResource[resourceType ?? ResourceType.ELECTRICITY];

  const finalResolutions = (compatibleResolution) =>
    resolutionOptions.filter(({ id }) => compatibleResolution.includes(id));

  if (currentTab === TSSiteTabs.BASIC_CONSUMPTION) {
    if (
      resourceType === ResourceType.ELECTRICITY &&
      isDailyOnly(fromDate, toDate)
    ) {
      return finalResolutions([Resolution.DAILY]);
    }
    return finalResolutions(resolutionsByResource);
  }
  return finalResolutions(resolutionByTab);
};

export const resourceTypeOptions = [
  {
    name: capitalizeFirstLetter(ResourceType.ELECTRICITY),
    id: ResourceType.ELECTRICITY,
  },
  { name: capitalizeFirstLetter(ResourceType.WATER), id: ResourceType.WATER },
  {
    name: 'Natural Gas',
    id: ResourceType.NATURAL_GAS,
  },
];

export const getUrlSearchParams = (
  urlParams: TSQuerySearchParams | Partial<TSQuerySearchParams>,
  currentTab: TSSiteTabs,
  sites: TSSite[],
  didSwitchPage: boolean
) => {
  const validatedParams = validateAndSetDefaultsForUrlParams(
    urlParams as TSQuerySearchParams,
    currentTab,
    sites,
    didSwitchPage
  );

  return queryStringify(validatedParams);
};

export const validateAndSetDefaultsForUrlParams = (
  urlParams: TSQuerySearchParams | Partial<TSQuerySearchParams>,
  currentTab: TSSiteTabs,
  sites: TSSite[],
  didSwitchPages: boolean
) => {
  const tabConfig = searchParamConfigByTab[currentTab];

  const defaultParamsForTab = {
    ...defaultQuerySearchParams,
    ...tabConfig.defaultValueOverrides, // any overrides to the defaults
  };

  const updatedParams = {
    ...defaultParamsForTab,
    ...urlParams,
  } as TSQuerySearchParams;

  const selectedSitesArray = updatedParams.site
    .split(',')
    .filter((site) => site);

  // set the resourceType first, the rest of the validation might depend on this
  updatedParams.resourceType =
    updatedParams.resourceType &&
    compatibleResource[currentTab].includes(
      updatedParams.resourceType as ResourceType
    )
      ? (updatedParams.resourceType as ResourceType)
      : compatibleResource[currentTab][0];

  // TODO: make this more generic / check valid measurement types
  urlParams.measurementTypes = defaultParamsForTab.measurementTypes;

  updatedParams.resolution =
    urlParams.resolution &&
    compatibleResolutions[currentTab].includes(
      urlParams.resolution as Resolution
    ) &&
    compatibleResolutionsByResource[updatedParams.resourceType].includes(
      urlParams.resolution as Resolution
    )
      ? (urlParams.resolution as Resolution)
      : defaultParamsForTab.resolution;

  updatedParams.grouping = compatibleGroupingsByResource[
    updatedParams.resourceType || ResourceType.ELECTRICITY
  ].includes(updatedParams.grouping)
    ? updatedParams.grouping
    : defaultParamsForTab.grouping;

  if (updatedParams.period === Period.CUSTOM) {
    const newDates = validateDateRange(updatedParams.resolution, currentTab, {
      fromDate: updatedParams?.fromDate,
      toDate: updatedParams?.toDate,
    });
    updatedParams.fromDate = newDates.fromDate;
    updatedParams.toDate = newDates.toDate;
  }

  if (isSingleSite(currentTab)) {
    // include the length check in case of refreshing on the voltage page
    if (
      sites.length &&
      (!updatedParams.site || selectedSitesArray.length > 1)
    ) {
      // pick the first one alphabetically, either from the selected sites or all sites if none selected
      const sortedSites = [...sites].sort(naturallySortSitesByValidName);
      updatedParams.site = updatedParams.site
        ? sortedSites.filter(
            (site) => (updatedParams.site || '').indexOf(site.id) >= 0
          )[0].id
        : sortedSites[0].id;
      // also clearing out equipment filters, these equipments could belong to a different site
      // than the one we're prefilling now:
      updatedParams.equipment = '';
    }
    if (didSwitchPages) {
      updatedParams.grouping = Grouping.EQUIPMENT;
    }
  }

  if (isGroupingLockedToSite(currentTab, selectedSitesArray)) {
    updatedParams.grouping = Grouping.SITE;
    updatedParams.groupingId = '';
  }
  if (isDailyOnly(updatedParams.fromDate, updatedParams.toDate)) {
    updatedParams.resolution = Resolution.DAILY;
  }
  return updatedParams;
};

export const getTabFromPageName = (pageName: string) => {
  return (camelCase(pageName) as TSSiteTabs) ?? TSSiteTabs.BASIC_CONSUMPTION;
};
