import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { energyStarApis } from './apis';
import {
  benchmarkingMapper,
  customerStatusMapper,
  espmMeterMapper,
  espmMeterResponseMapper,
  pendingPropertiesMapper,
  submissionsMapper,
  WPT_CUSTOMER_ID,
} from './utils/mapper';
import { demoUserMonthlySummaryPayload } from './utils/demoUserMapper';
import { isCurrentUserRedaptiveDemoUser } from '../../utils/demo';
import { ConnectPropertyMapped, TSEnableESCustomerPayload } from './types';
import { useSelector } from 'react-redux';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../../ducks/customers';
import { ES_COORDINATOR_GROUP_ID } from '../../constants/strings';
import { useUserHasPermissionGroup } from '../permissions';
import { useSearchParams } from 'react-router-dom';
import { getDateRange, getRequestMonthRange } from '../../api/utils';
import { useMemo } from 'react';
import { useThousandsSeparatorPreference } from '../settings';

const energyStarQueryKey = (queryNames: string[]) => {
  return ['energy-star', ...queryNames];
};

const sharedOptions = ({ queryName }: { queryName: string[] }) => ({
  queryKey: energyStarQueryKey(queryName),
  staleTime: 1000 * 20, // Cache for 20Sec,
});

export const useEnergyStarSitesCountQuery = () => {
  const { currentCustomerId: customerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );

  return useQuery({
    ...queryOptions({
      queryFn: () => energyStarApis.fetchEnabledSitesCount(customerId),
      ...sharedOptions({ queryName: [customerId, 'enabled-sites'] }),
      enabled: !!customerId,
    }),
    select: (data) => {
      return customerStatusMapper(data, customerId);
    },
  });
};

const useCommonData = () => {
  const queryClient = useQueryClient();

  const { data: userIsEsCoordinator = false } = useUserHasPermissionGroup(
    ES_COORDINATOR_GROUP_ID
  );

  const { data: thousandsSeparator } = useThousandsSeparatorPreference();

  const { currentCustomerId: customerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );

  const { data: customerStatusData } = useEnergyStarSitesCountQuery();

  return {
    userIsEsCoordinator,
    customerId,
    customerStatusData,
    thousandsSeparator,
    queryClient,
    isESEnabled: customerStatusData?.isEnabled,
  };
};

export const useOnboardingAccountDataQuery = ({
  currentAccountId,
}: {
  currentAccountId?: string;
} = {}) => {
  const { userIsEsCoordinator, customerStatusData, customerId } =
    useCommonData();

  const { energyStarCustomerId } = customerStatusData ?? {};

  const accountId = energyStarCustomerId ?? currentAccountId;

  return useQuery({
    ...queryOptions({
      queryFn: () =>
        energyStarApis.fetchOnboardingConnectAccount({ accountId }),
      ...sharedOptions({
        queryName: [customerId, 'onboarding'],
      }),
      enabled: !!accountId && userIsEsCoordinator,
    }),
  });
};

export const useBenchmarkingDataQuery = () => {
  const { customerId, thousandsSeparator, isESEnabled } = useCommonData();

  return useQuery({
    ...queryOptions({
      queryFn: () => energyStarApis.fetchBenchmarkingData(customerId),
      ...sharedOptions({ queryName: [customerId, 'benchmarking'] }),
      enabled: !!customerId && isESEnabled,
    }),
    select: (data) => {
      return benchmarkingMapper(data, customerId, thousandsSeparator);
    },
  });
};

export const useSummaryDataQuery = () => {
  const { customerId, isESEnabled } = useCommonData();

  return useQuery({
    ...queryOptions({
      queryFn: () => energyStarApis.fetchSummaryData(customerId),
      ...sharedOptions({ queryName: [customerId, 'summary'] }),
      enabled: !!customerId && isESEnabled,
    }),
    select: (data) => {
      if (isCurrentUserRedaptiveDemoUser() && customerId === WPT_CUSTOMER_ID) {
        return demoUserMonthlySummaryPayload(data);
      }
      return data;
    },
  });
};

export const useSubmissionDataQuery = () => {
  const { isESEnabled, customerId, thousandsSeparator } = useCommonData();
  const [searchParams] = useSearchParams();
  const serializeFormQuery = useMemo(() => {
    return Object.fromEntries(searchParams);
  }, [searchParams]);

  const { fromDate, toDate, period } = serializeFormQuery;
  const { startDate, endDate } = getDateRange(fromDate, toDate, period);
  const { from: startYearMonth, to: endYearMonth } = getRequestMonthRange(
    startDate.toString(),
    endDate.toString()
  );

  const combinedData = {
    startYearMonth,
    endYearMonth,
    customerId,
    thousandsSeparator,
  };

  return useQuery({
    ...queryOptions({
      queryFn: () => energyStarApis.fetchSubmissionsData(combinedData),
      ...sharedOptions({
        queryName: [customerId, 'submissions', startYearMonth, endYearMonth],
      }),
      enabled:
        !!customerId &&
        !!startYearMonth &&
        !!endYearMonth &&
        isESEnabled &&
        (!!period || (!!fromDate && !!toDate)),
    }),
    select: (data) => {
      const { mappedData, listOfMonths, allSitesDownloadJSON } =
        submissionsMapper(data, combinedData, customerId, thousandsSeparator);
      return {
        originalData: data,
        mappedData,
        listOfMonths,
        allSitesDownloadJSON,
      };
    },
  });
};

export const usePendingPropertiesQuery = () => {
  const { customerStatusData, isESEnabled, userIsEsCoordinator, customerId } =
    useCommonData();

  const { energyStarCustomerId } = customerStatusData ?? {};

  return useQuery({
    ...queryOptions({
      queryFn: () =>
        energyStarApis.fetchPendingProperties(energyStarCustomerId),
      ...sharedOptions({
        queryName: [customerId, 'pending-properties'],
      }),
      enabled: !!energyStarCustomerId && isESEnabled && userIsEsCoordinator,
    }),
    select: (data) => {
      return pendingPropertiesMapper(data);
    },
  });
};

export const useMeterMappingDataQuery = () => {
  const { customerId, isESEnabled, userIsEsCoordinator } = useCommonData();

  return useQuery({
    ...queryOptions({
      queryFn: () => energyStarApis.fetchESPMMeterMapping(customerId),
      ...sharedOptions({
        queryName: [customerId, 'meter-mapping'],
      }),
      enabled: !!customerId && isESEnabled && userIsEsCoordinator,
    }),
    select: (data) => {
      return espmMeterMapper(data);
    },
  });
};

export const useAttachPropertiesMutation = () => {
  const queryClient = useQueryClient();
  const { customerId } = useCommonData();

  return useMutation({
    mutationFn: ({
      reqData,
      customerId,
    }: {
      reqData: ConnectPropertyMapped[];
      customerId: string;
    }) =>
      energyStarApis.attachProperties(
        espmMeterResponseMapper(reqData, customerId)
      ),
    onSettled: () => {
      const toBeInvalidatedQueries = [
        'enabled-sites',
        'onboarding',
        'meter-mapping',
        'pending-properties',
      ];

      toBeInvalidatedQueries.forEach((query) => {
        queryClient.invalidateQueries({
          queryKey: energyStarQueryKey([customerId, query]),
          exact: false,
        });
      });
    },
  });
};

export const useUpdateEnergyStarCustomerMutation = () => {
  const queryClient = useQueryClient();
  const { customerId } = useCommonData();

  return useMutation({
    mutationFn: (payload: TSEnableESCustomerPayload) =>
      energyStarApis.updateESCustomer(payload),
    onSettled: () => {
      const toBeInvalidatedQueries = ['enabled-sites', 'onboarding'];

      toBeInvalidatedQueries.forEach((query) => {
        queryClient.invalidateQueries({
          queryKey: energyStarQueryKey([customerId, query]),
          exact: false,
        });
      });
    },
  });
};
