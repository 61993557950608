import styled from 'styled-components';

const GENERIC_MESSAGE = 'Something went wrong. Please refresh & try again.';

const IS_BUILT_APP = import.meta.env.PROD;

export const ErrorMessageStyles = styled.div<{
  hide: boolean;
}>`
  color: ${({ theme }) => theme.colors.error};
  margin: ${({ hide }) => (hide ? '0' : '20px 0')};
  height: ${({ hide }) => (hide ? '0' : '19px')};
`;

interface TSProps {
  collapseWhenEmpty?: boolean;
  className?: string;

  /* The error message to display */
  message: string;

  /* In some cases, we want to mask the error message from the server and simply
  display a generic message.
  If the app has been built and maskError is true, it will display the generic message.
  If the app is in development mode and maskError is true, it will pass through the
  message from the server (for debugging purposes). */
  maskError?: boolean;
}
const ErrorMessage = ({
  message = '',
  maskError = false,
  collapseWhenEmpty = false,
  ...rest
}: TSProps) => {
  return (
    <ErrorMessageStyles
      hide={!message && collapseWhenEmpty}
      {...rest}
    >
      {message && maskError && IS_BUILT_APP && GENERIC_MESSAGE}
      {(!IS_BUILT_APP || !maskError) && message}
    </ErrorMessageStyles>
  );
};

export default ErrorMessage;
