import { AxiosError } from 'axios';
import { trim } from 'lodash';
import moment, { Moment } from 'moment';
import { useSearchParams } from 'react-router-dom';
import { Action } from 'redux';
import { put } from 'redux-saga/effects';
import { getLoginToken } from '.';
import {
  DATE_FORMAT_DATA_API_REQUEST,
  DATE_RANGE_PICKER_MONTHLY_FORMAT,
  DATE_YEAR_MONTH_FORMAT_API_REQUEST,
  Period,
} from '../constants';
import {
  ERR_BAD_REQUEST,
  ERR_CONFLICT_ERROR,
  ERR_CONNECTIVITY,
  ERR_CONTENT_TOO_LARGE,
  ERR_FORBIDDEN,
  ERR_OTHER,
  ERR_REQUEST_ERROR,
  ERR_REQUEST_TIMEOUT,
  ERR_SERVER_ERROR,
  ERR_UNAUTHORIZED,
  NETWORK_ERROR,
  STORAGE_CUSTOMER_SELECTED_QUERY_ID,
  STORAGE_IMPERSONATE_CUSTOMER,
  STORAGE_USERNAME,
  STORAGE_USER_ID,
} from '../constants/api';
import {
  Grouping,
  Resolution,
  TSDateRange,
  TSQuerySearchParams,
} from '../ducks/types';
import { locationSearchToObject } from '../utils';
import { getOptionsDates } from '../utils/optionsDates';

// API Utils

export const getUsername = () => {
  return localStorage.getItem(STORAGE_USERNAME) || '';
};

export const getUserId = () => {
  return localStorage.getItem(STORAGE_USER_ID) || '';
};

export const getCurrentSelectedQueryId = () => {
  return localStorage.getItem(STORAGE_CUSTOMER_SELECTED_QUERY_ID) ?? '';
};

export const isAuthenticated = () => !!getLoginToken();

export function getCurrentCustomerId(): any {
  // check the url for a customerId passed via deep link:
  const locationSearchObj = locationSearchToObject(location.search);
  if (locationSearchObj.customerId) {
    return locationSearchObj.customerId;
  } else {
    return sessionStorage.getItem(STORAGE_IMPERSONATE_CUSTOMER);
  }
}

export const queryStringify = (params: any): any => {
  const filtered = {};
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value === null || value === undefined) {
      return;
    }
    filtered[key] = value;
  });

  return new URLSearchParams(filtered);
};

export const buildURLSearchQuery = (
  dateRange: TSDateRange,
  searchRequest: TSQuerySearchParams
): string => {
  const {
    grouping = Grouping.SITE,
    resolution = Resolution.DAILY,
    site = '',
    panel,
    buildingSystem,
    equipment,
    circuit,
    categoryValue,
    groupingId = null,
    meter,
  } = searchRequest;
  return queryStringify({
    ...dateRange,
    resolution,
    grouping,
    site,
    panel,
    buildingSystem,
    equipment,
    circuit,
    categoryValue,
    groupingId,
    meter,
  });
};

export const getDateRange = (
  fromDate?: string,
  toDate?: string,
  period?: string,
  programStartDate?: Moment
) => {
  const optionsDates = getOptionsDates({ programStartDate });
  const momentFromDate = moment(fromDate).format(DATE_FORMAT_DATA_API_REQUEST);
  const momentToDate = moment(toDate).format(DATE_FORMAT_DATA_API_REQUEST);

  const [startDate, endDate]: [Moment, Moment] =
    period !== Period.CUSTOM
      ? optionsDates[period ?? Period.LAST_30_DAYS]
      : [momentFromDate, momentToDate];
  return { startDate, endDate };
};

export const useDateRangeLabel = () => {
  const [queryParams] = useSearchParams();
  const { fromDate, toDate, period } = Object.fromEntries(
    queryParams.entries()
  );
  const { startDate, endDate } = getDateRange(fromDate, toDate, period);
  const momentFromDate = moment(startDate, DATE_FORMAT_DATA_API_REQUEST);
  const momentToDate = moment(endDate, DATE_FORMAT_DATA_API_REQUEST);
  const startText = momentFromDate.format(DATE_RANGE_PICKER_MONTHLY_FORMAT);
  let text = startText;
  const endText = endDate
    ? momentToDate.format(DATE_RANGE_PICKER_MONTHLY_FORMAT)
    : '';
  if (endDate && endText !== startText) {
    text = `${text} - ${endText}`;
  }
  return text;
};

export const getRequestRange = (fromDate: Moment, toDate: Moment): any => ({
  fromDate: moment(fromDate)
    .startOf('day')
    .format(DATE_FORMAT_DATA_API_REQUEST),
  toDate: moment(toDate).endOf('day').format(DATE_FORMAT_DATA_API_REQUEST),
});

export const getRequestMonthRange = (
  fromDate: string,
  toDate: string
): any => ({
  from: moment(fromDate)
    .startOf('day')
    .format(DATE_YEAR_MONTH_FORMAT_API_REQUEST),
  to: moment(toDate).endOf('day').format(DATE_YEAR_MONTH_FORMAT_API_REQUEST),
});

interface Data {
  messages: string;
  message: string;
}

const somethingWentWrong = 'Something went wrong. Please try again.';
// Error handler
export const handleAxiosError = (error: AxiosError<Data>) => {
  if (error.request && !error.request.status) {
    throw new Error(somethingWentWrong);
  }

  if (error.response) {
    const resp = error.response;
    if (resp.status === 403) {
      throw new Error('You are not authorized to perform this action.');
    } else if (resp.status === 401) {
      throw new Error(ERR_UNAUTHORIZED);
    } else if (resp.status === 400) {
      throw new Error(ERR_BAD_REQUEST);
    } else if (resp.status === 504) {
      throw new Error('The request took too long. Please try again.');
    } else if (resp.status >= 500) {
      throw new Error(somethingWentWrong);
    } else if (resp.status === 413) {
      throw new Error(ERR_CONTENT_TOO_LARGE);
    } else if (resp.status === 409) {
      throw new Error(ERR_CONFLICT_ERROR);
    } else if (resp.data) {
      throw new Error(
        resp.data.messages || resp.data.message || somethingWentWrong
      );
    } else {
      throw new Error(somethingWentWrong);
    }
  } else if (error.request) {
    throw new Error(somethingWentWrong);
  } else if (error.message === NETWORK_ERROR) {
    throw new Error(somethingWentWrong);
  } else {
    throw new Error(error.message);
  }
};

const getErrorMessage = (error) => {
  const { message } = error;
  switch (message) {
    case ERR_REQUEST_TIMEOUT:
      return 'The request took too long. Please try again.';
    case ERR_SERVER_ERROR:
    case ERR_REQUEST_ERROR:
    case ERR_CONNECTIVITY:
    case ERR_OTHER:
      return somethingWentWrong;
    case ERR_FORBIDDEN:
      return 'You are not authorized to perform this action.';
    default:
      return message;
  }
};

export function* handleSagaError(
  action: string | (({ error }: { error: string }) => Action),
  error: Error,
  props?: Record<string, unknown>
): Generator<any, void, any> {
  const message = getErrorMessage(error);
  if (typeof action === 'string') {
    yield put({ type: action, error: message, ...props });
  } else {
    yield put(action({ error: message }));
  }

  if (error.message === ERR_SERVER_ERROR) {
    yield put({ type: 'SERVER_ERROR' });
  }
}

export const isErrorUnauthorized = (error: string) => {
  return error === ERR_UNAUTHORIZED;
};

export const pipe =
  <T>(...fns) =>
  (x: T) =>
    fns.reduce((v, f) => f(v), x);

export const lower = (value: string) => value.toLowerCase();

export const lowerTrim = pipe<string>(lower, trim);
