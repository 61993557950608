import BookmarkIcon from '@iconscout/react-unicons/icons/uil-bookmark';
import { Button, Menu, Text } from '@mantine/core';

import BookmarkFullIcon from '../../../../components/Icons/BookmarkFullIcon';
import { useSavedFilters } from '../../../../queries/savedFilters';
import { useActiveSavedFilter } from '../../utils/savedFilters';
import SavedFilterMenuItem from './MenuItem';
import styles from './styles.module.css';
import { SavedFiltersMenuProps } from './types';

const SavedFiltersMenu = ({
  isOpen,
  openActionMenuId,
  openDeleteConfirmation,
  openSaveQueryModal,
  setOpenActionMenuId,
  toggleMenu,
}: SavedFiltersMenuProps) => {
  const { data: savedFilters = [] } = useSavedFilters();
  const activeSavedFilter = useActiveSavedFilter();

  return (
    <Menu
      width={220}
      position='bottom-end'
      withArrow
      arrowSize={8}
      arrowOffset={16}
      offset={4}
      opened={isOpen}
      onChange={toggleMenu}
      trapFocus={false}
    >
      <Menu.Target>
        <Button
          c='black'
          data-gainsight-id='data-explorer-saved-filters-menu'
          variant='transparent'
          leftSection={
            activeSavedFilter ? (
              <BookmarkFullIcon height={14} width={14} />
            ) : (
              <BookmarkIcon size={16} />
            )
          }
          size='compact-md'
          maw={460}
        >
          <Text span truncate>
            {activeSavedFilter?.name ?? 'Saved Filters'}
          </Text>
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label className={styles.menuLabel} h={24}>
          Saved Filters
        </Menu.Label>
        {savedFilters.map((query) => (
          <SavedFilterMenuItem
            id={query.id}
            key={query.id}
            name={query.name}
            selected={activeSavedFilter?.id === query.id}
            isActionMenuOpen={openActionMenuId === query.id}
            toggleActionMenu={() =>
              setOpenActionMenuId((openId) =>
                openId === query.id ? null : query.id
              )
            }
            openSaveQueryModal={() => {
              openSaveQueryModal(query.id);
            }}
            openDeleteConfirmation={() => {
              openDeleteConfirmation(query.id);
            }}
            onClose={toggleMenu}
          />
        ))}
        <Menu.Divider />
        <Menu.Item
          data-gainsight-id='data-explorer-new-saved-filter-button'
          disabled={!!activeSavedFilter}
          fz={14}
          h={32}
          leftSection={<BookmarkFullIcon width={18} />}
          onClick={() => {
            openSaveQueryModal(null);
          }}
        >
          Save Filter
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default SavedFiltersMenu;
