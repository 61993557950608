import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { defaultHeaders, gcApiBaseUrl } from '../api';
import { getUserId, handleAxiosError } from '../api/utils';

// Types
export type CurrencyPreferences = 'USD' | 'EUR' | 'GBP' | 'CAD';
export type SystemsOfMeasurement = 'imperial' | 'metric';
export type ThousandsSeparators = 'period' | 'comma';

export interface PreferencesResponse {
  currencyPreference?: CurrencyPreferences;
  displayUnitsPreference?: SystemsOfMeasurement;
  id: string;
  unitFormatPreference?: ThousandsSeparators;
  userId: string;
}

// API Calls
const fetchUserPreferences = () => {
  const url = `${gcApiBaseUrl()}/gc/account/settings`;
  return axios
    .get<PreferencesResponse>(url, { headers: defaultHeaders() })
    .then(({ data }) => data)
    .catch(handleAxiosError);
};

const patchUserPreferences = async (
  userId: string,
  preferences: Partial<PreferencesResponse>
) => {
  const url = `${gcApiBaseUrl()}/gc/account/settings`;
  return axios
    .put(url, preferences, { headers: defaultHeaders() })
    .then(({ data }) => data)
    .catch(handleAxiosError);
};

// Queries
const sharedOptions = (userId) => ({
  queryKey: ['preferences', userId],
  queryFn: () => fetchUserPreferences(),
  staleTime: 1000 * 60 * 60, // Cache for 1hr
  enabled: !!userId,
});

export const useUserPreferences = () => {
  const currentUserId = getUserId();
  return useQuery(sharedOptions(currentUserId));
};

export const useThousandsSeparatorPreference = () => {
  const currentUserId = getUserId();
  const query = useQuery({
    ...sharedOptions(currentUserId),
    select: (data) => data.unitFormatPreference,
  });
  return { ...query, data: query.data || 'comma' };
};

export const useCurrencyPreference = () => {
  const currentUserId = getUserId();
  const query = useQuery({
    ...sharedOptions(currentUserId),
    select: (data) => data.currencyPreference,
  });
  return { ...query, data: query.data || 'USD' };
};

export const useDisplayUnitsPreference = () => {
  const currentUserId = getUserId();
  const query = useQuery({
    ...sharedOptions(currentUserId),
    select: (data) => data.displayUnitsPreference,
  });
  return { ...query, data: query.data || 'imperial' };
};

// Mutations
export const useUpdateUserPreferences = () => {
  const queryClient = useQueryClient();
  const currentUserId = getUserId();

  return useMutation({
    mutationFn: (preferences: Partial<PreferencesResponse>) =>
      patchUserPreferences(currentUserId, preferences),
    onSuccess: (updates: PreferencesResponse) => {
      queryClient.setQueryData(['preferences', currentUserId], updates);
    },
  });
};
