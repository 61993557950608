import { Text } from '@mantine/core';
import { Alert } from '../../components/mantine/Alert/Alert';

export const QueryError = ({
  status,
  message,
}: {
  status?: number;
  message: string;
}) => (
  <Alert title='The has been an error processing the request.' type='red'>
    <Text ff='monospace' c='gray.7' fz='sm'>
      {status && `${status} - `}
      {message}
    </Text>
  </Alert>
);
