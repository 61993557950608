import EstateIcon from '@iconscout/react-unicons/icons/uil-estate';
import { ScrollArea, Stack } from '@mantine/core';
import { MenuItem } from '.';
import CustomerSelector from './CustomerSelector';
import ExpandedNavItem from './ExpandedNavItem';
import Logo from './Logo';
import NavLink from './NavLink';
import styles from './NewMainNav.module.css';

type NavProps = {
  navContent: MenuItem[];
};

const ExpandedNavContent = ({ navContent }: NavProps) => {
  return (
    <>
      <Stack h='calc(100vh - 68px)' gap='0'>
        <Stack pl='lg' pr='lg' pt='lg' pb='sm' gap='1.5rem'>
          <Logo />
          <CustomerSelector />
          <NavLink
            mt='auto'
            to='/'
            icon={<EstateIcon size={18} />}
            label='Home'
            fw={600}
            gainsightId='primary-nav-home'
          />
        </Stack>

        <ScrollArea type='hover'>
          <Stack p='lg' pt='sm' pb='0' gap='0'>
            <Stack gap='xs'>
              {navContent.map((item) => (
                <ExpandedNavItem key={item.label} {...item} />
              ))}
            </Stack>
          </Stack>
        </ScrollArea>
      </Stack>

      {/* Background for 'Need some help?' button */}
      <Stack h={68} className={styles.navFooter} />
    </>
  );
};

export default ExpandedNavContent;
