/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { camelCase } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { defaultHeaders, denaliApiBaseUrl } from '../../api/index';
import {
  handleAxiosError,
  handleSagaError,
  queryStringify,
} from '../../api/utils';
import circuitsMockMetaData from '../../mockData/circuits/circuitsMeta';
import { TSMetaState } from '../types';

import { isVariantActive } from '../../utils/variants';

export interface TSGetCircuitMetaData {
  customerId: string;
  site: string;
  panel?: string;
  buildingSystem?: string;
  equipment?: string;
  categoryValue?: string;
  resourceType: string;
}

interface TSCircuitMetaValue {
  name: string;
  id: string;
}

interface TSCircuitMetaResponse {
  fieldType: string;
  name: string;
  id: string | null;
  values: Array<TSCircuitMetaValue>;
}

export type TSCircuitsMetaResponse = {
  results: Array<TSCircuitMetaResponse>;
};

export interface TSCircuitsMetaData extends TSCircuitMetaResponse {
  id: string;
}

interface TSCircuitMetaDataState extends TSMetaState {
  noCircuitMetaData: boolean;
}

export interface TSCircuitsMetaEntityState {
  items: Array<TSCircuitsMetaData>;
  meta: TSCircuitMetaDataState;
}

interface TSState {
  entities: {
    circuitMetaData: TSCircuitsMetaEntityState;
  };
}

export const types = {
  FETCH_CIRCUIT_META_DATA: 'FETCH_CIRCUIT_META_DATA',
  FETCH_CIRCUIT_META_DATA_SUCCESS: 'FETCH_CIRCUIT_META_DATA_SUCCESS',
  FETCH_CIRCUIT_META_DATA_ERROR: 'FETCH_CIRCUIT_META_DATA_ERROR',
};

export const actions = {
  fetchCircuitMetaData: (payload: TSGetCircuitMetaData): any => ({
    type: types.FETCH_CIRCUIT_META_DATA,
    ...payload,
  }),
};

export const initialState: TSCircuitsMetaEntityState = {
  items: [],
  meta: {
    loading: false,
    error: '',
    noCircuitMetaData: false,
  },
};

function entityItems(action, state) {
  const newItems: Array<TSCircuitsMetaData> = Object.values(action.payload);
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems);
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_CIRCUIT_META_DATA:
      return initialState.items;
    case types.FETCH_CIRCUIT_META_DATA_SUCCESS:
      return entityItems(action, state);
    default:
      return state;
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_CIRCUIT_META_DATA:
      return {
        ...state,
        error: '',
        loading: true,
        noCircuitMetaData: false,
      };
    case types.FETCH_CIRCUIT_META_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_CIRCUIT_META_DATA_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noCircuitMetaData: !action.payload[0],
      };
    default:
      return state;
  }
}

export default combineReducers({
  items,
  meta,
});

export const selectCircuitMetaDataEntity = (
  state: TSState
): TSCircuitsMetaEntityState => state.entities.circuitMetaData;

export const enhanceCircuitGroup = (
  circuitGroup: TSCircuitMetaResponse
): TSCircuitsMetaData => ({
  ...circuitGroup,
  id: circuitGroup.id ? circuitGroup.id : camelCase(circuitGroup.name),
});

export const API = {
  fetchCircuitMetaData: ({
    customerId = '',
    site = '',
    panel = '',
    buildingSystem = '',
    equipment = '',
    categoryValue = '',
    resourceType,
  }: TSGetCircuitMetaData) => {
    if (isVariantActive('mock')) {
      const mockData = circuitsMockMetaData();
      return Promise.resolve(mockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      );
    }

    const query = queryStringify({
      site,
      panel,
      buildingSystem,
      equipment,
      categoryValue,
    });

    const url = `${denaliApiBaseUrl()}/customers/${customerId}/metadata/${resourceType}?${decodeURIComponent(
      query
    )}`;

    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSCircuitsMetaResponse }) => data)
      .catch(handleAxiosError);
  },
};

function* fetchCircuitMetaDataSaga({
  type,
  ...payload
}: TSGetCircuitMetaData & { type: string }): Generator<any, void, any> {
  try {
    const circuitsMetaData: TSCircuitsMetaResponse = yield call(
      API.fetchCircuitMetaData,
      payload
    );
    yield put({
      type: types.FETCH_CIRCUIT_META_DATA_SUCCESS,
      payload: circuitsMetaData?.results.map((group) =>
        enhanceCircuitGroup(group)
      ),
    });
  } catch (e) {
    yield handleSagaError(types.FETCH_CIRCUIT_META_DATA_ERROR, e as Error);
  }
}

export const sagas = [
  takeLatest(types.FETCH_CIRCUIT_META_DATA, fetchCircuitMetaDataSaga),
];
